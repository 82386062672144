<template>
<article>
  <section>
    <img src="https://estiem.blob.core.windows.net/estiem-images/logo_estiem_alumni.png" />
    <h1>About us</h1>
    <p>ESTIEM Alumni is a network of industrial engineering and management professionals, who share experiences and keep the friendship alive.</p>
  </section>
  <section v-if="(authenticated || authenticatedSSO) && !alumnus && this.estiemer.alumniStatus === 1">
    <h1>Apply for Alumni or Junior Alumni</h1>
    <b-form @submit.prevent="applyAlumni" v-if="isReady">
      <h2>General</h2>
      <strong>{{ estiemer.portalUserUserName }}</strong>
      <label for="email">
        <p>Email</p>
        <b-form-input
          id="email"
          type="email"
          v-model="estiemerForm.EmailAddress"
        />
      </label>
      <label for="mobilePhone">
        <p>Mobile Phone</p>
        <b-form-input
          id="mobilePhone"
          type="tel"
          v-model="estiemerForm.mobilePhone"
          placeholder="+34 123 45 67 89"
          maxlength="150"
        />
      </label>
      <label for="birthday">
        <p>Birthday</p>
        <b-form-input
          id="birthday"
          type="date"
          v-model="estiemerForm.birthday"
          maxlength="10"
        />
      </label>
      <h2>Why do you want to join ESTIEM Alumni</h2>
      <b-form-textarea
        id="description"
        v-model="estiemerForm.ApplicationText"
        placeholder="Enter your application"
        rows="3"
        max-rows="6"
      >
      </b-form-textarea>
      <h2>Your ESTIEM and study background</h2>
      <label for="startStudies">Start of Studies
        <b-form-input
        id="startStudies"
        type="date"
        v-model="estiemerForm.studiesStart"
        maxlength="10"
        />
      </label>
      <label for="endStudies">
        <p>End of Studies</p>
        <b-form-input
        id="endStudies"
        type="date"
        v-model="estiemerForm.studiesEnd"
        maxlength="10"
        />
      </label>
      <label for="estiemPositions">Former ESTIEM Position(s)</label>
      <b-form-textarea
        id="estiemPositions"
        v-model="estiemerForm.estiemPositions"
        rows="3"
        max-rows="6"
      >
      </b-form-textarea>
      <label for="estiemBackground">ESTIEM background</label>
      <b-form-textarea
        id="estiemBackground"
        v-model="estiemerForm.estiemActivities"
        rows="3"
        max-rows="6"
      >
      </b-form-textarea>
      <section>
        <div>
          <input type="radio" id="juniorAlumniMembership" name="studies" v-model="estiemerForm.HasConcludedStudies" :value="false">
          <label for="juniorAlumniMembership">Junior Alumni membership</label>
        </div>
        <div>
          <input type="radio" id="AlumniMembership" name="studies" v-model="estiemerForm.HasConcludedStudies" :value="true"/>
          <label for="AlumniMembership">Alumni membership</label>
        </div>
      </section>
      <transition name="fade">
        <section v-show="estiemerForm.HasConcludedStudies">
          <div>
            <input type="checkbox" id="memberList" name="memberList" v-model="estiemerForm.StayOnMemberList"/>
            <label for="memberList">Leave me on the ESTIEM member list</label>
          </div>
          <div>
            <input type="checkbox" id="lgList" name="lgList" v-model="estiemerForm.StayOnLgList"/>
            <label for="lgList">Leave me on my local group mailing list</label>
          </div>
          <p>You can later change those settings in the alumni portal</p>
        </section>
      </transition>
      <b-button type="sumbit" variant="primary">Apply</b-button>
    </b-form>
  </section>
  <section v-else-if="(authenticated || authenticatedSSO) && !alumnus && (this.estiemer.alumniStatus === 8 || this.estiemer.alumniStatus === 4)">
    <h1>Apply for Alumni or Junior Alumni</h1>
    <p>Your application was successfully submitted</p>
    <p>If you have any questions, please contact <a href="mailto:board.alumni@estiem.org">board.alumni@estiem.org</a>.</p>
  </section>
  <section v-else-if="(authenticated || authenticatedSSO) && alumnus">
    <h1>Congratulations! You are already an Alumni</h1>
    <b-button variant="primary" to="/events?q=alumni">Events</b-button>
    <b-button variant="primary" target="_blank" href="https://shop.spreadshirt.net/estiem-alumni/">Web shop</b-button>
    <b-button variant="primary" target="_blank" href="https://public.tableau.com/profile/estiem#!/vizhome/NumberofTravels-AlumniDashboard/Sheet1">Event heroes</b-button>
    <b-button variant="primary" v-if="isBoardAlumni" :to="{name: 'alumni-applicants'}">Check Applicants</b-button>
  </section>
  <section v-else>
    <h1>Application</h1>
    <p>If you are an ESTIEMer, please use your credentials to apply.</p>
    <p>If you do not remember your credentials please contact <a href="mailto:board.alumni@estiem.org">board.alumni@estiem.org</a>.</p>
    <p>If you just started studying, please go to <a href="/">estiem.org</a> and contact your Local Responsible.</p>
  </section>
  <section>
    <b-modal id="modal-success" centered>
      <template #modal-header>
        <h1>Alumni Application</h1>
      </template>
      <template #default>
        <strong>Your application was successfully submitted</strong>
      </template>
      <template #modal-footer>
        <router-link to="/events">
          <b-button variant="primary">Check Events</b-button>
        </router-link>
      </template>
    </b-modal>
  </section>
</article>
</template>

<script>
import axios from 'axios';
import { errorToast } from '../../helpers/toaster-helper.js';

export default {
  data() {
    return {
      estiemer: {},
      estiemerForm: {
        HasConcludedStudies: false,
        ApplicationText: '',
        EmailAddress: '',
        birthday: '',
        mobilePhone: '',
        studiesStart: '',
        studiesEnd: '',
        estiemPositions: '',
        estiemActivities: '',
        StayOnLgList: false,
        StayOnMemberList: false,
      },
      isReady: false,
      errorMsg: '',
    };
  },
  computed: {
    authenticated() {
      return this.$store.getters.authenticated;
    },
    authenticatedSSO() {
      return this.$store.getters.authenticatedSSO;
    },
    alumnus() {
      return this.$store.getters.userroles.includes('Alumni');
    },
    isBoardAlumni() {
      return this.$store.getters.userroles.includes('boardAlumni');
    },
    userId() {
      return this.$store.getters.user.userId;
    },
  },
  watch: {
    userId: {
      immediate: true, // Trigger the watcher immediately if userId is already available
      handler(newUserId) {
        if (newUserId) {
          this.fetchUser();
        }
      },
    },
  },
  methods: {
    async fetchUser() {
      const id = this.userId;
      const userUrl = `/user/${id}/full`;

      await axios.get(userUrl)
        .then((response) => {
          this.estiemer = response.data;
          // Upadte form with estiemer
          this.estiemerForm.estiemActivities = this.estiemer.estiemActivities;
          this.estiemerForm.estiemPositions = this.estiemer.estiemPositions;
          this.estiemerForm.EmailAddress = this.estiemer.portalUserEmail;
          this.estiemerForm.birthday = this.estiemer.birthday.substring(0, 10);
          this.estiemerForm.mobilePhone = this.estiemer.mobilePhone;
          this.estiemerForm.studiesStart = this.estiemer.studiesStart.substring(0, 10);
          this.estiemerForm.studiesEnd = this.estiemer.studiesEnd.substring(0, 10);
        })
        .catch(() => {
        });
      this.isReady = true;
    },
    applyAlumni() {
      const url = '/users/alumni/apply';
      const form = this.estiemerForm;
      console.log(form);
      axios.post(url, form)
        .then((response) => {
          if (response.status === 204) {
            const id = 'modal-success';
            this.$bvModal.show(id);
          }
        })
        .catch((error) => {
          if (error) {
            this.errorMsg = '';
            console.log(error);
            this.errorMsg += `Error: ${error.response.status.toString()} ${
              error.response.statusText
            }`;
            const errors = error.response.data.errors;
            const keys = Object.keys(error.response.data.errors);
            keys.forEach((key) => {
              this.errorMsg += `\n${key}: ${errors[key]}`;
            });
            return errorToast(this.$toasted, this.errorMsg);
          }
          return errorToast(this.$toasted, 'There was an error');
        });
    },
  },
};
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .8s ease;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
article {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 4rem;
}

section {
  display: flex;
  flex-direction: column;
  margin-inline: 2rem;
  row-gap: 8px;
}
section > img {
  max-width: 16rem;
  align-self: center;
}
section > form {
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;
  gap: 8px;
}

form > label {
 display: flex;
 flex-wrap: nowrap;
 justify-content: space-between;
 align-items: baseline;
 gap: 8px;
 width: 100%;
}

form section > div {
  display: flex;
  flex-direction: row;
  column-gap: 1rem;
  align-items: baseline;
}
</style>
