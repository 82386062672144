const errorOptions = {
  className: 'toaster-error',
  duration: 5000,
  theme: 'toasted-primary',
  position: 'top-right',
  action: {
    text: 'Close',
    onClick: (e, toastObject) => {
      toastObject.goAway(0);
    },
  },
};

const infoOptions = {
  className: 'toaster-info',
  duration: 2000,
  theme: 'toasted-primary',
  position: 'top-center',
  icon: 'info',
};

export const errorToast = (toaster, label) => {
  toaster.error(label, errorOptions);
  return false;
};
export const infoToast = (toaster, label) => {
  toaster.info(label, infoOptions); // create Options for info;
};
