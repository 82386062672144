<template>
<div>
    <eventHeader :event="event" :participant="participant"></eventHeader>
    <div class="container-narrow">
      <h3 style="text-align: center;">
        There are {{ qtyRegistered }} registered participants and {{ qtyWaiting }} on the waiting list
        <span v-if="event.maxparticipants"> (number of places: {{ event.maxparticipants }})</span>
        <b-button v-if ="isOrganiser || isAdmin || isBoard">
          <download-excel :fetch="fetchData"
                          worksheet="Participants"
                          name="Participants.xls"
                          v-if="isOrganiser || isAdmin">
                          Download Excel
          </download-excel>
        </b-button>
        <b-button href="#statistics" v-if ="isOrganiser || isAdmin || isBoard">Go to Applicant Statistics</b-button>
        <!--<span v-if="isOrganiser || isAdmin" class="buttons-shortcut">
          <b-button size="sm" v-b-modal.download class="button-download">Download list</b-button>
        </span> -->
      </h3>
      <div class="participants-header my-3">
        <b-form-select v-model="registrationStatus" :options="registrationStatusOptions" class="input_field"></b-form-select>
        <b-form-input v-model="searchString" placeholder="Search for names or LGs" class="input_field"></b-form-input>
        <div v-if="(event.eventType === 8 && isLR) || isAdmin  || isOrganiser || isLrOfEvent" class="add-participant-container">
        <b-button v-b-toggle="'collapse-2'" class="register-participant-toggle-button">Add participants</b-button>
        <b-collapse id="collapse-2" >
        <vue-bootstrap-typeahead  id="addParticipant"
                                  class="register-participant-search"
                                  :data="users"
                                  v-model="estiemerQuery"
                                  size="sm"
                                  :serializer="estiemer => `${estiemer.firstNameEnglish} ${estiemer.lastNameEnglish}, LG ${estiemer.localGroupName}`"
                                  placeholder="Search ESTIEMer"
                                  @hit="selectedUser = $event"
        ></vue-bootstrap-typeahead>
        <b-form-select v-if="isCm" v-model="cmRoleAdding" :options="isAdmin  || isOrganiser ? cmRolesOptions :  cmRolesOptionsLR" size="sm"></b-form-select>
          <b-button @click="registerParticipant">
            submit
          </b-button>
        </b-collapse>
      </div>
    </div>
      <spinner v-if="isEmpty"></spinner>
      <b-card-group>
        <template>
          <participant
          v-for="participant in filteredList"
          :key="participant.userId"
          :isLrOfEvent="isLrOfEvent"
          :isAdmin="isAdmin"
          :searchString="searchString"
          :participant="participant"
          @changeState="addUserToChangeStatus"
          :isCm="isCm"
            :isOrganiser="isOrganiser"
          />
        </template>
      </b-card-group>
    </div>
    <div class="event-part-options-container" v-if="isAdmin  || isOrganiser || isLrOfEvent">
      <div style="min-width: 130px">Change Status</div>
      <b-form-select v-model="selected" :options="options"></b-form-select>
      <b-button @click="changeParticipantsStatus">submit</b-button>
    </div>
    <div class="event-part-options-container" v-if=" isCm && (isAdmin  || isOrganiser)">
      <div style="min-width: 130px">Change CM Role</div>
      <b-form-select v-model="selctedCmStatus" :options="cmRolesOptions"></b-form-select>
      <b-button @click="changeParticipantsCmStatus">submit</b-button>
    </div>
    <div id="statistics">
      <TableauEmbed
      v-if="participantIdsLoaded && (isOrganiser || isAdmin || isBoard)"
      :vizData="eventSelectionCriteriaDashboardData"
      :contextualFilters="eventSelectionCriteriaDashboardDataCF"
      />
    </div>
    <!-- Modal Components -->
    <b-modal id="register" hide-footer title="Register participant">
      <div>
        <p>Still working on this functionality. To register participants, please use the <a href="https://old.estiem.org/Internal/default.aspx?PageId=518" class="modal-link">Old Portal</a>.</p>
      </div>
    </b-modal>
    <b-modal id="dns" hide-footer title="Register no-show">
      <div>
        <p>Still working on this functionality. To register no-shows, please use the <a href="https://old.estiem.org/Internal/default.aspx?PageId=518" class="modal-link">Old Portal</a>.</p>
      </div>
    </b-modal>
    <!--
    <b-modal id="download" hide-footer title="Download participants list">
      <div>
        <p>Still working on this functionality. To download the participants list, use the <a href="https://old.estiem.org/Internal/default.aspx?PageId=518" class="modal-link">Old Portal</a>.</p>
      </div>
    </b-modal>
    -->
</div>

</template>

<!-- TODO: Add filters and sorting -->
<script>
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead';
import axios from 'axios';
import _ from 'underscore';
import eventHeader from './event-header.vue';
import participant from './participant.vue';
import Spinner from '../Spinner/spinner.vue';
import downloadCsv from './download-csv.vue';
import loginPage from '../profile/login-page.vue';
import { PaxRegistrationStatus } from '../../helpers/pax-registration-status.js';
import { CmRegistrationStatus } from '../../helpers/cm-registration-status.js';
import { eventSelectionCriteriaDashboardData } from '../../helpers/tableau-embedding-data.js';
import TableauEmbed from '../dashboards/tableau-embed.vue';

export default {
  data() {
    return {
      dataForExcel: [],
      cmRoleAdding: 0,
      estiemerQuery: '',
      searchString: '',
      selectedUser: null,
      users: [],
      loadingEstiemers: false,
      registrationStatus: -1,
      selected: null,
      chosenUsers: [],
      selctedCmStatus: null,
      cmRolesOptionsLR: [{ value: 1, text: 'Delegate' }],
      cmRolesOptions: [
        { value: 0, text: 'None' },
        { value: 1, text: 'Delegate' },
        { value: 2, text: 'Board' },
        { value: 3, text: 'Working Group Leader' },
        { value: 4, text: 'Leader' },
        { value: 5, text: 'Extra Place' },
        // { value: 6, text: 'Cancelled' },
        { value: 7, text: 'Media' },
        { value: 8, text: 'Secretary' },
        { value: 9, text: 'Chairperson' },
        { value: 10, text: 'Trainer' },
        { value: 11, text: 'Voting Committee' },
        { value: 12, text: 'Project Leader' },
      ],
      options: [
        { value: 0, text: 'Registered' },
        { value: 1, text: 'Waiting' },
        { value: 2, text: 'Cancelled' },
        { value: 3, text: 'Cancelled after deadline' },
        { value: 4, text: 'Did not show up' },
        { value: 5, text: 'Organiser' },
        { value: 6, text: 'Application withdrawn' },
        { value: -1, text: 'Delete application' },
      ],
      registrationStatusOptions: [
        { text: 'Show all', value: -1 },
        { text: 'Registered', value: 0 },
        { text: 'Waiting', value: 1 },
        { text: 'Cancelled', value: 2 },
        { text: 'Cancelled after deadline', value: 3 },
        { text: 'Did not show up', value: 4 },
        { text: 'Organiser', value: 5 },
        { text: 'Application withdrawn', value: 6 },
      ],
      adminTableFields: {
        userFirstNameEnglish: {
          label: 'Name',
          sortable: true,
        },

        userLastNameEnglish: {
          label: 'Surname',
          sortable: true,
        },

        registrationDate: {
          label: 'Registration date',
          sortable: true,
        },

        userLocalGroupName: {
          label: 'Local Group',
          sortable: true,
        },

        show_details: {
          label: 'Application',
          sortable: false,
        },

        actions: {
          label: 'Actions',
          sortable: false,
        },
      },
      jwtToken: '',
      eventSelectionCriteriaDashboardData,
      participantIdsLoaded: false,
    };
  },
  props: {

    count: {
      default: 30,
    },
    organizer: {
      default: false,
    },
    registered: {
      default: false,
    },
    waiting: {
      default: false,
    },
    cancelled: {
      default: '',
    },
    isOrganiser: {
      default: false,
    },
    participantsFetched: {

    },
    eventParticipants: {
    },
    event: {

    },
    eventType: {

    },
    participant: {
      default() { return {}; },
    },
  },
  components: {
    VueBootstrapTypeahead,
    Spinner,
    participant,
    eventHeader,
    downloadCsv,
    loginPage,
    TableauEmbed,
  },
  computed: {
    participantCMStatus() {
      const cmStatuses = ['None', 'Delegate', 'Board', 'Working Group Leader', 'Leader', 'Extra Place', 'Cancelled', 'Media', 'Secretary', 'Chairperson', 'Trainer', 'Voting Committee', 'Project Leader'];
      return (cmStatuses[this.participant.registrationStatus]);
    },
    isLR() {
      return this.$store.getters.userroles.includes('lr');
    },
    isLrOfEvent() {
      if (this.$store.getters.isLR && this.$store.getters.user.localGroupId === this.event.parentGroupId) {
        return true;
      }
      return false;
    },
    isBoard() {
      return this.$store.getters.userroles.includes('board');
    },
    isAdmin() {
      return this.$store.getters.userroles.includes('Admins');
    },
    eventId() {
      return parseInt(this.$route.params.id, 10);
    },
    qtyRegistered() {
      return this.eventParticipants.filter(p => (p.registrationStatus === 0)).length;
    },
    qtyWaiting() {
      return this.eventParticipants.filter(p => (p.registrationStatus === 1)).length;
    },
    filteredList() {
      let participants = this.eventParticipants;
      participants = participants.sort((a, b) => new Date(b.registrationDate) - new Date(a.registrationDate));
      if (this.searchString) {
        participants = this.filterParticipantsBySearchstring(participants);
      }
      if (this.registrationStatus >= 0) {
        participants = this.filterParticipantsByStatus(participants);
      }
      return participants;
    },
    isEmpty() {
      return !this.participantsFetched;
    },
    isCm() {
      return this.event.eventType === 8;
    },
    isMobile() {
      return screen.width <= 450 ? 'mobile' : '';
    },
  },
  methods: {
    async fetchData() {
      this.$emit('update-participants');
      this.eventParticipants.forEach((eventParticipant) => {
        this.dataForExcel.push(this.getExcelHeaders(eventParticipant));
      });
      return this.dataForExcel;
    },
    getExcelHeaders(participantObj) {
      const excelHeaders = {
        First_Name: participantObj.userFirstNameEnglish,
        Last_Name: participantObj.userLastNameEnglish,
        Email: participantObj.userEstiemEmail,
        Participant_LG: participantObj.userLocalGroupName,
        Event_Name: participantObj.eventName,
        Event_Place: participantObj.eventPlace,
        Motivation_Text: participantObj.applicationText,
        Registration_Date: participantObj.registrationDate,
        Registration_Status: PaxRegistrationStatus[participantObj.registrationStatus],
      };
      if (this.isCm) {
        this.$set(excelHeaders, 'CM_Status', CmRegistrationStatus[participantObj.cmStatus]);
      }
      return excelHeaders;
    },
    refetchParticipants() {
      this.$emit('update-participants');
    },
    registerParticipant() {
      const body = { userId: this.selectedUser.userId, eventId: this.eventId, cmStatus: this.cmRoleAdding || 0 };
      let isRegister = false;
      this.eventParticipants.forEach((userRegister) => { // This loop check if the user you gonna register is already registered
        if (userRegister.userId === this.selectedUser.userId) isRegister = true;
      });
      if (!isRegister) { // if it is not registered --> user get register
        axios.post(`/events/${window.location.pathname.split('/')[2]}/participants`, body).then(() => {
          this.refetchParticipants();
          alert('Participants registered succesfully');
        }).catch(e => alert(`something went wrong${e}`));
      } else alert('Error: Participants already registered'); // if it is user feedback pop up
    },
    changeParticipantsStatus() {
      const body = this.chosenUsers.map(id => ({ userId: id, registrationStatus: this.selected }));
      if (this.selected < 0) {
        body.forEach((user) => {
          axios.delete(`/events/${this.$route.params.id}/participants/${user.userId}`).then(() => {
            this.refetchParticipants();
          })
            .catch((error) => {
              if (error.response) alert(error.response.data.title);
              else alert(`Something went wrong${error}`);
            });
        });
      } else {
        axios.put(`/events/${window.location.pathname.split('/')[2]}/participants/status`, body)
          .then(() => {
            this.refetchParticipants();
            alert('Participants status changed successfully');
          }).catch(e => alert(`Something went wrong: ${e}`));
      }
    },
    changeParticipantsCmStatus() {
      const body = this.chosenUsers.map(id => ({ userId: id, cmStatus: this.selctedCmStatus }));
      axios.put(`/events/${window.location.pathname.split('/')[2]}/participants/status`, body)
        .then(() => {
          this.refetchParticipants();
          alert('Participants CM role changed successfully');
        }).catch(e => alert(`something went wrong${e}`));
    },
    addUserToChangeStatus(checked, userId) {
      if (checked) {
        this.chosenUsers.push(userId);
      } else {
        this.chosenUsers = this.chosenUsers.filter(i => i !== userId);
      }
    },
    filterParticipantsBySearchstring(participants) {
      const self = this; // store vue instance for callback function
      return participants.filter((p) => {
        // Not an absolutely perfect search but fixes the current bug
        const searchName = `${p.userFirstNameEnglish.toLowerCase()} ${p.userLastNameEnglish.toLowerCase()}`;
        if (searchName.includes(self.searchString.toLowerCase())) return p;
        if (p.userLocalGroupName.toLowerCase().includes(self.searchString.toLowerCase())) return p;
        return false;
      });
    },
    filterParticipantsByStatus(participants) {
      return participants.filter(p => p.registrationStatus === this.registrationStatus);
    },
    updateContextualFilters(participants) {
      this.eventSelectionCriteriaDashboardDataCF = [
        {
          filterType: 'categorical',
          fieldName: 'EventId',
          values: [this.eventId],
          // KHAI CoM
          // values: [6314],
        },
        {
          filterType: 'categorical',
          fieldName: 'UserId',
          values: participants.map(p => p.userId),
          // KHAI CoM
          // values: [42502, 42906, 47417, 48847, 49392, 50789, 51883, 51988, 52650, 53227, 53318, 55947, 56406, 56455, 57510, 60139],
        },
      ];
      this.participantIdsLoaded = true;
      console.debug('this.eventSelectionCriteriaDashboardDataCF', this.eventSelectionCriteriaDashboardDataCF);
    },
  },
  watch: {
    // When the query value changes, fetch new results from
    // the API - in practice this action should be debounced
    estiemerQuery:
        _.debounce(function (newQuery) {
          axios
            .get(`/user/search/${newQuery}`)
            .then((res) => {
              this.users = res.data;
            });
        }, 750),
    eventParticipants: {
      handler(updatedParticipants) {
        console.debug('eventParticipants', this.eventParticipants);
        this.updateContextualFilters(updatedParticipants);
      },
      immediate: true,
    },
  },
  filters: {
    stringify(value) {
      return JSON.stringify(value, null, 2);
    },
  },
};
</script>

<style scoped>
  .mt-3{
    max-width: 100px;
    }
  .event-part-options-container{
    display: flex;
    justify-content: space-between;
    max-width: 500px;
    margin: 10px auto 20px auto;
  }
.material-icons.md-dark {
  color: rgba(32, 94, 68, 1);
}
.material-icons.md-dark.md-inactive {
  color: rgba(32, 94, 68, 0.5);
}

.card-group {
  justify-content: center;
  margin-left: 8%;
  margin-right: 8%;
  min-width: 250px;
}

.card-block {
  padding: 0.5rem 1.25rem;
}

.card-link {
  margin:0px;
}
.register-participant-search{
  max-width: 140px;
}
.register-participant-toggle-button{
  margin: 5px auto 5px;
  display: flex;
}
.add-participant-container{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 140px;
}
.container-narrow {
    padding: 10px 0;
}

.participants-header {
  display:flex;
  margin: 0 auto;
  justify-content: center;
}

.input_field {
  flex-basis: 20%;
  display: inline-block;
  margin: 5px;
  min-width: 200px;
  max-height: 40px;
}

.modal-link {
  text-decoration: underline;
}
mt-3{
  width: 100px
}
.mail-list-container {
  text-align: left;
  width: 80%;
  margin: 0 auto;
}
table {
  width: 100%;
  border-collapse: collapse;
}
th {
  text-align: center;
  width: 100%;
  padding: 10px;
  background-color: #e8eaea;
}
td {
  text-align: center;
  padding: 10px;
  border: 1px solid #ddd;
  word-wrap: break-word;
}
.tableau-container {
  width: 80%; /* Adjust the width as needed */
  margin: 0 auto; /* Center the container */
  box-sizing: border-box; /* Ensure padding is included in the width */
}
tableau-viz {
  min-height: 800px;
  position: static; /* Ensure the element is positioned correctly */
}
@media screen and (max-width: 730px) {
    .participants-header {
      display:block;
    }
    .input_field {
        margin: 0px;
    }
    th, td {
      display: block;
      width: 100%;
      box-sizing: border-box;
    }
    th {
      text-align: center;
    }
    td {
      text-align: left;
      border: none;
      border-bottom: 1px solid #ddd;
    }
}

</style>
