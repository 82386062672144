import axios from 'axios';
import Vue from 'vue';
// import request from '../../axios';

// Actions
const actions = {
  fetchPastEvents({ commit, state }, params) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    if (state.cancelToken) {
      state.cancelToken.cancel();
    }
    commit('FETCH_EVENTS_STARTED', source);
    let events;
    const categoryQuery = params.category && params.category.eventTypes && params.category.eventTypes.map(x => `&eventType=${x.value}`).join('');
    const url = `/events?futureOrPast=past&searchString=${params.searchString || ''}&pageNumber=${params.pageNumber - 1}&pageSize=20${categoryQuery || ''}`;
    axios.get(url, { cancelToken: source.token })
      .then((res) => {
        events = res.data;
        commit('FETCH_PAST_EVENTS', events);
      });
  },
  fetchEvents({ commit }, params) {
    let events;
    let url = '/events/future';
    if (params.eventtypes) {
      url += '?';
    }
    axios.get(url)
      .then((response) => {
        events = response.data;
        commit('FETCH_EVENTS', events);
      });
  },
  fetchAlumniEvents({ commit }) {
    const url = '/events/future/alumni';
    axios.get(url)
      .then((response) => {
        const events = response.data;
        commit('FETCH_ALUMNI_EVENTS', events);
      });
  },
  fetchEventParticipants({ commit }, id) {
    const url = `/events/${id}/participants`;
    axios.get(url)
      .then((response) => {
        const events = response.data;
        commit('FETCH_EVENTS', events);
      });
  },
  getUsers: (context) => {
    context.commit('fetch');
  },
  toggleSelected: ({ commit }, id) => {
    // Insert axios post request here
    commit('TOGGLE_SELECTED', id);
  },
};

// Mutations
const mutations = {
  FETCH_EVENTS(state, payload) {
    state.events = payload;
  },
  FETCH_EVENTS_STARTED(state, payload) {
    state.cancelToken = payload;
  },
  FETCH_PAST_EVENTS(state, payload) {
    state.pastEvents = payload;
  },
  FETCH_ALUMNI_EVENTS(state, payload) {
    state.alumniEvents = payload;
  },
  TOGGLE_SELECTED(state, id) {
    const event = state.events.find(e => e.id === id);
    if (!event.isSelected) {
      Vue.set(event, 'isSelected', true);
    } else {
      event.isSelected = false;
    }
  },
};

// Initial state
const state = {
  events: [],
  alumniEvents: [],
  pastEvents: [],
  cancelToken: undefined,
};

// Getters
const getters = {
  events: () => state.events,
  pastEvents: () => state.pastEvents,
  alumniEvents: () => state.alumniEvents,
  internalEvents: () => state.events,
  event: (state, getters) => id => state.events.find(event => event.id === Number(id)), // eslint-disable-line
  getEventById: (state, getters) => (id) => { // eslint-disable-line
    const event = state.events.find(event => event.id === Number(id)); // eslint-disable-line
    return event;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
