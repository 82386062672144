<template>
  <div v-if="!isAuthenticated" class="sign-up-page">
  <br>
    <h1 class="title">Apply to become an ESTIEMer</h1>
    <div class="entry-text">
      You can apply if you are a student and a member of an ESTIEM
        <a target="_blank" href="/localgroups">Local Group</a>.
        <br>Having trouble applying? Send an email to our IT committee at  <a target="_blank" href="mailto:help.it@estiem.org">help.it@estiem.org</a>
    </div>
    <br>
    <b-form @submit.prevent="createUser" class="form">
      <div class="general">
        <b-col sm="auto">
        <h2>Account data</h2>
        <label for="lg-dropdown">Local Group <FontAwesomeIcon icon="star-of-life" style="vertical-align:middle"></FontAwesomeIcon></label>
        <b-form-select
          :options="localGroupNames"
          class="text-inputs"
          id="lg-dropdown"
          v-model="form.localGroupId"
        >
          <option disabled value="">Select Local Group *</option>
        </b-form-select>
        </b-col>
        <b-col sm="auto">
        <label for="firstName">First name (English) <FontAwesomeIcon icon="star-of-life" style="vertical-align:middle"></FontAwesomeIcon></label>
        <b-form-input
              type="text"
              id="firstNameEnglish"
              v-model="form.firstNameEnglish"
              placeholder="First name"
              maxlength="20"
              required
          />
        <label for="lastName">Last name (English) <FontAwesomeIcon icon="star-of-life" style="vertical-align:middle"></FontAwesomeIcon></label>
        <b-form-input
              type="text"
              id="lastNameEnglish"
              v-model="form.lastNameEnglish"
              placeholder="Last name"
              maxlength="20"
              required
          />
        <label for="email">Email <FontAwesomeIcon icon="star-of-life" style="vertical-align:middle"></FontAwesomeIcon></label>
        <b-form-input
              type="email"
              id="emailAddress"
              v-model="form.emailAddress"
              placeholder="Email"
              maxlength="100"
              autocomplete="off"
              required
          />
        <label for="password">Password <FontAwesomeIcon icon="star-of-life" style="vertical-align:middle"></FontAwesomeIcon></label>
        <b-form-input
              type="password"
              id="password"
              v-model="form.password"
              placeholder="Password"
              value=""
              maxlength="256"
              minlength="8"
              required
          />
        <a v-b-toggle.passwordRequirements class="clickable-text" @click="passwordRequirementsExpanded = !passwordRequirementsExpanded">
          <span v-if="passwordRequirementsExpanded">Hide password requirements</span>
          <span v-else>See password requirements</span>
        </a>
        <b-collapse id="passwordRequirements">
            <b-list-group>
            <br>
            <p>Password must contain only these characters</p>
              <b-list-group-item>A – Z</b-list-group-item>
              <b-list-group-item>a - z</b-list-group-item>
              <b-list-group-item>0 – 9</b-list-group-item>
              <b-list-group-item>@ # $ % ^ & * - _ ! + = [ ] { } | \ : ' , . ? / ` ~ " ( ) ;</b-list-group-item>
              <b-list-group-item>blank space</b-list-group-item>
            <br>
            <p>Password must have 3 out of the 4 character types</p>
              <b-list-group-item>Lowercase characters</b-list-group-item>
              <b-list-group-item>Uppercase characters</b-list-group-item>
              <b-list-group-item>Numbers (0-9)</b-list-group-item>
              <b-list-group-item>Symbols</b-list-group-item>
            </b-list-group>
        </b-collapse>
        <br>
        <!--
        <input
          type="radio"
          id="isAlumni"
          name="isAlumni"
          v-model="form.isAlumni"
        />
        <label for="isAlumni"
        >I have finished my university studies (or I will finish them soon) and I want to apply to the ESTIEM Alumni </label>
        <br/>
        -->
        <!--this option doesn't actually do anything - it just makes sure that nobody selects Alumni by chance-->
        <!--
        <input
          type="radio"
          id="isStudent"
          name="isStudent"
        />
        <label for="isStudent"
        >I am a student </label>
        -->
        <br>
        <h2>Profile data</h2>
        <label for="gender" required>Gender <FontAwesomeIcon icon="star-of-life" style="vertical-align:middle"></FontAwesomeIcon></label>
        <select v-model="form.gender">
          <!-- <option value='d'>Diverse</option> -->
          <option disabled selected value> --- </option>
          <option value='m'>Male</option>
          <option value='f'>Female</option>
          <!-- ESTIEM isn't ready for this yet (: -->
          <!-- <option value='non-binary'>Non-binary</option> -->
          <!-- <option value='none'>Prefer not to say</option> -->
        </select>
        <br>
        <label for="phone">Phone <FontAwesomeIcon icon="star-of-life" style="vertical-align:middle"></FontAwesomeIcon></label>
        <b-form-input
              v-model="form.mobilePhone"
              type="text"
              id="phone"
              placeholder="+47 12345678"
              maxlength="20"
              required
        />
        <label for="birthday">Date of birth <FontAwesomeIcon icon="star-of-life" style="vertical-align:middle"></FontAwesomeIcon></label>
        <b-form-input
              class="date-inputs"
              v-model="form.birthday"
              type="date"
              id="birthday"
              placeholder="DD-MM-YYYY"
              maxlength="10"
              required
          />
        <label for="studyStart">Start of studies <FontAwesomeIcon icon="star-of-life" style="vertical-align:middle"></FontAwesomeIcon></label>
        <b-form-input
             class="date-inputs"
             v-model="form.studiesStart"
             type="date"
             id="studyStart"
             placeholder="DD-MM-YYYY"
             maxlength="10"
             required
        />
        <label for="studyEnd">Estimated end of studies <FontAwesomeIcon icon="star-of-life" style="vertical-align:middle"></FontAwesomeIcon></label>
        <b-form-input
              class="date-inputs"
              v-model="form.studiesEnd"
              type="date" id="studyEnd"
              placeholder="DD-MM-YYYY"
              maxlength="10"
              autocomplete="off"
              required
        />
      <br>
        <a v-b-toggle.optionalInputs class="clickable-text" @click="optionalProfileDataExpanded = !optionalProfileDataExpanded">
          <span v-if="optionalProfileDataExpanded">Hide optional fields</span>
          <span v-else>See optional fields</span>
        </a>
        <b-collapse id="optionalInputs">
          <label for="city">Hometown</label>
          <b-form-input
                v-model="form.hometown"
                type="text"
                id="hometown"
                placeholder="e.g. Oslo"
                maxlength="150"
          />
          <label for="about">About you</label>
          <b-form-textarea
              v-model="form.interests"
              id="about"
              placeholder="Describe yourself, your interests, hobbies, believes...">
          </b-form-textarea>
          <label for="about">ESTIEM background</label>
          <b-form-textarea
              v-model="form.estiemActivities"
              id="estiem-background"
              placeholder="Tell other ESTIEMers about your favourite experiences, events or achievements">
          </b-form-textarea>
          <label for="nickName">Nickname</label>
          <b-form-input
                v-model="form.nickName"
                type="text"
                id="nickName"
                placeholder="nickname"
                autocomplete="off"
          />
          <label for="skype">Skype</label>
          <b-form-input
                v-model="form.skypeName"
                type="text"
                id="skype"
                placeholder="@your_skype"
                autocomplete="off"
          />
          <label>Instagram</label>
          <b-form-input
                v-model="form.instagram"
                type="text"
                id="instagram"
                placeholder="@your_instagram"
                autocomplete="off"
          />
          <label for="linkedin">LinkedIn</label>
          <b-form-input
                v-model="form.linkedin"
                type="text"
                id="linkedin"
                placeholder="linkedin.com/in/<yourAccount>"
                autocomplete="off"
          />
          <label for="twitter">Twitter</label>
          <b-form-input
                v-model="form.twitter"
                type="text"
                id="twitter"
                placeholder="@your_twitter"
                autocomplete="off"
          />
          <label>Snapchat</label>
          <b-form-input
                v-model="form.snapchat"
                type="text"
                id="snapchat"
                placeholder="@your_snapchat"
                autocomplete="off"
          />
              </b-collapse>
        </b-col>
      </div>
      <br>
      <input
        type="checkbox"
        id="policyAccepted"
        name="policyAccepted"
        v-model="form.policyAccepted"
      />
      <label for="policyAccepted"
        >I confirm that I've read and accepted ESTIEM's
        <a href="http://internal.estiem.org/privacy-policy" class="link" target="_blank"
          >Privacy Policy</a
        >.</label
      ><br />
      <spinner v-if="spinnerClicked" :small="true"></spinner>
      <b-alert v-model="showErrorMsg" dismissible variant="danger">Could not create user: {{ editErrorMsg }}</b-alert>
      <b-alert v-on:dismissed="this.goMain" :show="successMsgDismissCountdown" fade @dismissed="successMsgDismissCountdown=0" @dismiss-count-down="this.countdownchanged" variant="success" id="alSuc">
      Account has been created successfully <br>
      <p> 1. Check your mailbox and follow the verification link we sent you </p>
      <p v-if="!this.form.isAlumni"> 2. wait until the Local Responsible confirms your account </p>
      <p v-if="this.form.isAlumni"> 2. Alumni Applicant: wait until the Alumni Board confirms your account </p>
      You will be redirect to main page in {{ successMsgDismissCountdown - 1 }}...
      <!-- It can display the seconds remaining but have to change smth-->
      </b-alert>
      <div class="submit-btn">
      <b-button v-show="toggle" type="submit" variant="primary" >Sign Up</b-button>
      <!-- <b-button v-show="toggle" type="submit" variant="primary" v-on:click="createUser">Sign Up</b-button> -->
      </div>
    </b-form>
    <b-modal ref="lgVWI" hide-footer title="Disclaimer">
      <div>
        <p>If you are a VWI and a ESTIEM member you do not need an account with VWI "Local Group", just use your ESTIEM Local Group Account</p>
      </div>
    </b-modal>
  </div>
  <div v-else class="sign-up-page">
      <router-link class="url" to="/">
        <h1>
          You are already logged in. You do not need to sign-up.
        </h1>
    </router-link>
  </div>
</template>

<script>
import axios from 'axios';
import { formatNumber } from 'libphonenumber-js';
import { errorToast } from '../../helpers/toaster-helper.js';
import spinner from '../Spinner/spinner.vue';

export default {
  data() {
    return {
      toggle: true,
      passwordRequirementsExpanded: false,
      optionalProfileDataExpanded: false,
      localgroups: [],
      form: {
        firstNameEnglish: '',
        lastNameEnglish: '',
        emailAddress: '',
        birthday: '',
        mobilePhone: '',
        interests: '',
        estiemActivities: '',
        studiesStart: '',
        studiesEnd: '',
        skypeName: '',
        hometown: '',
        instagram: '',
        linkedin: '',
        localGroupId: '',
        policyAccepted: false,
        gender: '',
        isAlumni: false,
        twitter: '',
        snapchat: '',
      },
      createdUserID: '',
      successMsgDismissCountdown: 0,
      successMsgDismissSeconds: 10,
      showErrorMsg: false,
      editErrorMsg: '',
      spinnerclicked: false,
    };
  },
  components: {
    spinner,
  },
  methods: {
    checkForm() {
      let isValid = true;
      const regex = /^[A-Za-z]+$/;
      const currentPhone = formatNumber(this.form.mobilePhone, 'International');

      // Basic field validations
      if (!this.form.localGroupId) isValid = errorToast(this.$toasted, 'Local Group must not be empty.');
      if (!this.form.firstNameEnglish || !this.form.lastNameEnglish) isValid = errorToast(this.$toasted, 'First name and Last name must be filled in');
      if (!regex.test(this.form.firstNameEnglish) || !regex.test(this.form.lastNameEnglish)) {
        isValid = errorToast(this.$toasted, 'Please use just English characters for first and last name');
      }
      if (!this.form.emailAddress) isValid = errorToast(this.$toasted, 'Email must not be empty.');
      if (!this.form.gender) isValid = errorToast(this.$toasted, 'Gender must not be empty.');
      if (this.form.policyAccepted !== true) isValid = errorToast(this.$toasted, 'To sign up, accept the Privacy Policy.');
      if (!currentPhone) isValid = errorToast(this.$toasted, 'Mobile phone must start from the country code, for example +XY or 00XY.');

      // Password validation
      if (!this.form.password) isValid = errorToast(this.$toasted, 'Password is required.');
      if (this.form.password.length < 8 || this.form.password.length > 256) {
        isValid = errorToast(this.$toasted, 'Password should contain a minimum of 8 characters and a maximum of 256 characters.');
      }

      // Ensure only allowed characters are used
      const allowedCharactersRegex = /^[A-Za-z0-9@#$%^&*()_+\-=[\]{}|\\:;"',.<>/?`~!\s]*$/;
      if (!allowedCharactersRegex.test(this.form.password)) {
        isValid = errorToast(this.$toasted, 'Password should contain only valid characters: A-Z, a-z, 0-9, symbols, and spaces.');
      }

      // Check for 3 out of 4 character types
      const hasLowercase = /[a-z]/.test(this.form.password);
      const hasUppercase = /[A-Z]/.test(this.form.password);
      const hasNumber = /\d/.test(this.form.password);
      const hasSymbol = /[@#$%^&*()_+\-=[\]{}|\\:;"',.<>/?`~!]/.test(this.form.password);

      const characterTypeCount = [hasLowercase, hasUppercase, hasNumber, hasSymbol].filter(Boolean).length;
      if (characterTypeCount < 3) {
        isValid = errorToast(this.$toasted, 'Password must include at least 3 of the following: lowercase, uppercase, numbers, or symbols.');
      }

      return isValid;
    },
    fetchLGs() {
      axios.get('/localgroups')
        .then((response) => {
          this.localgroups = response.data
            .sort((a, b) => {
              if (a.name > b.name) return 1;
              if (b.name > a.name) return -1;
              return 0;
            })
            .filter(lg => lg.status !== 8);
        });
    },
    createUser() {
      if (this.checkForm()) {
        this.toggle = false;
        this.spinnerClicked = true;
        const form = {
          emailAddress: this.form.emailAddress,
          firstName: this.form.firstNameEnglish,
          lastName: this.form.lastNameEnglish,
          firstNameEnglish: this.form.firstNameEnglish,
          lastNameEnglish: this.form.lastNameEnglish,
          gender: this.form.gender,
          password: this.form.password,
          localGroupId: this.form.localGroupId,
          isAlumni: this.form.isAlumni,
          birthday: this.form.birthday,
          mobilePhone: this.form.mobilePhone,
          policyAccepted: this.form.policyAccepted,
          interests: this.form.interests,
          estiemActivities: this.form.estiemActivities,
          nickName: this.form.nickname,
          studiesStart: this.form.studiesStart,
          studiesEnd: this.form.studiesEnd,
          skypeName: this.form.skypeName,
          hometown: this.form.hometown,
          twitter: this.form.twitter,
          snapchat: this.form.snapchat,
          instagram: this.form.instagram,
          linkedin: this.form.instagram,
        };
        axios.post('users', form)
          .then((response) => {
            // Check for HTTP errors
            console.log('Response:', response);
            if (response.status >= 200 && response.status < 300) {
              this.createdUserID = response.data.userId.toString();
              this.spinnerClicked = false;
              this.showSuccessMsg();
            } else {
              // Throw an error to be caught in the catch block
              const error = new Error(`Request failed with status code ${response.status}`);
              error.response = response;
              throw error;
            }
          })
          .catch((error) => {
            console.log('Error:', error);
            console.log('Error Response:', error.response);

            this.spinnerClicked = false;
            this.toggle = true;
            this.showErrorMsg = true;

            if (error.response) {
              // Access the response data
              const errorData = error.response.data;
              console.log('Error Data:', errorData);

              // Extract the error messages
              let errorMessages = '';

              if (errorData.errors) {
                // Loop through the errors object
                // eslint-disable-next-line no-restricted-syntax
                for (const [field, messages] of Object.entries(errorData.errors)) {
                  // eslint-disable-next-line no-loop-func
                  messages.forEach((msg) => {
                    errorMessages += `${field}: ${msg}\n`;
                  });
                }
              } else if (errorData.message) {
                errorMessages = errorData.message;
              } else {
                errorMessages = 'An unknown error occurred.';
              }

              // Set the error message to display
              this.editErrorMsg = `${error.response.status} ${error.response.statusText}:\n${errorMessages}`;
            } else if (error.request) {
              // The request was made but no response was received
              this.editErrorMsg = 'No response received from the server.';
            } else {
              // Something else caused the error
              this.editErrorMsg = error.message;
            }
          });
      }
    },

    // eslint-disable-next-line no-unused-vars
    countdownchanged(successMsgDismissCountdown) {
      this.successMsgDismissCountdown = successMsgDismissCountdown;
    },
    showSuccessMsg() {
      this.successMsgDismissCountdown = this.successMsgDismissSeconds;
    },
    goMain() {
      this.$router.push({ name: 'homepage' });
    },
    openDisclaimer() {
      setTimeout(
        () => {
          if (this.form.localGroupId === 77) this.$refs.lgVWI.show();
        }, 100);
    },
  },
  computed: {
    isAuthenticated() {
      return this.$store.getters.authenticated;
    },
    localGroupNames() {
      let lgNames = this.localgroups.map(lg => ({
        text: lg.name,
        value: lg.id,
      }));
      lgNames = lgNames.concat([{ text: 'No Local Group', value: 121 }]);
      return lgNames;
    },
  },
  created() {
    this.fetchLGs();
  },
  mounted() {
    document.getElementById('lg-dropdown').addEventListener('change', this.openDisclaimer);
    return null;
  },
};
</script>

<style scoped>
.sign-up-page {
  display: flex;
  flex-direction: column;
  padding:10px;
  align-self: center;
}

.url {
  justify-content: center;
  text-decoration:none;
}

.title {
  align-self: center;
}

.entry-text {
  align-self: center;
}

.form {
  align-self: center;
}

.submit-btn {
  align-self: center;
}

.clickable-text {
  color: #0275d8!important; /*the color of the other links in the portal*/
  cursor: pointer;
  font-weight: bold;
}

h1 {
  margin-bottom: 30px!important;
  color: black!important;
  font-size: 36px!important;
  font-style: bold!important;
}
h2 {
  color: black!important;
  font-size: 24px!important;
  margin-bottom: 10px;
}
h3 {
  margin-bottom: 40px;
  color: black;
  font-size: 20px;
}

@media screen and (max-width: 1024px) {
  h1 {
    margin-bottom: 0.5rem;
  }
  h2 {
    margin-bottom: 0.5rem;
  }
  h3 {
    margin-bottom: 0.5rem;
  }
  p{
    margin-bottom: 0.5rem;
  }
}


</style>
