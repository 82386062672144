<template>
  <div class="card" style="flex-grow:1; flex-basis:25%;">
    <router-link class="card-link" :to="'/profile/' + userName" style="display: flex; ">
      <div class="card-block" style="order:1; flex-shrink: 2; width:110px;">
        <!-- <img v-if="estiemer.userThumbNail==!null" style="margin: auto; height:80px;
 display: inline-block;" src="https://m.popkey.co/987552/NGLb3.gif" /> -->
        <img style="margin: auto; max-width: 80px; max-height: 80px; display: inline-block;"
             :src="this.profileImg">
      </div>
      <div class="card-block" style="padding: 10px 5px 10px 5px; order:2; flex-grow: 2; color:rgba(32, 94, 68, 1);">
        <h3>{{ estiemer.firstNameEnglish }} {{ estiemer.lastNameEnglish }}</h3>
          <i class="material-icons md-dark" style="vertical-align:middle;">location_city</i> {{ estiemer.localGroupName }} <br>
        <span v-if="estiemer.alumniStatus == 1">
          <i class="material-icons md-dark" style="vertical-align:middle" >account_circle</i> Student Member
        <br></span>
        <span v-if="estiemer.alumniStatus == 2">
          <i class="material-icons md-dark" style="vertical-align: middle;">supervisor_account</i> Alumni Member
          <br>
        </span>
        <span v-if="this.userEmail" style="display: block; max-width: 180px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
          <i class="material-icons" style="vertical-align: middle;">email</i> {{ this.userEmail }}@estiem.org
        </span>

        <span v-if="estiemer.mobilePhone">
            <i class="material-icons" style="vertical-align:middle">phone</i> {{ estiemer.mobilePhone }}
        <br></span>
      </div>
    </router-link>
  </div>
</template>

<script>
import moment from 'moment';
import axios from 'axios';

export default {
  data() {
    return {
      isSelected: false,
      profileImg: '',
    };
  },
  filters: {
    formatdate(value) {
      if (value) {
        return moment(value).format('DD.MM.YYYY');
      }
      return '';
    },
  },
  props: {
    estiemer: {},
  },
  methods: {
    getProfileImage() {
      const id = this.estiemer.userId;
      axios.get(`/files/profile/${id}`)
        .then((response) => {
          if (response.data.imageUrls.length > 0) { this.profileImg = response.data.imageUrls[0]; } else {
            axios.get('files/profile/99999').then((response2) => {
              this.profileImg = response2.data.imageUrls[0];
            });
          }
        });
    },
  },
  computed: {
    userName() {
      return this.estiemer.portalUserUserName.replace(' ', '%20');
    },
    userEmail() {
      const userName = this.estiemer.portalUserUserName.toLowerCase();
      return userName.replace(/ /g, '.');
    },
  },
  created() {
    this.getProfileImage();
  },
};
</script>

<style scoped>
.card {
  max-width: 400px;
}
.material-icons.md-dark { color: rgba(32, 94, 68, 1); }
.material-icons.md-dark.md-inactive { color: rgba(32, 94, 68, 0.5); }
.faicon { color: rgba(32, 94, 68, 1); }

</style>
