export const EventCategory = {
  ACADEMIC: 'academic',
  ALL: '',
  ALUMNI: 'alumni',
  CAREER: 'career',
  CENTRAL: 'central',
  CULTURAL: 'cultural',
  EXCHANGE: 'exchange',
  OTHER: 'other',
  PERSONAL: 'personal',
};
