<template>
    <tr v-bind:class="{ 'selected': selectedUsers.check }"
        :id='applicant.userId'
        @click="onClickSelect"
        v-if="(applicant.alumniStatus===1 && localResponsible) || admin"
    >
      <td>
        <!-- <div v-if="selectedUsers.check">&check;</div> -->
        <input type="checkbox" :checked="selectedUsers.check" @click.stop="toggleCheckbox" />
      </td>
      <td>{{ applicant.firstNameEnglish }}</td>
      <td>{{ applicant.lastNameEnglish }}</td>
      <td>{{ this.localGroup }}</td>
      <td>{{ applicant.email }}</td>
      <td v-if="isEmailVerified">Yes</td>
      <td v-else>No</td>
      <td>{{ applicant.mobilePhone }}</td>
      <td>
        <div v-if="applicant.alumniStatus===8"> Yes </div>
        <div v-else> No</div>
      </td>
      <td>{{ this.creationDate }}                           </td>
      <td>{{ this.deletionDate }}                           </td>
<!--      <td>{{applicant.localGroupId}}                        </td>-->

    </tr>
</template>

<script>
import moment from 'moment';

export default {
  name: 'signUpItem',

  data() {
    return {
      selectedUsers: {
        value: [],
        check: false,
      },
      lg: {},
    };
  },

  props: {
    applicant: {
      type: Object,
      required: true,
    },
    localGroups: {
      default: [],
    },
  },

  computed: {
    localResponsible() {
      return this.$store.getters.isLR;
    },
    admin() {
      return this.$store.getters.isAdmin;
    },
    localGroup() {
      const obj = this.localGroups[this.localGroups
        .findIndex(groupId => groupId.id === this.applicant.localGroupId)];
      if (obj !== undefined) {
        return obj.name;
      }
      return 'unknown LG';
    },

    isEmailVerified() {
      return this.applicant.mailStatus === 1;
    },

    creationDate() {
      return moment(this.applicant.created).format('DD.MM.YYYY');
    },
    deletionDate() {
      return moment(this.applicant.deletionDate).format('DD.MM.YYYY');
    },
  },

  methods: {
    onClickSelect() {
      this.$emit('checkboxChanged', this.applicant.userId, this.applicant.alumniStatus, !this.selectedUsers.check);
      this.selectedUsers.check = !this.selectedUsers.check;
    },
    toggleCheckbox(event) {
      event.stopPropagation();
      this.selectedUsers.check = !this.selectedUsers.check;
      this.$emit('checkboxChanged', this.applicant.userId, this.applicant.alumniStatus, this.selectedUsers.check);
    },
  },

  mounted() {
    this.$root.$on('SelectedAll', (status) => {
      if (this.selectedUsers.check !== status) {
        this.selectedUsers.check = status;
        this.$emit('checkboxChanged', this.$props.applicant.userId, this.applicant.alumniStatus, status);
      }
    });
  },
};
</script>

<style scoped>
td{
  font-size: 2vh;
  text-align: center;
  border-collapse: collapse;
  border: 1px solid #1d4836;
  padding: 8px;
  color: inherit;
  font-weight: inherit;
}

tr {
  height: 3vw;
  padding: .5rem 5rem;
  color: #205E44;
  cursor: pointer;
}

tr:hover{
  background-color: rgba(80,137,112, .2);
}

.selected, .selected:hover{
  background-color: rgba(80,137,112, .5);
}

</style>
