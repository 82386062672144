<template>
  <div>
    <div v-if="!small"
    class="spinner spinner-screen">
      <div class="spinner__inner" ></div>
    </div>
    <div v-if="small" class="spinner-screen-small">
      <div class="spinner__inner" ></div>
    </div>
  </div>
</template>


<script>
export default {
  name: 'spinner',
  props: {
    small: Boolean,
  },

};
</script>

<style scoped>
  @keyframes spinner {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  /* .spinner {

  } */
  .spinner-screen {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;
  }

  .spinner-screen-small {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20vh;
  }
  .spinner__inner {
    box-sizing: border-box;
    border: 2px solid transparent;
    border-radius: 100px;
    width: 50px;
    height: 50px;
    font-size: 15px;
    line-height: 28px;
    background: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)) -2px -73px;
    display: inline-block;
    border-top-color: #205e44;
    border-left-color: #205e44;
    animation: spinner 1s infinite linear;
    backface-visibility: hidden;
  }

  .spinner__inner:after {
    visibility: hidden;

    content: '\00A0'; /* &nbsp; */
  }
</style>

