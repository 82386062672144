<template>
  <div class="groups-form">
      <form @submit.prevent="fetchGroups">
      <b-row class="form-row mx-auto mb-4">
        <b-col cols="12" md="6">
          <label for="pageNumber">Page Number:</label>
          <b-form-input id="pageNumber" type="number" v-model="pageNumber" min="1"></b-form-input>
        </b-col>
        <b-col cols="12" md="6">
          <label for="pageSize">Page Size:</label>
          <b-form-input id="pageSize" type="number" v-model="pageSize" min="1"></b-form-input>
        </b-col>
        <b-col cols="12" md="6">
          <label for="adminUserId">Admin User ID:</label>
          <b-form-input id="adminUserId" type="number" v-model="adminUserId" min="1"></b-form-input>
        </b-col>
        <b-col cols="12" md="6">
          <label for="publicSubscriptionEnabled">Public Subscription Enabled:</label>
          <b-form-select id="publicSubscriptionEnabled" v-model="publicSubscriptionEnabled" :options="[{ value: null, text: 'Select...' }, { value: true, text: 'True' }, { value: false, text: 'False' }]"></b-form-select>
        </b-col>
        <b-col cols="12" md="6">
          <label for="localGroupFilter">Local Group Filter:</label>
          <b-form-select id="localGroupFilter" type="number" v-model="localGroupFilter" :options="localGroupFilterOptions"></b-form-select>
        </b-col>
        <b-col cols="12" md="6">
          <label for="lastChangedStart">Last Changed Start:</label>
          <b-form-input id="lastChangedStart" type="date" v-model="lastChangedStart"></b-form-input>
        </b-col>
        <b-col cols="12" md="6">
          <label for="lastChangedEnd">Last Changed End:</label>
          <b-form-input id="lastChangedEnd" type="date" v-model="lastChangedEnd"></b-form-input>
        </b-col>
        <b-col cols="12" md="6">
          <label for="senderRestriction">Sender Restriction:</label>
          <b-form-select id="senderRestriction" type="number" v-model="senderRestriction" :options="senderRestrictionOptions" min="0" max="2" />
        </b-col>
        <b-col cols="12">
          <label for="query">Search Query:</label>
          <b-form-input id="query" type="text" v-model="query" placeholder="Search..."></b-form-input>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" class="btn-group">
          <b-button type="submit" variant="success">Fetch Groups</b-button>
          <b-button v-if="isAdmin" :to="{ name: 'group-create'}" variant="danger">Create Group</b-button>
        </b-col>
      </b-row>
    </form>
    <div class="table-wrapper">
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Description</th>
            <th>Members Count</th>
            <th>Details</th>
            <th>Join</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(group, index) in groups" :key="group.id">
            <td>{{ group.roleId }}</td>
            <td>
              <router-link
                :to="{ name: 'group-details', params: {id: group.roleId}}"
              >
                {{ group.name }}
              </router-link>
            </td>
            <td>{{ group.description }}</td>
            <td>{{ group.membersCount }}</td>
            <td><router-link :to="{name: 'group-details', params: {id: group.roleId}}"> See more </router-link></td>
            <td><b-button v-if = "!isMembers[index]"  @click="joinThisGroup(group.publicSubscriptionEnabled, group.adminUserId,group.roleId)"
              :variant="isMembers[index] ? 'primary' : 'secondary' "
              >
                join this Group
                </b-button>
                <b-button v-if = "isMembers[index] " @click="removeMemberFromGroup(group.roleId)"
                :variant="isMembers[index] ? 'primary' : 'secondary'">
                Exit Group
                </b-button>
                <b-button v-if = "(!isMembers[index] && !group.publicSubscriptionEnabled) && !checkAdminStatus" @click="joinThisGroup(group.publicSubscriptionEnabled, group.adminUserId,group.roleId)"
                :variant="isMembers[index] ? 'primary' : 'secondary'">
                Contact Admin
                </b-button>
              </td>

          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { SenderRestrictionOptions } from '../../helpers/group-detail.js';
import { infoToast } from '../../helpers/toaster-helper.js';

export default {
  name: 'GroupsList',
  data() {
    return {
      groups: [],
      groupsUser: [],
      members: null,
      isMembers: null,
      pageNumber: 1,
      pageSize: 20,
      adminUserId: null,
      publicSubscriptionEnabled: null,
      localGroupFilter: null,
      lastChangedStart: null,
      lastChangedEnd: null,
      senderRestriction: null,
      query: null,
      senderRestrictionOptions: [...SenderRestrictionOptions],
      localgroups: [],
    };
  },
  created() {
    this.fetchGroups();
    this.fetchLocalGroups();
  },
  methods: {
    checkAdminStatus() {
      return !this.$store.getters.userroles.includes('Admins');
    },
    fetchLocalGroups() {
      axios.get('/localgroups')
        .then((response) => {
          this.localgroups = response.data
            .sort((a, b) => {
              if (a.name > b.name) return 1;
              if (b.name > a.name) return -1;
              return 0;
            })
            .filter(lg => lg.status !== 8);
        });
    },
    async fetchGroups() {
      const params = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        adminUserId: this.adminUserId,
        publicSubscriptionEnabled: this.publicSubscriptionEnabled,
        localGroupFilter: this.localGroupFilter,
        lastChangedStart: this.lastChangedStart,
        lastChangedEnd: this.lastChangedEnd,
        senderRestriction: this.senderRestriction,
        query: this.query,
      };
      axios.get('/groups', { params })
        .then(async (response) => {
          this.groups = response.data.groups;
          this.fetchGroupsUser();
        })
        .catch((error) => {
          console.error('There was an error fetching the groups: ', error);
        });
    },
    async fetchGroupsUser() {
      const userId = this.$store.getters.user.userId;
      axios.get(`/user/${userId}/groups`)
        .then((response) => {
          this.groupsUser = response.data;
          this.checkIfMember();
        })
        .catch((error) => {
          console.error('There was an error fetching the groups: ', error);
        });
    },
    async checkIfMember() {
      this.isMembers = []; // Clear the array before pushing new values
      for (const element of this.groups) {
        const userExists = this.groupsUser.some(group => group.roleId == element.roleId);

        // Push true or false to isMembers array based on the existence of the user
        this.isMembers.push(userExists);
      }
    },
    removeMemberFromGroup(roleId) {
      const userId = this.$store.getters.user.userId;
      // Assume the API accepts an array of userIds for deletion
      const payload = { userIds: [userId] };
      axios.delete(`/groups/${roleId}/users`, { data: payload })
        .then(() => {
          infoToast(this.$toasted, 'You are no longer a Member of this Group');
          this.fetchGroups();
        })
        .catch((error) => {
          console.error('Error removing group members:', error);
        });
    },
    joinThisGroup(publicSubscriptionEnabled, AdminId, roleId) {
      if (!publicSubscriptionEnabled && (!this.$store.getters.userroles.includes('Admins'))) {
      // Handle the case where no member is selected
        const userConfirmation = window.confirm(
          'You cannot add yourself to this group! We will forward you to the profile of the Admin. ' +
        "If you are okay with that, click on 'OK'. If not, click on 'Cancel'.",
        );

        if (userConfirmation) {
          // Wenn der Benutzer auf "OK" klickt, programmatisch zur Admin-Profil-Seite weiterleiten
          this.$router.push({ name: 'profile', params: { id: AdminId } });
        }

        return;
      }
      const payload = {
        userIds: [this.$store.getters.user.userId], // The API expects an array of user IDs
      };
      axios.post(`/groups/${roleId}/users`, payload)
        .then(() => {
          infoToast(this.$toasted, 'You are now a Member of this Group');
          this.fetchGroups();
        })
        .catch((error) => {
        // Handle errors
          console.error('Error adding member to group:', error);
        });
    },
  },
  computed: {
    isAdmin() {
      return this.$store.getters.userroles.includes('Admins');
    },
    localGroupFilterOptions() {
      let options = this.localgroups.map((lg) => {return { value: lg.id, text: lg.name }})
      options.unshift({ value: null, text: 'None' });
      return options;
    },
  },
};
</script>

<style scoped>
.table-wrapper {
  width: 100%;
  overflow-x: scroll;
}

table {
  border-collapse: collapse;
  width: 100%;
}

th, td {
  text-align: left;
  padding: 8px;
  border-bottom: 1px solid #ddd;
}
td a {
  color: var(--green-primary);
}
.groups-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  padding: 1rem 7%;
  background-color: #fff; /* Assuming a light background */
  border-radius: 8px; /* Rounded corners like buttons on the events page */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* A subtle shadow for depth */
  margin-bottom: 1rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 1rem;
}

.form-row > div {
  flex-basis: calc(50% - 10px); /* Adjust the percentage as necessary */
  max-width: calc(50% - 10px); /* This ensures padding and margins are accounted for */
}

.btn-group {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.submit-button {
  background-color: rgba(32, 94, 68, 1); /* Match the button color from events page */
  color: #fff;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.submit-button:hover {
  background-color: darken(rgba(32, 94, 68, 1), 10%);
}

@media screen and (max-width: 870px) {
  .form-row > div {
    flex-basis: 100%;
    max-width: 100%;
  }
}

input[type="number"], input[type="date"], input[type="text"], select {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 0.5rem;
}

input[type="number"]:focus, input[type="date"]:focus, input[type="text"]:focus, select:focus {
  outline: none;
  border-color: rgba(32, 94, 68, 1);
  box-shadow: 0 0 0 2px rgba(32, 94, 68, 0.2);
}
</style>
