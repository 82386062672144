/*
Conventional Login via the database

Client-side SSO authentication against Azure Identity. See the documentation under
https://www.npmjs.com/package/@azure/msal-browser#usage
 */

import {PublicClientApplication, InteractionRequiredAuthError} from '@azure/msal-browser';
import axios from 'axios';
import store from '../store/index.js';


import {HOST, MSAL_CONFIG, API_PERMISSION} from '../environments/index.js';

const LOGIN_URL = `${HOST}/Auth/login`;

const msalInstance = new PublicClientApplication(MSAL_CONFIG);
// those are the scopes defined on the Azure Portal
const request = {
  scopes: [API_PERMISSION],
};

const silentRequest = {
  scopes: [API_PERMISSION],
  userName: '',
};

export default {
  // Send a request to the login URL and save the returned JWT
  login(creds, redirect) {
    return new Promise((resolve, reject) => {
      axios.post(LOGIN_URL, creds, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((data) => {
          localStorage.setItem('token', data.data.data);
          localStorage.setItem('type', 'db');
          store.dispatch('login', {
            isSSO: false,
          });
          resolve();
          if (redirect) {
            this.$router.push(redirect);
          } else {
            this.$router.push('/');
          }
        })
        .catch((error) => {
          if (error.message.includes('401')) {
            reject('Your username or password is not valid. Please try again.');
          } else {
            reject('Something went wrong, it might be a server problem');
          }
        });
    });
  },
  logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('userName');
    localStorage.removeItem('filteredEventType');
    localStorage.removeItem('type');
    // Add logout to the store here
    store.dispatch('logout');
  },
  // This firstly receives an authorization code and then exchanges it for an access token
  async loginSSO() {
    try {
      await msalInstance.loginRedirect();
      // const tokenResponse = await msalInstance.acquireTokenRedirect(request);
      await msalInstance.acquireTokenRedirect(request);
    } catch (error) {
      alert(`Something went wrong. ${error}`);
    }
  },
  async handleLoginRedirect() {
    msalInstance.handleRedirectPromise().then((tokenResponse) => {
      if (tokenResponse && tokenResponse.accessToken) {
        localStorage.setItem('token', tokenResponse.accessToken);
        localStorage.setItem('sso_username', tokenResponse.account.username);
        localStorage.setItem('type', 'sso');
        store.dispatch('login', {
          isSSO: true,
        });
      }
      // Check if the tokenResponse is null
      // If the tokenResponse !== null, then you are coming back from a successful authentication redirect.
      // If the tokenResponse === null, you are not coming back from an auth redirect.
    }).catch((error) => {
      alert(`Something went wrong in handleLoginRedirect. ${error}`);
      // handle error, either in the library or coming back from the server
    });
  },
  async logoutSSO() {
    // Delete the token
    localStorage.removeItem('token');
    localStorage.removeItem('userName');
    localStorage.removeItem('sso_username');
    localStorage.removeItem('type');
    // Switch frontend to logged out
    await store.dispatch('logout');
    // Logout from Azure
    await msalInstance.logoutRedirect();
  },
  async refreshAuthToken() {
    const username = localStorage.getItem('sso_username');
    // check if the user is already logged in
    if (username) {
      try {
        const account = msalInstance.getAccountByUsername(username);
        if (!account) {
          throw new Error('No account found in cache for the provided username.');
        }
        silentRequest.account = account;

        const tokenResponse = await msalInstance.acquireTokenSilent(silentRequest);
        localStorage.setItem('token', tokenResponse.accessToken);
        location.reload();
      } catch (error) {
        if (error instanceof InteractionRequiredAuthError) {
          const tokenResponse = await msalInstance.acquireTokenPopup(request);
          localStorage.setItem('token', tokenResponse.accessToken);
          location.reload();
        } else {
          console.error('Error refreshing auth token:', error);
        }
      }
    }
  },
};
