import axios from 'axios';

/**
 * @fileoverview Helper functions for Tableau embedding.
 */

/**
 * Fetches a JWT token from the backend for embedding Tableau visualizations.
 * @param {Object} store - The Vuex store.
 * @param {string} tableauConnectedAppName - The name of the Tableau connected app defined in the Tableau Cloud.
 * @returns {Promise<string>} The JWT token for accessing Tableau visualizations as appropriate user.
 */
export async function fetchJwtToken(store, tableauConnectedAppName) {
  try {
    if (!tableauConnectedAppName) {
      // NOTE / TODO Consider moving this elsewhere, maybe to a config file
      // instead of hardcoding it here, e.g. secrets
      tableauConnectedAppName = 'TestAllProjAllDomains'; // Connected app which has full permissions. Empirically this has caused least issues.
    }
    // NOTE the endpoint called is defined here
    const url = `tableau/jwt?tableauConnectedAppName=${encodeURIComponent(tableauConnectedAppName)}`;
    // NOTE the endpoint called is defined here
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${store.getters.authToken}`,
      },
    });
    return response.data.token;
  } catch (error) {
    throw error;
  }
}

/**
 * Loads the Tableau JavaScript API script required for embedding Tableau visualizations.
 * @returns {Promise<void>} A promise that resolves when the Tableau JavaScript API script is loaded.
 */
export function loadTableauScript() {
  // Load the Tableau JavaScript API script
  // TODO consider if the dynamic loading of the script is necessary
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.type = 'module';
    script.src = 'https://eu-west-1a.online.tableau.com/javascripts/api/tableau.embedding.3.latest.min.js';
    script.onload = resolve;
    script.onerror = reject;
    document.body.appendChild(script);
  });
}

/**
 * Checks if the user is on a mobile device and adjusts the visualization attributes accordingly.
 * @param {Object} vizData - The data for the Tableau visualization, initially defined in tableau-embedding-data.js.
 * @returns {void}
 */
export function checkTableauVisualizationMobile(vizData) {
  const isMobile = /Mobile|Tablet|iPad|iPhone|Android/.test(navigator.userAgent);
  console.debug(`Is mobile device: ${isMobile}`);
  if (isMobile) {
    vizData.attributes.device = 'phone';
  }
}


/**
 * Renders a Tableau visualization in the specified container. Also applies categorical filters to the visualization.
 * @param {Object} vizData - The data for the Tableau visualization, initially defined in tableau-embedding-data.js.
 * @param {string} jwtToken - The JWT token for accessing Tableau visualizations as appropriate user.
 * @param {HTMLElement} container - The container element in which to render the Tableau visualization.
 * @returns {void}
 */
export function renderTableauVisualization(vizData, jwtToken, container) {
  const vizElement = document.createElement('tableau-viz');

  checkTableauVisualizationMobile(vizData);

  // Attributes are set based on attr defined in tableau-embedding-data.js
  Object.keys(vizData.attributes).forEach((key) => {
    vizElement.setAttribute(key, vizData.attributes[key]);
  });

  // Add JWT token as an attribute to the <tableau-viz> element
  // But only if the src is not a public Tableau URL
  if (vizData.attributes.src.includes('estiemanalysis')) {
    vizElement.setAttribute('token', jwtToken);
  } else if (vizData.attributes.src.includes('public.tableau.com')) {
    console.debug('Tableau viz is public, not setting JWT token');
  } else {
    console.debug('Tableau viz is not from estiemanalysis, attempting to set JWT token');
  }

  // Add filters as <viz-filter> elements
  // The static filters are straightforward, just add them as attributes
  // Note that these should work for categorical ones, but not sure about the others
  if (vizData.filters) {
    vizData.filters.forEach((filter) => {
      if (filter.fieldName && filter.filterType === 'categorical' && Array.isArray(filter.values)) {
        // For this special case, we can create a <viz-filter> element which
        // will be a child of the <tableau-viz> element and much simpler than
        // Applying filters using the Tableau JavaScript API
        const filterElement = document.createElement('viz-filter');
        // NOTE: Here's an assumption the predefined filters are already set correctly
        // in tableau-embedding-data.

        // Note the attribute names are different for HTML and Tableau API
        filterElement.setAttribute('field', filter.fieldName);
        filterElement.setAttribute('value', filter.values.join(','));

        vizElement.appendChild(filterElement);
      } else {
        console.error(`Filter "${String(filter)} must have both a "fieldName" and a "values".`);
      }
    });
  }
  container.appendChild(vizElement);
}


/**
 * Adds contextual filters to the source data from which the visualization is created.
 * @param {Object} vizData - The data for the Tableau visualization, initially defined in tableau-embedding-data.js.
 * @param {Array<Object>} filters - The filters to apply to the visualization. Follow specific format defined in tableau-embedding-data.js / source .vue file.
 * @returns {Object} The updated vizData object with the filters added.
 */
export function addContextualFiltersToVizData(vizData, filters) {
  // Add filters to the source data from which the viz is created
  // This happens before the viz is rendered

  // The filters are an array of objects, each object has a fieldName and values
  // The values are an array of strings
  if (!Array.isArray(filters)) {
    throw new Error('Filters must be an array');
  }
  console.debug(`Adding filters to vizData: ${JSON.stringify(filters)}`);

  filters.forEach((filter) => {
    if (filter.filterType && filter.fieldName) {
      switch (filter.filterType) {
      case 'categorical':
        if (Array.isArray(filter.values)) {
          vizData.filters.push(filter);
        } else {
          console.error(`Categorical filter "${filter.fieldName}" must have an array of values.`);
        }
        break;
      case 'hierarchical': // NOTE: This has not been tested yet!!!
        if (Array.isArray(filter.values)) {
          vizData.filters.push(filter);
        } else {
          console.error(`Hierarchical filter "${filter.fieldName}" must have an array of values.`);
        }
        break;
      case 'range':
        if (filter.minValue !== undefined && filter.maxValue !== undefined) {
          vizData.filters.push(filter);
        } else {
          console.error(`Range filter "${filter.fieldName}" must have min and max values.`);
        }
        break;
      case 'relativeDate': // NOTE: This has not been tested yet!!!
        if (filter.periodType && filter.rangeType) {
          vizData.filters.push(filter);
        } else {
          console.error(`Relative date filter "${filter.fieldName}" must have periodType and rangeType.`);
        }
        break;
      default:
        console.warn(`Unknown filter type "${filter.filterType}" for field "${filter.fieldName}". Not adding filter.`);
      }
    } else {
      console.warn(`Filter "${String(filter)}" must have a fieldName, filterType and worksheetName specified. Not adding filter.`);
    }
  });
  return vizData;
}


/**
 * Applies filters to a Tableau visualization. (range, hierarchical, relativeDate). hierarchical and relativeDate have not been tested yet.
 * @param {Object} viz - The Tableau visualization object (post-rendering).
 * @param {Array<Object>} filters - The filters to apply to the visualization. Follow specific format defined in tableau-embedding-data.js / source .vue file.
 * @returns {void}
 */
export async function applyFilters(viz, filters) {
  console.debug('Applying filters to the viz:', filters);
  const sheet = viz.workbook.activeSheet;
  if (!sheet) {
    console.error('Active sheet is not available.');
    return;
  }

  filters.forEach((filter) => {
    console.debug(`Applying filter for field "${filter.fieldName}" with type "${filter.filterType}".`);
    // Get the worksheet based on the filter's worksheetName
    let worksheets = [sheet.worksheets.find(ws => ws.name === filter.worksheetName)];
    // If the worksheet is not found, apply the filter to all worksheets
    if (!worksheets || worksheets[0] === undefined) {
      worksheets = sheet.worksheets;
    }
    worksheets.forEach((worksheet) => {
      switch (filter.filterType) {
      case 'categorical':
        // This approach would work but it takes a lot longer than the <viz-filter> approach
        // worksheet.applyFilterAsync(
        //   filter.fieldName,
        //   filter.values,
        //   'replace',
        // ).catch(error => console.error(`Error applying categorical filter: ${error}`));
        break; // These are taken care of by the <viz-filter> elements before the viz is rendered
      case 'hierarchical': // NOTE: This has not been tested yet!!!
        worksheet.applyHierarchicalFilterAsync(
          filter.fieldName,
          filter.values,
          'replace',
        ).catch(error => console.error(`Error applying hierarchical filter: ${error}`));
        break;
      case 'range':
        worksheet.applyRangeFilterAsync(filter.fieldName, {
          min: filter.minValue,
          max: filter.maxValue,
        }).catch(error => console.error(`Error applying range filter: ${error}`));
        break;
      case 'relativeDate': // NOTE: This has not been tested yet!!!
        worksheet.applyRelativeDateFilterAsync(filter.fieldName, {
          periodType: filter.periodType,
          rangeType: filter.rangeType,
        }).catch(error => console.error(`Error applying relative date filter: ${error}`));
        break;
      default:
        console.warn(`Unknown filter type "${filter.filterType}" for field "${filter.fieldName}". Not applying filter.`);
      }
    });
  });
}

/**
 * Prepares the Tableau dashboards for build by fetching the JWT token and loading the Tableau JavaScript API script. NOTE this calls other functions.
 * @param {string} jwtToken - The JWT token for accessing Tableau visualizations as appropriate user.
 */
export async function prepDashboardsForBuild(store) {
  try {
    // Fetch JWT token for Tableau by calling the backend
    const jwtToken = await fetchJwtToken(store);
    // Load the Tableau JavaScript API script required for embedding
    await loadTableauScript();
    console.debug('Tableau Embedding script loaded');

    // Dynamic updates to the dashboard data
    // Set the device to 'phone'
    // Had initially a hardcoded viz height for displayability, but that caused UI issues
    return jwtToken;
  } catch (error) {
    console.error('Error in preparing the dashboards:', error);
    throw error;
  }
}


/**
 * A helper function to pause execution at specific spot (idk how to do it properly with vue :D)
 */
export function helperDebugTrigger() {
  console.debug('Helper debug trigger');
}

// Run this on debug console to get info about the current filters of the viz
// replace 'viz-superstore-test' with the id of the viz you want to get the filters from
/*
let viz = document.getElementById('viz-superstore-test');

viz.workbook.activeSheet.getFiltersAsync()
    .then(filters => {
        const activeSheet = viz.workbook.activeSheet; // Reference the active sheet
        console.log(`Filters for sheet: ${activeSheet.name}`);
        filters.forEach(filter => {
            console.log("Filter Name:", filter.fieldName);
            console.log("Filter Type:", filter.filterType);

            if (filter.filterType === "categorical") {
                const values = filter.appliedValues.map(val => val.formattedValue).join(", ");
                console.log("Applied Values:", values);
            } else if (filter.filterType === "range") {
                console.log("Min Value:", filter.minValue);
                console.log("Max Value:", filter.maxValue);
                console.log("Domain Min:", filter.domainMin);
                console.log("Domain Max:", filter.domainMax);
            }

            console.log("Date Range Type:", filter.dateRangeType || "N/A");
            console.log("Worksheet:", filter.worksheet || "N/A");

            console.log("----------------------");
        });
    })
    .catch(error => {
        console.error(`Error retrieving filters for sheet:`, error);
    });

 */
