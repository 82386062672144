<template>
  <div class="homepage" v-bind:class="{ alumnus }">
    <div>
      <div class="card-container">
        <img
          slot="img"
          class="d-block img-fluid w-100 background-image"
          v-bind:src="'https://estiem.blob.core.windows.net/estiem-images/old_alumni.jpg'"
          alt="image slot"
        />
        <div v-if="!alumnus" class="card-block estiem-background alumni-background">
          <p class="card-text">
            <img class="event_logo" src="https://estiem.blob.core.windows.net/estiem-images/logo_estiem_alumni.png" />
          </p>
          <p class="card-text">
            <b>Stay connected</b> after your studies through our network of industrial engineering and management professionals
          </p>
          <p class="card-text">
            <b-button v-b-modal.login v-if="!authenticated">Sign in</b-button>
            <router-link :to="{name: 'alumni-join'}">
              <b-button>Join Alumni</b-button>
            </router-link>
            <b-button to="/events?q=alumni">Events</b-button>
          </p>
        </div>
        <div v-if="alumnus" class="card-block estiem-background alumni-background">
          <p class="card-text">
            <img class="event_logo" src="https://estiem.blob.core.windows.net/estiem-images/logo_estiem_alumni.png" />
          </p>
          <p class="card-text">
            <b>Stay connected</b> after your studies through our network of industrial engineering and management professionals
          </p>
          <p class="card-text">
            <b-button to="/events?q=alumni">Events</b-button>
            <b-button target="_blank" href="https://shop.spreadshirt.net/estiem-alumni/">Web shop</b-button>
            <b-button
              target="_blank"
              href="https://public.tableau.com/profile/estiem#!/vizhome/NumberofTravels-AlumniDashboard/Sheet1"
            >Event heroes</b-button>
            <router-link v-if="isBoardAlumni" :to="{name: 'alumni-applicants'}">
              <b-button>Check Applicants</b-button>
            </router-link>
          </p>
        </div>
      </div>
    </div>
    <!-- Modal Components -->
    <b-modal id="login" hide-footer title="Sign in to ESTIEM">
      <div class="signin">
        <b-form class="form" @submit.prevent="login">
          <b-alert :show="errorText != ''" variant="danger">{{ errorText }}</b-alert>
          <b-form-group>
            <b-form-input type="text" v-model="userName" required placeholder="Username" />
          </b-form-group>
          <b-form-group>
            <b-form-input type="password" v-model="password" required placeholder="Password" />
          </b-form-group>
          <b-button type="submit" @click="hideModal('login')" variant="primary">Sign in</b-button>
          <b-button type="button" @click.prevent="hideModal('login')">Cancel</b-button>
          <!-- Need functionality for lost password -->
          <b-button
            id="login_lost_btn"
            type="button"
            class="btn btn-link"
            v-b-modal.forgotten
          >Lost password?</b-button>
          <!-- In future it would be good for user to sign up themselves (followed by a final verification by LR) -->
        </b-form>
        <br />
        <p>
          To sign up, contact the Local Responsible at your
          <a href="/localgroups" class="link">university</a>
        </p>
      </div>
    </b-modal>
    <b-modal id="join" hide-footer title="Join ESTIEM">
      <p>All students of Industrial Engineering and Management (IEM) at one of our 74 universities can join ESTIEM.</p>
      <p>
        To get a user, simply contact the Local Responsible at your
        <a href="/localgroups" class="link">university</a>
      </p>
    </b-modal>
    <b-modal id="forgotten" hide-footer title="Forgotten password?">
      <p style="color:red">
        The lost password functionality is not implemented yet. Please use the old portal to
        <a
          href="https://old.estiem.org/Internal/Login.aspx"
          style="color:red; text-decoration:underline"
        >reset your password</a>
      </p>
      <p>To reset your password enter either your user name (your full name) or your email address.</p>
      <b-form-group>
        <b-form-input type="text" v-model="forgottenPassword" required placeholder="Username" />
      </b-form-group>
      <b-button type="submit" variant="primary" @click="hideModal('login')">Sign in</b-button>
      <b-button type="button" @click.prevent="hideModal('forgotten')">Cancel</b-button>
    </b-modal>
  </div>
</template>

<script>
import moment from 'moment';
import axios from 'axios';
import eventList from '../events/eventlist.vue';
import estiemerCard from './estiemer-card.vue';
import event from '../events/event.vue';
import auth from '../../auth/index.js';

export default {

  data() {
    return {
      forgottenPassword: '',
      msg: 'Welcome to the new ESTIEM',
      returnedEstiemers: [],
      searchString: '',
      pageNumber: 1,
      searchCompleted: false,
      user: {},
      lgs: [],
      greetings: ['Hello', 'Ciao', 'Hei', 'Hallo', 'Alo', 'Merhaba', 'Salut'],
      alumniGreetings: ['Cheers', 'Skål', 'Prost', 'Santé', 'Na zdrowie'],
      userName: '',
      password: '',
      errorText: '',
      userEvents: [],
      upcoming: [],
      currentEvent: {},

    };
  },

  components: {
    eventList,
    estiemerCard,
    event,
  },
  computed: {
    authenticated() {
      return this.$store.getters.authenticated;
    },
    isBoardAlumni() {
      return this.$store.getters.userroles.includes('boardAlumni');
    },
    alumnus() {
      return this.$store.getters.userroles.includes('Alumni');
    },
    userFirstName() {
      return `${this.$store.getters.user.firstNameEnglish}`;
      // currentEvent() {
      //   if (this.authenticated) {
      //     this.getUserName().then( function () {

      //       return this.$store.getters.GetCurrentEventId(this.userName)
      //     })
      //   } else {
      //     return null
      //   }
      // },
    },
    // this username is separate from the userName in data, because that one is linked to the login procedure and so it should not be a computed property
    storeUserName() {
      const name = `${this.$store.getters.user.firstNameEnglish} ${this.$store.getters.user.lastNameEnglish}`;
      if (name !== 'undefined undefined') {
        return name;
      }
      return '';
    },
    userProfile() {
      return this.storeUserName.split(' ').join('%20');
    },
    userMobile() {
      return screen.width <= 450 ? 'mobile/' : '';
    },
  },
  watch: {
    userProfile() {
      this.fetchUserEvents();
    },
  },
  methods: {
    search() {
      this.$router.push({ path: `/search?searchString=${this.searchString}` });
      // if (this.searchString.length > 1) {
      //   axios.get(`https://api.estiem.org/api/user/search/${this.searchString}?page=${this.pageNumber}`)
      //     .then((response) => {
      //       this.returnedEstiemers = response.data;
      //     });
      //   this.searchCompleted = true;
      // } else {
      // searching for a single letter makes the search on the backend two intensive
      //  alert('please enter at least 2 letters to start a search');
      // }
    },

    fetchUserEvents() {
      const eventsUrl = `/user/${this.userProfile}/events`;
      axios.get(eventsUrl)
        .then((response) => {
          this.userEvents = response.data;
          if (this.userEvents) {
            // this solution is a mess, we need to do this shit on the backend
            this.userEvents = this.userEvents.filter(a => a.registrationStatus === 0 || a.registrationStatus === 5 || a.registrationStatus === 1);
            // this.userEvents = this.userEvents.sort(this.compare);
            // this.userEvents.sort((a, b) => new Date(b.eventEndDate) - new Date(a.eventEndDate));
            const now = moment();
            this.upcoming = this.userEvents.filter(a => moment(a.eventEndDate).isSameOrAfter(now));
            this.upcoming.sort((a, b) => new Date(a.eventEndDate) - new Date(b.eventEndDate));
            this.currentEvent = this.upcoming[0];
            // this step is necessary as the viewmodel of the user events doesn't include the event Type
            axios.get(`/events/${this.currentEvent.eventId}`).then((res) => {
              this.currentEvent = res.data;
            });
          }
          this.ready = true;
        });
    },

    getApplicationOpen(events) {
      const now = moment();
      const filteredEvents = events.filter(e => (now.isSameOrAfter(e.applicationStartDate) && now.isSameOrBefore(e.applicationEndDate)));
      return filteredEvents;
    },
    getUpcoming(events) {
      const now = moment();
      const filteredEvents = events.filter(e => now.isSameOrBefore(e.endDate));
      return filteredEvents;
    },
    login() {
      const credentials = {
        userName: this.userName,
        password: this.password,
      };
      auth.login(credentials, '/alumni')
        .then()
        .catch(e => this.errorText = e); // eslint-disable-line
      this.password = '';
      // Tried finding a way that it closes the login modal if credentials are correct
      // if(localStorage.getItem('token')) {
      //  this.hideModal("login");
      // }
    },
    hideModal(id) {
      this.$root.$emit('bv::hide::modal', id);
    },
  },
  created() {
    axios.get('/localgroups')
      .then((response) => {
        this.lgs = response.data;
      });
  },
};
</script>

<style scoped>


.card-container {
  position:relative;
  text-align:center;
}


.background-image {
  height:calc(100vh - 60px);
  object-fit: cover;
}

.alumnus .background-image {
  height:calc(100vh - 70px);
}


@media screen and (min-width: 700px)  {
  .background-image {
    min-height:550px;
  }
}

@media screen and (max-width: 450px)  {
  .background-image {
    height:calc(100vh - 120px);
  }
  .alumnus .background-image {
    height:calc(100vh - 130px);
  }
}

@media screen and (min-width: 375px) and (max-width: 376px)  {
  .background-image {
    height:calc(100vh - 132px);
  }
  .alumnus .background-image {
    height:calc(100vh - 140px);
  }
}


.featured {
  margin:10px;
  font-size:1.4em;
  background-color: rgba(255,255,255,0.5)
}


.card-block {
  width: calc(100% - 40px);
  position: absolute;
  bottom: 150px;
  background-color: black;
  left: 0px;
  right: 0px;
  color: white;
  padding-left: 20px;
  padding-right: 20px;
  max-width: 600px;
  margin: 0px auto;
}

@media screen and (max-width: 350px)  {
  .card-block {
    bottom: 130px;
  }
}

.card-block a {
  color: rgb(255,255,255);
  text-decoration: underline;
}

.card-block .btn {
  color:var(--estiem-green);
  text-decoration:none;
  margin-top:5px;
}

.academic-background .btn {
  color:var(--red-primary);
}

.career-background .btn {
  color:var(--blue-primary);
}

.cultural-background .btn {
  color:var(--orange-primary);
}

.personal-background .btn {
  color:var(--green-primary);
}

.card-title {
  color: white;
}

.text-muted {
  color: white!important;
}

.featured h4 {
  font-size:34px;
  color:white;
}

.big {
  font-size:1.4em;
  color:white;
  margin-bottom:10px;
}

.big h4 {
  font-size:1.4em;
  color:white;
}

a.link {
  text-decoration: underline;
}

hr {
  border:0.3px white solid;
}

.about {
   max-width: 100%;
   padding:10px;
   background-color:var(--gray-20);
}

.mail:link {
  text-decoration:none;
  color:white;
}


@media screen and (max-width: 450px) {
    .about {
      font-size:0.8rem;
    }
    h2 {
      font-size:18px
    }
}

@media screen and (min-width: 1024px)  {
  .about {
    float:left;
    width:400px;
    min-height: calc(100vh - 100px);
  }
  .content {
    float:right;
    width: calc(100% - 400px);
  }
  .broad {
    width:100%
  }
}

.event-details {
  flex-basis:15%;
  flex-grow: 0;
  min-width: 300px;
}

.narrow {
  min-width:200px;
}


.card-deck .card {
  margin: 5px 5px;
}

.event-name {
  font-size: 16px;
}

.event-text {
  color: #fff;
}

.event-text a {
  color: #fff;
  text-decoration: underline
}

.getactive {
  vertical-align: middle;
}

.getactive .btn {
  width:150px;
  padding:10px;
  margin: 5px;
}

@media screen and (max-width: 450px) {
    .getactive .btn {
      padding:5px;
    }
}

.carousel-indicators {
  bottom:300px
  }

@media screen and (min-width: 450px) {
    .event-name, .event-text {
      font-size:16px;
    }
}

@media screen and (min-width: 1400px) {
    .event-details {
      flex-basis:24%
    }
    .event-name, .event-text {
      font-size:18px;
    }
}

@media screen and (max-width: 450px) {
    .event-name, .event-text {
      font-size:13px;
    }
}

@media screen and (max-width: 320px) {
    .event-details {
      min-width: 200px!important;
    }
}

.searchbar {
  height:50px;
  text-align: center;
}

.searchbarhp{
  margin:0px auto 20px auto;
  max-width: 400px;
}
.homepage {
    padding-top: 0px;
    padding-bottom: 0px;
}

.entrypage {
  width:100%;
  max-width:500px;
  margin:auto auto;
  text-align:center;
}

.entrypage .card {
  padding:15px;
}

.entrypage .entry-text {
  font-size:1.2em;
  color: #8890a2;
}

.event_logo {
  width:200px;
}

.academic-background .event_logo {
  width:180px;
}

@media screen and (max-width: 768px) {
  .event_logo {
    width:145px;
  }
  .academic-background .event_logo {
    width:145px;
  }
}

.partner_logo {
  width:150px;
  margin: 15px;
}


.scrolling-wrapper-flexbox {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  width: calc(100% - 5px);
  flex: 0 0 auto;
  margin-right: 3px;
  border: 1px solid white;
  min-width: 200px;
  margin: 5px;
  -webkit-overflow-scrolling: touch;
}

h2 {
  float:left;
  margin:10px;
  font-variant:small-caps;
}

.basic-background {
  background-color: var(--gray-20);
}

.basic-background .event-text {
  color: var(--green-primary);
}

.estiem, .personal {
  color: var(--green-primary)
}

.estiem-background, .personal-background {
  background-color: var(--green-primary);
  color: white;
}

.transparent-background {
  background-color: rgba(0,0,0,0.4)
}

.no-background {
  background-color: transparent;
}

.career {
  color: var(--blue-primary)
}

.career-background {
  background-color: var(--blue-primary)
}

.cultural {
  color: var(--orange-primary)
}

.cultural-background {
  background-color: var(--orange-primary)
}

.academic {
  color: var(--red-primary)
}

.academic-background {
  background-color: var(--red-primary)
}

.gray-background {
  background-color: rgb(100, 100, 100);
}

.scrolling-wrapper-flexbox::-webkit-scrollbar {
    display: none;
  }

.alert {
  margin-bottom:0px;
}

.feedback {
  text-decoration:none;
}

</style>
