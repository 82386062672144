import { EventCategory } from './event-category.js';

export const EventQuestions = {
  generalQuestionsForEvents: [
    'Introduce yourself shortly.',
    'What are your expectations regarding this event ?',
  ],
  specificQuestionsForEvents: [
    { category: EventCategory.ACADEMIC,
      specificQuestions: [
        'Do you have any previous experience in the topic ?',
      ] },
    { category: EventCategory.CULTURAL,
      specificQuestions: [
        'What is the cultural experience you would like to gain in the event ?',
        'Do you already have past experience with this culture ?',
      ] },
    { category: EventCategory.PERSONAL,
      specificQuestions: [
        'What is your personal interest in this topic ?',
        'How do you think this event will improve the quality of your daily life ?',
      ] },
    { category: EventCategory.CENTRAL,
      specificQuestions: [
        'What do you want to get from this event in terms of ESTIEM knowledge ?',
        'What do you want to achieve in Central ESTIEM after this event ?',
      ] },
    { category: EventCategory.CAREER,
      specificQuestions: [
        'How familiar are you with the topic of the event and what is your experience in the area ?',
        'How would this event impact your skills and future professional career ?',
      ] },
    { category: EventCategory.ALUMNI,
      specificQuestions: [] },
    {
      category: EventCategory.OTHER,
      specificQuestions: [
        'What is your motivation for attending this event?',
      ] },
  ],
};
