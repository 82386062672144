import vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store/index.js';
import { routes } from './routes.js'; // Import the routes from the separate file

vue.use(VueRouter);
const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes, // Use the imported routes
});

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth) {
    const token = localStorage.getItem('token');
    if (!token || token === 'null') {
      next({
        name: 'login',
      })
    } else if (to.meta.requiresAdmin) {
      const isAdmin = store.getters.userroles.includes('Admins');
      if (!isAdmin) {
        next({
          name: '404',
        })
      } else {
        next()
      }
    } else if (to.meta.requiresBoardAlumni) {
      const isBoardAlumni = store.getters.userroles.includes('boardAlumni');
      if (!isBoardAlumni) {
        next({
          name: '404',
        })
      } else {
        next()
      }
    } else {
      next()
    }
  } else next() // make sure to always call next()!
})

export default router;
