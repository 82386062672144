<template>
  <div>
<!--     <b-input-group style="margin-bottom:20px; max-width: 900px; margin: auto;">
      <b-form-input @keyup.enter.native="search" v-model="searchStringNew"></b-form-input>
      <b-input-group-button>
        <b-btn v-b-tooltip.hover title="Search">
          <i @click="search" class="material-icons" style="vertical-align:middle">search</i>
        </b-btn>
      </b-input-group-button>
    </b-input-group> -->
    <b-card-group deck style="justify-content: center;">
    <localgroup-list :searchString="searchString" :localgroups="localgroups"></localgroup-list>
    </b-card-group>
    <b-card-group deck style="justify-content: center;">
    <estiemer-list :searchString="searchString" :newSearch="newSearch"></estiemer-list>
    </b-card-group>
  </div>
</template>
<script>
import axios from 'axios';
import localgroup from '../localgroups/localgroups.vue';
import localgroupList from '../localgroups/localgrouplist.vue';
import estiemerList from './estiemer-list.vue';

export default {
  data() {
    return {
      localgroups: [],
      searchStringNew: '',
      newSearch: false,
    };
  },
  props: {
    count: {
      default: 100,
    },
  },
  components: {
    localgroup,
    localgroupList,
    estiemerList,
    /* people */
  },
  computed: {
    // returns events in chronological order, with filters applied if filters are selected
    searchString() {
      const searchString = this.$route.query.searchString;
      this.search(searchString);
      return searchString;
    },
  },
  methods: {
    search(searchString) {
      this.$router.replace({ path: `/search?searchString=${searchString}` });
      // this variable is passed to estiemerlist and watched there to trigger a new search
      this.newSearch = !this.newSearch;
    },
  },
  created() {
    this.searchStringNew = this.searchString();
    axios.get('/localgroups')
      .then((response) => {
        this.localgroups = response.data;
      });
  },

};
</script>

