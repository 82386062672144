<template>
  <div>
    <b-alert :show="verificationSuccess"
           variant="success">
    Your e-mail was successfully validated
  </b-alert>
  <b-alert :show="verificationFailure"
           variant="danger">
    Your token is invalid: {{ creationErrorMsg }}
  </b-alert>

  </div>
</template>

<!-- TODO: Add filters and sorting -->
<script>
import axios from 'axios';

export default {
  data() {
    return {
      verificationSuccess: false,
      verificationFailure: false,
      creationErrorMsg: '',
    };
  },

  methods: {
    emailVerification() {
      axios.post('/users/email-verification', { token: this.$route.query.verificationToken })
        .then(() => {
          this.verificationSuccess = true;
        })
        .catch((error) => {
          this.verificationFailure = true;
          this.creationErrorMsg = `${error.response.status.toString()} ${error.response.statusText}`;
        });
    },
  },

  mounted() {
    this.emailVerification();
  },
};

</script>


<style scoped>
div {
  text-align:center;
  width: 100%;
  height: auto;
}


</style>
