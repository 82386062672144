<template>
<div class="about-page" v-bind:class="{ mobile : isMobile }">

  <div class="card-container">
    <div class="card-block">
      <h1>European Students of Industrial Engineering and Management (ESTIEM)</h1>
      <div id="facts">
        <p>
          <strong>ESTIEM</strong> is a non-profit organisation for <span v-b-tooltip.hover title="Industrial Engineering and Management">IEM<i class="material-icons" style="vertical-align:middle">info_outline</i></span>
          students who combine technological understanding with management skills. Founded in 1990, our goal is to foster relations between IEM students and support their development.
        </p>
      </div>
      <div class="iframe-container">
        <iframe src="https://www.youtube-nocookie.com/embed/1JCuYGMUVZU" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>

    </div>

    <div class="card-block">
      <h1>Our vision is to be the connector of IEM students in Europe</h1>
      <p class="card-text">An IEM Europe is the ecosystem of companies, universities, organisations and other entities interested in the field of IEM.
        We are striving for each IEM Student to have a connection not only with other students all over Europe of the same field but also to other companies and universities,
        to ensure each student as a voice as well as the opportunity to make a difference, and to allow each outstanding innovative idea to become reality.</p>
    </div>

    <div class="card-block">
      <h1>Our values</h1>
      <img class="value-image" src="https://estiem.blob.core.windows.net/estiem-images/icon2024-EmbracingDiversity.png">
      <h2>Embracing diversity</h2>
      <p class="card-text">We see multiculturalism as a strength of ESTIEM.
        We benefit from our cultural diversity by being able to approach chances and challenges with different insights.
        Respect is not just something we strive for, but part of the very basis of our network.</p>
      <img class="value-image" src="https://estiem.blob.core.windows.net/estiem-images/icon2024-StrivingForDevelopment.png">
      <h2>Striving for development</h2>
      <p class="card-text">We grow together by trying hard and learning from mistakes, we are eager to go through a learning process and see accessibility and freedom as highest goals.
        ESTIEMers gain practical experience and important soft-skills needed in today's world</p>
      <img class="value-image" src="https://estiem.blob.core.windows.net/estiem-images/icon2024-EncouragingParticipation.png">
      <h2>Encouraging participation</h2>
      <p class="card-text">ESTIEM being a democratic organisation with a flat hierarchy encourages the participation of all its members.
        We inspire each other to partake in ESTIEM’s activities and develop new ideas</p>
      <img class="value-image" src="https://estiem.blob.core.windows.net/estiem-images/icon2024-AimingHigh.png">
      <h2>Aiming high</h2>
      <p class="card-text">We are not afraid of stepping out of our comfort zone.
        ESTIEM's members are proactive students that always give 100%.
        We encourage an entrepreneurial spirit among students and other stakeholders by gathering and exchanging experiences and best practices</p>
    </div>

  </div>
</div>
</template>

<script>

export default {
  data() {
    return {
      msg: 'Welcome to the new ESTIEM',
    };
  },
  computed: {
    isMobile() {
      return screen.width <= 450 ? 'mobile' : '';
    },
  },
  methods: {
    handleScroll() {
      const scrollPosition = window.scrollY;
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;
      const maxScroll = documentHeight - windowHeight;
      const backgroundPositionY = (scrollPosition / maxScroll) * 100; // Calculate the percentage of scroll
      document.querySelector('.about-page').style.backgroundPositionY = `${backgroundPositionY}%`;
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
    this.handleScroll(); // Initialize the background position on mount
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  }
};
</script>


<style scoped>


.background-image {
  position: absolute;
}

.about-page:not(.mobile) {
  background-image: url("https://estiem.blob.core.windows.net/estiem-images/porto2023_1920x1920.jpg");
  min-height: 100%;
  height: 2300px;
  width: 100%;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center top;
  background-size: cover;
  text-align: center;
}

.mobile {
  background-image: url("https://estiem.blob.core.windows.net/estiem-images/porto2023_1920x1920.jpg");
  min-height: 100%;
  height: 2400px;
  width: 100%;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center top;
  background-size: cover;
  text-align: center;
}

.card-block {
  width: calc(100% - 40px);
  background-color: white;
  left: 0px;
  right: 0px;
  color: black;
  padding-left: 20px;
  padding-right: 20px;
  max-width: 600px;
  margin: 30px auto;
  border: 1px solid rgb(0,0,0);
}

.value-image {
  width: 25%; /* Set the width of the images to 25% of the parent container */
}

.iframe-container {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%; /* 16:9 aspect ratio (divide 9 by 16 and multiply by 100) */
  height: 0;
  overflow: hidden;
}

.iframe-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none; /* Remove the border */
}

.card-container {
  position: absolute;
  width:100%;
}

.material-icons{
  line-height: 10px;
  margin: 0;
  padding: 0;
  font-size:16px;
  vertical-align:top;
  height: 20px;
}


  #facts {
    float:left;
    margin: 5px auto;
    text-align:center;
  }

  #facts {width:calc(100% - 10px); text-align:center; margin: 0 auto; padding:10px 0; border-bottom:1px solid #d7d7d7; border-top:1px solid #d7d7d7;}
  #facts p {margin:auto; padding:0 10px; text-align:center; color:#8890a2; font-size:15px; line-height:27px; }

@media screen and (max-width: 820px) {
    #facts { min-height:140px}
    .division {width:50%}
    #video {width:50%}
}

@media screen and (max-width: 450px) {
    #facts { min-height:230px; width:100% }
    .division {width:100%}
    #video {width:100%}
}

@media screen and (min-width: 450px) {
    .division {min-height: calc(100vh - 405px)}

}
</style>
