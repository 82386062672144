import Vue from 'vue';
import App from './App.vue';
import JsonExcel from 'vue-json-excel';
import axios from 'axios';
import {BootstrapVue, BootstrapVueIcons} from 'bootstrap-vue';
import Toasted from 'vue-toasted';
import FontAwesomeIcon from './utils/fontawesome-icons.js';

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import "leaflet/dist/leaflet.css";
import './global.css'


import './filters.js';
import store from './store/index.js';
import router from './router/index.js';
import auth from './auth/index.js';
import {HOST} from './environments/index.js';

Vue.component('FontAwesomeIcon', FontAwesomeIcon);
Vue.component('download-excel', JsonExcel);

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(Toasted);
Vue.config.productionTip = false;

// Axios Configuration
axios.defaults.baseURL = HOST;

axios.interceptors.request.use((req) => {
  req.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
  return req;
});

axios.interceptors.response.use(
  (response) => response,
  (err) => {
    if (err.config && err.response && err.response.status === 401) {
      auth.refreshAuthToken();
    }
    return Promise.reject(err);
  }
);

// Azure SSO Login Redirect
auth.handleLoginRedirect();

Vue.directive('scroll', {
  inserted(el, binding) {
    const f = function (evt) {
      if (binding.value(evt, el)) {
        window.removeEventListener('scroll', f);
      }
    };
    window.addEventListener('scroll', f);
  },
});

new Vue({
  el: '#app',
  store,
  router,
  render: (h) => h(App),
}).$mount('#app');
