<template>
  <div id="map"></div>
</template>

<script>
import L from 'leaflet';

export default {
  data() {
    return {
      center: { lat: 53.0, lng: 15.0 },
      map: null,
      markers: [],
    };
  },

  props: {
    localgroups: {
      default: [],
    },
    searchString: {
      default: '',
    },
    includeInactive: {
      default: false,
    },
  },
  computed: {
    mapLocalGroups() {
      let lgs = this.localgroups.filter((lg) => lg.gpslocation !== null); // Filter valid GPS locations

      if (!this.includeInactive && this.searchString === '') {
        lgs = lgs.filter((lg) => lg.status !== 8); // Filter inactive local groups
      } else if (this.searchString !== '') {
        const search = this.searchString.toLowerCase();
        lgs = lgs.filter((lg) =>
          lg.name.toLowerCase().includes(search) ||
        lg.country.toLowerCase().includes(search)
        );
      }

      const mapped = lgs.map((lg) => ({
        position: {
          lat: Number(lg.gpslocation.split(',')[0]),
          lng: Number(lg.gpslocation.split(',')[1]),
        },
        lg,
      }));

      return mapped;
    },
  },
  mounted() {
    this.map = L.map('map').setView(this.center, 4);
    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
      maxZoom: 18,
    }).addTo(this.map);

    this.updateMarkers();

  },
  watch: {
    mapLocalGroups: {
      handler: 'updateMarkers', // Trigger marker update on computed change
      immediate: true,
    },
  },

  methods: {
    updateMarkers() {
      // Remove all existing markers from the map
      this.markers.forEach((marker) => this.map.removeLayer(marker));
      this.markers = []; // Clear the markers array

      // Add new markers based on mapLocalGroups
      const ESTIEMicon = L.icon({
        iconUrl: 'https://estiem.blob.core.windows.net/estiem-images/marker.png',
        iconSize: [40, 40],
        iconAnchor: [20, 40],
        popupAnchor: [1, -34],
      });

      this.mapLocalGroups.forEach((element) => {
        if (!isNaN(element.position.lat) && !isNaN(element.position.lng)) {
          const popupContent = `
            <div style="display: flex; flex-direction: column; align-items: center; gap: 1rem;">
              <h1>${element.lg.name}</h1>
              <a href="mailto:${element.lg.email}" style="color: var(--estiem-green);">Contact: ${element.lg.email}</a>
              <a href="${element.lg.homePage}" target="_blank" style="color: var(--estiem-green);">${element.lg.homePage}</a>
              <a href=/localgroup/${element.lg.id} style="color: var(--estiem-green);">See more</a>
            </div>
          `;
          const marker = L.marker(element.position, { icon: ESTIEMicon })
            .addTo(this.map)
            .bindTooltip(element.lg.name)
            .bindPopup(popupContent);

          this.markers.push(marker); // Keep track of the new marker
        }
      });
    },
  },
};
</script>

<style scoped>
#map {
  width: 100%;
  height: calc(100vh - 180px);
  min-height:550px;
 }
</style>
