// eslint-disable no-shadow
import axios from 'axios';
import Vue from 'vue';

// Initial state
const state = {
  localgroups: [],
};

// Getters
const getters = {
  localgroups: () => state.localgroups,

  localgroup: st => id => st.localgroups.find(localgroup => localgroup.id === Number(id)),
  getLocalGroupById: (state, getters) => (id) => { // eslint-disable-line
    const localgroup = state.localgroups.find(localgroup => localgroups.id === Number(id)); // eslint-disable-line
    return localgroup;
  },
};

// Actions
const actions = {
  fetchLGS({ commit }) {
    let localgroups;
    const url = '/localgroups';
    axios.get(url)
      .then((response) => {
        localgroups = response.data;
        commit('FETCH_LGS', localgroups);
      });
  },
  toggleSelected: ({ commit }, id) => {
    // Insert axios post request here
    commit('TOGGLE_SELECTED', id);
  },
};

// Mutations
const mutations = {
  FETCH_LGS(st, payload) {
    st.localgroups = payload;
  },
  TOGGLE_SELECTED(st, id) {
    const localgroup = st.localgroups.find(l => l.id === id);
    if (!localgroup.isSelected) {
      Vue.set(localgroup, 'isSelected', true);
    } else {
      localgroup.isSelected = false;
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
