<template>
  <div>
    <div class="accordion">
      <b-card no-body>
        <b-card-header header-tag="header" class="estiem-structure-header">
          <h1>ESTIEM STRUCTURE</h1>
        </b-card-header>
        <b-card>
          <p>
            ESTIEM is organised in Local Groups affiliated with universities offering IEM studies. Each year the Local Groups elect the ESTIEM Board,
            to coordinate and support the activity in the Local Groups. Find more information about the entities in ESTIEM below
          </p>
          <b-button
            target="_blank"
            href="https://drive.google.com/file/d/1scvwm-NPtxKO_T7cZCKc7bgJiMRQn4g-/view?usp=sharing"
            variant="secondary"
          >See the Structure</b-button>
          <b-button
            target="_blank"
            href="https://internal.estiem.org/opencalls/"
            variant="primary"
          >Get active</b-button>
        </b-card>
      </b-card>

      <b-card no-body class="mb-1">
        <b-card-header header-tag="header" class="p-1">
          <b-button block href="#">THE BOARD</b-button>
        </b-card-header>
        <b-card>
          <b-card-body>
            <b-card-group deck>
              <b-card
                header="President"
                header-tag="header"
                header-class="other"
                tag="article"
                class="mb-2"
              >
                <b-card>
                  <p>The President representes the Local Groups and is the main responsible for the operational management and strategic development of ESTIEM.</p>
                  <p v-if="authenticated">
                    <a href="mailto:president@estiem.org">president@estiem.org</a>
                  </p>
                </b-card>
              </b-card>

              <b-card
                header="Vice President of Activities"
                header-tag="header"
                header-class="other"
                tag="article"
                class="mb-2"
              >
                <b-card>
                  <p>The Vice President of Activities is responsible for ensuring events and services that promote the personal and cultural development of IEM students.</p>
                  <p v-if="authenticated">
                    <a href="mailto:vp-activities@estiem.org">vp-activities@estiem.org</a>
                  </p>
                </b-card>
              </b-card>

              <b-card
                header="Vice President of Education"
                header-tag="header"
                header-class="other"
                tag="article"
                class="mb-2"
              >
                <b-card>
                  <p>The Vice President of Education is responsible for ensuring events and services that promote the career and academic development of IEM students.</p>
                  <p v-if="authenticated">
                    <a href="mailto:vp-education@estiem.org">vp-education@estiem.org</a>
                  </p>
                </b-card>
              </b-card>

              <b-card
                header="Vice President of Public Relations"
                header-tag="header"
                header-class="other"
                tag="article"
                class="mb-2"
              >
                <b-card>
                  <p>The Vice President of Public Relations is responsible for ensuring that as many IEM students as possible benefit from ESTIEM's events and services.</p>
                  <p v-if="authenticated">
                    <a href="mailto:vp-pr@estiem.org">vp-pr@estiem.org</a>
                  </p>
                </b-card>
              </b-card>

              <b-card
                header="Vice President of Finance"
                header-tag="header"
                header-class="other"
                tag="article"
                class="mb-2"
              >
                <b-card>
                  <p>The Vice President of Finance is responsible for the financial accounts of ESTIEM and ensuring funding to the organisation.</p>
                  <p v-if="authenticated">
                    <a href="mailto:vp-finance@estiem.org">vp-finance@estiem.org</a>
                  </p>
                </b-card>
              </b-card>

              <b-card
                header="Vice President of Administration"
                header-tag="header"
                header-class="other"
                tag="article"
                class="mb-2"
              >
                <b-card>
                  <p>The Vice President of Administration is responsible for ensuring the internal coordination and collaboration within ESTIEM.</p>
                  <p v-if="authenticated">
                    <a href="mailto:vp-administration@estiem.org">vp-administration@estiem.org</a>
                  </p>
                </b-card>
              </b-card>
            </b-card-group>
          </b-card-body>
        </b-card>
      </b-card>

      <b-card no-body class="mb-1">
        <b-card-header header-tag="header" class="p-1" role="tab">
          <b-button block href="#">DEPARTMENTS</b-button>
        </b-card-header>
        <b-card id="accordion-4" accordion="my-accordion" role="tabpanel">
          <b-card-body>
            <b-card-group deck>
              <b-card
                header-html="<i class='material-icons' style='vertical-align:middle'>work</i> CAREER DEVELOPMENT"
                header-tag="header"
                header-class="other"
                tag="article"
                class="mb-2"
              >
                <b-card>
                  <p>
                    The Career Development department aims at helping students with their transition towards the corporate life. The main brands are
                    <a href="/events/times" class="inline-link">TIMES</a> and Business Booster.
                  </p>
                  <p v-if="authenticated">
                    <a href="mailto:career@estiem.org">career@estiem.org</a>
                  </p>
                  <b-button
                    target="_blank"
                    href="https://internal.estiem.org/career-development-department/"
                    variant="primary"
                  >Learn more</b-button>
                </b-card>
              </b-card>

              <b-card
                header-html="<i class='material-icons' style='vertical-align:middle'>school</i> ACADEMIC DEVELOPMENT"
                header-tag="header"
                header-class="other"
                tag="article"
                class="mb-2"
              >
                <b-card>
                  <p>
                    The Academic Development department works towards improving the study quality of IEM students and providing academic supplements. The main brands are Vision, Academic Days and
                    <a
                      href="https://internal.estiem.org/leansixsigma/"
                      class="inline-link"
                    >Lean Six Sigma</a>
                  </p>
                  <p v-if="authenticated">
                    <a href="mailto:academic@estiem.org">academic@estiem.org</a>
                  </p>
                  <b-button
                    target="_blank"
                    href="https://internal.estiem.org/academic-development-department/"
                    variant="primary"
                  >Learn more</b-button>
                </b-card>
              </b-card>

              <b-card
                header-html="<i class='material-icons' style='vertical-align:middle'>mood</i> INTERCULTURAL DEVELOPMENT"
                header-tag="header"
                header-class="other"
                tag="article"
                class="mb-2"
              >
                <b-card>
                  <p>
                    Intercultural Development department promotes understanding among cultures and helps ESTIEMers to build intercultural competencies
                    within our multicultural network. The main brands are Europe3D, Language Programme, Activity Week and Local Group Exchange.
                  </p>
                  <p v-if="authenticated">
                    <a
                      href="mailto:coordinator.intercultural@estiem.org"
                    >coordinator.intercultural@estiem.org</a>
                  </p>
                  <b-button
                    target="_blank"
                    href="https://internal.estiem.org/intercultural-development-department/"
                    variant="primary"
                  >Learn more</b-button>
                </b-card>
              </b-card>

              <b-card
                header-html="<i class='material-icons' style='vertical-align:middle'>person</i> PERSONAL DEVELOPMENT"
                header-tag="header"
                header-class="other"
                tag="article"
                class="mb-2"
              >
                <b-card>
                  <p>The Personal Development department focuses on the personal growth of IEM students. The main brands are BrainTrainer and Summer Academy.</p>
                  <p v-if="authenticated">
                    <a href="mailto:leader.braintrainer@estiem.org">leader.braintrainer@estiem.org</a>
                  </p>
                  <p v-if="authenticated">
                    <a href="mailto:leader.sac@estiem.org">leader.sac@estiem.org</a>
                  </p>
                  <b-button
                    target="_blank"
                    href="https://internal.estiem.org/personal-development-department/"
                    variant="primary"
                  >Learn more</b-button>
                </b-card>
              </b-card>
            </b-card-group>
          </b-card-body>
        </b-card>
      </b-card>

      <b-card no-body class="mb-1">
        <b-card-header header-tag="header" class="p-1" role="tab">
          <b-button block href="#">COMMITTEES AND TASK GROUPS</b-button>
        </b-card-header>
        <b-card id="accordion-5" accordion="my-accordion" role="tabpanel">
          <b-card-body>
            <b-card-group deck>
              <b-card
                header="ANALYSIS"
                header-tag="header"
                header-class="other"
                tag="article"
                class="mb-2"
              >
                <b-card>
                  <p>The Analysis Committee aims to make decision-making more fact-based in order to help the ESTIEM move forward.</p>
                  <p v-if="authenticated">
                    <a href="mailto:leader.analysis@estiem.org">leader.analysis@estiem.org</a>
                  </p>
                  <b-button
                    target="_blank"
                    href="https://internal.estiem.org/analysis-committee/"
                    variant="primary"
                  >Learn more</b-button>
                </b-card>
              </b-card>

              <b-card
                header="CORPORATE RELATIONS"
                header-tag="header"
                header-class="other"
                tag="article"
                class="mb-2"
              >
                <b-card>
                  <p>The Corporate Relations Committee is responsible for the corporate and academic partners in ESTIEM.</p>
                  <p v-if="authenticated">
                    <a href="mailto:leader.crc@estiem.org">leader.crc@estiem.org</a>
                  </p>
                  <b-button
                    target="_blank"
                    href="https://internal.estiem.org/corporate-relations-committee/"
                    variant="primary"
                  >Learn more</b-button>
                </b-card>
              </b-card>

              <b-card
                header="HUMAN RESOURCES"
                header-tag="header"
                header-class="other"
                tag="article"
                class="mb-2"
              >
                <b-card>
                  <p>The Human Resources Committee ensures the recruitment and development of active ESTIEMers.</p>
                  <p v-if="authenticated">
                    <a href="mailto:leader.hrc@estiem.org">leader.hrc@estiem.org</a>
                  </p>
                  <b-button
                    target="_blank"
                    href="https://internal.estiem.org/human-resources-committee/"
                    variant="primary"
                  >Learn more</b-button>
                </b-card>
              </b-card>

              <b-card
                header="INFORMATION TECHNOLOGY"
                header-tag="header"
                header-class="other"
                tag="article"
                class="mb-2"
              >
                <b-card>
                  <p>The IT Committee is responsible for all the IT platforms and infrastructure in ESTIEM.</p>
                  <p v-if="authenticated">
                    <a href="mailto:leader.it@estiem.org">leader.it@estiem.org</a>
                  </p>
                  <b-button
                    target="_blank"
                    href="https://internal.estiem.org/information-technology-committee/"
                    variant="primary"
                  >Learn more</b-button>
                </b-card>
              </b-card>

              <b-card
                header="KNOWLEDGE FACILITATION"
                header-tag="header"
                header-class="other"
                tag="article"
                class="mb-2"
              >
                <b-card>
                  <p>The Knowledge Facilitation Committee ensures the sharing of knowledge and experience within the organisation.</p>
                  <p v-if="authenticated">
                    <a href="mailto:leader.kfc@estiem.org">leader.kfc@estiem.org</a>
                  </p>
                  <b-button
                    target="_blank"
                    href="https://internal.estiem.org/knowledge-facilitation-committee/"
                    variant="primary"
                  >Learn more</b-button>
                </b-card>
              </b-card>

              <b-card
                header="MEMBERS"
                header-tag="header"
                header-class="other"
                tag="article"
                class="mb-2"
              >
                <b-card>
                  <p>The Members Committee aims to expand the network of Local Groups and ensure healthy Local Groups in ESTIEM.</p>
                  <p v-if="authenticated">
                    <a href="mailto:leader.mc@estiem.org">leader.mc@estiem.org</a>
                  </p>
                  <b-button
                    target="_blank"
                    href="https://internal.estiem.org/members-committee/"
                    variant="primary"
                  >Learn more</b-button>
                </b-card>
              </b-card>

              <b-card
                header="PROFESSIONAL DEVELOPMENT"
                header-tag="header"
                header-class="other"
                tag="article"
                class="mb-2"
              >
                <b-card>
                  <p>The Professional Development Committee analyzes and develops the status of IEM fields in IEMers’ education.</p>
                  <p v-if="authenticated">
                    <a href="mailto:leader.pdc@estiem.org">leader.pdc@estiem.org</a>
                  </p>
                  <b-button
                    target="_blank"
                    href="https://internal.estiem.org/professional-development-committee/"
                    variant="primary"
                  >Learn more</b-button>
                </b-card>
              </b-card>

              <b-card
                header="PUBLIC RELATIONS"
                header-tag="header"
                header-class="other"
                tag="article"
                class="mb-2"
              >
                <b-card>
                  <p>The Public Relations Committee ensures that IEM students participate in ESTIEM events and services.</p>
                  <p v-if="authenticated">
                    <a href="mailto:leader.prc@estiem.org">leader.prc@estiem.org</a>
                  </p>
                  <b-button
                    target="_blank"
                    href="https://internal.estiem.org/public-relations-committee/"
                    variant="primary"
                  >Learn more</b-button>
                </b-card>
              </b-card>

              <b-card
                header="SOCIAL AND ENVIRONMENTAL RESPONSIBILITY"
                header-tag="header"
                header-class="other"
                tag="article"
                class="mb-2"
              >
                <b-card>
                  <p>The SER Committee supports ESTIEM in the making of initiatives relevant to Social and Environmental Responsibility.</p>
                  <p v-if="authenticated">
                    <a href="mailto:leader.serc@estiem.org">leader.serc@estiem.org</a>
                  </p>
                  <b-button
                    target="_blank"
                    href="https://internal.estiem.org/social-environmental-responsibility-committee/"
                    variant="primary"
                  >Learn more</b-button>
                </b-card>
              </b-card>

              <b-card
                header="TRAINERS"
                header-tag="header"
                header-class="other"
                tag="article"
                class="mb-2"
              >
                <b-card>
                  <p>The Trainers Community ensures that active ESTIEMers are adequatly trained to carry out their responsibilities.</p>
                  <p v-if="authenticated">
                    <a href="mailto:leader.tc@estiem.org">leader.tc@estiem.org</a>
                  </p>
                  <b-button
                    target="_blank"
                    href="https://internal.estiem.org/trainers-community/"
                    variant="primary"
                  >Learn more</b-button>
                </b-card>
              </b-card>

              <b-card
                header="LOCAL GROUP SUPPORT SYSTEM"
                header-tag="header"
                header-class="other"
                tag="article"
                class="mb-2"
              >
                <b-card>
                  <p>Have a support system for Local Groups’ development, with a customized, project-based approach.</p>
                  <p v-if="authenticated">
                    <a href="mailto:leader.lgss@estiem.org">leader.lgss@estiem.org</a>
                  </p>
                  <b-button
                    target="_blank"
                    href="https://internal.estiem.org/lgss-task-group/"
                    variant="primary"
                  >Learn more</b-button>
                </b-card>
              </b-card>

              <b-card
                header="EVENT QUALITY TASK GROUP"
                header-tag="header"
                header-class="other"
                tag="article"
                class="mb-2"
              >
                <b-card>
                  <p>The Event Quality Task Group aims to have a standardized Event's quality improvement system and consistent quality in ESTIEM events.</p>
                  <p v-if="authenticated">
                    <a href="mailto:leader.eqtg@estiem.org">leader.eqtg@estiem.org</a>
                  </p>
                  <b-button
                    target="_blank"
                    href="https://internal.estiem.org/event-quality-task-group-page/"
                    variant="primary"
                  >Learn more</b-button>
                </b-card>
              </b-card>
            </b-card-group>
          </b-card-body>
        </b-card>
      </b-card>

      <b-card no-body class="mb-1">
        <b-card-header header-tag="header" class="p-1" role="tab">
          <b-button block href="#">PROJECTS</b-button>
        </b-card-header>
        <b-card id="accordion-5" accordion="my-accordion" role="tabpanel">
          <b-card-body>
            <b-card-group deck>
              <b-card
                header="MAGAZINE"
                header-tag="header"
                header-class="other"
                tag="article"
                class="mb-2"
              >
                <b-card>
                  <p>ESTIEM Magazine is sharing the most relevant and popular news from the field of IEM, innovations in the tech world, and most importantly, being a window to all ESTIEM activities.</p>
                  <p v-if="authenticated">
                    <a href="mailto:leader.magazine@estiem.org">leader.magazine@estiem.org</a>
                  </p>
                  <b-button
                    target="_blank"
                    href="https://internal.estiem.org/estiem-magazine/"
                    variant="primary"
                  >Learn more</b-button>
                </b-card>
              </b-card>
            </b-card-group>
          </b-card-body>
        </b-card>
      </b-card>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    authenticated() {
      return this.$store.getters.authenticated;
    },
  },
};
</script>


<style scoped>


.accordion {max-width: 1100px; margin-left: auto; margin-right: auto}
header {font-weight: bold}
header a {background-color: var(--green-accent-2); color: var(--green-primary)}
header a:hover {background-color: var(--green-accent-2); color: var(--green-primary)}
#accordion-1 {
  padding:10px;
}
b-card {
  text-align: left;
}

.estiem-structure-header{
  margin-top: 10px;
}

.card-deck {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin: 0;
    align-content: flex-start;
    overflow: auto;
    resize:both;
}

.card-block {
    position: absolute;
    bottom: 0px;
    left: 0px;
    width:100%;
    background-color: rgba(0,0,0,0.5);
    color:#fff;
    padding: 10px;
    overflow:hidden;
}

.card {
    border: none;
}

.card-deck .card {
    margin-left: 0.5%;
    margin-right: 0.5%;
}


@media only screen and (max-width: 499px) {
  .card {
    flex: 0 1 99%;
  }
}
@media only screen and (min-width: 500px) {
  .card {
    flex: 0 1 48.5%;
  }
}
@media only screen and (min-width: 900px) {
  .card {
    flex: 0 1 32%;
  }
}
@media only screen and (min-width: 1100px) {
  .card {
    flex: 0 1 32%;
  }
  #accordion-4 .card {
    flex: 0 1 48.5%;
  }
}


.card {
  overflow:hidden
}

.card-block h4 {
    color:#fff;
    font-variant:small-caps;
}

.alert {
  margin: 1rem 0 0 0;
}

@media (max-width: 600px) {
  .card-name {
    width: 200px;
    display: block;
    white-space: nowrap;
  }
}

.card img {
  max-width: 300px;
  margin: 0px auto;
}

.card-body {
  padding: 1.25rem
}

.card {
  text-align:center;
}

.card-body .btn {
  margin-top:10px;
}

.square-buttons .btn {
  width: 150px;
  height: 80px;
  white-space: normal;
  display: table-cell;
  vertical-align: middle;
  margin: 10px;
  border-spacing: 10px;
}

.alert a {
  text-decoration:underline;
  color: var(--blue-primary)
}

.inline-link {
  text-decoration: underline
}

article {border:none;}

</style>
