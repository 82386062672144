import axios from 'axios';

function imageUrl(lgName, parentGroupId, userMobile, userOnMap) {
  const url = `/files/localgroup/${parentGroupId}`;

  return axios.get(url)
    .then((response) => {
      const respData = response.data;
      let imageUrl;

      if (userMobile && userOnMap) {
        // User is mobile and on map
        // --> Get the first image which has neither _bg nor _dt in the name
        imageUrl = respData.imageUrls.find(url => !url.includes('--bg') && !url.includes('--dt'));
        if (!imageUrl) {
          console.debug(`No image without found for for ${lgName}, using the default E`);
        }
      } else if (!userMobile && !userOnMap) {
        // User is not mobile and not on map
        // --> get the _bg one
        imageUrl = respData.imageUrls.find(url => url.includes('--bg'));
        if (!imageUrl) {
          console.debug(`No image with --bg found for ${lgName}, using the default E`);
        }
      } else {
        // User is either on mobile and not on map or not on mobile and on map
        // --> get the _dt one, i.e. the "middle one"
        imageUrl = respData.imageUrls.find(url => url.includes('--dt'));
        if (!imageUrl) {
          console.debug(`No image with --dt found for ${lgName}, using the default E`);
        }
      }

      if (!imageUrl) {
        // If no image was found, use the default E
        imageUrl = 'https://estiem.blob.core.windows.net/localgroup-default/000-ESTIEM-2024-8-24-123456..jpg';
      }

      return imageUrl;
    })
    .catch((error) => {
      console.error('Error fetching image URL:', error);
      throw error;
    });
}

// eslint-disable-next-line import/prefer-default-export
export { imageUrl };
