<template>

  <div style="position:relative;max-width:640px;margin:0 auto;">
    <div style="display:none; height:0">
      <iframe :src="npsUrl">...NPS</iframe>

    </div>
    <div style="height:0">
    <div class="feedback-img"> <!-- This div is for the image and text; the formatting in <style scoped> is kinda specific... -->
      <img src="https://estiem.blob.core.windows.net/estiem-images/feedback.PNG" width="300px" /><br />
      Our goal is to create the best possible events, and your thoughts, ideas, and suggestions play a major role in helping us identify opportunities to improve ESTIEM events. Please take a minute to give your feedback.</div>
    </div>
    <iframe frameborder="0"  id="FeedbackForm" :src="formUrl" marginheight="0" marginwidth="0" width="640" style="overflow:visible" onload="window.parent.scrollTo({top: 0,left: 0,behavior: 'smooth'})">Loading...</iframe>
  </div>
</template>

<script>

export default {
  computed: {
    height() {
      return window.innerHeight;
    },
    formUrl() {
      const eventID = this.$route.query.eventID;
      const userToken = this.$route.query.userToken;
      const online = this.$route.query.online;

      let url = '';

      if (online === '1') {
        url = `https://docs.google.com/forms/d/e/1FAIpQLSeQhSs-NO62CYlqknOBAGfIY-OMPU1pn3ZrP5-WJA_RhonIXg/viewform?embedded=true&usp=pp_url&entry.269803502=${eventID}&entry.819363025=${userToken}`;
      } else {
        url = `https://docs.google.com/forms/d/e/1FAIpQLSehHiLTL5xFDaumkhSpaAMR6zBlkyVQpehr0W6BIxuwpcpTVQ/viewform?embedded=true&usp=pp_url&entry.269803502=${eventID}&entry.819363025=${userToken}`;
      }

      return url;
    },
    npsUrl() {
      const eventID = this.$route.query.eventID;
      const userToken = this.$route.query.userToken;
      const NPS = this.$route.query.NPS;
      let npsUrl = '';
      if (eventID !== undefined && userToken !== undefined && NPS !== undefined) {
        npsUrl = `https://docs.google.com/forms/d/e/1FAIpQLScXaO9k7_GDmXBuZzrzyWiHjwAo9gccsgKlGfHPe-4-6BHB8A/formResponse?entry.730685900=${userToken}&entry.745832233=${eventID}&entry.995382275=${NPS}`;
      }
      return npsUrl;
    },
  },
};

</script>

<style scoped>

iframe {
  width: 100%;
  height: 5300px;
}

.feedback-img {
  padding: 30px 20px 20px 20px;
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 640px;
  margin: 0 auto;
  position: relative;
  height: 350px;
  background: #fff;
  z-index: 999;
  font-size: 20px;
  line-height: 23px;
  top: 163px;
}

</style>
