<template>
  <!--deleted the word "card" from the class name to override the border rule of the card-->
  <div class="event-details" :class="eventTypeName.category">
    <router-link class="card-link" :to="{name: 'eventDetails', params: { id: event.id }}">
      <div class="card-block">
        <h4 class="card-title event-name">{{ event.name }}</h4>
      </div>
      <div class="info-container">
        <span class="info-part category">{{eventTypeName.category}}</span>
        <span class="info-part date"><i class="material-icons">date_range</i> {{ eventDatesList(this.event.startDate, this.event.endDate) }} </span>
        <span class="info-part participation-fee"><i class="material-icons">euro_symbol</i> {{ participationFee }} </span>
        <span class="info-part application-status">
          <i class="material-icons">date_range</i>
          Application {{applicationOpen}}
        </span>
        <!-- <span class="info-part "><i class="material-icons">people</i> {{ this.event.maxparticipants }}</span> -->
        <span class="info-part "><i class="material-icons">place</i>{{ event.place }}</span>
        <!-- <b-btn class="see-more-btn"> See More</b-btn> -->
      </div>
    </router-link>
  </div>
</template>

<script>
import moment from 'moment';
import { imageUrl } from '../../helpers/image-helper.js';
import { EventCategory } from '../../helpers/event-category.js';

export default {
  data() {
    return {
      isSelected: false,
      evTypeOptions: [
        { text: 'Academic Days', category: EventCategory.ACADEMIC, value: 11 },
        { text: 'Activity Week', category: EventCategory.CULTURAL, value: 10 },
        { text: 'BrainTrainer', category: EventCategory.PERSONAL, value: 2 },
        { text: 'businessbooster', category: EventCategory.CAREER, value: 14 },
        { text: 'Europe3D', category: EventCategory.CULTURAL, value: 1 },
        { text: 'Lean Six Sigma', category: EventCategory.ACADEMIC, value: 15 },
        { text: 'Language Programme', category: EventCategory.CULTURAL, value: 13 },
        { text: 'Local Group Exchange', category: EventCategory.EXCHANGE, value: 9 },
        { text: 'Summer Academy', category: EventCategory.PERSONAL, value: 3 },
        { text: 'TIMES', category: EventCategory.CAREER, value: 4 },
        { text: 'Vision', category: EventCategory.ACADEMIC, value: 5 },
        { text: 'Council Meeting', category: EventCategory.CENTRAL, value: 8 },
        { text: 'Central ESTIEM', category: EventCategory.CENTRAL, value: 7 },
        { text: 'Other cultural events', category: EventCategory.CULTURAL, value: 17 },
        { text: 'Other academic events', category: EventCategory.ACADEMIC, value: 18 },
        { text: 'Other career events', category: EventCategory.CAREER, value: 19 },
        { text: 'Other personal events', category: EventCategory.PERSONAL, value: 20 },
        { text: 'Other events', category: EventCategory.OTHER, value: 6 },
        { text: 'Alumni', category: EventCategory.ALUMNI, value: 12 },
        { text: 'Alumni Meeting', category: EventCategory.ALUMNI, value: 16 },
        { text: 'Alumni CM', category: EventCategory.ALUMNI, value: 21 },
        { text: 'Alumni Round Table', category: EventCategory.ALUMNI, value: 22 },
        { text: 'Be X For X Days', category: EventCategory.CAREER, value: 23 },
      ],
    };
  },
  props: {
    event: {
      default: {},
    },
    userOnMap: false,
  },
  components: {
  },
  filters: {
    formatdate(value) {
      if (value) {
        return moment(value).format('DD.MM.YYYY');
      }
      return '';
    },
  },
  methods: {
    eventDatesList(startDate, endDate) {
      var startDateMoment = moment(startDate);
      var endDateMoment = moment(endDate);

      if (startDateMoment.isSame(endDateMoment, 'day')) {
        return startDateMoment.format('MMM Do');
      }
      const sameMonth = startDateMoment.isSame(endDateMoment, 'month');
      const sameYear = startDateMoment.isSame(endDateMoment, 'year');

      var startFormat = sameMonth ? 'D' : 'D MMM';
      const endFormat = sameYear ? 'D MMM' : 'D MMM YYYY';

      return `${startDateMoment.format(startFormat)} to ${endDateMoment.format(endFormat)}`;
    },
  },
  computed: {
    applicationOpen() {
      const now = moment();
      if (
        now.isAfter(this.event.applicationEndDate) &&
                    now.isBefore(this.event.applicationEndDate)
      ) {
        return 'willopen';
      } else if (now.isBefore(this.event.applicationEndDate)) {
        return 'open';
      }

      return 'closed';
    },
    eventClass() {
      return this.eventTypeName().category;
    },
    eventTypeName() {
      return this.evTypeOptions.find(
        e => e.value === this.event.eventType,
      ); // filter event type name
    },
    participationFee() {
      if (this.event.participationFee == null || this.event.participationFee <= 0) {
        return '—';
      }
      return this.event.participationFee;
    },
    imageUrl() {
      const userMobile = screen.width <= 450;
      return imageUrl(this.event.place, this.event.parentGroupId, userMobile, this.userOnMap);
    },
  },
};
</script>

<style scoped>

  .card-block {
    padding: 5px 0;
    text-align:left;
  }

  .category{
    display: inline-block;
    min-width: 75px;
    margin-right: 10px;
    max-height: 22px;
    background-color: blanchedalmond;
    color: #625750;
  }
  .participation-fee{
    display: inline-block;
    min-width: 75px;
  }
  .date{
    display: inline-block;
    min-width: 160px;
  }
  .application-status {
    display: inline-block;
    min-width: 160px;
  }
  .event-details {
    text-align: center;
    padding: 8px 16px;
    margin: 20px 7%;
    border-radius: 5px;
    border: 1px solid #205e44;
  }

  div.event-details:hover {
    background:#fff;
    box-shadow: -1px 1px 3px 3px rgba(32, 94, 68, .5);
    transition: all .2s ease-out;
    transition-property: all;
    transition-duration: 0.2s;
    transition-timing-function: ease-out;
    transition-delay: 0s;
  }

    /* TODO: remove min-width of evements on mobile */
  @media only screen and (max-width: 499px) {
    .event-details {
      flex: 0 1 99%;
      margin: 10px 7%;
      padding: 5px 15px;
    }
    .info-part {
      padding-top:2px;
    }
    .category{
      display: none;
    }
    .participation-fee{
      min-width: 50px;
    }
    .date{
      min-width: 120px;
    }
    .application-status {
      display: none;
    }
    a {
      font-size: 12px;
    }
  }
  @media only screen and (min-width: 500px) {
    .event-details {
      flex: 0 1 48.5%;
    }
  }
  @media only screen and (min-width: 900px) {
    .event-details {
      flex: 0 1 32%;
    }
  }
  @media only screen and (min-width: 1100px) {
    .event-details {
      flex: 0 1 24%;
    }
  }
    /*this refers to the cards of my events on the frontpage*/

  .frontPage-card > .card {
    width: 100%;
    max-width: 350px;
  }
  .info-container{
    text-align: left;
    color: #625750;;
  }
  .card-deck .card {
    margin: 0 5px;
    padding: 8px 5px;
  }

  .event-name {
    font-size: 16px;
    display: inline;
  }

  @media screen and (min-width: 450px) {
    .event-name, .event-text {
      font-size:16px;
    }
  }

  @media screen and (min-width: 1400px) {
    .event-name, .event-text {
      font-size:18px;
    }
  }

  @media screen and (max-width: 450px) {
    .event-name, .event-text {
      font-size:16px;
    }
  }

  @media screen and (max-width: 320px) {
    .event-details {
      min-width: 270px;
    }
  }

  .frontPage-card .tags {
    display:none;
  }

  .frontPage-card .event-details {
    width: 100%;
    max-width: 350px;
    margin: auto;
  }

  .frontPage-card a {
    color:#ffffff;
  }

  .frontPage-card .category {
    display:none;
  }

  .category {
    border-radius: 0.25rem;
    background-color: blanchedalmond;
    text-align: center;
    max-height:25px;
    color: #625750;
  }

  .personal .category {
    background-color:#3491bd;
    color: rgb(255,255,255);
  }

  .career .category {
    background-color:rgb(12, 43, 94);
    color: rgb(255,255,255);
  }

  .cultural .category {
    background-color: rgba(247, 132, 0, 0.8);
    color: rgb(255,255,255);
  }

  .academic .category {
    background-color: rgba(118, 18, 20, 0.8);
    color: rgb(255,255,255);
  }

  .central .category {
  background-color: #205e44;
  color: rgb(255,255,255);
}

  .material-icons {
    vertical-align: middle;
  }

  @media (max-width: 600px) {
    .event-details-name {
      width: 200px;
      display: block;
      white-space: nowrap;
    }
  }

  .apply-btn-b {
    /*font-family: "Gill Sans", "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif; */
    font-size: 16px;
    font-weight: 500;
    background-color: rgb(32, 94, 68);
    color: #ffffff;
    text-align: center;
    position: relative;
    margin: auto auto 10px;
  }
</style>
