/*
Since the country code stuff will break stuff, let's instead just
create an array with the country names.
A bit redundant, but with this we can easily transition back
to 2-char codes if need be
*/

export const countryNames = [
  { text: 'Albania', value: 'Albania' },
  { text: 'Andorra', value: 'Andorra' },
  { text: 'Austria', value: 'Austria' },
  { text: 'Azerbaijan', value: 'Azerbaijan' },
  { text: 'Belarus', value: 'Belarus' },
  { text: 'Belgium', value: 'Belgium' },
  { text: 'Bosnia and Herzegovina', value: 'Bosnia and Herzegovina' },
  { text: 'Bulgaria', value: 'Bulgaria' },
  { text: 'Croatia', value: 'Croatia' },
  { text: 'Cyprus', value: 'Cyprus' },
  { text: 'Czechia', value: 'Czechia' },
  { text: 'Denmark', value: 'Denmark' },
  { text: 'Estonia', value: 'Estonia' },
  { text: 'Finland', value: 'Finland' },
  { text: 'France', value: 'France' },
  { text: 'Germany', value: 'Germany' },
  { text: 'Greece', value: 'Greece' },
  { text: 'Hungary', value: 'Hungary' },
  { text: 'Iceland', value: 'Iceland' },
  { text: 'Ireland', value: 'Ireland' },
  { text: 'Italy', value: 'Italy' },
  { text: 'Latvia', value: 'Latvia' },
  { text: 'Liechtenstein', value: 'Liechtenstein' },
  { text: 'Lithuania', value: 'Lithuania' },
  { text: 'Luxembourg', value: 'Luxembourg' },
  { text: 'Malta', value: 'Malta' },
  { text: 'Moldova, Republic of', value: 'Moldova, Republic of' },
  { text: 'Montenegro', value: 'Montenegro' },
  { text: 'Netherlands', value: 'Netherlands' },
  { text: 'North Macedonia', value: 'North Macedonia' },
  { text: 'Norway', value: 'Norway' },
  { text: 'Poland', value: 'Poland' },
  { text: 'Portugal', value: 'Portugal' },
  { text: 'Romania', value: 'Romania' },
  { text: 'Russia', value: 'Russia' },
  { text: 'Serbia', value: 'Serbia' },
  { text: 'Slovakia', value: 'Slovakia' },
  { text: 'Slovenia', value: 'Slovenia' },
  { text: 'Switzerland', value: 'Switzerland' },
  { text: 'Spain', value: 'Spain' },
  { text: 'Sweden', value: 'Sweden' },
  { text: 'Turkey', value: 'Turkey' },
  { text: 'Ukraine', value: 'Ukraine' },
  { text: 'United Kingdom', value: 'United Kingdom' },
];
