<template>
        <span  class="buttons-shortcut">
          <b-button @click="csvExport(eventParticipants)" size="sm" v-b-modal.download id="button-download">Download list</b-button>
        </span>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      users: [],
      eventParticipants: [],
      participantsFetched: false,
    };
  },

  mounted() {
    this.fetchEventParticipants();
  },

  methods: {

    fetchEventParticipants() {
      const url = `/events/${window.location.pathname.split('/')[2]}/participants`;
      return axios.get(url)
        .then((response) => {
          this.eventParticipants = response.data;
          this.participantsFetched = true;
        });
    },

    csvExport(arrData) {
      let csvContent = 'data:text/csv;charset=utf-8,';
      let objarray = Object.keys(arrData[1]);

      let items = arrData.splice(0);

      items = items
        .map((row) => {
          delete row.userThumbNail;
          return Object.values(row).map((el) => {
            if (el === '') {
              return 'null';
            }
            if (typeof el === 'number') return el;
            return typeof el === 'string' && el.replace(/\n|;/g, '');
          })
            .join(';');
        });
      objarray = objarray.filter(el => el !== 'userThumbNail');
      csvContent += [
        objarray.join(';'),
        ...items,
      ]
        .join('\n')
        .replace(/(^\[)|(\]$)/gm, '')
      ;

      const data = encodeURI(csvContent);
      const link = document.createElement('a');
      link.setAttribute('href', data);
      link.setAttribute('download', 'participants.csv');
      link.click();
    },
  },
};
</script>

<style scoped>

</style>
