<template xmlns:v-slot='http://www.w3.org/1999/XSL/Transform'>
  <div id='LgProfile'>
    <spinner v-if='!lg'></spinner>
    <div class='lg-container' v-if='lg'>
      <img class='card-img-top' :src='lgImageUrl' />
      <!--<p>{{ lgImageUrl }}</p>-->
      <b-card v-if='lg' class='lg-overview'>
        <h1>Local Group {{ lg.name }}</h1>
        <h3>
          {{ lg.region }}
        </h3>
        <b-card style='margin-top: 20px'>
          <h3 style='text-align: center'>Quick Facts</h3>
          <b-card-text class='quick-facts'>
            <div>
              <i class='material-icons lg-icons'>event_seat</i>
              Membership Status: {{ lgStatus }}
              <br />
              <i class='material-icons lg-icons'>info</i>
              Local Responsible:
              <router-link
                :to='{
                  name: "profile",
                  params: {
                    id: this.lr.userId
                  }
                }'
              >
                {{ lr.firstNameEnglish }} {{ lr.lastNameEnglish }}
              </router-link>
              <!--<br />
              <i class='material-icons lg-icons'>person</i> Board Responsible:
              <router-link :to='{name: 'profile', params: { id: this.br.firstNameEnglish + ' ' + this.br.lastNameEnglish }}'>
                {{ br.firstNameEnglish }} {{ br.lastNameEnglish }}
              </router-link>-->
              <br />
              <i class='material-icons lg-icons'>email</i>
              Email:
              <a
                :href="'mailto:lr.' + this.lg.name + '@estiem.org'"
                title='Email'
              >
                lr.{{ lg.name }}@estiem.org
              </a>
              <br />
              <i class='material-icons lg-icons'>cake</i> ESTIEM Member Since
              {{ birthdayYear }}
            </div>
            <br />
        <b-button v-b-toggle="'collapse'" class='info-btn'
              >More info</b-button
            >
            <b-collapse id='collapse'>
              <div>
                <i class='material-icons lg-icons'>school</i>
                University: {{ lg.universityName }}
              </div>
              <br />
              <div>
                <i class='material-icons lg-icons'>school</i>
                Official name: {{ lg.officialName }}
              </div>
              <br />
              <div>
                <i class='material-icons lg-icons'>home</i>
                Address: {{ lg.address }}
              </div>
              <br />
              <div style='width:100%;'>
                <i class='material-icons lg-icons'>info</i>
                LG Structure:
                <b-form-textarea
                  no-auto-shrink
                  no-resize rows="10"
                  max-rows="20"
                  class="structure"
                  id="textarea-plaintext"
                  plaintext
                  :value="lg.freeTextField1"
                  style="overflow:hidden">
                </b-form-textarea>
              </div>
            </b-collapse>
          </b-card-text>
        </b-card>
        <div
          class='main'
          :class='{
            admin: admin || localResponsible,
            clasic: !admin && !localResponsible
          }'
        >
          <div>
            <a
              v-if='lg.homePage'
              :href="'https://' + lg.homePage"
              target='_blank'
              title='Website'
            >
              <i class='material-icons'>laptop</i>
            </a>
          </div>
          <div>
            <a
              v-if='lg.gpslocation'
              :href='`https://www.google.com/maps/?q=${lg.gpslocation}`'
              target='_blank'
              title='Address'
            >
              <i class='material-icons'>home</i>
            </a>
          </div>
          <div>
            <a
              v-if='lg.name'
              :href='`mailto:lr.${lg.name}@estiem.org`'
              target='_blank'
              title='Email'
            >
              <i class='material-icons'>email</i>
            </a>
          </div>
          <div v-if='admin'>
            <router-link
              :to="{ name: 'localgroup-edit' }"
              target='_blank'
              title='EditLG'
              ><i class='material-icons'>edit</i></router-link
            >
          </div>
          <div>
            <a
              class='icon-descriptions'
              v-if='lg.homePage'
              :href="'https://' + lg.homePage"
              target='_blank'
              title='Website'
              >Visit Website</a
            >
          </div>
          <div>
            <a
              class='icon-descriptions'
              v-if='lg.gpslocation'
              :href='`https://www.google.com/maps/?q=${lg.gpslocation}`'
              target='_blank'
              >Show Address</a
            >
          </div>
          <div>
            <a
              class='icon-descriptions'
              v-if='lg.name'
              :href='`mailto:lr.${lg.name}@estiem.org`'
              target='_blank'
              title='Email'
              >E-Mail</a
            >
          </div>
          <div v-if='localResponsible || admin'>
            <router-link
              class='icon-descriptions'
              :to="{ name: 'localgroup-edit' }"
              target='_blank'
              title='EditLG'
              >Edit LG</router-link
            >
          </div>
        </div>
      </b-card>

      <spinner
        v-if='isAuthenticated && (!travels || !members)'
        :small='true'
      ></spinner>
      <div v-if='travels && members && isAuthenticated'>
        <div class='banner'>
          <i
            v-b-toggle="'collapse-2'"
            class='material-icons'
            style='color: #ffffff; font-size: 24pt; padding-top: 5px'
            >add</i
          >
          <h2>LG Members</h2>
        </div>
        <b-collapse id='collapse-2'>
          <b-table
            striped
            hover
            :items='members'
            :fields='memberFields'
            :current-page='currentPage'
            :per-page='recordsPerPage'
          >
            <template #cell(firstNameEnglish)="data">
              <router-link
                class="card-link"
                :to="{ name: 'profile', params: { id: data.item.userId } }"
              >
                {{ data.item.firstNameEnglish }}
              </router-link>
            </template>
          </b-table>
          <b-pagination
            v-model='currentPage'
            :total-rows='numMembers'
            :per-page='recordsPerPage'
          >
          </b-pagination>
        </b-collapse>
        <div class='banner'>
          <i
            v-b-toggle="'collapse-3'"
            class='material-icons'
            style='color: #ffffff; font-size: 24pt; padding-top: 5px'
            >add</i
          >
          <h2>LG Travels</h2>
        </div>
        <b-collapse id='collapse-3'>
          <b-table
            striped
            hover
            responsive
            :items='travels'
            :fields='travelFields'
            :current-page='currentPageTravel'
            :per-page='recordsPerPage'
          >
              <template #cell(userFirstNameEnglish)="data">
              <router-link class="card-link" :to="{name: 'profile', params: { id: data.item.userId }}">
                {{ data.item.userFirstNameEnglish }}
              </router-link>
            </template>
            <template #cell(eventName)="data">
              <router-link
                class="card-link"
                :to="{
                  name: 'eventDetails',
                  params: { id: data.item.eventId },
                }"
              >
                {{ data.item.eventName }}
              </router-link>
            </template>
          </b-table>
          <b-pagination
            v-model='currentPageTravel'
            :total-rows='20000'
            :per-page='recordsPerPage'
          >
          </b-pagination>
        </b-collapse>
      </div>
      <!--   <div class='member-container'>

             <b-card class='member' v-for='member in members' v-bind:key='member.userId'
                     v-on:click='selectedMemberId = member.userId;'>
               <img :src='`https://old.estiem.org/userimage.aspx?id=${member.userId}`'
                    v-bind:alt='`${member.firstNameEnglish} ${member.lastNameEnglish}`'>
               <router-link
                 :to='member.userId === selectedMemberId ? `https://m.estiem.org/profile/${member.firstNameEnglish}%20${member.lastNameEnglish}` : null'>
                 <div class='overlay'
                      v-bind:class='[member.userId === selectedMemberId ? 'show-overlay' : 'hide-overlay']'>
                   <h2>
                     {{member.firstNameEnglish}} {{member.lastNameEnglish}}
                   </h2>
                 </div>
               </router-link>
             </b-card>

           </div>-->

      <b-card
        class='organized-events-overview'
        v-if='organizedEvents && organizedEvents.length'
      >
        <div class='banner'>
          <h2>Events Organized</h2>
        </div>

        <div class='organized-events-container'>
          <div v-for='event in organizedEvents' v-bind:key='event.id'>
            <router-link :to='`../event/${event.id}`'>
              <h3>
                {{ event.name }}
              </h3>
            </router-link>
          </div>
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
// import auth from '../../auth';
import moment from 'moment';
import Spinner from '../Spinner/spinner.vue';
import { imageUrl } from '../../helpers/image-helper';
import { lgStatuses } from '../../helpers/lg-status.js';

export default {
  components: { Spinner },
  data() {
    return {
      IsChangeGrid: false,
      lg: null,
      members: [],
      travels: [],
      initialTravels: [],
      travelTableBusy: false,
      recordsPerPage: 10,
      currentPage: 1,
      currentPageTravel: 1,
      memberFields: [
        {
          key: 'firstNameEnglish',
          label: 'First Name',
        },
        {
          key: 'lastNameEnglish',
          label: 'Last Name',
        },
      ],
      travelFields: [
        {
          key: 'userFirstNameEnglish',
          label: 'First Name',
        },
        {
          key: 'userLastNameEnglish',
          label: 'Last Name',
        },
        {
          key: 'eventName',
          label: 'Event',
        },
        {
          key: 'eventStartDate',
          label: 'Event Started',
          formatter: this.trimDate,
        },
        {
          key: 'ventPlace',
          label: 'Place',
        },
      ],
      organizedEvents: [],
      selectedMemberId: null,
      lgStatus: '',
      lr: {},
      br: {},
      lgImageUrl: '',
    };
  },
  computed: {
    // imageUrl() {
    //   if (this.lg != null) {
    //     const userMobile = screen.width <= 450;
    //     return imageUrl(this.lg.name, this.lg.id, userMobile, false);
    //   }
    //   return null;
    // },
    numMembers() {
      return this.members.length;
    },
    localResponsible() {
      return this.$store.getters.isLR;
    },
    admin() {
      return this.$store.getters.isAdmin;
    },
    isAuthenticated() {
      return this.$store.getters.authenticated;
    },
    birthdayYear() {
      const bday = new Date(this.lg.membershipStart);
      return bday.getFullYear();
    },
  },
  methods: {
    changeIsGrid() {
      if (document.getElementById('editLG') == null) {
        this.IsChangeGrid = false;
      } else {
        this.IsChangeGrid = true;
      }
    },
    fetchLG() {
      return new Promise((resolve, reject) => {
        const id = this.$route.params.id;
        axios
          .get(`/localgroups/${id}`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    fetchLR() {
      axios.get(`/user/${this.lg.localResponsibleId}`).then((response) => {
        this.lr = response.data;
      });
    },
    fetchBR() {
      axios.get(`/user/${this.lg.boardResponsibleId}`).then((response) => {
        this.br = response.data;
      });
    },
    async fetchTravels() {
      const amount = this.currentPageTravel * this.recordsPerPage;
      // this.toggleTableBusy();
      await axios
        .get(
          `/events/localgroup/${this.lg.id}/participants?page=0&amount=${amount}`,
        )
        .then((response) => {
          this.initialTravels = response.data;
          // this.toggleTableBusy();
        })
        .then(() => {
          this.initialTravels.forEach((travel) => {
            if (
              travel.registrationStatus === 0 ||
              travel.registrationStatus === 5
            ) {
              this.travels.push(travel);
            }
          });
        });
    },
    fetchUsers() {
      axios.get(`/localgroups/${this.lg.id}/members`).then((response) => {
        this.members = response.data;
      });
    },
    fetchImage() {
      if (this.lg != null) {
        const userMobile = screen.width <= 450;
        imageUrl(this.lg.name, this.lg.id, userMobile, false).then((response) => { this.lgImageUrl = response; });
      }
      return null;
    },
    toggleTableBusy() {
      this.travelTableBusy = !this.travelTableBusy;
    },
    trimDate(value) {
      return value.toString().substring(0, value.length - 9);
    },
  },
  filters: {
    formatdate(value) {
      if (value) {
        return moment(value).format('DD.MM.YYYY');
      }
      return '';
    },
  },
  created() {
    this.fetchLG().then((lg) => {
      this.lg = lg;
      // this.lg.freeTextField1 = this.lg.freeTextField1.trim().split('-');
      this.lgStatus = { ...lgStatuses }[lg.status];
      this.fetchLR();
      this.fetchBR();
      this.fetchUsers();
      this.fetchTravels();
      this.fetchImage();
    });
    // .catch(err => console.log(err));
  },
  watch: {
    currentPageTravel(newPage, oldPage) {
      if (newPage > oldPage) this.fetchTravels(newPage);
    },
  },
};
</script>

<style scoped>
.card-img-top {
  width: 100%;
  max-height: 250px;
  min-height: 120px;
  object-fit: cover;
}

.facts-container {
  text-align: left;
  font-size: 18px;
}

.lg-container {
  padding: 20px;
}

.lg-icons {
  vertical-align: sub;
  font-size: 24px;
  color: #205e44;
}

.lg-overview {
  padding: 30px 20px 20px 20px;
}

.lg-overview h1,
h3 {
  text-align: center;
  color: #205e44;
}

.lg-overview h1 {
  font-size: 2rem;
}

.lg-overview h3 {
  font-size: 1.5rem;
}

.quick-facts {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* text-align: center; */
}

#collapse {
  width: 50%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin: 10px;
  text-align: justify;
}

.structure {
  margin: 2px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: justify;
  width:inherit;
  border: transparent;
}

.info-btn {
  background-color: #205e44;
  color: white;
  margin: 10px;
}

.main {
  padding: 10px 0 0 0;
  display: grid;
  text-align: center;
  font-size: 14px;
}

.admin {
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.clasic {
  grid-template-columns: 1fr 1fr 1fr;
}

.icon-descriptions {
  font-size: 14px;
  padding-bottom: 15px;
}

.lg-overview-container i {
  color: #205e44;
  font-size: 1.9rem;
}

.banner {
  display: flex;
  width: 100%;
  background-color: #205e44;
  padding-top: 10px;
  padding-bottom: 10px;
}

.banner h1,
h2 {
  color: white;
  text-align: center;
}

.material-icons {
  line-height: 10px;
  margin: 0;
  padding: 0;
  height: 20px;
  vertical-align: sub;
  color:#205e44;
}

.member-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  padding: 20px 20px 20px 0px;
}

.member {
  margin-left: 20px;
}

.member img {
  height: 180px;
  width: 180px;
  object-fit: cover;
}

.member .overlay {
  margin-top: -180px;
  height: 180px;
  width: 180px;
  text-align: center;
  color: white;
  background-color: #205e44;
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.member .overlay:hover {
  opacity: 0.7;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.member .overlay h2 {
  color: white;
  border-bottom: none;
}

@media (max-width: 768px) {
  #collapse {
    width: 90%;
  }
  .lg-overview h1 {
    font-size: 1.6rem;
  }

  .lg-overview h3 {
    font-size: 1.5rem;
  }

  .icon-descriptions {
    font-size: 14px;
  }
  .changeGrid {
    grid-template-columns: 1fr 1fr 1fr 1fr !important;
  }

  .icon-descriptions {
    font-size: 14px;
    padding-bottom: 15px;
  }

  .lg-overview-container i {
    font-size: 1.7rem;
  }

  .member img {
    height: 120px;
    width: 120px;
  }

  .member .overlay {
    margin-top: -120px;
    height: 120px;
    width: 120px;
  }

  .lgInfo {
    font-size: 12px;
    position: relative;
    color: gray;
    display: table-cell;
    float: left;
    /*overflow:scroll;*/
    padding-left: 40px;
    margin-right: 160px;
  }
}

@media (pointer: coarse) {
  .show-overlay {
    opacity: 0.7;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
  }

  .hide-overlay {
    opacity: 0;
  }
}
</style>
