<template>
  <div class="container">
    <h1>Dashboard Test</h1>
    <p>Hey, this is a test page for dashboards, though in the future it might be something more 👀.
      <br>
      Currently it's more of an easter egg. Hope you like it here :)!
    </p>
    <div>
      <div class="button-container">
        <TableauEmbedButton
          :vizData="usersCreatedSince2017DashboardData"
          :contextualFilters="[]" />
        <TableauEmbedButton
          :vizData="superstoreEmbedded800x800"
          :contextualFilters="superstoreEmbedded800x800CF"
        />

      </div>
    </div>
  </div>
</template>

<script>
import { usersCreatedSince2017DashboardData, superstoreEmbedded800x800 } from '../../helpers/tableau-embedding-data.js';
import spinner from '../Spinner/spinner.vue';
import TableauEmbedButton from './tableau-embed-button.vue';

export default {
  name: 'dashboards-test',
  data() {
    return {
      usersCreatedSince2017DashboardData,
      superstoreEmbedded800x800,
    };
  },
  components: {
    spinner,
    TableauEmbedButton,
  },
  mounted() {
    this.superstoreEmbedded800x800CF = [
      {
        filterType: 'categorical',
        fieldName: 'State',
        worksheetName: 'SaleMap',
        values: ['Alabama', 'Louisiana', 'Florida', 'Fakestate'] },
      {
        filterType: 'range',
        fieldName: 'Order Date',
        worksheetName: 'SaleMap',
        minValue: new Date(Date.UTC(2020, 2, 1)),
        maxValue: new Date(Date.UTC(2022, 11, 31)),
      },
    ];
  },
};
</script>

<style scoped>
.container {
  text-align: center;
}

.button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.viz-container {
  width: 100%;
  margin-bottom: 1rem;
}

.centered-button {
  margin: 0 auto;
}
</style>
