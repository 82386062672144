// router/routes.js
import eventspage from '../components/events/event-list-page.vue';
import page404 from '../components/page404.vue';
import eventroutes from '../components/event/routes-event.js';
import aboutPage from '../components/home/about-page.vue';
import homePage from '../components/home/home-page.vue';
import singleEvent from '../components/event/container-single-event.vue';
import createEvent from '../components/event/event-create.vue';
import feedbackIframe from '../components/home/feedback-iframe.vue';
import localGroups from '../components/localgroups/localgroups.vue';
import profilePage from '../components/profile/profile-page.vue';
import profileEdit from '../components/profile/profile-edit.vue';
import loginPage from '../components/profile/login-page.vue';
import lgPage from '../components/localgroups/localgroup-page.vue';
import searchPage from '../components/search/search-page.vue';
import resourcePage from '../components/home/resources.vue';
import alumniPage from '../components/home/alumni-page.vue';
import activePage from '../components/home/active.vue';
import structurePage from '../components/home/structure.vue';
import contactPage from '../components/home/contact.vue';
import eventFeedback from '../components/home/event-feedback.vue';
import signupPage from '../components/signup/sign-up-page.vue';
import emailVerification from '../components/login/email-verification.vue';
import localgroupEdit from '../components/localgroups/localgroup-edit.vue';
import signUpList from '../components/signup/signUpList.vue';
import GroupsList from '../components/groups/GroupsList.vue';
import GroupDetails from '../components/groups/GroupDetails.vue';
import GroupCreate from '../components/groups/GroupCreate.vue';
import GroupEdit from '../components/groups/GroupEdit.vue';
import alumniJoin from '../components/alumni/alumni-join.vue';
import alumniApplicants from '../components/alumni/alumni-applicants.vue';
import DashboardsTest from '../components/dashboards/DashboardsTest.vue';

export const routes = [
  {
    path: '/',
    name: 'homepage',
    component: homePage,
  },
  {
    path: '/events',
    name: 'eventspage',
    component: eventspage,
  },
  {
    path: '/event/:id',
    name: 'event-details',
    component: singleEvent,
    children: eventroutes,
  },
  {
    path: '/localgroups',
    name: 'localgroups',
    component: localGroups,
  },
  {
    path: '/profile/:id',
    name: 'profile',
    component: profilePage,
    meta: { requiresAuth: true },
  },
  {
    path: '/create',
    name: 'createEvent',
    component: createEvent,
    meta: { requiresAuth: true },
  },
  {
    path: '/profile/:id/edit',
    name: 'profile-edit',
    component: profileEdit,
    meta: { requiresAuth: true },
  },
  {
    path: '/login',
    name: 'login',
    component: loginPage,
  },
  {
    path: '/about',
    name: 'about',
    component: aboutPage,
  },
  {
    path: '/localgroup/:id',
    name: 'lgpage',
    component: lgPage,
  },
  {
    path: '/search',
    name: 'search',
    component: searchPage,
  },
  {
    path: '/feedback',
    name: 'feedback',
    component: feedbackIframe,
  },
  {
    path: '/event-feedback',
    name: 'event-feedback',
    component: eventFeedback,
  },
  {
    path: '/partners',
    beforeEnter() {
      window.location.href = 'https://internal.estiem.org/career-center-overview/partners/';
    },
    name: 'partners',
  },
  {
    path: '/resources',
    name: 'resources',
    component: resourcePage,
  },
  {
    path: '/alumni',
    name: 'alumni',
    component: alumniPage,
  },
  {
    path: '/active',
    name: 'active',
    component: activePage,
    meta: { requiresAuth: true },
  },
  {
    path: '/structure',
    name: 'structure',
    component: structurePage,
  },
  {
    path: '/contact',
    name: 'contact',
    component: contactPage,
  },
  {
    path: '/email-verification',
    name: 'email-verification',
    component: emailVerification,
  },
  {
    path: '/sign-up',
    name: 'sign-up',
    component: signupPage,
  },
  {
    path: '/sign-up-list',
    name: 'sign-up-list',
    component: signUpList,
  },
  {
    path: '/localgroup/:id/edit',
    name: 'localgroup-edit',
    component: localgroupEdit,
    meta: { requiresAuth: true, },
  },
  {
    path: '/404',
    name: '404',
    component: page404,
  },
  {
    path: '*',
    redirect: '/404',
  },
  {
    path: '/groups',
    name: 'GroupsList',
    component: GroupsList,
    meta: { requiresAuth: true, },
  },
  {
    path: '/group/:id',
    name: 'group-details',
    component: GroupDetails,
    meta: { requiresAuth: true, },
  },
  {
    path: '/groups/create',
    name: 'group-create',
    component: GroupCreate,
    meta : {  requiresAuth: true,  requiresAdmin: true },
  },
  {
    path: '/group/:id/edit',
    name: 'group-edit',
    component: GroupEdit,
    meta : {  requiresAuth: true,  requiresAdmin: true },
  },
  {
    path: '/alumni/join',
    name: 'alumni-join',
    component: alumniJoin,
  },
  {
    path: '/alumni/applicants',
    name: 'alumni-applicants',
    component: alumniApplicants,
    meta: { requiresAuth: true, requiresBoardAlumni: true },
  },
  {
    path: '/dashboards',
    name: 'dashboards-test',
    component: DashboardsTest,
    meta: { requiresAuth: true },
  },
];
