<template>
  <div>
    <div v-if='localResponsible || admin'>
      <div class='applicant-list'>
        <div class='header'>
          <h1>
            <center>Applicants list</center>
          </h1>
          <p id='intro-text'>
            <br />
            In this list you can see the people who applied to join your Local
            Group. You can accept or deny their application by selecting it in
            the list and tapping 'Accept' or 'Deny' button below.
            <a v-b-toggle.info id='learn-more'>Learn more</a>
            <b-collapse id='info'>
              <br />
              If you accept an application, the applicant will receive an email
              notification and will be able to Sign in to the ESTIEM portal and
              apply for events. You can only accept applications from users that
              have verified their mail.
              <br /><br />
              If you deny an application, it will be deleted immediately. If you
              have denied someone by mistake, they can apply again.
              <br /><br />
              If you neither accept or deny an application, it will be deleted
              in 30 days.
            </b-collapse>
            <br /><br />
          </p>
        </div>
        <div v-if='applicants.length !== 0' id='wrapper'>
          <table ref='table' id='table'>
            <tr>
              <th>
                Select all<br />
                <input
                  type='checkbox'
                  v-model='selectAll'
                  style='text-align: center'
                  @change="$root.$emit('SelectedAll', selectAll)"
                />
              </th>
              <th @click="sortBy('firstNameEnglish')">
                First Name
                {{
                  lastOrder === 'firstNameEnglish'
                    ? sortOrders['firstNameEnglish']
                      ? '▼'
                      : '▲'
                    : ''
                }}
              </th>
              <th @click="sortBy('lastNameEnglish')">
                Last Name
                {{
                  lastOrder === 'lastNameEnglish'
                    ? sortOrders['lastNameEnglish']
                      ? '▼'
                      : '▲'
                    : ''
                }}
              </th>
              <th>Local Group</th>
              <th @click="sortBy('email')">
                Email
                {{
                  lastOrder === 'email' ? (sortOrders['email'] ? '▼' : '▲') : ''
                }}
              </th>
              <th @click="sortBy('mailStatus')">
                Email verified
                {{
                  lastOrder === 'mailStatus'
                    ? sortOrders['mailStatus']
                      ? '▼'
                      : '▲'
                    : ''
                }}
              </th>
              <th>Mobile phone</th>
              <th @click="sortBy('alumniStatus')">
                Alumni
                {{
                  lastOrder === 'alumniStatus'
                    ? sortOrders['alumniStatus']
                      ? '▼'
                      : '▲'
                    : ''
                }}
              </th>
              <th @click="sortBy('created')">
                Application date
                {{
                  lastOrder === 'created'
                    ? sortOrders['created']
                      ? '▼'
                      : '▲'
                    : ''
                }}
              </th>
              <th @click="sortBy('deletionDate')">
                Deletion date
                {{
                  lastOrder === 'deletionDate'
                    ? sortOrders['deletionDate']
                      ? '▼'
                      : '▲'
                    : ''
                }}
              </th>
            </tr>
            <signUpItem
              ref='signUpList'
              v-for='applicant in applicants'
              v-bind:applicant='applicant'
              v-bind:key='applicant.userId'
              v-on:checkboxChanged='dataCollect'
              :localGroups='lgs'
            ></signUpItem>
          </table>

          <div class='pagination'>
            <b-btn @click='prevPage'>Prev</b-btn>
            <span class='dot'>{{ this.pageNumber + 1 }}</span>
            <b-btn @click='nextPage'>Next</b-btn>
          </div>
          <div class='bottom_buttons'>
            <b-btn
              v-if="selectedNonVerifiedUsers"
              disabled
              title="You can not accept a user with a non-verified mail!"
            >
              Accept
            </b-btn>
            <b-btn v-else variant='success' @click='AcceptAction'>Accept</b-btn>
            <b-btn @click='denyAction'>Deny</b-btn>
          </div>
          <!-- Debug div to show number of selected users
          <div>
            {{ selectedArray.length }} Users selected
          </div>
          -->
        </div>
        <div v-if='applicants.length === 0'>
          <center>
            <h2>Sorry, but it looks like there are no more applicants ;(</h2>
            <div class='pagination'>
              <b-btn @click='prevPage'>Prev</b-btn>
              <span class='dot'>{{ this.pageNumber + 1 }}</span>
              <b-btn @click='nextPage'>Next</b-btn>
            </div>
          </center>
        </div>
      </div>
      <b-modal ref='accept' hide-footer title='Application(s) accepted'>
        <div>
          <p>
            You have successfully accepted {{ this.count }} application(s). If
            the table does not update, please reload this page.
          </p>
        </div>
      </b-modal>
      <b-modal ref='deny' hide-footer title='Application(s) denied'>
        <div>
          <p>
            You have successfully denied {{ this.count }} application(s). If the
            table does not update, please reload this page.
          </p>
        </div>
      </b-modal>
      <b-modal ref='error' hide-footer title='Error'>
        <div>
          <p>Something went wrong, it might be a server problem.</p>
        </div>
      </b-modal>
      <b-modal ref="disabled-accept" hide-footer title="Can't accept user(s) with a non-verified email!">
        <div>
          <p>
            You cannot accept Users that have not verified their E-Mail!
          </p>
        </div>
      </b-modal>
      <!-- <b-modal ref='my-modal' hide-footer title='Using Component Methods'>
      <div class='d-block text-center'>
        <h3>Are you sure you want to delete these users?</h3>
      </div>
      <b-button class='mt-3' @click='hideModal'>Close</b-button>
      <b-button class='mt-2' @click='denyAction'>Proceed</b-button>
    </b-modal> -->
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import signUpItem from './signUpItem.vue';
import {find, forEach} from "underscore";
import user from "../../store/modules/user";

export default {
  name: 'sign-up-list',

  data() {
    let count;
    return {
      count,
      error: false,
      pageSize: 10,
      pageNumber: 0,
      applicants: {},
      selectedArray: [],
      selectAll: false,
      sortOrders: {},
      lastOrder: '',
      lgs: [],
    };
  },

  mounted() {
    const url = `/users?disabled=true&pageSize=${this.pageSize}&pageNumber=${this.pageNumber}`;
    axios.defaults.headers.common.accept = 'application/json';
    axios
      .get(url)
      .then((response) => {
        this.applicants = Object.values(response.data);
        Object.keys(this.applicants[1]).forEach((key) => {
          this.sortOrders[key] = 1;
        });
      })
      .catch((error) => {
        if (error.response.status) {
          this.$router.push('/404');
        }
      });
  },

  components: { signUpItem },

  computed: {
    localResponsible() {
      return this.$store.getters.isLR;
    },
    admin() {
      return this.$store.getters.isAdmin;
    },
    selectedNonVerifiedUsers() {
      return this.hasSelectedNonVerifiedUsers();
    },
  },

  methods: {
    hasSelectedNonVerifiedUsers() {
      if (this.selectedArray.length === 0) {
        return false; // no selected applicants
      }
      if (this.applicants.length === 0) {
        return false; // No applicants
      }

      for (let i = 0; i < this.selectedArray.length; i += 1) {
        const selectedUser = this.selectedArray[i];
        const matchingApplicant = this.applicants.find(applicant => applicant.userId === selectedUser.id);
        if (matchingApplicant && matchingApplicant.mailStatus === 2) {
          return true; // Early return if a non-verified user is found
        }
      }

      return false;
    },
    nextPage() {
      // we check for 0 so because nr*0=0 so we'd go to an empty page
      // if (this.pageNumber === 0) {
      //   if (this.pageNumber + this.pageSize <= this.applicants.length) {
      //     this.pageNumber = this.pageNumber + 1;
      //     this.getTable();
      //   }
      // } else if (this.pageNumber * this.pageSize < this.applicants.length) {
      //   this.pageNumber = this.pageNumber + 1;
      //   this.getTable();
      // }
      if (this.applicants.length === this.pageSize) {
        this.pageNumber = this.pageNumber + 1;
        this.getTable();
      }
    },
    prevPage() {
      if (this.pageNumber >= 1) {
        this.pageNumber = this.pageNumber - 1;
        this.getTable();
      }
    },
    async getTable() {
      const url = `/users?disabled=true&pageSize=${this.pageSize}&pageNumber=${this.pageNumber}`;
      axios.defaults.headers.common.accept = 'application/json';
      await axios.get(url).then((response) => {
        response.data.forEach((element) => {
          const myDate = new Date(element.created);
          myDate.setDate(myDate.getDate() + 30);
          element.deletionDate = myDate.toISOString();
        });
        this.applicants = response.data;
      });
    },
    dataCollect(id, status, check) {
      const user = {
        id,
        status,
      };
      if (check) {
        this.selectedArray.push(user);
      } else {
        const index = this.selectedArray.findIndex(u => u.id === id);
        if (index !== -1) {
          this.selectedArray.splice(index, 1);
        }
        // this.selectedArray.splice(this.selectedArray.indexOf(user), 1);
      }
    },

    async AcceptAction() {
      let flag = true;

      this.count = this.selectedArray.length;
      while (this.selectedArray.length !== 0) {
        const user = this.selectedArray.pop();
        const userId = user.id;
        const config = {
          headers: {
            header1: 'accept: */*',
            header2: 'Content-Type: text/json',
          },
        };

        // TODO: ask for alumni status code for sending to server
        if (user.status === 8) {
          // eslint-disable-next-line no-await-in-loop
          await axios
            .put(
              `/users/${userId}/status`,
              {
                userId,
                status: 1,
              },
              config,
            )
            // eslint-disable-next-line no-loop-func
            .then((response) => {
              if (response.status !== 204) {
                flag = false;
              }
            })
            // eslint-disable-next-line no-loop-func
            .catch(() => {
              flag = false;
            });
        } else {
          // eslint-disable-next-line no-await-in-loop
          await axios
            .put(
              `/users/${userId}/status`,
              {
                userId,
                status: 2,
              },
              config,
            )
            // eslint-disable-next-line no-loop-func
            .then((response) => {
              if (response.status !== 204) {
                flag = false;
              }
            })
            // eslint-disable-next-line no-loop-func
            .catch(() => {
              flag = false;
            });
        }
      }

      if (flag) {
        this.$refs.accept.show();
      } else {
        this.$refs.error.show();
      }
      this.$root.$emit('SelectedAll', false);
      await this.getTable();
    },

    denyAction() {
      this.count = this.selectedArray.length;
      while (this.selectedArray.length !== 0) {
        this.selectedArray.forEach((applicant) => {
          this.selectedArray.splice(this.selectedArray.indexOf(applicant), 1);
          axios.delete(`/users/${applicant.id}`).then(() => {
            this.getTable();
          });
        });
      }
      this.$refs.deny.show();
    },

    sortBy(key) {
      this.applicants.sort((a, b) => {
        const nameA = a[key];
        const nameB = b[key];
        if (typeof nameA === 'string' && typeof nameB === 'string') {
          nameA.toLowerCase();
          nameB.toLowerCase();
        }

        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });

      if (this.lastOrder === key) {
        this.sortOrders[key] = !this.sortOrders[key];
      }
      this.lastOrder = key;

      if (!this.sortOrders[key]) {
        return this.applicants;
      }

      return this.applicants.reverse();
    },
    // showModal() {
    //     this.$refs['my-modal'].show()
    //   },
    //  hideModal() {
    //     this.$refs['my-modal'].hide()
    //   },
  },
  created() {
    axios
      .get('/localgroups')
      .then((response) => {
        this.lgs = response.data;
      })
      .then(this.getTable());
  },
};
</script>

<style scoped>
#learn-more {
  color: #0275d8; /*the color of the other links in the portal*/
  cursor: pointer;
  font-weight: bold;
}

#intro-text {
  margin: 0 20%;
}

#wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

table {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  background-color: rgba(80, 137, 112, 0.05);
  box-sizing: border-box;
}

th {
  font-size: 2vh;
  top: 60px;
  text-align: center;
  border: 1px solid #1d4836;
  padding: 8px;
  background-color: #205e44;
  color: rgba(255, 255, 255, 0.5);
  position: sticky;
}

signUpItem {
  overflow: auto;
}

.header {
  padding: 10px;
}

.pagination {
  display: inline-block;
  margin: 20px 0 10px 0;
  /* color:  #8FAEA1;  */
}

.dot {
  width: 35px;
  height: 35px;
  margin: 0 5px;
  background-color: #205e44;
  border-radius: 50%;
  display: inline-flex;
  color: rgba(255, 255, 255, 0.5);
  font-weight: bold;
  font-size: 18px;
  justify-content: center;
  align-items: center;
}

.bottom_buttons {
  text-align: center;
  padding-top: 20px;
  padding-bottom: 80px;
}

.applicant-list {
  top: auto;
  width: 80%;
  display: contents;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
}
</style>
