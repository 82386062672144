<template>
<!-- Sign In Pop-up -->
    <b-modal id="login" hide-footer title="Sign in to ESTIEM">
    <div class="signin">
      <b-form class="form" @submit.prevent="login">
        <b-alert :show="errorText != ''" variant="danger">{{ errorText }}</b-alert>
        <b-form-group>
          <b-form-input type="text" v-model="userName" required placeholder="Username"/>
        </b-form-group>
        <b-form-group>
          <b-form-input type="password" v-model="password" required placeholder="Password"/>
        </b-form-group>
        <b-button type="submit" variant="primary">Sign in</b-button>
        <b-button type="button" @click.prevent="hideModal('login')">Cancel</b-button>
        <!-- Need functionality for lost password -->
        <b-button id="login_lost_btn" type="button" class="btn btn-link" v-b-modal.forgotten>Lost password?</b-button>
        <!-- In future it would be good for user to sign up themselves (followed by a final verification by LR) -->
      </b-form>
      <br>
      <p>To sign up, contact the Local Responsible at your <a href="/localgroups" class="link">university.</a></p>
      <div class="alert alert-info fade show" role="alert">
          <strong>Admin and LR</strong> features are currently only available through the
          <a href="https://docs.google.com/document/d/10c7iBG2cFxdJyoIZ6vzMgF74rikar6qTMD1Br6-u608/edit?usp=sharing">Single Sign On</a>.
      </div>
    </div>
  </b-modal>
</template>

<script>
import auth from '../../auth/index.js';

export default {
  data() {
    return {
      forgottenPassword: '',
      errorText: '',
      userName: '',
      password: '',
    };
  },
  methods: {
    login() {
      const credentials = {
        userName: this.userName,
        password: this.password,
      };
      auth.login(credentials, this.$route.fullPath)
        .then(() => this.hideModal('login'))
        .catch(e => this.errorText = e); // eslint-disable-line
      this.password = '';
    },
    hideModal(id) {
      this.$root.$emit('bv::hide::modal', id);
    },
  },

};
</script>
