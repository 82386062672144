<template>
  <div class="container">
    <section class="search-admin">
      <vue-bootstrap-typeahead id="eventadmin" ref="adminTypeahead" style="width: 75%" :value="estiemerQuery"
      v-model="estiemerQuery" :data="estiemerOptions"
      placeholder="Event Administrator (required)"></vue-bootstrap-typeahead>
      <b-button variant="secondary" class="my-2" @click="searchUsers">
        Search Admin
      </b-button>
    </section>
    <form @submit.prevent="createGroup" class="form-create">
      <label for="name" >Name</label>
      <b-form-input id="name" type="text" v-model="form.name" required/>
      <label for="subcription">Public Subscription</label>
      <input id="subcription" type="checkbox" v-model="form.publicSubscriptionEnabled">
      <label for="senderRestriction">Sender Restriction</label>
      <b-form-select id="senderRestriction" v-model="form.senderRestriction" :options="senderRestrictionOptions" required/>
      <label for="externalMembers">Externals Members</label>
      <input id="externalMembers" type="checkbox" v-model="form.hasExternalMembers"/>
      <label for="description">Description</label>
      <b-form-input id="description" type="text" v-model="form.description" required/>
      <b-button v-show="!toggle" type="submit" variant="success">Create Group</b-button>
      <spinner v-if="toggle"></spinner>
    </form>
  </div>
</template>
<script>
import axios from 'axios';
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead';
import { errorToast } from '../../helpers/toaster-helper.js';
import { SenderRestrictionOptions } from '../../helpers/group-detail.js';
import spinner from '../Spinner/spinner.vue';

export default {
  data() {
    return {
      toggle: false,
      form: {
        adminUserId: '',
        publicSubscriptionEnabled: false,
        senderRestriction: '',
        hasExternalMembers: false,
        description: '',
        name: '',
      },
      senderRestrictionOptions: [...SenderRestrictionOptions],
      createdGroupID: '',
      estiemerList: [],
      estiemerQuery: '',
      loadingEstiemers: false,
    };
  },
  components: {
    VueBootstrapTypeahead,
    spinner,
  },
  methods: {
    checkForm() {
      let isValid = true;
      // Get the adminuserId from the query
      const eventAdminName = this.estiemerQuery.split(', LG ')[0];
      const eventAdminLG = this.estiemerQuery.split(', LG ')[1];
      if (!this.estiemerList || this.estiemerList.length === 0) {
        isValid = errorToast(this.$toasted, 'Please enter a Group Administrator.');
      } else {
        this.form.adminUserId = this.estiemerList.filter(estiemer =>
          (`${estiemer.firstNameEnglish} ${estiemer.lastNameEnglish}`) === eventAdminName
            && estiemer.localGroupName === eventAdminLG)[0].userId;
      }

      if (!this.form.adminUserId) isValid = errorToast(this.$toasted, 'Given Group Admin unknown.');
      if (!this.form.name) isValid = errorToast(this.$toasted, 'Group name must not be empty.');
      if (!this.form.description) isValid = errorToast(this.$toasted, 'Group must have a decription');
      if (this.form.senderRestriction < 0 || this.form.senderRestriction > 2) isValid = errorToast(this.$toasted, 'Sender restriction should be a number between 0 and 2');

      return isValid;
    },
    createGroup() {
      if (this.checkForm()) {
        const params = this.form;
        this.toggle = true;
        axios.post('/groups', params)
          .then((response) => {
            if (response.status === 200) {
              this.createdGroupID = response.data.roleId.toString();
              setTimeout(() => {
                this.$router.push({ name: 'GroupsList' });
              }, 1000);
            } else {
              this.toggle = false;
              errorToast(this.$toasted, 'Something went bad in the request');
            }
          })
          .catch((error) => {
            this.toggle = false;
            console.error('There was an error creating the group: ', error);
          });
      }
    },
    searchUsers() {
      this.loadingEstiemers = true;
      axios
        .get(`/user/search/${this.estiemerQuery}`)
        .then((response) => {
          this.estiemerList = response.data;
          this.loadingEstiemers = false;
        })
        .catch(() => {
          this.loadingEstiemers = false;
        });
    },
  },
  computed: {
    // Sort ESTIEMer
    estiemerOptions() {
      if (this.estiemerList) {
        return this.estiemerList.map(estiemer => `${estiemer.firstNameEnglish} ${estiemer.lastNameEnglish}, LG ${estiemer.localGroupName}`);
      }
      return null;
    },
  },
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  align-items: center;
}
.search-admin {
  display: flex;
  gap: 4px;
  justify-content: start;
  align-items: center;
}
.form-create {
  display: grid;
  grid-template-columns: repeat(2, minmax(100px,auto));
  gap: 16px;
  justify-content: start;
  align-items: center;
  margin-top: 32 px;
}
.clickable-text {
  color: var(--blue-primary) !important; /*the color of the other links in the portal*/
  cursor: pointer;
  font-weight: bold;
}
</style>
