<template>
  <div>
    <div class="event-filters mb-4 mx-auto">
      <b-row class="event-filter-row mx-auto mb-4">
         <b-btn variant="success" v-b-tooltip.hover v-if="isAdmin" title="Create a New Event" to="/create"
          router-tag="button">
          <b-icon icon="plus"></b-icon>Add Event
        </b-btn>
        <b-btn variant="success" @click="switchNextDisplayType()" id="maplist">
          Switch View
        </b-btn>
        <b-btn variant="success" v-if='mode === EventMode.ACTUAL' @click="changeMode">Past events</b-btn>
        <b-button-group v-if='mode === EventMode.ACTUAL'>
          <b-btn variant="success" v-model="evStatus" :disabled="selectedEventStatus" value="all"
            @click="changeEventStatus">Upcoming Events</b-btn>
          <b-btn variant="success" v-model="evStatus" :disabled="!selectedEventStatus" value="open"
            @click="changeEventStatus">Application Open</b-btn>
        </b-button-group>
        <b-btn variant="success" v-if="mode === EventMode.PAST" @click="changeMode">Current Events</b-btn>
        <b-pagination class="mb-0" v-if="mode === EventMode.PAST" v-model="page" :total-rows="rows" :per-page="1"
          :container-class="'pagination'" :pageClass="'page-item'" :pageLinkClass="'page-link-item'"></b-pagination>
        <b-form-select class="form-control" v-model="evCategory" :options="evCategoryOptions" />
        <b-form-input class="event-search" v-model="searchString" placeholder="Search for Events"></b-form-input>
      </b-row>
      <div class="mx-auto">
        <event-list v-if="getCurrentDisplayType().title === 'cards'" :events="events" :isList="false"></event-list>
        <event-map v-else-if="getCurrentDisplayType().title === 'map'" :events="events"></event-map>
        <event-list class="col-12" v-if="getCurrentDisplayType().title === 'list'" :isList="true"
          :events="events"></event-list>
      </div>
      </div>
    <BackToTop></BackToTop>
  </div>
</template>

<script>
import moment from 'moment';
import eventList from './eventlist.vue';
import listEvent from './list-event.vue';
import eventMap from './eventmap.vue';
import BackToTop from '../home/back-to-top-btn.vue';
import { EventCategoryOptions } from '../../helpers/event-category-options.js';
import { EventMode } from '../../helpers/event-mode.js';
import { EventCategory } from '../../helpers/event-category.js';
import { EventStatus } from '../../helpers/event-status.js';

export default {
  data() {
    return {
      // actual | past events
      mode: EventMode.ACTUAL,
      page: 1,
      // Per page and rows are only used to calculate how many pages are visible.
      // In the future it could make sense to return total number of events for pagination
      perPage: 1,
      rows: 100,
      searchString: '',
      evCategory: EventCategory.ALL,
      evStatus: EventStatus.ALL,
      selectedEventStatus: true,
      applicationOpen: false,
      evCategoryOptions: [...EventCategoryOptions],
      displayType: {
        types: [{ title: 'cards', icon: 'view_module' }, { title: 'list', icon: 'list' }, { title: 'map', icon: 'map' }],
        i: 0,
        currentType() { return this.types[this.i]; },
        next() {
          this.i = (this.i + 1) % 3;
        },
      },
      displayMap: false,
      mapOrList: 'map',
      displaySwitch: 'Map View',
      EventMode,
      EventCategory,
      EventStatus,
    };
  },
  props: ['count'],
  components: {
    BackToTop,
    listEvent,
    eventList,
    eventMap,
  },
  computed: {
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
    // returns events in chronological order, with filters applied if filters are selected
    events() {
      // How to do filtering for futue events vs past events.
      // Future events we want to filter here, past we just pass
      if (this.mode === EventMode.PAST) {
        return this.$store.getters.pastEvents;
      }
      const studentEvents = this.$store.getters.events;
      const alumniEvents = this.$store.getters.alumniEvents;
      let events = studentEvents.concat(alumniEvents);
      if (!this.showPastEvents) {
        events.sort((a, b) => new Date(a.startDate) - new Date(b.startDate));
      }
      if (this.evStatus === EventStatus.OPEN) {
        events = this.filterEventsByStatus(events);
      }
      if (this.evCategory) {
        events = this.filterEventsByCategory(events);
      }

      if (this.applicationOpen) {
        events = this.getApplicationOpen(events);
      }
      if (this.searchString) {
        events = this.filterEventsBySearchstring(events);
      }
      events = events.slice(0, this.count);
      return events;
    },
  },
  watch: {
    searchString() {
      if (this.mode === EventMode.PAST) {
        this.page = 1;
        this.fetchPastEvents();
      }
    },
    page() {
      if (this.mode === EventMode.PAST) {
        this.fetchPastEvents();
      }
    },
    evCategory() {
      if (this.mode === EventMode.PAST) {
        this.page = 1;
        this.fetchPastEvents();
      }
      localStorage.setItem('filteredEventType', this.evCategory);
    },
    //just in Case the Route get Change somehow without loading the Page
    $route(to, from) {
      this.loadFilter();
    },
  },
  methods: {
    changeMode() {
      if (this.mode === EventMode.ACTUAL) {
        this.mode = EventMode.PAST;
        this.fetchPastEvents();
      } else {
        this.mode = EventMode.ACTUAL;
      }
    },
    changeEventStatus() {
      if (this.evStatus === EventStatus.ALL) {
        this.evStatus = EventStatus.OPEN;
        this.selectedEventStatus = !this.selectedEventStatus;
      } else if (this.evStatus === EventStatus.OPEN) {
        this.evStatus = EventStatus.ALL;
        this.selectedEventStatus = !this.selectedEventStatus;
      }
    },
    switchNextDisplayType() {
      this.displayType.next();
    },
    getCurrentDisplayType() {
      return this.displayType.currentType();
    },
    getDefaultEventCategory() {
      return localStorage.getItem('filteredEventType');
    },
    setEventCategoryDefaultValue() {
      const eventDefaultCategoryFromLocalStorage = this.getDefaultEventCategory();
      let eventCategory;
      if (eventDefaultCategoryFromLocalStorage) {
        eventCategory = eventDefaultCategoryFromLocalStorage;
      } else {
        eventCategory = this.alumnus() ? EventCategory.ALUMNI : EventCategory.ALL;
      }
      return eventCategory;
    },
    fetchPastEvents() {
      const params = {
        pageNumber: this.page,
        category: this.evCategoryOptions.find(x => x.value === this.evCategory),
        searchString: this.searchString,
      };
      this.$store.dispatch('fetchPastEvents', params);
    },
    switchDisplay() {
      this.displayMap = !this.displayMap;
      this.mapOrList = (this.displayMap ? 'view_module' : 'map');
      if (this.displaySwitch === 'Map View') {
        this.displaySwitch = 'List View';
      } else {
        this.displaySwitch = 'Map View';
      }
    },
    resetFilters() {
      this.evCategory = EventCategory.ALL;
      this.evStatus = EventStatus.ALL;
      this.applicationOpen = false;
    },
    loadFilter() {
      if (this.$route.query.q === 'applicationOpen') {this.evStatus = EventStatus.OPEN; this.selectedEventStatus = false; this.evCategory = EventCategory.ALL;}
      if (this.$route.query.q === 'academic') this.evCategory = EventCategory.ACADEMIC;
      if (this.$route.query.q === 'career') this.evCategory = EventCategory.CAREER;
      if (this.$route.query.q === 'cultural') this.evCategory = EventCategory.CULTURAL;
      if (this.$route.query.q === 'personal') this.evCategory = EventCategory.PERSONAL;
      if (this.$route.query.q === 'central') this.evCategory = EventCategory.CENTRAL;
      if (this.$route.query.q === 'exchange') this.evCategory = EventCategory.EXCHANGE;
      if (this.$route.query.q === 'alumni') this.evCategory = EventCategory.ALUMNI;
      if (this.$route.query.q === 'all') this.evCategory = EventCategory.ALL;
    },
    filterEventsByType(events) {
      return events.filter(ev => this.evTypes.includes(ev.eventType));
    },
    filterEventsByStatus(events) {
      const now = moment();
      const newevents = events.filter(e => now.isSameOrBefore(e.applicationEndDate) && now.isSameOrAfter(e.applicationStartDate));
      return newevents;
    },
    filterEventsBySearchstring(events) {
      const self = this; // store vue instance for callback function
      return events.filter((e) => {
        if (e.name.toLowerCase().includes(self.searchString.toLowerCase())) return e;
        if (e.place.toLowerCase().includes(self.searchString.toLowerCase())) return e;
        return false;
      });
    },
    filterEventsByEarliestDate(events) {
      return events.filter((e) => {
        const startDatex = moment(e.startDate);
        return startDatex.isSameOrAfter(this.earliestDate);
      });
    },
    filterEventsByLatestDate(events) {
      return events.filter((e) => {
        const startDatex = moment(e.startDate);
        return startDatex.isSameOrBefore(this.latestDate);
      });
    },
    getApplicationOpen(events) {
      const now = moment();
      const newevents = events.filter(e => now.isSameOrBefore(e.applicationEndDate) && now.isSameOrAfter(e.applicationStartDate));
      return newevents;
    },
    filterEventsByCategory(events) {
      // All events is empty
      if (this.evCategory === '') return events;
      const category = this.evCategoryOptions.find(x => x.value === this.evCategory);
      return events.filter(e => category.eventTypes.some(x => x.value === e.eventType));
    },
    alumnus() {
      return this.$store.getters.userroles.includes('Alumni') || this.$route.name === EventCategory.ALUMNI;
    },
  },
  beforeMount() {
    //i just leave this hier, because there might be a usecase for it
    //the second want after mouting is still necesarry
    this.loadFilter();
    this.evCategory = this.setEventCategoryDefaultValue();
  },
  mounted() {
    this.loadFilter();
    // Show all events if user is on the active list
    if (this.$store.getters.userroles.includes('active')) {
      this.evCategory = EventCategory.ALL;
    }
    // Show alumni events if user is alumni, but not junior alumni
    if (this.$store.getters.userroles.includes('Alumni') && !this.$store.getters.userroles.includes('Student')) {
      this.evCategory = EventCategory.ALUMNI;
    }
    //just set Default if there is'nt any specified Category in the URL
    if(!this.$route.query.q) {
      this.evCategory = this.setEventCategoryDefaultValue();
    }
  },
};
</script>

<style scoped>
.event-filters {
  padding: 1rem 7%;
}

.event-filter-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; /* Allow items to wrap */
  justify-content: center;
  gap: 6px;
}

@media screen and (max-width:870px) {
  /* Remove flex-direction: column to maintain row direction */
  .event-filter-row {
    flex-wrap: wrap; /* Ensure wrapping is enabled */
    /* flex-direction: row; This is optional since it's already set above */
  }
}

.material-icons {
  vertical-align: middle;
  color: white;
}

.event-search {
  display: inline-block;
  min-width: 160px;
  max-width: 400px;
  color: rgba(32, 94, 68, 1);
}

.event-search:hover {
  border-color: rgba(32, 94, 68, 1);
}

.event-search:focus {
  border-color: rgba(32, 94, 68, 1);
}

.custom-select {
  min-width: 110px;
  max-width: 300px;
  vertical-align: middle;
  height: 42px !important;
}

.custom-select:hover,
.custom-select:focus {
  border-color: rgba(32, 94, 68, 1);
  outline: none;
}

.custom-select option:checked,
.custom-select option:hover,
.custom-select:focus option:hover,
.form-control:focus option:hover {
  color: white;
  background-color: rgba(32, 94, 68, 1);
}

option:hover {
  color: white !important;
  background-color: rgba(32, 94, 68, 1) !important;
}

.btn[disabled] {
  cursor: default !important;
}
</style>

