<template>
  <div v-bind:class="{ alumnus }">
    <div class="navbar-container">
      <b-navbar>
        <div class="back-container">
          <b-navbar-brand @click="goBack" v-if="!isHome" class="back-icon"><i class="material-icons header-arrow">chevron_left</i></b-navbar-brand>
        </div>
        <b-navbar-brand to="/" v-if="!alumnus" class="logo-container"><img src="https://estiem.blob.core.windows.net/estiem-images/estiem_logo.png" class="logo" alt="Estiem"></b-navbar-brand>
        <b-navbar-brand to="/" v-if="alumnus" class="logo-container"><img src="https://estiem.blob.core.windows.net/estiem-images/estiem_alumni_logo.png" class="logo" alt="Estiem"></b-navbar-brand>
        <div id="skip" class="search-container">
          <b-navbar-brand v-b-toggle.collapseSearch v-if="authenticated"><i class="material-icons">search</i></b-navbar-brand>
        </div>
        <b-navbar-nav class="navbar-nav">
          <b-nav-item to="/events">
            <span>Events</span>
          </b-nav-item>
          <!-- COUNCIL MEETING APPLICATION -->
          <b-nav-item target="_blank" href="https://internal.estiem.org/lxx-council-meeting/" v-if="authenticated">
            <span>Council Meeting</span>
          </b-nav-item>
          <b-nav-item  href="https://internal.estiem.org/career-center-overview/partners/">
            <span>Partners</span>
          </b-nav-item>
          <b-nav-item-dropdown v-if="authenticated" text="Tools" right class="text-color">
            <b-dropdown-item v-if="localResponsible || admin" to="/sign-up-list"  ><span >Applicants list</span></b-dropdown-item>
            <b-dropdown-item  target="_blank" href="https://internal.estiem.org/opencalls/" v-if="authenticated" ><span >Join Central ESTIEM</span></b-dropdown-item>
            <b-dropdown-item target="_blank" href="https://drive.google.com/drive/folders/0AGqnWG0MGFdUUk9PVA" ><span >Members Drive</span></b-dropdown-item>
            <b-dropdown-item target="_blank" href="https://estiem.elium.com/" ><span >Elium</span></b-dropdown-item>
            <b-dropdown-item href="https://internal.estiem.org/career-center-overview/" v-if="authenticated" target="_blank" ><span >Career Center</span></b-dropdown-item>
          </b-nav-item-dropdown>
          <b-nav-item-dropdown text="Shops" right>
            <b-dropdown-item href="https://shop.spreadshirt.nl/estiem/" target="_blank" ><span >ESTIEM Shop</span></b-dropdown-item>
            <b-dropdown-item href="https://internal.estiem.org/magazine-shop/" target="_blank" ><span >ESTIEM Magazine Shop</span></b-dropdown-item>
          </b-nav-item-dropdown>
          <b-nav-item-dropdown text="About" right class="text-color">
            <b-dropdown-item :to="'/about'"><span>About ESTIEM</span></b-dropdown-item>
            <b-dropdown-item :to="'/structure'"><span>ESTIEM Structure</span></b-dropdown-item>
            <b-dropdown-item :to="'/localgroups'"><span>Local Groups</span></b-dropdown-item>
            <b-dropdown-item v-if="authenticated" :to="'/active'"><span>Active ESTIEM</span></b-dropdown-item>
            <b-dropdown-item v-if="authenticated" :to="'/groups'"><span>ESTIEM Groups</span></b-dropdown-item>
          </b-nav-item-dropdown>
          <b-nav-item key="authenticated" @click="loginSSO" v-if="!authenticated">
            <font-awesome-icon class="fa-icons" :icon="['fab', 'microsoft']" /><span style="vertical-align: top">Sign in with SSO</span>
          </b-nav-item>
          <b-nav-item key='unauthenticated'  v-if="authenticated" :to="'/profile/' + userProfile">
            <span>My profile</span>
          </b-nav-item>
          <b-nav-item-dropdown right>
            <!-- Using 'button-content' slot -->
            <template slot="button-content">More</template>
            <b-dropdown-item key="authenticated" v-b-modal.login v-if="!authenticated" >
              <span >Sign in</span> <i class="material-icons" style="vertical-align:middle; color: var(--estiem-green)">input</i>
            </b-dropdown-item>
            <b-dropdown-item v-if="!authenticated" to="/sign-up" >
              <span >Apply to become an ESTIEMer</span>
            </b-dropdown-item>
            <!-- <b-dropdown-item @click="loginSSO" v-if="!authenticated">
              <font-awesome-icon class="fa-icons" :icon="['fab', 'microsoft']" /><span style="vertical-align: top">SSO Login</span>
            </b-dropdown-item> -->
            <b-dropdown-item  href="https://old.estiem.org/default.aspx?pageId=508" >
                <!--removed the v-if="!alumnus" so that alumni and junior alumni still have a way to access the old portal -Robin 3.9.19-->
                <!--TODO: put the check back in once old portal is discontinued-->
              <span >Old portal</span>
            </b-dropdown-item>
            <b-dropdown-item v-if="alumnus" href="https://old.estiem.org/Alumni/default.aspx?PageId=481" >
              <span >Old alumni portal</span>
            </b-dropdown-item>
            <b-dropdown-item v-if="authenticated && !authenticatedSSO" @click="logout" >
              <i class="material-icons" style="vertical-align:middle">input</i> <span >Sign out</span>
            </b-dropdown-item>
            <b-dropdown-item v-if="authenticatedSSO" @click="logoutSSO" >
              <span >Sign out from SSO</span> <i class="material-icons" style="vertical-align:middle; color: var(--estiem-green);" >input</i>
            </b-dropdown-item>
          </b-nav-item-dropdown>
          <!--<b-nav-item-dropdown v-if="authenticated && admin" text="Admin" right>
           // <b-dropdown-item><router-link to = "/groups" ><span >Groups</span> </router-link></b-dropdown-item>
          //</b-nav-item-dropdown> --> <!--Because it is not realy necesary now. There is no special Thing admin cant do by Clicking somewhere else on the page
          -> He can manage groups by Clicking on Estiemgroups. He will just have more rights on the page.
          -> We may also Think about it not to Show this Grouppage to normal Users! Or may be in a different way. The code in the Backend may also Change
          -> Example: can a user remove hisself from every Kind of Groups? It is cool for an Admin to see all the Group, but user schould only
          -> Groups the can remove themself from and also ask for getting added.
          -->
        </b-navbar-nav>
      </b-navbar>
    </div>
    <div class="searchbar">
      <b-collapse id="collapseSearch" class="mt-2" ref="collapseSearch" v-model="searchBarVisible">
          <b-input-group class="searchbarhp" v-if="authenticated">
            <b-form-input v-model="searchString" placeholder="Find ESTIEMers or Local Groups" @keyup.enter.native="search"></b-form-input>
            <b-input-group-button>
              <b-btn v-b-tooltip.hover title="Search">
                  <i class="material-icons" style="vertical-align:middle" @click="search">search</i>
              </b-btn>
            </b-input-group-button>
          </b-input-group>
      </b-collapse>
    </div>
    <login></login>
  </div>
</template>

<script>
import login from '../components/login/login.vue';
import auth from '../auth/index.js';

export default {
  data() {
    return {
      forgottenPassword: '',
      searchString: '',
      pageNumber: 1,
      searchCompleted: false,
      errorText: '',
      user: {},
      password: '',
      searchBarVisible: false,
      clicked: false,
    };
  },
  components: {
    login,
  },
  computed: {
    authenticated() {
      return this.$store.getters.authenticated;
    },
    authenticatedSSO() {
      return this.$store.getters.authenticatedSSO;
    },
    alumnus() {
      return this.$store.getters.userroles.includes('Alumni') || this.$route.name === 'alumni';
    },
    localResponsible() {
      return this.$store.getters.isLR;
    },
    admin() {
      return this.$store.getters.isAdmin;
    },
    userName() {
      return localStorage.getItem('userName');
    },
    userProfile() {
      return encodeURIComponent(this.userName);
    },
    isHome() {
      return this.$route.name === 'homepage';
    },
  },
  methods: {
    loginSSO() {
      auth.loginSSO();
    },
    logout() {
      auth.logout();
    },
    logoutSSO() {
      auth.logoutSSO();
    },
    goBack() {
      // eslint-disable-next-line no-unused-expressions
      window.history.length > 1
        ? this.$router.go(-1)
        : this.$router.push('/');
    },
    search() {
      this.$router.push({ path: `/search?searchString=${this.searchString}` });
      // if (this.searchString.length > 1) {
      //   axios.get(`https://api.estiem.org/api/user/search/${this.searchString}?page=${this.pageNumber}`)
      //     .then((response) => {
      //       this.returnedEstiemers = response.data;
      //     });
      //   this.searchCompleted = true;
      // } else {
      // searching for a single letter makes the search on the backend two intensive
      //  alert('please enter at least 2 letters to start a search');
      // }
    },
    hideSearchBar() {
      this.searchBarVisible = false;
    },
    isClicked() {
      this.clicked = true;
    },
    checkCollapseSearchBarVisibilty(e) {
      if (this.clicked === true) {
        this.clicked = false;
        document.stopPropagation();
      } else if (!this.$refs.collapseSearch.$el.contains(e.target)) {
        this.$refs.collapseSearch.$props.visible = false;
      }
    },
  },
  mounted() {
    // document.addEventListener('scroll', this.checkCollapseSearchBarVisibilty);
    document.getElementById('skip').addEventListener('click', this.isClicked);
    document.addEventListener('click', this.checkCollapseSearchBarVisibilty);
  },
};
</script>

<style scoped>
.fa-icons {
  font-size: 25px;
  margin-left: 5px;
  margin-right: 5px;
}

.logo {
  margin-left: 25px;
}

.material-icons{
  color: white;
  margin: 0;
  padding: 0;
}

.navbar {
  flex-direction: row;
}

.navbar-container {
  position: fixed;
  top: 0;
  background-color: var(--estiem-green);
  color: rgba(255,255,255,0.75);
  width:100%;
  z-index:1000;
  height: 60px;
}

.navbar-nav {
  display: flex;
  justify-content: flex-end;
  width:100%;
}

.dropdown-menu > li {
  background-color: var(--dropdown-background);
}
.dropdown-item span {
  background-color: var(--dropdown-background);
  color: var(--estiem-green);
}
.dropdown-menu li :hover {
  background-color: var(--gray-20);
  color: var(--estiem-green);
  box-shadow: none;
}
.dropdown-menu li :focus {
  background-color: var(--gray-20);
  color: var(--estiem-green);
  box-shadow: none;
}
.dropdown-menu li :active {
  background-color: var(--gray-primary);
  color: var(--estiem-green);
  box-shadow: none;
}

.navbar-nav li a.dropdown-item {
  background-color: rgba(255,255,255,0.9);
  color: var(--estiem-green)
}
.header-arrow{
  font-size: 30px
}

.searchbar {
  text-align: center;
  margin: 60px 0 0 auto;
}

.searchbarhp{
  position: fixed;
  width: 100%;
  height: 50px;
  z-index: 1000;
  padding: 5px;
}

.search-container .material-icons {
    transform: rotate(74deg);
    margin: 7px 0;
}

.back-container, .search-container {
  width: 30px;
  margin: auto 0px;
  height: 40px;
}

@media screen and (min-width: 1458px) {
    .back-icon {
      display:none;
    }
    .logo-container {
      margin:0px;
      margin-right:10px;
    }

    .navbar {
      padding: 0.5rem 12rem;
    }
}

@media screen and (min-width: 1335px) {
    .back-icon {
      display:none;
    }
    .logo-container {
      margin:0px;
      margin-right:10px;
    }
    .navbar {
      padding: 0.5rem 8rem;
    }
}


@media screen and (min-width: 1270px) {
    .back-icon {
      display:none;
    }
    .logo-container {
      margin:0px;
      margin:0px;
      margin-right:10px;
    }
    .navbar {
      padding: 0.5rem 5rem;
    }
}

@media screen and (max-width: 1120px) {
    .navbar-container {
      position: absolute;
    }
    .navbar-nav {
      display:none;
    }

    .navbar-nav .nav-item {
      display:none;
      margin:0px!important;
    }
    .navbar-hamburger {
      display:none
    }

    .search-container {
      display:flex;
      justify-content: flex-end;
    }
    .navbar {
      padding: 0.5rem 3rem;
    }
}

@media screen and (min-width: 100px) {
    .navbar-menu {
      overflow: hidden;
      background-color: rgb(32, 94, 68);
      width: 100%;
      display: grid;
      grid-auto-flow: column;
      justify-content: stretch
    }
}

.alumnus .navbar-container {
  height: 68px;
}

.alumnus .navbar {
    padding: 0.5rem 1rem 0.2rem;
}

.alumnus .searchbar {
  margin: 68px 0 0 auto;
}

.alumnus .back-container {
  margin: 0;
}
</style>
