<template>
    <div class="card localgroup-details">
    <router-link class="card-link" :to="'/localgroup/' + localgroup.id">
     <img class="card-img-top" :src='lgImageUrl' style="width:100%" alt="Image not available" onerror="this.onerror=null;this.src='https://estiem.blob.core.windows.net/estiem-images/estiem_e.jpg'">
      <div class="card-block img-text">
        <h1 class="card-title">
          {{localgroup.name}}
          <span class="status" v-if="localgroup.status===4">(Guest)</span>
          <span class="status" v-if="localgroup.status===2">(Observer)</span>
          <span class="status" v-if="localgroup.status===8">(Inactive)</span>
          </h1>
          <h3 class="card-title mb-1">{{ localgroup.country }}</h3>
          <h3 class="card-title my-0">
            {{ this.lr ? this.getLocalResponsibleFullName() : '' }}
          </h3>
          <h3 class="card-title my-0">lr.{{ lrEmail }}@estiem.org</h3>
        <!-- we have to add the country to the database authomatically -->
      </div>
    </router-link>
  </div>
</template>

<script>
import axios from 'axios';
import { imageUrl } from '../../helpers/image-helper.js';

export default {
  data() {
    return {
      isSelected: false,
      lr: null,
      lgImageUrl: '',
    };
  },
  // How to include here to show the Local groups
  props: {
    localgroup: {
      default: {},
    },
  },
  computed: {
    // imageUrl() {
    //   if (this.localgroup != null) {
    //     const userMobile = screen.width <= 450;
    //     return imageUrl(this.localgroup.name, this.localgroup.id, userMobile, true);
    //   } return null;
    // },
    lrEmail() {
      if (this.localgroup != null) {
        // manual fix for LG Saint Ptersburg
        if (this.localgroup.name === 'Saint Petersburg') {
          return 'St_Petersburg';
        }
        return this.localgroup.name.replace(' ', '_');
      }
      return null;
    },
  },
  methods: {
    getLocalResponsibleFullName() {
      return `${this.lr.firstNameEnglish} ${this.lr.lastNameEnglish}`;
    },
    fetchLR() {
      axios
        .get(`/user/${this.localgroup.localResponsibleId}`)
        .then((response) => {
          this.lr = response.data;
        });
    },
    fetchImage() {
      if (this.localgroup != null) {
        const userMobile = screen.width <= 450;
        imageUrl(this.localgroup.name, this.localgroup.id, userMobile, true).then((response) => { this.lgImageUrl = response; });
      }
      return null;
    },
  },
  created() {
    this.fetchLR();
    this.fetchImage();
  },
};
</script>

<style scoped>
.localgroup-details {
  color: #205E44 !important;
  min-height: 120px;
  flex-basis:20%;
  flex-grow: 0;
  margin: 5px 5px;
  min-width:250px;
}

.img-text{
  top:25%;
  width:100%;
  height:110px;
  padding:0px;
  padding-top:5px;
  margin-top: 0px;
  position: absolute;
  background-color: rgb(255,255,255);
  background-color: rgba(255,255,255,0.9);
  display:block;
  text-align:center;
}

.status{
font-size:0.9rem;
}

@media (max-width: 600px) {
  .localgroup-details-name {
    width: 200px;
    display: block;
    white-space: nowrap;
  }
}

@media screen and (max-width: 450px) {
    .card-filter .btn-group {width:100%}
    .card-img-top, .card-footer {display:none}
}

@media screen and (max-width: 450px) {
    #facts {width:100%}
    .card-img-top, .card-footer {display:block} /* Whether or not to show image when mobile */
}

.localgroup-details{
  margin-left:15px;
}
</style>
