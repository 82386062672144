import { errorToast } from './toaster-helper.js';

export const validateEventDates = (startDate, endDate, applicationStartDate, applicationEndDate, cancelDate, toaster) => {
  let isValid = true;
  if (!startDate) isValid = errorToast(toaster, 'Event start date must not be empty');
  if (!endDate) isValid = errorToast(toaster, 'Event end date must not be empty');
  if (!applicationStartDate) isValid = errorToast(toaster, 'Event application start date must not be empty');
  if (!applicationEndDate) isValid = errorToast(toaster, 'Event application end date must not be empty');
  const dendDate = new Date(endDate);
  const dstartDate = new Date(startDate);
  const dstartAppDate = new Date(applicationStartDate);
  const dendAppDate = new Date(applicationEndDate);
  const dcancelDate = new Date(cancelDate);
  if (dendDate < dstartDate) isValid = errorToast(toaster, 'End date must be later than the start date.');
  if (dendAppDate < dstartAppDate) isValid = errorToast(toaster, 'Application deadline must be later than application start date');
  if (dcancelDate != null && dcancelDate < dstartAppDate) isValid = errorToast(toaster, 'Cancellation deadline must be later than application start date');
  if (dcancelDate < dendAppDate) isValid = errorToast(toaster, "Cancellation deadline can't be before end of application period");
  if (dstartDate < dstartAppDate) isValid = errorToast(toaster, "Application can't start after the event has started");
  if (dcancelDate > dstartDate) isValid = errorToast(toaster, "Cancellation deadline can't be after the start date");
  return isValid;
};
