<template>
  <div>
    <div v-if="returnedEstiemers.length == 0">
      <h1 v-if="searchCompleted">No estiemers found for "{{searchString}}"</h1>
      <spinner v-if="!searchCompleted"></spinner>
    </div>
    <div v-if="returnedEstiemers.length != 0" >
      <h1 class="title">ESTIEMers</h1>
      <b-card-group deck style="justify-content: center;">
        <template>
          <estiemer-card v-for="estiemer in returnedEstiemers" :estiemer="estiemer" :key="estiemer.id"/>
        </template>
      </b-card-group>
      <div style="margin: auto !important">
        <b-button @click="returnEstiemers" >load more</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import estiemerCard from './estiemer-card.vue';
import Spinner from '../Spinner/spinner.vue';


export default {
  data() {
    return {
      pageNumber: 1,
      searchCompleted: false,
      returnedEstiemers: [],
      firstSearch: '',
    };
  },
  props: {
    count: {
      default: 100,
    },
    searchString: {
      default: '',
    },
    newSearch: {
      default: false,
    },
  },
  components: {
    Spinner,
    estiemerCard,
    /* people */
  },
  created() {
    this.firstSearch = this.searchString;
    this.returnEstiemers();
  },
  watch: {
    newSearch() {
      this.returnEstiemers();
    },
  },
  methods: {
    returnEstiemers() {
      if (this.searchString !== this.firstSearch) {
        this.returnedEstiemers = [];
        this.pageNumber = 1;
        this.firstSearch = this.searchString;
        this.searchCompleted = false;
      }// without this, "load more" will just add more estiemers to the results of the last page
      if (this.searchString.length > 1 && this.searchString !== "minsk" && this.searchString !== "mins" && this.searchString !== "Minsk" && this.searchString !== "Mins") {
        if (this.returnedEstiemers.length !== 0) {
          this.pageNumber = this.pageNumber + 1;
        }
        axios.get(`/user/search/${this.searchString}?page=${this.pageNumber}`)
          .then((response) => {
            this.returnedEstiemers = this.returnedEstiemers.concat(response.data);
            this.searchCompleted = true;
          });
      } else {
        // searching for a single letter makes the search on the backend two intensive
        alert('please enter at least 2 letters to start a search');
      }
    },
  },
};
</script>

<style scoped>
.title{
  margin: auto !important;
  text-align: center;
}

</style>
