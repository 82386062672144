<template>
<div>
  <b-card-group deck>
    <b-card
      title="Get active"
      img-src="https://picsum.photos/600/300/?image=25"
      img-alt="Image"
      img-top
      tag="article"
      class="mb-2"
    >
    <b-card>
      Learn valuable organisational skills while making new friends. To get active, simply fill out the form below. If you have questions, <a href="mailto:hrc@estiem.org" class="mail">get in touch with our HR team</a>.
    </b-card>
    <br>
    <b-button target="_blank" href="https://docs.google.com/forms/d/e/1FAIpQLSeMuy3XrBV3Y9WOco9wPxftb2s-PhL37ULscp8-fXMqhCaRag/viewform?usp=sf_link" variant="primary">Get active form</b-button>
    </b-card>
    <b-card
      title="Elium"
      img-src="https://picsum.photos/600/300/?image=25"
      img-alt="Image"
      img-top
      tag="article"
      class="mb-2"
    >
      <b-card>
        Elium is our knowledge management system, which we use to share useful material and interact, in order to improve ESTIEM further. If you need a user, <a href="mailto:hrc@estiem.org" class="mail">get in touch with our HR team</a>.
      </b-card>
      <br>
      <b-button target="_blank" href="https://estiem.elium.com" variant="primary">Visit Elium</b-button>
    </b-card>
    <b-card
      title="PR material"
      img-src="https://picsum.photos/600/300/?image=25"
      img-alt="Image"
      img-top
      tag="article"
      class="mb-2"
    >
      <b-card>
        We have plenty of PR material to help you promote your local events. Simply roam around on our Drive to find what you are looking for.
        Can't find what you are looking for? <a href="mailto:leader.prc@estiem.org" class="mail">Get in touch with the PR Committee Leader</a>.
      </b-card>
      <br>
      <b-button target="_blank" href="https://drive.google.com/drive/folders/0B_5y4rLKFNJ5d01rOXJWVUNrU3c" variant="primary">Show PR material</b-button>
    </b-card>
    <b-card
      title="Best Practice Documents (BPDs)"
      img-src="https://picsum.photos/600/300/?image=25"
      img-alt="Image"
      img-top
      tag="article"
      class="mb-2"
    >
      <b-card>
        Most likely, someone has done it before. Read through our Best Practice Documents to get good tips. If you have any questions, <a href="mailto:leader.kmc@estiem.org" class="mail">get in touch with the KMC Committee Leader</a>.
      </b-card>
      <br>
      <b-button target="_blank" href="https://drive.google.com/drive/folders/1DhTLEk4BhppQvhkhxtFd5DKCtzsDA0Uf" variant="primary">Show BPDs</b-button>
    </b-card>
  </b-card-group>
  </div>
</template>

<script>

export default {
  data() {
    return {};
  },
  props: {
    count: {
      default: 100,
    },
  },
};
</script>


<style scoped>


.tut img {padding: 15px}
.tue img {padding: 10px 0px}
.chalmers img {padding: 25px}


.card-deck {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin: 0;
    align-content: flex-start;
    overflow: auto;
    resize:both;
}

.card-block {
    position: absolute;
    bottom: 0px;
    left: 0px;
    width:100%;
    background-color: rgba(0,0,0,0.5);
    color:#fff;
    padding: 10px;
    overflow:hidden;
}

.card {
    margin-left: 0.5%;
    margin-right: 0.5%;

}
@media only screen and (max-width: 499px) {
  .card {
    flex: 0 1 99%;
  }
}
@media only screen and (min-width: 500px) {
  .card {
    flex: 0 1 48.5%;
  }
}
@media only screen and (min-width: 900px) {
  .card {
    flex: 0 1 32%;
  }
}
@media only screen and (min-width: 1100px) {
  .card {
      flex: 0 1 24%;
  }
}

.card-deck .card {
  margin: 5px 5px;
}

.card {
  overflow:hidden
}

.card-block h4 {
    color:#fff;
    font-variant:small-caps;
}

@media (max-width: 600px) {
  .card-name {
    width: 200px;
    display: block;
    white-space: nowrap;
  }
}

.card img {
  width:100%;
}

.card-body {
  padding: 1.25rem
}

.card {
  text-align:center;
}

.card .btn {
  margin-top:10px;
}

.mail:link {
  text-decoration:underline;
  color: #292b2c
}

</style>
