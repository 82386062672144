<template>
  <div id='main-div'>
    <!--<div class='form-group' :class='{ 'error': $v.form.name.$error }'>
  <input id='name' class='text-inputs' placeholder='Event Name' type='text' v-model.trim='$v.form.name'>
  </div>-->
    <div id='edit-event-container'>
      <input
        class='text-inputs'
        id='name'
        placeholder='Event Name (required)'
        type='text'
        maxlength='50'
        v-model.trim='form.name'
      />
      <div
        class='input-group-addon'
        v-text="50 - form.name.length + ' characters left'"
      ></div>
      <input
        class='text-inputs'
        id='place'
        placeholder='Event Location (required)'
        type='text'
        maxlength='100'
        v-model='form.place'
      />
      <div
        class='input-group-addon'
        v-text="100 - form.place.length + ' characters left'"
      ></div>
      <b-form-select
        :options='evTypeOptions'
        class='text-inputs'
        v-model='form.eventType'
      >
        <option disabled value=''>Select Event Type (required)</option>
      </b-form-select>
      <b-form-select
        :options='localGroupNames'
        class='text-inputs'
        v-model='form.parentGroupId'
      >
        <option disabled value=''>Select Local Group (required)</option>
      </b-form-select>
      <div class='row-group'>
        <vue-bootstrap-typeahead
          id='eventadmin'
          ref='adminTypeahead'
          style='width: 75%'
          :value='previousEstiemerQuery'
          v-model='previousEstiemerQuery'
          :data='estiemerOptions'
          placeholder='Event Administrator (required)'
        ></vue-bootstrap-typeahead>
        <b-btn
          v-if='userMobile'
          style='width: 50%;
            white-space: pre-wrap;
            height: 2.1em;
            font-size: 0.95em;
            padding: 0;'
          v-on:click='searchUsers'
          >Search
        </b-btn>
        <b-btn
          v-if='!userMobile'
          style='width: 25%; white-space: pre-wrap'
          v-on:click='searchUsers'
          >Search</b-btn
        >
      </div>
      <input
        type='email'
        class='text-inputs'
        id='email'
        placeholder='Contact Email (required)'
        maxlength='100'
        v-model='form.email'
      />
      <b-alert :show='loadingEstiemers' variant='success'
        >Searching ESTIEMers...
      </b-alert>
      <div class='row-group'>
        <span style='width: 100%'>Event Dates (required)</span>
      </div>
      <div class='row-group'>
        <b-form-input
          class='date-inputs'
          id='eventStart'
          type='date'
          v-model='form.startDate'
        ></b-form-input>
        <b-form-input
          class='date-inputs'
          id='eventEnd'
          type='date'
          v-model='form.endDate'
        ></b-form-input>
      </div>
      <div class='row-group'>
        <span style='width: 100%; overflow: hidden'
          >Application period (required)</span
        >
      </div>
      <div class='row-group'>
        <b-form-input
          class='date-inputs'
          id='applicationStart'
          type='date'
          v-model='form.applicationStartDate'
        ></b-form-input>
        <b-form-input
          class='date-inputs'
          id='applicationEnd'
          type='date'
          v-model='form.applicationEndDate'
        ></b-form-input>
      </div>
      <div style='margin: auto'>Cancellation Deadline (00:00h, required)</div>
      <input
        id='retireDeadline'
        class='text-inputs'
        type='date'
        v-model='form.retireDeadline'
      />
      <input
        id='participationFee'
        class='text-inputs'
        placeholder='Participation fee in Euro'
        type='text'
        v-model='form.participationFee'
      />
      <input
        id='maxparticipants'
        class='text-inputs'
        placeholder='Maximum Participants'
        type='text'
        v-model='form.maxparticipants'
      />
      <div>Email lists (the names cannot be modified)</div>
      <input
        id='registeredMailList'
        class='text-inputs'
        placeholder='Registered participants email list alias'
        type='text'
        :value="`${this.event.emailListAlias}.registered@estiem.org`"
        disabled
      />
      <input
        id='waitingMailList'
        class='text-inputs'
        placeholder='Waiting list email list alias'
        type='text'
        :value="`${this.event.emailListAlias}.waiting@estiem.org`"
        disabled
      />
      <div>The email lists are automatically deleted after the event.</div>
      <div>.registered lists get expired and deleted 60 days after the end date while .waiting list expire 10 days after.</div>
      <div>NOTE that the expiry date is based on the FIRST end date of the event and editing the event does NOT update the group expiry date.</div>
      <div>
        <br />
        <div style='margin: auto'>
          Upload images for event slider. Images will appear in the order of
          selection, old images will be deleted:
        </div>
        <upload type='slider' v-on:pass-files='takeFiles'></upload>
      </div>
      <div style='margin: auto'>
        Upload a custom event thumbnail/header image. Only one picture is
        allowed. Previously uploaded images will be deleted.
      </div>
      <upload type='thumb' v-on:pass-files='takeThumbnailFiles'></upload>

      <div>
        <vue-editor
          style='margin-top: 20px'
          id='description-editor'
          useCustomImageHandler
          @imageAdded='imageInEditor'
          v-model='form.description'
        ></vue-editor>
      </div>
      <textarea
        placeholder='accomodation (name, address, facilitites)'
        v-model='form.accomodation'
      ></textarea>

      <textarea
        placeholder='Info about arrival (how to get there etc.)'
        v-model='form.arrivalInfo'
      ></textarea>

      <input
        type='text'
        class='text-inputs'
        id='youtube'
        placeholder='Link to promotion video (embedded)'
        maxlength='100'
        v-model='form.youtube'
      />
      <input
        type='text'
        class='text-inputs'
        id='whatsapp'
        placeholder='Link to the group chat'
        v-model='form.whatsapp'
      />
      <input
        type='text'
        class='text-inputs'
        id='photoshare'
        placeholder='Link to Photos'
        v-model='form.photoshare'
      />
      <input
        class='text-inputs'
        id='partners'
        placeholder='Information about partners / sponsors of the event'
        type='text'
        v-model='form.partners'
      />
      <b-alert :show='showErrorMsg' dismissible variant='danger'>
        Could not complete operation, the following errors occurred:
        <pre>{{ editErrorMsg }}</pre>
      </b-alert>
      <b-alert
        :show='successMsgDismissCountdown'
        @dismiss-count-down='countDownChanged'
        fade
        variant='success'
      >
        Event successfully updated! Redirecting in
        {{ successMsgDismissCountdown - 1 }}...
      </b-alert>
      <b-alert :show='deleted' fade variant='success'>
        Event has been deleted. Redirecting to events page...
      </b-alert>
      <button
        v-if='isAdmin'
        id='show-modal'
        @click='showModal = true'
        class='btn btn-primary btn-lg'
        style='background: #761214'
        type='button'
        v-show='toggle'
      >
        Delete Event
      </button>
      <modal v-if='showModal' @remove='deleteEvent' @close='showModal = false'>
        <h1 slot='header'>Delete event '{{ form.name }}'</h1>
      </modal>
      <button
        class='btn btn-primary btn-lg'
        style='background: rgb(2, 117, 216)'
        type='button'
        v-on:click='updateEventInfo'
        v-show='toggle'
      >
        Update Event
      </button>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { VueEditor } from 'vue2-editor';
import axios from 'axios';
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead';
import upload from './upload.vue';
import loginPage from '../profile/login-page.vue';
import { validateEventDates } from '../../helpers/dates-validator.js';
import { EventCategory } from '../../helpers/event-category.js'
import { errorToast } from '../../helpers/toaster-helper.js'

const Modal = {
  name: 'modal',
  template: `
   <transition name='modal'>
   <div class='modal-mask'>
     <div class='modal-wrapper'>
     <div class='modal-container'>

      <div class='modal-header'>
      <slot name='header'>
      </slot>
      </div>

      <div class='modal-body'>
      <slot name='body'>
        Are you sure you want to delete this event?
      </slot>
      </div>

      <div class='modal-footer'>
      <slot name='footer'>
        <button class='btn btn-secondary' @click='$emit('close')'>
        Cancel
        </button>
        <button class='btn btn-primary' @click='deleteEvent'>
        Yes
        </button>
      </slot>
      </div>
     </div>
     </div>
   </div>
   </transition>
  `,
  methods: {
    deleteEvent() {
      this.toggle = false;
      this.$emit('remove');
    },
  },
};

export default {
  components: {
    VueBootstrapTypeahead,
    upload,
    VueEditor,
    loginPage,
    modal: Modal,
  },
  data() {
    return {
      toggle: true,
      form: {
        id: '',
        name: '',
        place: '',
        parentGroupId: '',
        eventType: '',
        // localGroup: '',
        contactPerson: '',
        // registrationMode: '',
        registerRules: '',
        startDate: '',
        endDate: '',
        applicationStartDate: '',
        applicationEndDate: '',
        retireDeadline: '',
        participationFee: '',
        maxparticipants: '',
        maxParticipantsPerLG: '',
        email: '',
        description: '',
        moreInformation: '',
        youtube: '',
        whatsapp: '',
        emailListAlias: '',
        photoshare: '',
        arrivalInfo: '',
        partners: '',
        accomodation: '',
        adminUserId: '',
      },
      showModal: false,
      deleted: false,
      event: {},
      evTypeOptions: [
        { text: 'Academic Days', category: EventCategory.ACADEMIC, value: 11 },
        { text: 'Activity Week', category: EventCategory.CULTURAL, value: 10 },
        { text: 'BrainTrainer', category: EventCategory.PERSONAL, value: 2 },
        { text: 'businessbooster', category: EventCategory.CAREER, value: 14 },
        { text: 'Europe3D', category: EventCategory.CULTURAL, value: 1 },
        { text: 'Lean Six Sigma', category: EventCategory.ACADEMIC, value: 15 },
        { text: 'Language Programme', category: EventCategory.CULTURAL, value: 13 },
        { text: 'Local Group Exchange', category: EventCategory.EXCHANGE, value: 9 },
        { text: 'Summer Academy', category: EventCategory.PERSONAL, value: 3 },
        { text: 'TIMES', category: EventCategory.CAREER, value: 4 },
        { text: 'Vision', category: EventCategory.ACADEMIC, value: 5 },
        { text: 'Council Meeting', category: EventCategory.CENTRAL, value: 8 },
        { text: 'Central ESTIEM', category: EventCategory.CENTRAL, value: 7 },
        { text: 'Other cultural events', category: EventCategory.CULTURAL, value: 17 },
        { text: 'Other academic events', category: EventCategory.ACADEMIC, value: 18 },
        { text: 'Other career events', category: EventCategory.CAREER, value: 19 },
        { text: 'Other personal events', category: EventCategory.PERSONAL, value: 20 },
        { text: 'Other events', category: EventCategory.OTHER, value: 6 },
        { text: 'Alumni', category: EventCategory.ALUMNI, value: 12 },
        { text: 'Alumni Meeting', category: EventCategory.ALUMNI, value: 16 },
        { text: 'Alumni CM', category: EventCategory.ALUMNI, value: 21 },
        { text: 'Alumni Round Table', category: EventCategory.ALUMNI, value: 22 },
        { text: 'Be X For X Days', category: EventCategory.CAREER, value: 23 },
      ],
      localgroups: [],
      estiemerList: [],
      sliderPictures: [],
      thumbnailPicture: [],
      estiemerQuery: '',
      previousEstiemerQuery: '',
      successMsgDismissCountdown: -1,
      successMsgDismissSeconds: 4,
      showErrorMsg: false,
      loadingEstiemers: false,
      editErrorMsg: '',
    };
  },

  methods: {
    checkForm() {
      let isValid = true;
      if (this.thumbnailPicture.length > 1) {
        alert('Only one thumbnail picture allowed!');
        return false;
      }
      const eventAdminName = this.estiemerQuery.split(', LG ')[0];
      const eventAdminLG = this.estiemerQuery.split(', LG ')[1];
      if (this.event.adminUserId != null) {
        this.form.adminUserId = this.event.adminUserId;
      } else if (this.estiemerList.length > 0) {
        this.form.adminUserId = this.estiemerList.filter(
          estiemer =>
            `${estiemer.firstNameEnglish} ${estiemer.lastNameEnglish}` ===
              eventAdminName && estiemer.localGroupName === eventAdminLG,
        )[0].userId;
      } else {
        errorToast(this.$toasted, 'Please enter an Event Administrator.');
        return false;
      }
      const {
        startDate,
        endDate,
        applicationStartDate,
        applicationEndDate,
        retireDeadline,
      } = this.form;
      isValid = validateEventDates(
        startDate,
        endDate,
        applicationStartDate,
        applicationEndDate,
        retireDeadline,
        this.$toasted,
      );
      if (!this.form.place && this.form.parentGroupId) {
        // If place is not set, set place to name of Local Group
        this.form.place = this.localgroups.filter(
          lg => lg.id === this.form.parentGroupId,
        )[0].name;
      }
      // if the event is a CM, LRs should be able to register people from their LG to it as delegates - thus registrationMode = 1
      if (this.form.eventType === 8) { this.form.registrationMode = 1; }
      // The backend expects integers for participationFee and maxparticipants
      this.form.participationFee = parseInt(this.form.participationFee, 10);
      this.form.maxparticipants = parseInt(this.form.maxparticipants, 10);
      // default values for these numbers turn out to be the minimum value of an integer
      if (this.event.participationFee < 0) { this.form.participationFee = 0; }
      if (this.event.maxparticipants <= 0) { this.form.maxparticipants = 20; }
      if (!this.form.adminUserId) {
        errorToast(this.$toasted, 'Given Event Admin unknown.');
      }
      if (!this.form.name) {
        errorToast(this.$toasted, 'Event name must not be empty.');
      }
      if (!this.form.parentGroupId) {
        isValid = errorToast(this.$toasted, 'Local Group must not be empty.');
      }
      if (!this.form.email) {
        isValid = errorToast(this.$toasted, 'Contact Email must not be empty');
      }
      if (!this.form.eventType) {
        isValid = errorToast(this.$toasted, 'Event type must not be empty.');
      }
      if (!this.form.place) {
        isValid = errorToast(this.$toasted, 'Event Location must not be empty');
      }
      this.form.applicationEndDate = moment
        .utc(
          moment(this.form.applicationEndDate).set({
            hour: 23,
            minute: 59,
            second: 0,
          }),
        )
        .toString();

      return isValid;
    },
    deleteEvent() {
      const eventId = this.$route.params.id;
      const url = `events/${eventId}`;
      axios
        .delete(url)
        .then((response) => {
          this.showModal = false;
          if (response.data) {
            this.showErrorMsg = true;
            this.editErrorMsg = response.data;
          } else {
            this.deleted = true;
            // we also need to delete files connected with this event
            axios.delete(`/files/event/${this.event.id}`);
            setTimeout(() => {
              document.location.href = '/events';
            }, 3000);
          }
        })
        .catch((error) => {
          this.showModal = false;
          this.showErrorMsg = true;
          this.editErrorMsg += `\nDeleting the event failed with ${error.response.status.toString()} ${
            error.response.statusText
          }`;
          const errors = error.response.data.errors;
          const keys = Object.keys(error.response.data.errors);
          keys.forEach((key) => {
            this.editErrorMsg += `\n${key}: ${errors[key]}`;
          });
        });
    },
    async updateEventInfo() {
      const eventId = this.$route.params.id;
      try {
        // the keyword await makes this wait for the image upload to finish before proceeding
        if (this.sliderPictures.length > 0) {
          await this.uploadImages();
        }
        if (this.thumbnailPicture.length > 0) {
          await this.uploadThumbImages();
        }
      } catch (response) {
        if (response.status !== 200) {
          this.showErrorMsg = true;
          this.editErrorMsg = `Uploading images failed with: ${response.status.toString()} ${
            response.statusText
          }`;
          return;
        }
      }
      if (this.checkForm()) {
        const url = `events/${eventId}`;
        const toSend = this.form;
        // This is adding hours and minutes to the date, so that people can apply until the end of the application period.
        // seconds can't be added because of the time format in the backend
        this.form.endDate = moment(this.form.endDate).set({
          hour: 23,
          minute: 59,
        });
        this.form.endDate = moment(this.form.endDate).format(
          'YYYY-MM-DD[T]HH:mm',
        );
        this.form.applicationEndDate = moment(
          this.form.applicationEndDate,
        ).set({ hour: 23, minute: 59 });
        this.form.applicationEndDate = moment(
          this.form.applicationEndDate,
        ).format('YYYY-MM-DD[T]HH:mm');
        this.form.retireDeadline = moment(this.form.retireDeadline).set({
          hour: 23,
          minute: 59,
        });
        this.form.retireDeadline = moment(this.form.retireDeadline).format(
          'YYYY-MM-DD[T]HH:mm',
        );
        axios
          .put(url, toSend)
          .then(() => {
            this.showSuccessMsg();
          })
          .catch((error) => {
            this.showErrorMsg = true;
            this.editErrorMsg += `\nUpdating the event failed with: ${error.response.status.toString()} ${
              error.response.statusText
            }`;
            const errors = error.response.data.errors;
            const keys = Object.keys(error.response.data.errors);
            keys.forEach((key) => {
              this.editErrorMsg += `\n${key}: ${errors[key]}`;
            });
          });
        this.toggle = false;
      }
    },
    takeFiles(files) {
      this.sliderPictures = files;
    },
    takeThumbnailFiles(files) {
      this.thumbnailPicture = files;
    },
    uploadImages() {
      // POST the images to the file API using a multipart/form-data request
      return new Promise(async (resolve, reject) => {
        if (this.sliderPictures.length === 0) resolve();
        const imgFormData = new FormData();
        for (let i = 0; i < this.sliderPictures.length; i += 1) {
          const img = this.sliderPictures[i];
          // append the files one-by-one
          imgFormData.append('sliderImages', img, img.name);
        }
        const config = {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        };
        const response = await axios.post(
          `/files/event/${this.event.id}`,
          imgFormData,
          config,
        );
        if (response.status === 204) {
          // resolve the Promise and proceed with updating event data
          resolve();
        } else {
          // reject the Promise and cancel update process
          reject(response);
        }
      });
    },
    uploadThumbImages() {
      // POST the images to the file API using a multipart/form-data request
      return new Promise(async (resolve, reject) => {
        if (this.thumbnailPicture.length === 0) resolve();
        const imgFormData = new FormData();
        imgFormData.append(
          'headerImage',
          this.thumbnailPicture[0],
          this.thumbnailPicture.name,
        );
        const config = {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        };
        const response = await axios.post(
          `/files/eventhead/${this.event.id}`,
          imgFormData,
          config,
        );
        if (response.status === 204) {
          // resolve the Promise and proceed with updating event data
          resolve();
        } else {
          // reject the Promise and cancel update process
          reject(response);
        }
      });
    },
    fetchLGs() {
      axios.get('/localgroups').then((response) => {
        this.localgroups = response.data
          .sort((a, b) => {
            if (a.name > b.name) return 1;
            if (b.name > a.name) return -1;
            return 0;
          })
          .filter(lg => lg.status !== 8);
      });
    },
    fetchEvent() {
      const id = this.$route.params.id;
      const url = `/events/${id}`;
      axios.get(url).then((response) => {
        this.event = response.data;
        this.updateParameters();
        this.getEventTypeName();
        this.getEventAdminString();
      });
    },
    getEventTypeName() {
      this.eventTypeName = this.evTypeOptions.find(
        e => e.value === this.event.eventType,
      ); // filter event type name
    },
    getEventAdminString() {
      // get the data for event admin
      const url = `/user/${this.event.adminUserId}`;
      axios.get(url).then((usr) => {
        const firstName = usr.data.firstNameEnglish;
        const lastName = usr.data.lastNameEnglish;
        axios.get(`/user/search/${firstName} ${lastName}`).then((usrSearch) => {
          const lg = usrSearch.data[0].localGroupName;
          this.estiemerQuery = `${firstName} ${lastName}, LG ${lg}`;
          this.previousEstiemerQuery = this.estiemerQuery;
          this.$refs.adminTypeahead.inputValue = this.previousEstiemerQuery;
        });
      });
    },
    updateParameters() {
      // TODO: FILL THE REST OF THE PARAMETERS
      this.form.id = this.event.id;
      this.form.adminUserId = this.event.adminUserId;
      this.form.name = this.event.name;
      this.form.place = this.event.place;
      this.form.eventType = this.event.eventType;
      this.form.parentGroupId = this.event.parentGroupId;
      if (this.event.applicationStartDate != null) {
        this.form.applicationStartDate = this.event.applicationStartDate.substring(
          0,
          10,
        );
      }
      if (this.event.applicationEndDate != null) {
        this.form.applicationEndDate = this.event.applicationEndDate.substring(
          0,
          10,
        );
      }
      if (this.event.retireDeadline != null) {
        this.form.retireDeadline = this.event.retireDeadline.substring(0, 10);
      }
      this.form.maxparticipants = this.event.maxparticipants;
      this.form.email = this.event.email;
      this.form.description = this.event.description;
      this.form.youtube = this.event.youtube;
      this.form.whatsapp = this.event.whatsapp;
      this.form.photoshare = this.event.photoshare;
      this.form.arrivalInfo = this.event.arrivalInfo;
      this.form.partners = this.event.partners;
      this.form.accomodation = this.event.accomodation;
      if (this.event.participationFee >= 0) {
        this.form.participationFee = this.event.participationFee;
      } else {
        this.form.participationFee = 0;
      }
      // TODO: FUNCTION FOR GETTING NAME FROM USER ID
      // this.form.contactPerson = this.event.adminUserId;
      this.form.startDate = this.event.startDate.toString().substring(0, 10);
      this.form.endDate = this.event.endDate.toString().substring(0, 10);
    },
    searchUsers() {
      this.loadingEstiemers = true;
      axios.get(`/user/search/${this.previousEstiemerQuery}`).then((response) => {
        this.estiemerList = response.data;
        this.loadingEstiemers = false;
      });
    },
    showSuccessMsg() {
      this.successMsgDismissCountdown = this.successMsgDismissSeconds;
    },
    countDownChanged(countDown) {
      this.successMsgDismissCountdown = countDown;
      if (this.successMsgDismissCountdown === 1) {
        this.$router.push(`/event/${this.form.id}`);
      }
    },
    imageInEditor() {
      // currently disabled
      errorToast(
        this.$toasted,
        'Please use the file upload dialogue to upload add images to your event!',
      );
    },
  },
  computed: {
    localGroupNames() {
      let lgNames = this.localgroups.map(lg => ({
        text: lg.name,
        value: lg.id,
      }));
      lgNames = lgNames.concat([{ text: 'No Local Group', value: 121 }]);
      return lgNames;
    },
    estiemerOptions() {
      if (this.estiemerList) {
        return this.estiemerList.map(
          estiemer =>
            `${estiemer.firstNameEnglish} ${estiemer.lastNameEnglish}, LG ${estiemer.localGroupName}`,
        );
      }
      return null;
    },
    exists() {
      return !!this.event.id;
    },
    userMobile() {
      return screen.width <= 450;
    },
    isAdmin() {
      return this.$store.getters.userroles.includes('Admins');
    },
  },
  // CURRENTLY NOT USED
  /*  watch: {
   estiemerQuery: function () {
     if (this.estiemerQuery.length > 3) {
     axios
      .get(`/user/search/${this.estiemerQuery}`)
      .then(response => {
      this.estiemerList = response.data;
      });
     /!*if (this.estiemerList) {
      this.form.contactPerson = this.contactPerson
     }*!/
     }
   }
   }, */

  created() {
    this.fetchEvent();
    this.fetchLGs();
  },
};
</script>

<style scoped>
#main-div {
  text-align: center;
  margin: auto;
  width: 60%;
  padding: 10px;
}

#main-div input,
select,
textarea {
  width: 100%;
  border-radius: 0.25rem;
  border: 1px solid rgba(0, 0, 0, 0.15);
  font-size: 0.95rem;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  line-height: 1.25;
  padding: 5px;
}

.row-group {
  display: flex;
  width: 100%;
}

.text-inputs {
  margin-top: 5px;
  margin-bottom: 5px;
}

.date-inputs {
  overflow: hidden;
  font-size: 1rem;
}

.input-group-addon {
  font-size: 85%;
  padding-top: 2px;
  padding-bottom: 2px;
  margin-top: 0px;
}

#description-editor {
  height: 350px;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 300px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
}

/*
  * The following styles are auto-applied to elements with
  * transition='modal' when their visibility is toggled
  * by Vue.js.
  *
  * You can easily play with the modal transition by editing
  * these styles.
  */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

@media screen and (max-width: 768px) {
  #main-div {
    width: 90%;
  }
}
</style>
