<template>
  <div id="create-event-container">
    <!--<div class="form-group" :class="{ 'error': $v.form.name.$error }">
    <input id="name" class="text-inputs" placeholder="Event Name" type="text" v-model.trim="$v.form.name">
    </div>-->
    <input class="text-inputs" id="name" placeholder="Event Name (required)" type="text" maxlength="50"
           v-model.trim="form.name">
    <div class="input-group-addon" v-text="(50 - form.name.length) + ' characters left'"></div>
    <input class="text-inputs" id="place" placeholder="Event Location (required)" type="text" maxlength="100"
           v-model="form.place">
    <div class="input-group-addon" v-text="(100 - form.place.length) + ' characters left'"></div>
    <b-form-select :options="evTypeOptions" class="text-inputs" v-model="form.eventType">
      <option disabled value="">-- Select Event Type (required) --</option>
    </b-form-select>
    <b-form-select :options="localGroupNames" class="text-inputs" v-model="form.parentGroupId">
      <option disabled value="">-- Select Local Group (required) --</option>
    </b-form-select>
    <div class="row-group">
      <vue-bootstrap-typeahead id="eventadmin" ref="adminTypeahead" style="width: 75%" :value="estiemerQuery"
                               v-model="estiemerQuery" :data="estiemerOptions"
                               placeholder="Event Administrator (required)"></vue-bootstrap-typeahead>
      <b-btn v-if="userMobile" style="width: 50%; white-space: pre-wrap;" v-on:click="searchUsers">Search</b-btn>
      <b-btn v-if="!userMobile" style="width: 25%; white-space: pre-wrap;" v-on:click="searchUsers">Search ESTIEMers
      </b-btn>
    </div>
    <b-alert
      :show="loadingEstiemers" variant="success">Searching ESTIEMers...
    </b-alert>
    <input type="email" class="text-inputs" id="email" placeholder="Contact E-mail (required)" v-model="form.email">
    <div class="row-group">
      <span style="width: 50%; text-align: left">Event Start Date (required)</span>
      <span style="width: 50%; text-align: left; overflow: hidden">Event End Date (required)</span>
    </div>
    <div class="row-group">
      <b-form-input class="date-inputs" id="eventStart" type="date" v-model="form.startDate"></b-form-input>
      <b-form-input class="date-inputs" id="eventEnd" type="date" v-model="form.endDate"></b-form-input>
    </div>
    <div class="row-group">
      <span style="width: 50%; text-align: left; overflow: hidden">Start of Application Time (required)</span>
      <span style="width: 50%; text-align: left; overflow: hidden">End of Application Time (00:00h, required)</span>
    </div>
    <div class="row-group ">
      <b-form-input class="date-inputs" id="applicationStart" type="date"
                    v-model="form.applicationStartDate"></b-form-input>
      <b-form-input class="date-inputs" id="applicationEnd" type="date"
                    v-model="form.applicationEndDate"></b-form-input>
    </div>
    <div style="margin: auto;">Cancellation Deadline (00:00h, required)</div>
    <input id="retireDeadline" class="text-inputs" type="date" v-model="form.retireDeadline">
    <input id="participationFee" class="text-inputs" placeholder="Participation fee in Euro" type="text"
           v-model="form.participationFee">
    <input id="maxparticipants" class="text-inputs" placeholder="Maximum participants" type="text"
           v-model="form.maxparticipants">
    <div>Email list alias (the names cannot be modified later)</div>
    <input id="emailListAlias" class="text-inputs"
           placeholder="e.g. 'Calabria_LGX_Piraeus_Apr25'        Note: .registered@estiem.org and .waiting@estiem.org will be added automatically" type="text"
           v-model="form.emailListAlias">
    <div>The email lists are automatically deleted after the event.</div>
    <div>.registered lists get expired and deleted 60 days after the end date while .waiting list expire 10 days after.</div>
    <div>NOTE that the expiry date is based on the FIRST end date of the event you are about to set NOW, and editing the event later on the /edit page does NOT update the group expiry date.</div>
    <!--<textarea class="text-inputs" placeholder="Short description (what will the participant get out of the event)"
              v-model="form.description"></textarea>-->
    <div>
      <vue-editor style="margin-top: 20px;" id="description-editor" useCustomImageHandler @imageAdded="imageInEditor"
                  v-model="form.description"></vue-editor>
    </div>
    <textarea placeholder="Accomodation (name, address, facilitites)" v-model="form.accomodation"></textarea>

    <textarea placeholder="Info about arrival (how to get there etc.)" v-model="form.arrivalInfo"></textarea>

    <input type="text" class="text-inputs" id="youtube" placeholder="Link to promotion video (embedded)"
           v-model="form.youtube">
    <input type="text" class="text-inputs" id="whatsapp" placeholder="Link to Whatsapp group" v-model="form.whatsapp">
    <input type="text" class="text-inputs" id="facebook" placeholder="Link to Facebook event" v-model="form.facebook">
    <input type="text" class="text-inputs" id="photoshare" placeholder="Link to Photos" v-model="form.photoshare">
    <input type="text" class="text-inputs" id="locations" placeholder="Link to map with locations for the event"
           v-model="form.locations">
    <input class="text-inputs" id="partners" placeholder="Information about partners / sponsors of the event"
           type="text" v-model="form.partners">
    <b-alert :show="showErrorMsg"
             dismissible
             variant="danger">
      Could not create event: <pre>{{ creationErrorMsg }}</pre>
    </b-alert>
    <b-alert :show="successMsgDismissCountdown"
             @dismiss-count-down="countDownChanged"
             @dismissed="msgDismissed"
             dismissible
             fade
             variant="success">
      Event successfully created! Redirecting in {{ successMsgDismissCountdown-1 }}...
    </b-alert>
    <button v-show="toggle" class="btn btn-primary btn-lg" style="font-size: 1em" type="button"
            v-on:click="publishEvent">
      Publish Event
    </button>
  </div>
</template>


<script>
import axios from 'axios';
import { VueEditor } from 'vue2-editor';
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead';
import { validateEventDates } from '../../helpers/dates-validator.js';
import { EventCategory } from '../../helpers/event-category.js'
import { errorToast } from '../../helpers/toaster-helper.js'

export default {
  components: {
    VueBootstrapTypeahead,
    VueEditor,
  },
  data() {
    return {
      toggle: true,
      form: {
        name: '',
        place: '',
        parentGroupId: '',
        eventType: '',
        contactPerson: '',
        registrationMode: '',
        registerRules: '',
        startDate: '',
        endDate: '',
        applicationStartDate: '',
        applicationEndDate: '',
        retireDeadline: '',
        participationFee: '',
        maxparticipants: '',
        maxParticipantsPerLG: '',
        email: '',
        description: '',
        moreInformation: '',
        facebook: '',
        youtube: '',
        whatsapp: '',
        photoshare: '',
        arrivalInfo: '',
        locations: '',
        partners: '',
        accomodation: '',
        adminUserId: '',
        emailListAlias: '',
      },
      evTypeOptions: [
        { text: 'Academic Days', category: EventCategory.ACADEMIC, value: 11 },
        { text: 'Activity Week', category: EventCategory.CULTURAL, value: 10 },
        { text: 'BrainTrainer', category: EventCategory.PERSONAL, value: 2 },
        { text: 'businessbooster', category: EventCategory.CAREER, value: 14 },
        { text: 'Europe3D', category: EventCategory.CULTURAL, value: 1 },
        { text: 'Lean Six Sigma', category: EventCategory.ACADEMIC, value: 15 },
        { text: 'Language Programme', category: EventCategory.CULTURAL, value: 13 },
        { text: 'Local Group Exchange', category: EventCategory.EXCHANGE, value: 9 },
        { text: 'Summer Academy', category: EventCategory.PERSONAL, value: 3 },
        { text: 'TIMES', category: EventCategory.CAREER, value: 4 },
        { text: 'Vision', category: EventCategory.ACADEMIC, value: 5 },
        { text: 'Council Meeting', category: EventCategory.CENTRAL, value: 8 },
        { text: 'Central ESTIEM', category: EventCategory.CENTRAL, value: 7 },
        { text: 'Other cultural events', category: EventCategory.CULTURAL, value: 17 },
        { text: 'Other academic events', category: EventCategory.ACADEMIC, value: 18 },
        { text: 'Other career events', category: EventCategory.CAREER, value: 19 },
        { text: 'Other personal events', category: EventCategory.PERSONAL, value: 20 },
        { text: 'Other events', category: EventCategory.OTHER, value: 6 },
        { text: 'Alumni', category: EventCategory.ALUMNI, value: 12 },
        { text: 'Alumni Meeting', category: EventCategory.ALUMNI, value: 16 },
        { text: 'Alumni CM', category: EventCategory.ALUMNI, value: 21 },
        { text: 'Alumni Round Table', category: EventCategory.ALUMNI, value: 22 },
        { text: 'Be X For X Days', category: EventCategory.CAREER, value: 23 },

      ],
      localgroups: [],
      estiemerList: [],
      estiemerQuery: '',
      loadingEstiemers: false,
      contactPerson: '',
      successMsgDismissCountdown: -1,
      successMsgDismissSeconds: 4,
      showErrorMsg: false,
      creationErrorMsg: '',
      createdEventID: '',
    };
  },
  methods: {
    checkForm() {
      let isValid = true;
      const eventAdminName = this.estiemerQuery.split(', LG ')[0];
      const eventAdminLG = this.estiemerQuery.split(', LG ')[1];
      if (!this.estiemerList) {
        alert('Please enter an Event Administrator.');
        this.form.adminUserId = this.event.adminUserId;
      } else {
        this.form.adminUserId = this.estiemerList.filter(estiemer =>
          (`${estiemer.firstNameEnglish} ${estiemer.lastNameEnglish}`) === eventAdminName
            && estiemer.localGroupName === eventAdminLG)[0].userId;
      }
      const {
        startDate, endDate, applicationStartDate, applicationEndDate, retireDeadline,
      } = this.form;
      isValid = validateEventDates(startDate, endDate, applicationStartDate, applicationEndDate, retireDeadline, this.$toasted);
      // if the event is a CM, LRs should be able to register people from their LG to it as delegates - thus registrationMode = 1
      if (this.form.eventType === 8) this.form.registrationMode = 1;
      // default values for these numbers turn out to be the minimum value of an integer
      if (this.form.participationFee < 0) this.form.participationFee = 0;
      if (this.form.maxparticipants <= 0) this.form.maxparticipants = 20;
      // The backend expects integers for participationFee and maxparticipants
      this.form.participationFee = parseInt(this.form.participationFee, 10);
      this.form.maxparticipants = parseInt(this.form.maxparticipants, 10);
      if (!this.form.adminUserId) isValid = errorToast(this.$toasted, 'Given Event Admin unknown.');
      if (!this.form.name) isValid = errorToast(this.$toasted, 'Event name must not be empty.');
      if (!this.form.startDate) isValid = errorToast(this.$toasted, 'Event start date must not be empty');
      if (!this.form.endDate) isValid = errorToast(this.$toasted, 'Event end date must not be empty');
      if (!this.form.applicationStartDate) isValid = errorToast(this.$toasted, 'Event application start date must not be empty');
      if (!this.form.applicationEndDate) isValid = errorToast(this.$toasted, 'Event application end date must not be empty');
      if (!this.form.eventType) isValid = errorToast(this.$toasted, 'Event type must not be empty.');
      if (!this.form.parentGroupId) isValid = errorToast(this.$toasted, 'Local Group must not be empty.');
      if (!this.form.email) isValid = errorToast(this.$toasted, 'Contact Email must not be empty');
      if (!this.form.place) isValid = errorToast(this.$toasted, 'Event Location must not be empty');
      if (!this.form.emailListAlias) isValid = errorToast(this.$toasted, 'you must choose an Alias for the Email list');
      if (!this.form.emailListAlias.match(/^[a-zA-Z0-9_-]*$/)) isValid = errorToast(this.$toasted, 'The Email list alias must only contain alphanumeric characters, underscores and dashes');
      return isValid;
    },
    fetchLGs() {
      axios.get('/localgroups')
        .then((response) => {
          this.localgroups = response.data
            .sort((a, b) => {
              if (a.name > b.name) return 1;
              if (b.name > a.name) return -1;
              return 0;
            })
            .filter(lg => lg.status !== 8);
        });
    },
    publishEvent() {
      if (this.checkForm()) {
        if (!this.form.place) {
          // If place is not set, set place to name of Local Group
          this.form.place = this.localgroups.filter(lg => lg.id === this.form.parentGroupId)[0].name;
        }
        const url = 'events';
        const toSend = this.form;
        axios.post(url, toSend)
          .then((response) => {
            this.createdEventID = response.data.id.toString();
            this.showSuccessMsg();
          })
          .catch((error) => {
            this.showErrorMsg = true;
            this.creationErrorMsg += `\n${error.response.status.toString()}: ${error.response.statusText}`;
            const errors = error.response.data.errors;
            const keys = Object.keys(error.response.data.errors);
            keys.forEach((key) => {
              this.creationErrorMsg += `\n${key}: ${errors[key]}`;
            });
          });
        this.toggle = false;
      }
    },
    searchUsers() {
      this.loadingEstiemers = true;
      axios
        .get(`/user/search/${this.estiemerQuery}`)
        .then((response) => {
          this.estiemerList = response.data;
          this.loadingEstiemers = false;
        })
        .catch(() => {
          this.loadingEstiemers = false;
        });
    },
    showSuccessMsg() {
      this.successMsgDismissCountdown = this.successMsgDismissSeconds;
    },
    countDownChanged(countDown) {
      this.successMsgDismissCountdown = countDown;
      if (this.successMsgDismissCountdown === 1) {
        this.$router.push(`/event/${this.createdEventID}`);
      }
    },
    msgDismissed() {
      this.successMsgDismissCountdown = 1;
    },
    imageInEditor() {
      // currently disabled
      alert('Please use the event slider in the edit view to add images to your event!');
    },
    userMobile() {
      return screen.width <= 450;
    },
  },
  computed: {
    localGroupNames() {
      let lgNames = this.localgroups.map(lg => ({ text: lg.name, value: lg.id }));
      lgNames = lgNames.concat([{ text: 'No Local Group', value: 121 }]);
      return lgNames;
    },
    estiemerOptions() {
      if (this.estiemerList) {
        return this.estiemerList.map(estiemer => `${estiemer.firstNameEnglish} ${estiemer.lastNameEnglish}, LG ${estiemer.localGroupName}`);
      }
      return null;
    },
  },
  /*  watch: {
            contactPerson: function() {
              if (this.contactPerson.length > 1) {
                axios
                  .get(`/user/search/${this.contactPerson}?page=1`)
                  .then(response => {
                    this.estiemerList = response.data;
                  });
                if (this.estiemerList) {
                  this.form.contactPerson = this.contactPerson
                }
              }
            }
          } */
  created() {
    this.fetchLGs();
  },
};
</script>


<style scoped>
  #create-event-container {
    text-align: center;
    margin: auto;
    width: 60%;
    padding: 10px;
  }

  #create-event-container input,
  select,
  textarea {
    width: 100%;
    border-radius: 0.25rem;
    border: 1px solid rgba(0, 0, 0, 0.15);
    font-size: 0.95em;
    line-height: 1.25;
    padding: 5px;
  }

  select.form-control:not([size]):not([multiple]) {
    height: 2.1em;
    font-size: 0.95em;
  }

  .row-group {
    display: flex;
    width: 100%;
    font-size: 0.95em;
    margin: 5px 0 0 0;
  }

  .text-inputs {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .date-inputs {
    overflow: hidden;
    font-size: 1rem;
  }

  .input-group-addon {
    font-size: 85%;
    padding-top: 2px;
    padding-bottom: 2px;
    margin-top: 0px;
    background-color: var(--gray-10);
  }

  #description-editor {
    height: 350px;
  }

  @media screen and (max-width: 768px) {
    #create-event-container {
      width: 90%
    }
  }
</style>
