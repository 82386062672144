<template>
<!--deleted the word "card" from the class name to override the border rule of the card-->
  <div class="event-details" :class="(eventTypeName && eventTypeName.category)">
    <router-link class="card-link" :to="{name: 'eventDetails', params: { id: event.id }}">
      <img :src="thumbUrl" alt="Image not available" onerror="this.onerror=null;this.src='https://estiem.blob.core.windows.net/estiem-images/estiem_e.jpg'">
       <div class="card-block">
        <h4 class="card-title event-name">{{ event.name }}</h4>
      </div>
      <div class="tags">
        <div class="category">{{eventTypeName && eventTypeName.category}}</div>
        <div class="date"><i class="material-icons"  style="vertical-align:middle">date_range</i> {{ eventDatesEventCard }} </div>
        <div><i class="material-icons" style="vertical-align:middle">place</i>{{ this.eventDisplayPlaceName(event.place) }}</div>
          <div >
            <i class="material-icons" style="vertical-align:middle">euro_symbol</i> {{ participationFee }}
          </div>
        <div>
          <i class="material-icons" style="vertical-align:middle">people</i> {{ event.numRegisteredParticipants }}
        </div>
        </div>
    </router-link>
  </div>
</template>

<script>
import moment from 'moment';
import axios from 'axios';
import { EventCategory } from '../../helpers/event-category.js';

export default {
  data() {
    return {
      isSelected: false,
      thumbUrl: '',
      evTypeOptions: [
        { text: 'Academic Days', category: EventCategory.ACADEMIC, value: 11 },
        { text: 'Activity Week', category: EventCategory.CULTURAL, value: 10 },
        { text: 'BrainTrainer', category: EventCategory.PERSONAL, value: 2 },
        { text: 'businessbooster', category: EventCategory.CAREER, value: 14 },
        { text: 'Europe3D', category: EventCategory.CULTURAL, value: 1 },
        { text: 'Lean Six Sigma', category: EventCategory.ACADEMIC, value: 15 },
        { text: 'Language Programme', category: EventCategory.CULTURAL, value: 13 },
        { text: 'Local Group Exchange', category: EventCategory.EXCHANGE, value: 9 },
        { text: 'Summer Academy', category: EventCategory.PERSONAL, value: 3 },
        { text: 'TIMES', category: EventCategory.CAREER, value: 4 },
        { text: 'Vision', category: EventCategory.ACADEMIC, value: 5 },
        { text: 'Council Meeting', category: EventCategory.CENTRAL, value: 8 },
        { text: 'Central ESTIEM', category: EventCategory.CENTRAL, value: 7 },
        { text: 'Other cultural events', category: EventCategory.CULTURAL, value: 17 },
        { text: 'Other academic events', category: EventCategory.ACADEMIC, value: 18 },
        { text: 'Other career events', category: EventCategory.CAREER, value: 19 },
        { text: 'Other personal events', category: EventCategory.PERSONAL, value: 20 },
        { text: 'Other events', category: EventCategory.OTHER, value: 6 },
        { text: 'Alumni', category: EventCategory.ALUMNI, value: 12 },
        { text: 'Alumni Meeting', category: EventCategory.ALUMNI, value: 16 },
        { text: 'Alumni CM', category: EventCategory.ALUMNI, value: 21 },
        { text: 'Alumni Round Table', category: EventCategory.ALUMNI, value: 22 },
        { text: 'Be X For X Days', category: EventCategory.CAREER, value: 23 },
      ],
    };
  },
  props: {
    event: {
      default: {},
    },
    userOnMap: false,
  },
  components: {
  },
  filters: {
    formatdate(value) {
      if (value) {
        return moment(value).format('DD.MM.YYYY');
      }
      return '';
    },
  },
  computed: {
    applicationOpen() {
      const now = moment();
      if (
        now.isAfter(this.event.applicationEndDate) &&
        now.isBefore(this.event.applicationEndDate)
      ) {
        return 'willopen';
      } else if (now.isBefore(this.event.applicationEndDate)) {
        return 'open';
      }

      return 'closed';
    },
    eventClass() {
      return this.eventTypeName().category;
    },
    eventTypeName() {
      return this.evTypeOptions.find(
        e => e.value === this.event.eventType,
      ); // filter event type name
    },
    participationFee() {
      if (this.event.participationFee == null || this.event.participationFee <= 0) {
        return '—';
      }
      return this.event.participationFee;
    },
    eventDatesEventCard() {
      const startDateMoment = moment(this.event.startDate);
      const endDateMoment = moment(this.event.endDate);

      const sameDay = startDateMoment.isSame(endDateMoment, 'day');
      const sameMonth = startDateMoment.isSame(endDateMoment, 'month');
      const sameYear = startDateMoment.isSame(endDateMoment, 'year');
      const endThisYear = endDateMoment.isSame(moment(), 'year');

      if (sameDay) {
        return endThisYear ? startDateMoment.format('D MMM') : startDateMoment.format('D MMM YYYY');
      }

      // if event start is in same year and same month, only show day
      // if event start is in same year and different month, show day and month
      // if event start is in different year, show day, month and year
      const startFormat = sameYear ? (sameMonth ? 'D' : 'D MMM') : 'D MMM YYYY';

      // Same logic, show at least day and month, and also year if
      // end year is different from start year or end year is not current year
      const endFormat = sameYear ? (endThisYear ? 'D MMM' : 'D MMM YYYY') : 'D MMM YYYY';

      return `${startDateMoment.format(startFormat)} to ${endDateMoment.format(endFormat)}`;
    },
  },
  methods: {
    async fetchThumb() {
      const eventid = this.event.id;
      const lgid = this.event.parentGroupId;
      const res = screen.width < 450 ? '' : 'dt';
      await axios.get(`/files/eventhead/${eventid}?lgId=${lgid}&resolution=${res}`).then((response) => {
        this.thumbUrl = response.data.imageUrls[0];
      });
    },
    eventDisplayPlaceName(eventPlaceName) {
      const seperatePlaceFullName = eventPlaceName.split(',');
      const placeName = seperatePlaceFullName.length > 1 ? `${seperatePlaceFullName[0]} ...` : seperatePlaceFullName[0];
      return placeName;
    },
  },
  watch: {
    event() {
      this.fetchThumb();
    },
  },
  created() {
    this.fetchThumb();
  },
};
</script>

  <style scoped>

.event-details card {
  border-color: unset;
}

.event-details {
    margin-left: 0;
    margin-right: 0;
    text-align: center;
}
 /*this refers to the cards of my events on the frontpage*/

.frontPage-card > .card {
  width: 100%;
  max-width: 350px;
}

.card-deck .card {
    margin: 0 5px;
    padding: 8px 5px;
}

.event-name {
  font-size: 16px;
}

.event-details-name {
  color: #205e44;
  padding-top: 5px;
  padding-left: 5px;
  padding-right: 5px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.event-details-place {
  padding-top: 5px;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 0.8em;
}

.event-details-dates {
  padding-bottom: 5px;
  margin-left: 5px;
  padding-right: 5px;
  color: black;
  text-decoration: none !important;
  text-align: center;
  font-size: 0.8em;
}

.event-details-application {
  padding-bottom: 5px;
  margin-left: 5px;
  padding-right: 5px;
  color: black;
  text-decoration: none !important;
  text-align: center;
  font-size: 0.8em;
}

.event-box {
  background-color: #e8eaea;
  border: 1px solid #bccec7;
  font-weight: bold;
  font-size: medium;
  margin-bottom: 5px;
  position: relative;
}

.event-star-button {
  right: 0;
  top: 0;
  position: absolute;
  border-color: black;
}

.selected {
  color: #e75480 !important;
}

.event-apply {
  right: 30px;
  top: 0px;
  position: absolute;
  color: #205e44;
}

.frontPage-card .tags {
  display:none;
}

.frontPage-card .event-details {
  width: 100%;
  max-width: 350px;
  margin: auto;
}

.frontPage-card .card-block {
  text-align:center;
  color:#ffffff;
}

.frontPage-card .card-block h4{
  color:#ffffff;
}

.frontPage-card a {
  color:#ffffff;
}

.frontPage-card .category {
  display:none;
}

.card-block {
    /*position: absolute; */
    bottom: 0px;
    left: 0px;
    width:100%;
    /*background-color: rgba(0,0,0,0.5);
    color:rgb(0, 0, 0);*/
    padding: 10px 5% 0px;
    overflow:hidden;
    text-align:left;
}

.tags {
  margin: 0 5% 59px;
  font-size: 10px;
  color:#625750;
}

.tags div {
  float:left;
  margin-right: 3%;
}

.category {
  border-radius: 0.25rem;
  padding: 5px;
  background-color: blanchedalmond;
  color: #625750;
}

.personal .category {
  background-color:rgb(52, 145, 188);
  color: rgb(255,255,255);
}

.career .category {
  background-color:rgb(12, 43, 94);
  color: rgb(255,255,255);
}

.cultural .category {
  background-color: rgba(247, 132, 0, 0.8);
  color: rgb(255,255,255);
}

.academic .category {
  background-color: rgba(118, 18, 20, 0.8);
  color: rgb(255,255,255);
}

.central .category {
  background-color: #205e44;
  color: rgb(255,255,255);
}

.event-details {
  overflow:hidden
}

.card-block h4 {
    /*color:#fff;*/
    font-variant:small-caps;
}

.event-details img {
  width:90%;
  height: 179px;
  border-radius: 0.25rem;
  object-fit: contain;
  margin-top: 10px;

  /* background: #205e44;
  border: 1px solid #205e44; */
}

.event-details img:hover {
  width: 95%;
  height: 185px;
  transition: all .2s ease-out;
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
  transition-delay: 0s;

}

.apply-btn-b {
  /*font-family: "Gill Sans", "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif; */
  font-size: 16px;
  font-weight: 500;
  background-color: rgb(32, 94, 68);
  color: #ffffff;
  text-align: center;
  position: relative;
  margin: auto;
  margin-bottom: 10px;
}


@media (max-width: 600px) {
  .event-details-name {
    width: 200px;
    display: block;
    white-space: nowrap;
  }
}

@media only screen and (max-width: 499px) {
  .event-details {
    flex: 0 1 99%;
  }
}
@media only screen and (min-width: 500px) {
  .event-details {
    flex: 0 1 48.5%;
  }
}
@media only screen and (min-width: 900px) {
  .event-details {
    flex: 0 1 32%;
  }
}
@media only screen and (min-width: 1100px) {
  .event-details {
      flex: 0 1 24%;
  }
}

@media screen and (min-width: 450px) {
    .event-name, .event-text {
      font-size:16px;
    }
}

@media screen and (min-width: 1400px) {
    .event-name, .event-text {
      font-size:18px;
    }

    .tags {
      font-size: 14px;
    }
}

@media screen and (max-width: 450px) {
    .event-name, .event-text {
      font-size:13px;
    }
}

@media screen and (max-width: 320px) {
    .event-details {
      min-width: 270px;
    }
}

/*
@media only screen and (max-width: 499px) {
  .frontPage-card {
    flex: 0 1 99%;
  }
}
@media only screen and (min-width: 500px) {
  .frontPage-card {
    flex: 0 1 48.5%;
  }
}
@media only screen and (min-width: 900px) {
  .frontPage-card {
    flex: 0 1 32%;
  }
}
@media only screen and (min-width: 1100px) {
  .frontPage-card {
      flex: 0 1 24%;
  }
}
@media screen and (max-width: 320px) {
    .frontPage-card {
      min-width: 270px;
  }
}
*/
</style>
