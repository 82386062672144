<template>
  <div class="container">
    <h1>404</h1>
    <p>Oops, seems like this page doesn't exist :(
      <br>

      Try going to the <a href="/">Homepage</a></p>
  </div>
</template>

<script>
export default {
  name: 'page404',
};
</script>

<style scoped>
  .container {
    margin: auto;
    text-align: center;
  }
  .container h1{
    line-height: 300px;
    font-size: 150px;
  }
</style>
