<template>
<div>
  <div v-if="!(isAdmin || isOrganiser || isLrOfEvent || isBoard)">
    <br>
    <div style="text-align: center;"><h3>Only event Organisers, the LR of the organising Local Group and members of ESTIEM Board can view the report.</h3></div>
  </div>
  <div v-else>
    <eventHeader :event="event" :participant="participant"></eventHeader>
    <div class="container-narrow">
      <TableauEmbed
        :vizData="eventFeedbackReportDashboardData"
        :contextualFilters="eventFeedbackReportDashboardDataCF"
      />
    </div>
  </div>
</div>
</template>

<script>
import eventHeader from './event-header.vue';
import Spinner from '../Spinner/spinner.vue';
import TableauEmbed from '../dashboards/tableau-embed.vue';
import { eventFeedbackReportDashboardData } from '../../helpers/tableau-embedding-data.js';

export default {
  data() {
    return {
      eventFeedbackReportDashboardData,
    };
  },
  props: {
    isOrganiser: {
      default: false,
    },
    eventParticipants: {},
    event: {},
    eventType: {},
    participant: {
      default() { return {}; },
    },
  },
  components: {
    Spinner,
    eventHeader,
    TableauEmbed,
  },
  created() {
    this.eventFeedbackReportDashboardDataCF = [
      {
        filterType: 'categorical',
        fieldName: 'Event Id',
        values: [this.eventId],
      },
    ];
  },
  computed: {
    isLR() {
      return this.$store.getters.userroles.includes('lr');
    },
    isLrOfEvent() {
      if (this.$store.getters.isLR && this.$store.getters.user.localGroupId === this.event.parentGroupId) {
        return true;
      }
      return false;
    },
    isBoard() {
      return this.$store.getters.userroles.includes('board');
    },
    isAdmin() {
      return this.$store.getters.userroles.includes('Admins');
    },
    eventId() {
      return parseInt(this.$route.params.id, 10);
    },
  },
};
</script>

<style scoped>
.container-narrow {
  padding: 10px 0;
}
</style>
