<!-- A wrapper component for creating a button to display/hide a TableauEmbed dashboard -->

<template>
  <div class="tableau-embed-button">
    <b-button
    class="centered-button"
    variant="primary"
    v-b-toggle="collapseId"
    > {{ vizData.metadata.title }}</b-button>
    <b-collapse
    visible
    :id="collapseId"
    class="mt-2"
    >
      <TableauEmbed
        :vizData="vizData"
        :contextualFilters="contextualFilters"
      />
    </b-collapse>
  </div>
</template>

<script>
import TableauEmbed from './tableau-embed.vue';

export default {
  props: {
    vizData: {
      type: Object,
      required: true,
    },
    contextualFilters: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      collapseId : 'collapse-' + this.$props.vizData.attributes.id,
    };
  },
  components: {
    TableauEmbed,
  },
  methods: {
    embedDashboard() {
      console.log('Embedding dashboard');
    },
  },
};

</script>

<style scoped>
</style>
