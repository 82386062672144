<template>
  <div class="footer-container">
    <router-link to="/contact" class="footer-contact">Contact</router-link>|
    <router-link to="/feedback" class="footer-contact">Feedback</router-link>|
    <router-link to="/alumni" class="footer-contact">ESTIEM Alumni</router-link>|
    <a target="_blank" href="https://donorbox.org/estiem" class="footer-contact">Donate to ESTIEM</a>|
    <a target="_blank" href="https://internal.estiem.org/privacy-policy/#:~:text=ESTIEM%20makes%20no%20profits%20out,policy%20this%20way%2C%20for%20you" class="footer-contact">Privacy Policy</a>
    <!-- <a target="_blank" href="https://www.paypal.com/paypalme/estiemofficial" class="footer-contact">Donate to ESTIEM</a> -->
  </div>
</template>

<script>
export default {
  name: 'desktop-footer',
};
</script>

<style scoped >
.footer-contact {
  text-decoration: none;
  color: #fff;
}
.footer-contact:active {
  color: #fff;
}
.footer-container {
  position: fixed;
  bottom: 0;
  height: 50px;
  width: 100%;
  z-index: 9999;
  font-size: 16px;
  margin:  0;
  line-height: 50px;
  background-color: #205e44;
  color: #fff;
  text-align: center;
}
.footer-container p {
  margin: 0;
}
.footer-container a {
  padding: 15px;
}
</style>
