<template>
  <div class="homepage">
    <!-- <b-alert show variant="warning" v-if="authenticated">Congratulations! If you can see this, we haven't broken anything yet. <a href="https://www.youtube.com/watch?v=OWFBqiUgspg" target="_blank" style="color:#31708f; text-decoration: underline;">Cheers from IT CoM Berlin!</a> Yours, IT Committee :*</b-alert> -->
    <div class="card-container first-screen">
      <img
        slot="img"
        class="d-block img-fluid w-100 background-image"
        id="cmimage"
        v-bind:src="randomImageUrl"
        alt="image slot"
        style="object-fit: cover"
      />
      <div class="card-block transparent-background" v-if="!authenticated">
        <div class="big">
          <h4>180 events</h4>
          <div>with 3.000 students participating each year</div>
        </div>
        <p class="card-text">
          Apply now and meet up with students of industrial engineering and
          management from all over Europe! <br />
          <router-link to="/about" class="about-link"
            >Read more about ESTIEM</router-link
          >
        </p>
        <p class="card-text">
          <b-button to="/events" v-if="!authenticated" class="alpha-button"
            >Show all events</b-button
          >
          <!-- removed sign-in button due to the issue 489 -->
          <!-- <b-button v-b-modal.login v-if="!authenticated">Sign in</b-button> -->
          <br /><br />
        </p>
      </div>
    </div>
    <div class="card-container">
      <div
        class="card-block no-background info-block-authenticated"
        v-if="authenticated"
      >
        <div class="big-auth">
          <div v-if="!alumnus">
            {{ greetings[Math.floor(Math.random() * greetings.length)] }}
            {{ userFirstName }}!
          </div>
          <div v-if="alumnus">
            {{
              alumniGreetings[
                Math.floor(Math.random() * alumniGreetings.length)
              ]
            }}
            {{ userFirstName }}!
          </div>
        </div>
        <p class="card-text frontPage-card">
          <!-- You have no upcoming events. Let's find one for you. -->
          <event v-if="currentEvent.id" :event="currentEvent"> </event>
        </p>
        <p>
          <b-button to="/events?q=applicationOpen" class="alpha-button"
            >Apply for upcoming events</b-button
          >
          <br /><br />
          <b-button
            target="_blank" class="alpha-button"
            href="https://old.estiem.org/Internal/default.aspx?PageId=645"
            >Join actives’ mail list</b-button
          >
        </p>
      </div>
    </div>
    <!-- <router-link class="anniversary-promo" to="/event/5519">
      <div>
        <img class="gift-box" src="https://estiem.blob.core.windows.net/estiem-images/gift-box.svg">
      </div>
      <div class="promo-text">
        30 Years of Awesomeness - ESTIEM anniversary
        <br>
        <hr style="border-color: #fff">
        Darmstadt<br>24.06-28.06
        <div class="apply-text">
          Apply from 24.02
        </div>
      </div>
    </router-link> -->
    <div v-if="!alumnus"><h2>Events</h2></div>
    <div class="department-card-container" v-if="!alumnus">
      <div class="department-card academic-background">
          <p class="academics-block">
            <img
              src="https://estiem.blob.core.windows.net/projects/white/vision.png" class="event_logo" alt="visionLogo"
            />
            <img
              src="https://estiem.blob.core.windows.net/projects/white/academicdays.png" class="event_logo" id="academicDaysID" alt="academicDaysLogo"
            />
            <img
              src="https://estiem.blob.core.windows.net/projects/white/leansixsigma.png" class="event_logo" alt="leanSixSigmaLogo"
            />
          </p>
        <p class="card-text">
          Get your <b>academic supplement</b> to courses in university through
          our in-depth seminars on relevant topics
        </p>
        <p class="card-text">
          <b-button to="/events?q=academic">Show academic events</b-button>
        </p>
      </div>
      <div class="department-card career-background">
        <p class="card-text">
          <img
            src="https://estiem.blob.core.windows.net/projects/white/times.png" class="event_logo" alt="timesLogo"
          />
          <img
            src="https://estiem.blob.core.windows.net/projects/white/businessbooster.png" class="event_logo" id="businessboosterID" alt="businessboosterLogo"
          />
        </p>
        <p class="card-text">
          Practise your <b>career skills</b> by competing in our case
          competitions and learn how to become an entrepreneur
        </p>
        <p class="card-text">
          <b-button to="/events?q=career">Show career events</b-button>
        </p>
      </div>
      <div class="department-card cultural-background">
        <p class="card-text">
          <img
            src="https://estiem.blob.core.windows.net/projects/white/europe3d.png" class="event_logo" alt="europe3dLogo"
          />
          <img
            src="https://estiem.blob.core.windows.net/projects/white/lpi.png" class="event_logo" alt="lpiLogo"
          />
        </p>
        <p class="card-text">
          Broaden your <b>cultural horizon</b> and get a unique insight into the
          differences between European countries
        </p>
        <p class="card-text">
          <b-button to="/events?q=cultural">Show cultural events</b-button>
        </p>
      </div>
      <div class="department-card personal-background">
        <p class="card-text">
          <img
            src="https://estiem.blob.core.windows.net/projects/white/braintrainer.png" class="event_logo" alt="braintrainerLogo"
          />
          <img
            src="https://estiem.blob.core.windows.net/projects/white/summeracademy.png" class="event_logo" alt="summeracademyLogo"
          />
        </p>
        <p class="card-text">
          Grow on a <b>personal level</b> by training your soft skills and learn
          more about your inner motivations
        </p>
        <p class="card-text">
          <b-button to="/events?q=personal">Show personal events</b-button>
        </p>
      </div>
    </div>
    <!-- <div v-if="!alumnus"><h2>Get active in ESTIEM</h2></div>
    <div class="department-card-container" v-if="!alumnus">
        <div class="getactive-card">
          <p class="card-text">
            <img class="event_logo" src="static/img/projects/white/leansixsigma.png" >
          </p>
          <p class="card-text">Contribute to the network and <b>join an international team.</b> Learn about Central ESTIEM and its opportunities by visiting the <b>Get Active! Page.</b> And stay up-to-date by <b>joining Actives’ email list.</b></p>
          <p class="card-text">
            <b-button to="/events?q=academic">Show academic events</b-button>
          </p>
        </div>
    </div>-->
    <div class="card-container" v-if="authenticated && !alumnus">
      <img
        slot="img"
        class="d-block img-fluid w-100 background-image"
        v-bind:src="
          'https://estiem.blob.core.windows.net/estiem-images/old_get-active.jpg'
        "
        alt="image slot"
      />
      <div class="card-block transparent-background">
        <p class="card-text">
          <b>Get active in ESTIEM</b><br />
          Contribute to the network and join an international team. Learn about
          Central ESTIEM and its opportunities by visiting the
          <b>Get Active! Page.</b> And stay up-to-date by
          <b>joining Actives’ mail list.</b>
        </p>
        <p class="card-text">
          <b-button
            style="margin-top: 8px"
            target="_blank"
            href="http://internal.estiem.org/opencalls"
            >Get Active! Page</b-button
          >
          <b-button
            style="margin-top: 8px"
            target="_blank"
            href="https://old.estiem.org/Internal/default.aspx?PageId=645"
            >Join actives’ mail list</b-button
          >
        </p>
      </div>
    </div>
    <div class="card-container" v-if="!alumnus">
      <img
        slot="img"
        class="d-block img-fluid w-100 background-image"
        v-bind:src="
          'https://estiem.blob.core.windows.net/estiem-images/old_alumni.jpg'
        "
        alt="image slot"
      />
      <div class="card-block transparent-background">
        <p class="card-text">
          <img class="event_logo alumni-background" src="https://estiem.blob.core.windows.net/estiem-images/estiem_alumni_logo.png" />
        </p>
        <p class="card-text">
          <b>Stay connected</b> after your studies through our network of
          industrial engineering and management professionals
        </p>
        <p class="card-text">
          <router-link :to="{name: 'alumni-join'}">
            <b-button>Join Alumni</b-button>
          </router-link>
          <b-button to="/events?q=alumni">Alumni events</b-button>
        </p>
      </div>
    </div>
    <div class="card-container" v-if="!alumnus">
      <img
        slot="img"
        class="d-block img-fluid w-100 background-image"
        v-bind:src="
          'https://estiem.blob.core.windows.net/estiem-images/old_partners.jpg'
        "
        alt="image slot"
      />
      <div class="card-block transparent-background">
        <p class="card-text">
          <img
            class="partner_logo"
            src="https://estiem.blob.core.windows.net/partners/inverse/lut.png"
          />
          <img
            class="partner_logo"
            src="https://estiem.blob.core.windows.net/partners/inverse/chalmers.png"
          />
        </p>
        <p class="card-text">
          We are proud of our <b>partners</b>, who help us develop our network
          even further.
        </p>
        <p class="card-text">
          <b-button href="https://internal.estiem.org/career-center-overview/partners/">Show all partners</b-button>
          <!-- commented this part out until the partners page is fully functional
          <b-button to="/partners">Show all partners</b-button>
          -->
        </p>
      </div>
    </div>

    <!-- Modal Components -->
    <b-modal id="join" hide-footer title="Join ESTIEM">
      <p>All students of Industrial Engineering and Management (IEM) at one of our 74 universities can join ESTIEM.</p>
      <p>
        To get a user, simply contact the Local Responsible at your
        <a href="/localgroups" class="link">university</a>
      </p>
    </b-modal>
    <b-modal id="forgotten" hide-footer title="Forgotten password?">
      <p style="color:red">
        The lost password functionality is not implemented yet. Please use the
        old portal to
        <a
          href="https://old.estiem.org/Internal/Login.aspx"
          style="color:red; text-decoration:underline"
          >reset your password</a
        >
      </p>
      <p>
        To reset your password enter either your user name (your full name) or
        your email address.
      </p>
      <b-form-group>
        <b-form-input
          type="text"
          v-model="forgottenPassword"
          required
          placeholder="Username"
        />
      </b-form-group>
      <b-button type="submit" variant="primary" @click="hideModal('login')"
        >Sign in</b-button
      >
      <b-button type="button" @click.prevent="hideModal('forgotten')"
        >Cancel</b-button
      >
    </b-modal>
    <!-- Removing the chatbot due to the issue 480 -->
    <!-- <Chatbot></Chatbot> -->
    <BackToTop></BackToTop>
  </div>
</template>

<script>
import moment from 'moment';
import axios from 'axios';
import eventList from '../events/eventlist.vue';
import estiemerCard from './estiemer-card.vue';
import event from '../events/event.vue';
import BackToTop from './back-to-top-btn.vue';
// import Chatbot from './chatbot-btn.vue';

export default {
  data() {
    return {
      forgottenPassword: '',
      msg: 'Welcome to the new ESTIEM',
      returnedEstiemers: [],
      searchString: '',
      pageNumber: 1,
      searchCompleted: false,
      user: {},
      greetings: ['Hello', 'Ciao', 'Hei', 'Hallo', 'Alo', 'Merhaba', 'Salut', 'Olá', 'Zdravo', 'Hejssan', 'Tere', 'Καλημέρα', 'Привіт', 'Привет', 'Morjensta nääs'],
      alumniGreetings: ['Cheers', 'Skål', 'Prost', 'Santé', 'Na zdrowie', 'Živjeli', 'Bud’mo', 'Hölökynkölökökyn'],
      userName: '',
      password: '',
      errorText: '',
      userEvent: [],
      upcoming: [],
      currentEvent: {},
      imageUrls: [
        'https://estiem.blob.core.windows.net/cm-images/cm18aWarsaw.jpg',
        'https://estiem.blob.core.windows.net/cm-images/cm21aSeville.jpg',
        'https://estiem.blob.core.windows.net/cm-images/cm22aYildiz.jpg',
        'https://estiem.blob.core.windows.net/cm-images/cm23sPorto.jpg',
        'https://estiem.blob.core.windows.net/cm-images/cm23aBudapest.jpg',
        'https://estiem.blob.core.windows.net/cm-images/cm24sAveiro.jpg',
      ],
    };
  },

  components: {
    eventList,
    estiemerCard,
    event,
    BackToTop,
    // Chatbot,
  },
  computed: {
    authenticated() {
      return this.$store.getters.authenticated;
    },
    userId() {
      return this.$store.getters.user.userId;
    },
    alumnus() {
      return this.$store.getters.userroles.includes('Alumni');
    },
    userFirstName() {
      return `${this.$store.getters.user.firstNameEnglish}`;
      // currentEvent() {
      //   if (this.authenticated) {
      //     this.getUserName().then( function () {
      //       console.log(this.username);
      //       return this.$store.getters.GetCurrentEventId(this.userName)
      //     })
      //   } else {
      //     return null
      //   }
      // },
    },
    // this username is separate from the userName in data, because that one is linked to the login procedure and so it should not be a computed property
    storeUserName() {
      const name = `${this.$store.getters.user.firstNameEnglish} ${this.$store.getters.user.lastNameEnglish}`;
      if (name !== 'undefined undefined') {
        return name;
      }
      return '';
    },
    userProfile() {
      return this.storeUserName.split(' ').join('%20');
    },
    userMobile() {
      return screen.width <= 450 ? 'mobile/' : '';
    },
  },
  // This makes sure that the current event of the user is called only when the user has been fetched from the store
  watch: {
    userId() {
      this.fetchUserEvent();
    },
  },
  methods: {
    search() {
      this.$router.push({ path: `/search?searchString=${this.searchString}` });
      // if (this.searchString.length > 1) {
      //   axios.get(`https://api.estiem.org/api/user/search/${this.searchString}?page=${this.pageNumber}`)
      //     .then((response) => {
      //       this.returnedEstiemers = response.data;
      //     });
      //   this.searchCompleted = true;
      // } else {
      // searching for a single letter makes the search on the backend two intensive
      //  alert('please enter at least 2 letters to start a search');
      // }
    },
    // it's time to put this method out of its misery
    fetchUserEvent() {
      // this method should only run if the User Id is present.
      if (!this.userId) return;
      // this endpoint fetches the eventId of the current / upcoming event of the user
      const eventsUrl = `users/${this.userId}/events/upcoming`;
      axios
        .get(eventsUrl)
        .then((response) => {
          // when the id has been found, the event can be loaded
          this.currentEvent = response.data;
        })
        // needed to add error handler
        .catch((error) => {
          switch (error.code)
          {
          case 500:
          case 404:
          default:
          }
        });
    },
    getApplicationOpen(events) {
      const now = moment();
      const filteredEvents = events.filter(
        e =>
          now.isSameOrAfter(e.applicationStartDate) &&
          now.isSameOrBefore(e.applicationEndDate),
      );
      return filteredEvents;
    },
    getUpcoming(events) {
      const now = moment();
      const filteredEvents = events.filter(e => now.isSameOrBefore(e.endDate));
      return filteredEvents;
    },
    hideModal(id) {
      this.$root.$emit('bv::hide::modal', id);
    },
    setRandomImage() {
      const randomIndex = Math.floor(Math.random() * this.imageUrls.length);
      this.randomImageUrl = this.imageUrls[randomIndex];
    },
  },
  created() {
    this.setRandomImage();
  },
  mounted() {
    this.fetchUserEvent();
  },
};
</script>


<style scoped>


/*
.first-screen {
   height: calc(100vh - 280px);
   background-color: white;
}
*/
.anniversary-promo {
  height: 150px;
  background-color: var(--green-primary);
  background-image: url('https://estiem.blob.core.windows.net/estiem-images/promo-background.svg');
  display: flex;
  justify-content: center;
  color: white;
  flex-wrap: wrap;
  align-items: center;
}

.promo-text {
  max-width: 300px;
  line-height: initial;
  font-size: 1em;
  margin-left: 80px;
  background-color: var(--green-primary);
}

.gift-box {
  max-height: 110px;
}

.apply-text {
  font-size: 16px;

}

.card-container {
  position:relative;
  text-align:center;
}

.background-image {
  height:calc(100vh - 209px);
  object-fit: cover;
}

.card-block {
  width: calc(100% - 40px);
  position: absolute;
  bottom: 25%;
  background-color: black;
  border-radius: 2px;
  left: 0px;
  right: 0px;
  color: #fff;
  padding-left: 20px;
  padding-right: 20px;
  max-width: 600px;
  margin: 0px auto;
}

.department-card {
  width: 450px;
  height: auto;
  border-radius: 2px;
  margin: 10px;
  color: #fff;
  padding: 20px 25px;
  text-align: center;
}

.department-card-container {
  display: flex;
  justify-content: center;
  flex-flow: wrap;
  padding: 50px;
}

.card-block a {
  color: rgb(255,255,255);
  text-decoration: underline;
}

.card-block .btn {
  color:var(--estiem-green);
  text-decoration:none;
}

.academic-background .btn {
  color:var(--red-primary);
}

.career-background .btn {
  color:var(--blue-primary);
}

.cultural-background .btn {
  color:var(--orange-primary);
}

.personal-background .btn {
  color:var(--green-primary);
}

.card-title {
  color: #fff;
}

.big .big-auth {
  font-size:1.4em;
  color:white;
  margin-bottom:10px;
}

.big h4 {
  font-size:1.4em;
  color:white;
}

.big-auth {
    font-size: 1.5em;
}

a.link {
  text-decoration: underline;
}

.mail:link {
  text-decoration:none;
  color:white;
}

.event-details {
  flex-basis:15%;
  flex-grow: 0;
}

.card-deck .card {
  margin: 5px 5px;
}

.event-name {
  font-size: 16px;
}

.event-text {
  color: #fff;
}

.event-text a {
  color: #fff;
  text-decoration: underline
}

.homepage {
    padding-top: 0px;
    padding-bottom: 0px;
}

.event_logo {
  width:200px;
}

.academic-background .event_logo {
  width:170px;
}

.partner_logo {
  width:150px;
  margin: 15px;
}


.scrolling-wrapper-flexbox {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  width: calc(100% - 5px);
  flex: 0 0 auto;
  margin-right: 3px;
  border: 1px solid white;
  min-width: 200px;
  margin: 5px;
  -webkit-overflow-scrolling: touch;
}

h2 {
  margin: 35px auto 10px;
  font-variant: small-caps;
  text-align: center;
  font-size: 36px;
}

.alumni-background{
  background-color: var(--green-primary);
  padding: 20px;
  width: fit-content;
}

.transparent-background {
  background-color: rgba(0,0,0,0.55)
}

.no-background {
  background-color: transparent;
}

.career {
  color: var(--blue-primary)
}

.career-background {
  background-color: var(--blue-primary)
}

.cultural {
  color: var(--orange-primary)
}

.cultural-background {
  background-color: var(--orange-primary)
}

.academic {
  color: var(--red-primary)
}

.academic-background {
  background-color: var(--red-primary)
}

.personal-background {
  background-color: #3491bd
}

.scrolling-wrapper-flexbox::-webkit-scrollbar {
    display: none;
  }

.alert {
  margin-bottom:0px;
}

.about-link {
  display:block;
  margin-top:0.5em;
}

.alpha-button {
  background-color: rgba(255, 255, 255, 0.8);
  border: 1px solid rgba(255, 255, 255, 0.8);
  color: inherit;
}


@media screen and (max-width: 320px) {
    .event-details {
      min-width: 200px!important;
    }

    .card-block {
      bottom: 10px
    }
}

@media screen and (max-width: 450px) {
    .event-name, .event-text {
      font-size:13px;
    }

    .getactive .btn {
      padding: 5px;
    }

    h2 {
      font-size:26px
    }

    .department-card-container {
      padding: 0px;
    }

    .card-block {
      bottom: 150px
    }

    .background-image {
      height:calc(100vh - 91px);
    }
  .anniversary-promo {
    height: 125px;
    font-size: 12px;
  }
  .promo-text {
    font-size: 12px;
    width: 135px;
    margin-left: 38px;
  }
  .apply-text {
    font-size: 10px;
  }
  hr {
    margin: 7px 0;
  }
  .gift-box {
    height: 90px;
  }
}

@media screen and (max-width: 768px) {
  .event_logo {
    width:145px;
  }
  .academic-background .event_logo {
    width:145px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1120px) {
  .background-image {
    height:calc(100vh - 342px);
  }
}

@media screen and (min-width: 450px) {
    .event-name, .event-text {
      font-size:16px;
    }

    .department-card-container {
      padding: 10px;
    }
}

@media screen and (min-width: 1024px)  {
  .about {
    float:left;
    width:400px;
    min-height: calc(100vh - 100px);
  }
  .content {
    float:right;
    width: calc(100% - 400px);
  }
  .broad {
    width:100%
  }

  .department-card-container {
    padding: 20px;
  }

  .getactive {
    width: 700px;
  }
}

@media screen and (max-width: 1400px){
  .academics-block{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    margin: 0px;
  }
}

@media screen and (min-width: 1400px) {
    .card-block {
      bottom: 60px;
    }
    .event-details {
      flex-basis:24%
    }
    .event-name, .event-text {
      font-size:18px;
    }
    .department-card {
      width: 610px;
    }
}

.info-block-authenticated {
  bottom: 10px;
}

.academics-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 30px 0px;
}

#academicDaysID {
  margin-top: 23px;
}

#businessboosterID {
  margin-top: 20px;
  margin-bottom: 10px;
}

#cmimage {
  height: calc(100vh - 100px);
}

</style>
