<template>
  <div v-if="!!event">
    <section class="main-screen">
      <img class="event-pic" :src="headerImg" alt="">
      <spinner v-if="!contentLoaded"></spinner>
      <div class="event-details">
        <h1>{{ event.name }}</h1>
        <h2>{{event.place}}</h2>
        <h3>{{ eventDatesHeader }}</h3>
        <p>Application period: {{ event.applicationStartDate | formatdateApp }} – {{ event.applicationEndDate | formatdateApp }}</p>
        <p>Cancellation deadline: {{ event.retireDeadline | formatdateApp }}</p>
        <div class="split">
          <span v-if="event.participationFee != null && event.participationFee >= 0"> {{ event.participationFee }}€ </span>
          <div v-if="isPastEvent=='eventPast'">
            <b-button class="disabled" id="application-closed">Application closed</b-button>
          </div>
          <router-link class="url" :to="{name: 'eventApply', params: { id: event.id }}" v-else-if="authenticated && (this.alreadyApplied || this.alreadyRegistered)">
            <b-button> Update Application </b-button>
          </router-link>
          <router-link class="url" :to="{name: 'eventApply', params: { id: event.id }}" v-else-if="applicationOpen=='open'">
            <b-button> Apply </b-button>
          </router-link>
          <div v-else-if="applicationOpen=='willopen'">
            <b-button disabled id="application-closed">Application will open on {{ event.applicationStartDate | formatdateApp }}</b-button>
          </div>
          <div v-else-if="applicationOpen=='closed'">
            <b-button class="disabled" id="application-closed">Application closed</b-button>
          </div>
          <div v-else-if="applicationOpen=='not'">
            <b-button class="disabled" id="application-closed">Application closed</b-button>
          </div>
        </div>
      </div>
  </section>
  <section class="menu my-4">
    <article class="links">
      <a target="_blank" href="https://store.compensate.com/offset/flights/">
        <i v-b-tooltip.hover title="Compensate your emissions" class="material-icons">eco</i>
      </a>
      <a v-if="alreadyRegistered && event.whatsapp" :href="event.whatsapp">
        <i v-b-tooltip.hover title="Join WhatsApp Group" class="material-icons">chat</i>
      </a>
      <a v-if="authenticated && event.phone" :href="`tel:${event.phone}`">
        <i v-b-tooltip.hover title="Call the organiser" class="material-icons">phone</i>
      </a>
      <a v-if="event.youtube" :href="event.youtube">
        <i v-b-tooltip.hover title="Promotion Video" class="material-icons">movie</i>
      </a>
      <a v-if="authenticated && event.facebook" :href="event.facebook">
        <i v-b-tooltip.hover title="Facebook Event" class="material-icons">facebook</i>
      </a>
      <a v-if="alreadyRegistered && event.photoshare" :href="event.photoshare">
        <i v-b-tooltip.hover title="Photos & Videos" class="material-icons">photo_camera</i>
      </a>
      <a target="_blank" v-if="authenticated && event.email" :href="`mailto:${event.email}`" >
        <!-- Need to find the email of the main organiser (from the estiemer profile) -->
        <i v-b-tooltip.hover title="Email the organiser" class="material-icons">mail</i>
      </a>
      <a v-if="isAdmin || isOrganiser || isLrOfEvent" v-on:click="edit" style="cursor: pointer;">
        <i v-b-tooltip.hover title="Edit Event" class="material-icons">edit</i>
      </a>
    </article>
    <article class="event-pages">
        <b-nav tabs justified align="center">
          <b-nav-item class="url" :active="this.activeD" id="tabD" ref="tabD" :to="{name: 'eventDetails', params: { id: event.id }}">
            <span>Description</span>
          </b-nav-item>
          <b-nav-item class="url" :active="this.activeP" id="tabP" ref="tabP" :to="{name: 'eventParticipants', params: { id: event.id }}" v-if="authenticated">
            <span>Participants</span>
          </b-nav-item>
          <b-nav-item class="url" :active="this.activeF" id="tabF" ref="tabF" :to="{name: 'eventFeedbackReport', params: { id: event.id }}" v-if="isAdmin || isOrganiser || isLrOfEvent">
            <span>Feedback Report</span>
          </b-nav-item>
        </b-nav>
    </article>
  </section>
       <!-- Modal Components -->
    <b-modal ref="applied" hide-footer title="Apply to event">
      <div>
        <p>You have successfully applied for the event and are now on the waiting list.</p>
      </div>
    </b-modal>
    <b-modal ref="reapplied" hide-footer title="Apply to event">
      <div>
        <p>You have successfully re-applied for the event and are now on the waiting list.</p>
      </div>
    </b-modal>
    <b-modal ref="updated" hide-footer title="Apply to event">
      <div>
        <p>You have successfully updated your application.</p>
      </div>
    </b-modal>
    <b-modal ref="cancelled" hide-footer title="Apply to event">
      <div>
        <p>You have successfully cancelled your application.</p>
      </div>
    </b-modal>
        <!-- commented out link to agenda functionality.
             this functionality allows users to add an interactive agenda with separate points for each entry
             it is specified in event-schedule-list and event-schedule-activity
             It was commented out because, the way that it is set up, it was too complicated to use. To see an example implementation, check CM Warsaw at https://m.estiem.org/event/5008/schedule
             could still work in some future cases, but it's being used too little to justify the place the icon takes up
        <router-link class="card-link"  :to="{name: 'eventSchedule', params: { id: eventId }}" v-if="authenticated">
          <i class="material-icons" style="vertical-align:middle;">access_time</i><br> <span>Agenda</span>
        </router-link>
        -->
        <!-- Should show "X participants" if there is at least one registered. Else show "X applicants" -->
  </div>
</template>

<script>
import moment from 'moment';
import axios from 'axios';
import spinner from '../Spinner/spinner.vue';
import { RegistrationStatus } from '../../helpers/registration-status.js';

export default {
  data() {
    return {
      headerImg: 'yes',
    };
  },
  components: {
    spinner,
  },
  filters: {
    formatdate(value) {
      if (value) {
        return moment(value).format('DD.MM.YYYY');
      }
      return '';
    },
    formatdateApp(value) {
      if (value) {
        return moment(value).format('ll');
      }
      return '';
    },
  },
  computed: {
    activeD() {
      if (this.$route.name === 'eventDetails') {
        return true;
      }
      return false;
    },
    activeP() {
      if (this.$route.name === 'eventParticipants') {
        return true;
      }
      return false;
    },
    activeF() {
      if (this.$route.name === 'eventFeedbackReport') {
        return true;
      }
      return false;
    },
    organiserOrParticipant() {
      const a = this.participant.registrationStatus === RegistrationStatus.ORGANIZER;
      const b = this.participant.registrationStatus === RegistrationStatus.REGISTERED;
      return a || b;
    },
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
    isOrganiser() {
      return this.participant && this.participant.registrationStatus === 5;
    },
    isLrOfEvent() {
      if (this.$store.getters.isLR && this.$store.getters.user.localGroupId === this.event.parentGroupId) {
        return true;
      }
      return false;
    },
    alumnus() {
      return this.$store.getters.userroles.includes('Alumni');
    },
    alreadyApplied() {
      return this.participant.registrationStatus === RegistrationStatus.WAITING;
    },
    contentLoaded() {
      return this.event.name;
    },
    eventOrganiser() {
      return this.participant.registrationStatus === RegistrationStatus.ORGANIZER;
    },
    alreadyRegistered() {
      return (this.participant.registrationStatus === RegistrationStatus.REGISTERED || this.participant.registrationStatus === RegistrationStatus.ORGANIZER);
    },
    userId() {
      return this.$store.getters.user.userId;
    },
    eventId() {
      return this.$route.params.id;
    },
    authenticated() {
      return this.$store.getters.authenticated;
    },
    applicationOpen() {
      const now = moment();
      if (
        now.isSameOrAfter(this.event.applicationStartDate) &&
        now.isSameOrBefore(this.event.applicationEndDate)
      ) {
        return 'open';
      } else if (now.isBefore(this.event.applicationEndDate)) {
        return 'willopen';
      } else if (
        this.event.applicationStartDate == null &&
        this.event.applicationEndDate == null
      ) {
        return 'not';
      }

      return 'closed';
    },
    isPastEvent() {
      const now = moment();
      if (
        now.isSameOrAfter(this.event.endDate)
      ) {
        return 'eventPast';
      }
      return null;
    },
    eventDatesHeader() {
      const startDate = moment(this.event.startDate);
      const endDate = moment(this.event.endDate);

      if (startDate.isSame(endDate, 'day')) {
        return startDate.format('ddd D MMMM YYYY');
      }

      const sameMonth = startDate.isSame(endDate, 'month');
      const sameYear = startDate.isSame(endDate, 'year');

      var startFormat = sameMonth ? 'ddd D' : 'ddd D MMMM';
      if (!sameYear) {
        startFormat = 'ddd D MMMM YYYY';
      }

      const endFormat = 'ddd D MMMM YYYY';

      return `${startDate.format(startFormat)} – ${endDate.format(endFormat)}`;
    },
  },
  props: {
    event: {
    },
    participant: {
      default() { return {}; },
    },
  },
  methods: {
    edit() {
      let route = this.$route.fullPath;
      if (route.includes('participants')) {
        route = route.replace('participants', '');
      }
      if (route.slice(-1) === '/') {
        this.$router.push(`${route}edit`);
      } else this.$router.push(`${route}/edit`);
    },
    getEventTypeName() {
      this.eventTypeName = this.evTypeOptions.find(
        e => e.value === this.event.eventType,
      ); // filter event type name
    },
    fetchEvent() {
      const id = this.$route.params.id;
      const url = `/events/${id}`;
      axios.get(url).then((response) => {
        this.event = response.data;
      });
    },
    async fetchHeaderImg() {
      const eventid = this.$route.params.id;
      const lgid = this.event.parentGroupId;
      const res = screen.width < 450 ? 'dt' : 'bg';
      await axios.get(`/files/eventhead/${eventid}?lgId=${lgid}&resolution=${res}`)
        .then((response) => {
          this.headerImg = response.data.imageUrls[0];
        }).catch(() => {
          this.headerImg = 'https://estiem.blob.core.windows.net/estiem-images/estiem_e.jpg';
        });
    },
    // onScroll() {
    //   const navbar = document.querySelector('.event-pages');
    //   const sticky = navbar.offsetTop;
    //   if (window.pageYOffset >= sticky) {
    //     navbar.classList.add('sticky');
    //   } else {
    //     navbar.classList.remove('sticky');
    //   }
    // },
  },
  watch: {
    event() {
      this.fetchHeaderImg();
    },
  },
  mounted() {
    this.fetchEvent();
    document.addEventListener('scroll', this.onScroll);
    if (this.$route.query.status === 'applied') return this.$refs.applied.show();
    if (this.$route.query.status === 'updated') return this.$refs.updated.show();
    if (this.$route.query.status === 'cancelled') return this.$refs.cancelled.show();
    if (this.$route.query.status === 'reapplied') return this.$refs.reapplied.show();
    return null;
  },
};
</script>

<style scoped>

.main-screen {
    display: grid;
    grid-template-columns: auto 684px 498px auto;
    grid-template-rows: min-content;
    justify-content: center;
}

.event-pic {
    object-fit: contain;
    max-height: 498px;
    width: 100%;
    height: 100%;
    grid-column-start: 1;
    grid-column-end: 3;
}

.event-details {
  display: grid;
  grid-auto-rows: max-content;
  grid-column-start: 3;
  grid-column-end: 5;
  color: black;
  padding: 80px 77px 30px;
  .split {
    display: grid;
    grid-template-columns: 25% 1fr;
    span {
      grid-column-start:1;
      grid-column-end:2;
      color: black;
      font-size: 34px;
      font-weight: bold;
      margin-right: 10px;
    }
    div {
      grid-column-start:2;
      grid-column-end:3;
    }
  }
  h1 {
    margin-bottom: 50px;
    color: black!important;
    font-size: 36px!important;
    font-style: bold!important;
  }
  h2 {
    color: black!important;
    font-size: 24px!important;
    margin-bottom: 10px;
  }
  h3 {
    margin-bottom: 40px;
    color: black;
    font-size: 20px;
  }
  button {
    color: white;
    font-size: 20px;
    line-height: 30px;
    background-color: #205e44;
    min-width: 210px;
    min-height: 50px;
    width: max-content;
    height: min-content;
  }
}

.url {
  text-decoration:none;
}

#application-closed:hover{
  background-color: #205e44;
  cursor: default;
}

.menu {
  display: grid;
  grid-template-columns: auto 684px 498px auto;
  grid-template-rows: min-content;
  justify-content: center;
  margin-bottom:5px;
  .links {
    padding-top: 15px;
    grid-column-start: 1;
    grid-column-end: 3;
  }

  .event-pages {
    grid-column-start: 3;
    grid-column-end: 5;
    a {
      color: #808285;
    }
    .active {
      color: #000000;
    }
    .navbar {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      width:100%;
      li {
        font-size: 20px;
        line-height: 30px;
        max-width: 249px; /* 498/2 */
        max-height: 50px;
        margin: 0 4%;
        .active {
          color: #000000;
          padding: 10px;
        }
        a {
          color: #808285;
        }
      }
    }
  }

  .disabled:hover {
    border: none;
  }

  .nav-tabs {
    border-bottom: transparent;
  }

  .material-icons {
    font-size: 35px;
    margin: 0 10px;
    color: #205E44;
  }
}

@media screen and (max-width: 1024px)
{
  .main-screen {
    grid-template-columns: auto auto auto;
  }

  .menu {
    grid-template-columns: 20px auto 20px;
    .links {
      grid-row-start: 1;
      grid-column-start:2;
      grid-column-end:3;
      justify-content: center;
    }
    .event-pages  {
      grid-row-start: 2;
      grid-column-start:2;
      grid-column-end:3;
      li{
        padding: 10px;
      }
      .active{
        padding: 10px;
      }
    }
  }

  .event-pic {
    grid-row-start: 1;
    grid-column-start:1;
    grid-column-end:4;
  }

  .event-details {
    padding: 10px 0 5px;
    grid-row-start: 2;
    grid-column-start:2;
    grid-column-end:3;
    text-align : center;
    h1 {
      margin-bottom: 0.5rem;
    }
    h2 {
      margin-bottom: 0.5rem;
    }
    h3 {
      margin-bottom: 0.5rem;
    }
    p{
      margin-bottom: 0.5rem;
    }
  }
}
@media screen and (min-width: 1800px) {
  .event-pic {
    grid-column-start: 2;
    grid-column-end: 3;
  }

  .event-details {
    grid-column-start: 3;
    grid-column-end: 4;
  }

  .links {
    grid-column-start: 2;
    grid-column-end: 3;
  }

  .event-pages {
    grid-column-start: 3;
    grid-column-end: 5;
  }
}
</style>
