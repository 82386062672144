<template>
  <div class="card-container">
    <img
      slot="img"
      class="d-block img-fluid w-100 background-image"
      v-bind:src="'https://estiem.blob.core.windows.net/estiem-images/pexels-alex-andrews-271121-821754-crop.jpg'"
      alt="image slot"
    />
    <article class="card-block">
      <section>
        <h2>If you want your university to join ESTIEM</h2>
        <a href="mailto:membership@estiem.org">membership@estiem.org</a>
      </section>
      <section>
        <h2>If you want your NGO to collaborate with ESTIEM</h2>
        <a href="mailto:ambassador@estiem.org">ambassador@estiem.org</a>
      </section>
      <section>
        <h2>For any other questions</h2>
        <a href="mailto:help@estiem.org">help@estiem.org</a>
      </section>
      <section>
        <h1>ESTIEM</h1>
        <p>Registration Number: 40240535</p>
        <p>Groene Loper 3</p>
        <p>5612AE Eindhoven</p>
        <p>The Netherlands</p>
      </section>
      <section>
        <h1>{{ lgLength }} Local Groups</h1>
        <p>with a reach of more than 60.000 IEM students</p>
        <RouterLink to="/localgroups">
          <b-btn variant="primary">View all</b-btn>
        </RouterLink>
      </section>
    </article>
  </div>
</template>

<script>
import axios from "axios";
import { RouterLink } from "vue-router";

export default {
  data() {
    return {
      localGroups: [],
    };
  },
  props: {
    count: {
      default: 100,
    },
  },
  computed: {
    lgLength() {
      return this.localGroups.length;
    },
  },
  created() {
    axios.get("/localgroups").then((response) => {
      this.localGroups = response.data;
    });
  },
};
</script>

<style scoped>
section {
  display: flex;
  flex-direction: column;
  margin: 4px;
}
.card-container {
  position: relative;
  text-align: center;
}

.background-image {
  height: 100vh;
  width: auto;
  object-fit: cover;
}

@media screen and (min-width: 700px) {
  .background-image {
    min-height: 550px;
  }
}

.card-block {
  width: calc(100% - 40px);
  position: absolute;
  bottom: 150px;
  background-color: rgba(255, 255, 255, 0.9);
  left: 0px;
  right: 0px;
  color: black;
  padding-left: 20px;
  padding-right: 20px;
  max-width: 600px;
  margin: 0px auto;
}

@media screen and (max-width: 350px) {
  .card-block {
    bottom: 130px;
  }
}

.card-block a {
  text-decoration: underline;
  color: rgb(0, 0, 0);
}
</style>
