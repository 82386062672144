<template>
  <div v-if="activeLocalGroups.length !== 0" style="padding: 30px 0; margin: 0 5px;">
    <h1 class="title">Local Groups</h1>
    <b-card-group deck style="justify-content: center;">
        <localgroup v-for="localgroup in activeLocalGroups" :key="localgroup.id" :localgroup="localgroup" v-if="!localgroup.isSelected" ></localgroup>
    </b-card-group>
  </div>
</template>

<script>
import localgroup from './localgroup.vue';

export default {
  props: {
    // count: {
    //  default: 100,
    // },
    searchString: {
      default: '',
    },
    localgroups: {
      default: [],
    },
    includeInactive: {
      default: false,
    },
  },
  components: {
    localgroup,
  },
  computed: {
    activeLocalGroups() {
      if (this.includeInactive && this.searchString === '') {
        return this.localgroups.concat().sort((a, b) => {
          if (a.name > b.name) return 1;
          if (b.name > a.name) return -1;
          return 0;
        });
      } else if (this.includeInactive === false && this.searchString === '') {
        return this.localgroups.filter(lg => lg.status !== 8).concat().sort((a, b) => {
          if (a.name > b.name) return 1;
          if (b.name > a.name) return -1;
          return 0;
        });
      } else if (this.searchString !== '') {
        return this.localgroups.filter((a) => {
          if (a.name.toLowerCase().includes(this.searchString.toLowerCase())) return a;
          if (a.country.toLowerCase().includes(this.searchString.toLowerCase())) return a;
          return false;
        });
      }
      return false;
    },
  },
};
</script>

<style scoped>
.title{
  margin: auto !important;
  text-align: center;
}
</style>
