<template>
  <div class="tableau-main-container">
    <h2 v-if="vizData && vizData.metadata && vizData.metadata.title && vizData.metadata.title.length > 0">{{ vizData.metadata.title }}</h2>
    <div v-if="vizData && vizData.metadata && vizData.metadata.description && vizData.metadata.description.length > 0">{{ vizData.metadata.description }}</div>
    <i v-show="showSpinner" class="load-message">{{ (vizData && vizData.metadata && vizData.metadata.title) ? vizData.metadata.title : 'The dashboard' }} is loading ...</i>
    <i v-show="showSpinner" class="load-message">{{ loadPhase }}</i>
    <spinner v-show="showSpinner" />
    <div ref="embedContainer" class="embed-container" v-show="showViz"></div>
  </div>
</template>

<script>
import { renderTableauVisualization, prepDashboardsForBuild, addContextualFiltersToVizData, applyFilters } from '../../helpers/tableau-helper-functions.js';
import Spinner from '../Spinner/spinner.vue';

export default {
  components: {
    spinner: Spinner,
  },
  props: {
    vizData: {
      type: Object,
      required: true,
    },
    contextualFilters: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      jwtToken: '',
      showSpinner: true,
      showViz: false,
      loadPhase: 'Fetching the dashboard ...',
    };
  },
  mounted() {
    this.fetchJwtTokenAndRenderVisualization();
  },
  methods: {
    async fetchJwtTokenAndRenderVisualization() {
      try {
        this.jwtToken = await prepDashboardsForBuild(this.$store);
        this.renderVisualization();
      } catch (error) {
        console.error('Error fetching JWT token or loading Tableau script:', error);
      }
    },
    async renderVisualization() {
      try {
        const container = this.$refs.embedContainer;
        console.debug('The contextual filters are:', this.contextualFilters);

        // Create a deep copy of the vizData object to avoid modifying the original
        // This approach was recommended by Copilot, as direct mutation causes issues in further page-loads
        var vizDataCopy = JSON.parse(JSON.stringify(this.vizData));

        vizDataCopy = addContextualFiltersToVizData(vizDataCopy, this.contextualFilters);
        renderTableauVisualization(vizDataCopy, this.jwtToken, container);
        console.debug('The length of the contextual filters is:', this.contextualFilters.length);

        const vizObj = document.getElementById(
          vizDataCopy.attributes.id,
        );

        vizObj.addEventListener('firstvizsizeknown', () => {
          console.debug('firstvizsizeknown fired');
          this.loadPhase = 'Applying filters & resizing to the dashboard ...';
          this.showViz = true;
          if (!this.contextualFilters || this.contextualFilters.length === 0) {
            this.showSpinner = false;
          }
        });

        // Wait for the viz to be fully interactive before applying filters
        // or resizing, as resizing a viz with "automatic" sizing can be tricky
        vizObj.addEventListener('firstinteractive', () => {
          console.debug('The viz is fully interactive / firstinteractive fired');
          if (this.contextualFilters.length > 0) { // Todo check instead if types other than categorical exist
            applyFilters(vizObj, vizDataCopy.filters);
          }

          if (vizObj.workbook.activeSheet.size.behavior !== 'automatic') {
            // Actually setting it to the height of the active sheet...
            vizObj.height = vizObj.workbook.activeSheet.size.minSize.height;
          } else if (vizObj.workbook.activeSheet.size.behavior === 'automatic'
            && (!vizDataCopy.attributes.height || vizDataCopy.attributes.height === 'auto' || vizDataCopy.attributes.height === '100%')
          ) { // automatic height is used and no applicable height is set...
            // make the height based 16:9 aspect ratio based on the width
            // If this happens, mistakes were made i.e.
            // the viz size was not specified in
            //  - tableau-embedding-data.js or
            //  - on tableau when the viz was published
            // and the resizing will happen after the spinner is hidden / viz shown
            vizObj.height = container.offsetWidth * 0.5625;
          }

          this.showSpinner = false;
          this.$nextTick(() => {
            document.querySelector('.embed-container').style.opacity = '1';
            document.querySelector('.embed-container').style.height = 'auto';
          });
        });

      } catch (error) {
        console.error('Error rendering Tableau visualization:', error);
      }
    },
  },
};
</script>

<style scoped>
.tableau-main-container {
  width: 80%;
  margin: 0 auto;
  text-align: justify;
}

.embed-container {
  width: 100%;
  height: 0px;
  margin: 0 auto;
  box-sizing: border-box;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.load-message {
  display: block;
  text-align: center;
  margin-top: 20px;
}
</style>
