<template>
  <section class="signin" v-if="!authenticated">
    <div id="sso-login">
      <font-awesome-icon class="fa-icons" :icon="['fab', 'microsoft']" />
      <b-button variant="primary" @click="loginSSO">Sign in with SSO</b-button>
    </div>
    <b-form class="form" @submit.prevent="login">
      <b-alert :show="errorText != ''" variant="danger">{{ errorText }}</b-alert>
      <b-form-group>
        <b-form-input type="text" v-model="userName" required placeholder="Username"/>
      </b-form-group>
      <b-form-group>
        <b-form-input type="password" v-model="password" required placeholder="Password"/>
      </b-form-group>
      <b-button type="submit" variant="primary">Sign in</b-button>
      <!-- Need functionality for lost password -->
      <!-- <b-button id="login_lost_btn" type="button" class="btn btn-link">Lost password?</b-button> -->
      <!-- In future it would be good for user to sign up themselves (followed by a final verification by LR) -->
      <!-- <b-button id="login_register_btn" type="button" class="btn btn-link">Register</b-button> -->
    </b-form>
  </section>
  <section v-else id="already-signed-in">
    <h1>Succesfully signed in</h1>
    <p>Sign in with SSO might have direct you to login page. We are working on this issue, please be patient.</p>
    <b-button variant="primary":to="{name: 'homepage'}">GO TO HOME PAGE</b-button>
  </section>
</template>

<script>
import auth from '../../auth/index.js';

export default {
  data() {
    return {
      userName: '',
      password: '',
      errorText: '',
    };
  },
  computed: {
    authenticated() {
      return this.$store.getters.authenticated;
    },
  },
  methods: {
    loginSSO() {
      auth.loginSSO();
    },
    login() {
      const credentials = {
        userName: this.userName,
        password: this.password,
      };
      // It seems like params is an empty object normally which means path is undefined
      const path = this.$route.fullPath;
      auth.login(credentials, path)
        .then(() => this.$router.go(-1))
        .catch(e => this.errorText = e);
    },
  },
};
</script>

<style scoped>
#sso-login {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
}

#sso-login p {
  font-style: italic;
}

.sso-login-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}

.fa-icons {
  color: var(--green-primary);
  font-size: 2em;
}

h2 {
    text-align: center;
}

.form {
    max-width: 500px;
    margin: auto;
}

.signin {
  padding:20px;
}

#already-signed-in {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

#already-signed-in h1 {
  text-transform: uppercase;
  margin-top: 1rem;
}

#already-signed-in p {
  font-style: italic;
}
</style>
