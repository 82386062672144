
<template>
  <article>
    <h1>Alumni Applicant List</h1>
    <section>
      <label for="statusFilter">Filter by Status:</label>
      <select id="statusFilter" v-model="selectedStatus" @change="loadApplicants">
        <option v-for="status in statusOptions" :key="status" :value="status">
          {{ status }}
        </option>
      </select>
    </section>
    <table>
      <thead>
        <tr>
          <th>
            Select <br>
            <input type="checkbox" :checked="allSelected" @change="toggleSelectAll" />
          </th>
          <th @click="sort('firstName')" :class="{ sortable: true }">
            Name
            <span v-if="sortField === 'firstName'">
              {{ sortDirection === 'asc' ? '↑' : '↓' }}
            </span>
          </th>
          <th @click="sort('applicationType')" :class="{ sortable: true }">
            Application Type
            <span v-if="sortField === 'applicationType'">
              {{ sortDirection === 'asc' ? '↑' : '↓' }}
            </span>
          </th>
          <th @click="sort('localGroup')" :class="{ sortable: true }">
            Local Group
            <span v-if="sortField === 'localGroup'">
              {{ sortDirection === 'asc' ? '↑' : '↓' }}
            </span>
          </th>
          <th @click="sort('applicationText')" :class="{ sortable: true }">
            Application
            <span v-if="sortField === 'applicationText'">
              {{ sortDirection === 'asc' ? '↑' : '↓' }}
            </span>
          </th>
          <th @click="sort('applicationDate')" :class="{ sortable: true }">
            Application Date
            <span v-if="sortField === 'applicationDate'">
              {{ sortDirection === 'asc' ? '↑' : '↓' }}
            </span>
          </th>
          <th>
            Profile
          </th>
        </tr>
      </thead>
      <tbody v-if="applicants.length">
        <tr
          v-for="(applicant) in sortedApplicants"
          :key="applicant.userId"
          :class="{ selected: selectedApplicants.includes(applicant.userId) }"
        >
          <td>
            <input
              type="checkbox"
              :value="applicant.userId"
              v-model="selectedApplicants"
            />
          </td>
          <td>{{ applicant.firstName }} {{ applicant.lastName }}</td>
          <td>{{ getApplicationType(applicant.status, applicant.alumniStatus) }}</td>
          <td>{{ getLocalGroup(applicant.localGroupId) }}</td>
          <td>{{ applicant.applicationText }}</td>
          <td>{{ new Date(applicant.applicationDate).toLocaleDateString() }}</td>
          <td>
            <router-link :to="{ name: 'profile', params: { id: applicant.userId }}">
              See More
            </router-link>
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td colspan="7">
            <spinner></spinner>
          </td>
        </tr>
      </tbody>
    </table>
    <section v-if="hasMoreApplicants">
      <b-button variant="primary" v-show="!isLoading" @click="loadMoreApplicants">Load More</b-button>
      <spinner v-show="isLoading" small/>
    </section>
    <section id="accept-reject">
      <b-button variant="primary" @click="processApplications(true)">Accept</b-button>
      <b-button variant="danger" @click="processApplications(false)">Reject</b-button>
    </section>
    <backToTopBtn />
  </article>
</template>


<script>
import axios from 'axios';
import Page404 from '../page404.vue';
import { alumniStatus, statusAlumniFilter } from '../../helpers/user-status.js';
import spinner from '../Spinner/spinner.vue';
import backToTopBtn from '../home/back-to-top-btn.vue';

export default {
  data() {
    return {
      alumniStatus,
      statusAlumniFilter,
      applicants: [],
      localGroups: [],
      selectedApplicants: [],
      selectedStatus: 'Applicants (incl. unconfirmed and junior)', // Default status
      sortField: '',
      sortDirection: 'asc',
      statusOptions: Object.values(statusAlumniFilter),
    };
  },
  components: {
    Page404,
    spinner,
    backToTopBtn,
  },
  computed: {
    allSelected() {
      return (
        this.applicants.length > 0 &&
        this.selectedApplicants.length === this.applicants.length
      );
    },
    sortedApplicants() {
      if (!this.sortField) return this.applicants;

      return [...this.applicants].sort((a, b) => {
        let fieldA;
        let fieldB;

        // Handle complex fields
        if (this.sortField === 'applicationType') {
          fieldA = this.getApplicationType(a.status, a.alumniStatus);
          fieldB = this.getApplicationType(b.status, b.alumniStatus);
        } else if (this.sortField === 'localGroup') {
          fieldA = this.getLocalGroup(a.localGroupId);
          fieldB = this.getLocalGroup(b.localGroupId);
        } else {
          fieldA = a[this.sortField] || '';
          fieldB = b[this.sortField] || '';
        }

        if (typeof fieldA === 'string') fieldA = fieldA.toLowerCase();
        if (typeof fieldB === 'string') fieldB = fieldB.toLowerCase();

        if (this.sortDirection === 'asc') {
          return fieldA > fieldB ? 1 : -1;
        }
        return fieldA < fieldB ? 1 : -1;
      });
    },
  },
  created() {
    this.loadApplicants();
    this.loadLocalGroups();
  },
  methods: {
    sort(field) {
      if (this.sortField === field) {
        this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
      } else {
        this.sortField = field;
        this.sortDirection = 'asc';
      }
    },
    async loadApplicants() {
      if (this.isLoading) return;

      this.isLoading = true;
      const url = '/users/alumni/status';
      const statusFilter = { StatusFilter: this.selectedStatus };
      try {
        const response = await axios.post(url, statusFilter);
        if (response.status === 200) {
          this.applicants = response.data;
        }
      } catch (error) {
        console.error('Error fetching applicants:', error);
      } finally {
        this.isLoading = false;
      }
    },
    async loadLocalGroups() {
      try {
        const response = await axios.get('/localgroups'); // Endpoint to fetch Local Groups
        if (response.status === 200) {
          this.localGroups = response.data;
        }
      } catch (error) {
        console.error('Error fetching local groups:', error);
      }
    },
    getLocalGroup(localGroupId) {
      // Match the Local Group ID with the Local Groups list
      const group = this.localGroups.find(lg => lg.id === localGroupId);
      return group ? group.name : 'Unknown';
    },
    getApplicationType(status, alumniStatus) {
      if (alumniStatus === 4) {
        // AlumniStatus.Applicant
        return status === 2 // MemberStatus.Member
          ? 'Junior Alumni Applicant'
          : 'Alumni Applicant';
      }
      return '';
    },
    toggleSelectAll() {
      if (this.allSelected) {
        this.selectedApplicants = [];
      } else {
        this.selectedApplicants = this.applicants.map(applicant => applicant.userId);
      }
    },
    async processApplications(approve) {
      if (this.selectedApplicants.length === 0) {
        alert('Please select at least one applicant.');
        return;
      }

      const url = '/users/alumni/processApplications';
      const payload = {
        UserIds: this.selectedApplicants,
        Approve: approve,
      };

      try {
        const response = await axios.post(url, payload);
        if (response.status === 204) {
          this.applicants = this.applicants.filter(
            applicant => !this.selectedApplicants.includes(applicant.userId),
          );
          this.selectedApplicants = [];
          alert('Applications processed successfully.');
        }
      } catch (error) {
        console.error('Error processing applications:', error);
        if (error.response && error.response.data && error.response.data.Errors) {
          alert(`Errors occurred:\n${error.response.data.Errors.join('\n')}`);
        } else {
          alert('An error occurred while processing applications.');
        }
      }
    },
  },
};
</script>


  <style scoped>
  article {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 5rem;
    row-gap: 1rem;
  }
  #accept-reject {
    display: flex;
    gap: 1rem;
    padding: 1rem;
  }
  table {
    width: 90%;
    border-collapse: collapse;
  }
  table, th, td {
    border: 1px solid var(--green-accent-1);
    padding: 1rem;
  }
  thead {
    background-color: var(--green-primary);
    color: white;
  }
  tbody {
    background-color: var(--gray-10);
    color: var(--green-primary);
  }
  tbody tr:hover {
    background-color: var(--green-accent-1);
    color: white;
  }
  th {
    text-align: center;
    cursor: pointer; /* Indicate clickable */
  }
  th:hover {
    background-color: var(--green-accent-2); /* Highlight on hover */
  }
  td {
    text-align: left;
    word-break: break-word;
  }
  td > a {
    color: var(--green-primary);
  }
  .selected {
    background-color: var(--green-accent-1);
    color: white;
  }
  </style>
