<template>
  <div>
    <div v-if='!localResponsible && !admin'>
      <center>You don't have the rights to be on this page!</center>
    </div>
    <div v-if='localResponsible || admin' id='lg-page'>
      <!-- <b-tabs fill pills align='center'> -->
      <!-- <b-tab title='ESTIEM Info'> -->
      <b-card v-if='lg'>
        <h1>Edit Local Group {{ lg.name }}</h1>
      </b-card>
      <div class='wrapper'>
        <div class='content'>
          <label for='localResponsible'>Local Responsible</label>
          <vue-bootstrap-typeahead
            :data='users'
            v-model='estiemerQuery'
            :serializer='
              estiemer =>
                `${estiemer.firstNameEnglish} ${estiemer.lastNameEnglish}, LG ${estiemer.localGroupName}`
            '
            :placeholder='`${selectedLR.firstNameEnglish} ${selectedLR.lastNameEnglish}`'
            @hit='selectedLR = $event'
          ></vue-bootstrap-typeahead>

          <label for='gpslocation'>GPS Location</label>
          <b-form-input
            class='form-input'
            type='text'
            id='gpslocation'
            v-model='form.gpslocation'
            placeholder='latitude, longitude'
            maxlength='50'
            required
          ></b-form-input>
          <label for='officialName'>Official Name</label>
          <b-form-input
            type='text'
            id='officialName'
            v-model='form.officialName'
            placeholder='Official Name'
            maxlength='100'
            required
          ></b-form-input>
        </div>
        <div class='content'>
          <label for='universityName'>University Name</label>
          <b-form-input
            type='text'
            id='universityName'
            v-model='form.universityName'
            placeholder='University Name'
            maxlength='100'
            required
          ></b-form-input>
          <label for='address'>Address</label>
          <b-form-input
            type='text'
            id='address'
            v-model='form.address'
            placeholder='City, street, nr...'
            maxlength='500'
            required
          ></b-form-input>
          <label for='homePage'>Website</label>
          <b-form-input
            type='url'
            id='homePage'
            v-model='form.homePage'
            placeholder='Home Page'
            maxlength='300'
            required
          >
          </b-form-input>
        </div>
      </div>
      <div>
        <label for='freeTextField2'>LG Board Structure</label>
        <b-form-textarea
          id='freeTextField2'
          v-model='form.freeTextField1'
          placeholder='How the LG is structured'
          rows='3'
          max-rows='8'
          maxlength='350'
        ></b-form-textarea>
        <span class='message-counter'
          >{{ form.freeTextField1.length }} / 350</span
        >
      </div>
      <div v-if='admin'>
        <!-- </b-tab> -->
        <!-- This is info that actually affect database and so on. So I don't think we should let people change that as in the old portal -->
        <h2>Sensitive Portal Info</h2>
        <div class='wrapper'>
          <div class='content'>
            <label for='name'>Local Group Name</label>
            <b-form-input
              class='form-input'
              type='text'
              id='name'
              v-model='form.name'
              placeholder='Local Group'
              maxlength='50'
              required
            ></b-form-input>
            <label for='country'>Country</label>
            <b-form-select
              :options='countryNames'
              id='country'
              v-model='form.country'
              class='text-inputs'
              required
              disabled-field='notEnabled'
            >
            </b-form-select>
            <label for='lgStatus'>Membership Status</label>
            <b-form-select
              :options='lgStatuses'
              id='lgStatus'
              v-model='form.status'
              class='text-inputs'
              required
              disabled-field='notEnabled'
            >
            </b-form-select>
          </div>
          <!-- we don't have an API endpoint to fetch the regions -->
          <!-- <label for='region'>Region</label>
          <b-form-select
            id='region'
            v-model='form.region'
            class='text-inputs'
            required
            disabled-field='notEnabled'
          >
            <option disabled value=''>Select Region *</option>
          </b-form-select> -->

          <div class='content'>
            <div id='datepicker'>
              <label for='membership-start'>Member since</label>
              <b-input-group class='mb-3'>
                <b-form-input
                  id='example-input'
                  v-model='form.membershipStart'
                  type='text'
                  placeholder='YYYY-MM-DD'
                  autocomplete='off'
                ></b-form-input>
                <b-input-group-append>
                  <b-form-datepicker
                    id='membership-start'
                    v-model='form.membershipStart'
                    button-only
                    right
                    aria-controls='example-input'
                  ></b-form-datepicker>
                </b-input-group-append>
              </b-input-group>
            </div>
            <label for='alias'>Alias</label>
            <b-form-text id='input-live-help'
              >Alias that is used for email lists (e.g. 'Zurich' for
              Zurich@estiem.org)</b-form-text
            >
            <b-form-input
              class='form-input'
              type='text'
              id='alias'
              v-model='form.alias'
              placeholder='Alias'
              maxlength='50'
              required
            ></b-form-input>
          </div>
        </div>
      </div>
      <b-alert :show='showErrorMsg' dismissible variant='danger'>
        Could not complete operation, the following errors occurred:
        <pre>{{ editErrorMsg }}</pre>
      </b-alert>
      <b-alert
        :show='successMsgDismissCountdown'
        @dismiss-count-down='countDownChanged'
        fade
        variant='success'
      >
        Local Group successfully updated! Redirecting in
        {{ successMsgDismissCountdown - 1 }}...
      </b-alert>
      <b-btn variant='primary' @click='updateLgInfo' id='update-btn' v-show="toggle">
        Update Local Group
      </b-btn>
    </div>
  </div>
</template>

<script>
import { VueEditor } from 'vue2-editor';
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead';
import axios from 'axios';
import _ from 'underscore';
import { lgStatuses } from '../../helpers/lg-status.js';
import { countryNames } from '../../helpers/country-names.js';

export default {
  data() {
    return {
      toggle: true,
      lg: null,
      lgStatuses,
      countryNames,
      localgroups: [],
      users: [],
      selectedLR: null,
      estiemerQuery: '',
      successMsgDismissCountdown: -1,
      successMsgDismissSeconds: 4,
      showErrorMsg: false,
      editErrorMsg: '',
      form: {
        id: '',
        name: '',
        country: '',
        address: '',
        //   region: '',
        officialName: '',
        status: '',
        universityName: '',
        activeMemberCount: '',
        membershipStart: '',
        // membershipEnd: '',
        // freeText: '',
        // boardResponsibleId: '',
        localResponsibleId: '',
        // coordinates: '',
        alias: '',
        gpslocation: '',
        // mapCoordinateX: '',
        // mapCoordinateY: '',
        logoPath: '',
        homePage: '',
        email: '',
        freeTextField1: '',
        // freeTextField2: ''
        // freeTextField3: '',
        // freeTextField4: '',
        // freeTextField5: '',
        // freeTextField6: '',
      },
    };
  },
  components: {
    VueBootstrapTypeahead,
    VueEditor,
  },
  methods: {
    // checkForm() {
    //   let isValid = true;
    //   return isValid;
    // },
    fetchLG() {
      const id = this.$route.params.id;
      const url = `/localgroups/${id}`;
      axios.get(url).then((response) => {
        this.lg = response.data;
        axios.get(`/user/${this.lg.localResponsibleId}`).then((LR) => {
          this.selectedLR = LR.data;
          this.updateParameters();
        });
      });
    },
    updateParameters() {
      this.form.id = this.lg.id;
      this.form.name = this.lg.name;
      countryNames.forEach((element) => {
        if (element.text === this.lg.country || element.value === this.lg.country) {
          this.form.country = element.value;
        }
      });
      // this.form.country = this.lg.country;
      this.form.address = this.lg.address;
      // this.form.region = this.lg.region;
      this.form.universityName = this.lg.universityName;
      this.form.officialName = this.lg.officialName;
      this.form.status = this.lg.status;
      this.form.activeMemberCount = this.lg.activeMemberCount;
      this.form.membershipStart = this.lg.membershipStart;
      this.form.localResponsibleId = this.lg.localResponsibleId;
      this.form.alias = this.lg.alias;
      this.form.gpslocation = this.lg.gpslocation;
      this.form.logoPath = this.lg.logoPath;
      // this.form.mapCoordinateX = this.lg.mapCoordinateX;
      // this.form.mapCoordinateY = this.lg.mapCoordinateY;
      this.form.homePage = this.lg.homePage;
      this.form.email = this.lg.email;
      this.form.freeTextField1 = this.lg.freeTextField1;
    },
    async updateLgInfo() {
      this.toggle = false;
      const lgId = this.$route.params.id;
      const url = `localgroups/${lgId}`;
      this.form.localResponsibleId = this.selectedLR.userId;
      const toSend = this.form;
      axios
        .put(url, toSend)
        .then(() => {
          this.showSuccessMsg();
        })
        .catch((error) => {
          this.showErrorMsg = true;
          this.editErrorMsg += `\nUpdating the Local Group failed with: ${error.response.status.toString()} ${
            error.response.statusText
          }`;
          const errors = error.response.data.errors;
          const keys = Object.keys(error.response.data.errors);
          keys.forEach((key) => {
            this.editErrorMsg += `\n${key}: ${errors[key]}`;
          });
        });
    },
    showSuccessMsg() {
      this.successMsgDismissCountdown = this.successMsgDismissSeconds;
    },
    countDownChanged(countDown) {
      this.successMsgDismissCountdown = countDown;
      if (this.successMsgDismissCountdown === 1) {
        this.$router.push(`/localgroup/${this.lg.id}`);
      }
    },
  },
  computed: {
    localResponsible() {
      return this.$store.getters.isLR;
    },
    admin() {
      return this.$store.getters.isAdmin;
    },
  },
  created() {
    this.fetchLG();
  },
  watch: {
    // When the query value changes, fetch new results from
    // the API - in practice this action should be debounced
    estiemerQuery: _.debounce(async function (newQuery2) {
      await axios.get(`/user/search/${newQuery2}`).then((res) => {
        const lgUsers = res.data.filter((user) => user.localGroupName == this.lg.name)
        this.users = lgUsers;
      });
    }, 750),
  },
  filters: {
    stringify(value) {
      // console.log(JSON.stringify(value, null, 2));
      return JSON.stringify(value, null, 2);
    },
  },
  /*
   watch: {
    // When the query value changes, fetch new results from
    // the API - in practice this action should be debounced
    estiemerQuery:
        _.debounce(function (newQuery) {
          axios
            .get(`/user/search/${newQuery}`)
            .then((res) => {
              this.users = res.data;
            });
        }, 750),
  },
    filters: {
    stringify(value) {
      return JSON.stringify(value, null, 2);
    },
  }, */
};
</script>

<style scoped>


#lg-page {
  margin: 0 20%;
  margin-top: 100px;
  margin-bottom: 70px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.wrapper{
  display:flex;
  align-items: center;
  justify-content: space-between;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 450px;
}

h2{
  margin-top:20px;
}

.card {
  border: none;
  margin: 15px 0 5px 0;
}

#collapse-2 {
  margin: 10px 0;
}

.message-counter {
  margin-left: 10px;
}

label {
  font-weight: bold;
  margin-top: 15px;
}

.form-input {
  margin-bottom: 7px;
  width: 240px;
}

.btn {
  width: 240px;
}

#update-btn {
  margin: 30px 0 10px 0;
}

#example-input {
  width: 175px;
}

#membership-start__outer_ {
  width: 65px;
}

#membership-start__dialog_ {
  width: 200px;
}

@media (max-width: 985px) {
  .content {
    width: auto;
    overflow: auto;
  }
  .wrapper{
    flex-direction: column;
    align-items: normal;
  }
}
</style>
