<template>
  <div id="map"></div>
</template>

<script>
import L from "leaflet";
import event from "./event.vue";

export default {
  data() {
    return {
      center: { lat: 53.0, lng: 15.0 },
      map: null,
      markers: [],
    };
  },
  props: {
    count: {
      default: 30,
    },
    events: {
      default: () => [],
    },
  },
  components: {
    event,
  },
  computed: {
    eventsMap() {
      let evs = this.events;
      evs.sort((a, b) => new Date(a.startDate) - new Date(b.startDate));
      evs = evs.slice(0, this.count);

      evs = evs
        .filter((e) => e.parentGroupGpslocation !== null)
        .map((ev) => ({
          position: {
            lat: Number(ev.parentGroupGpslocation.split(",")[0]),
            lng: Number(ev.parentGroupGpslocation.split(",")[1]),
          },
          ev,
        }));
      return evs;
    },
  },
  mounted() {
    this.map = L.map("map").setView(this.center, 4);
    L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
      attribution:
        '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
      maxZoom: 18,
    }).addTo(this.map);

    this.updateMarkers();
  },
  watch: {
    eventsMap: {
      handler: "updateMarkers",
      immediate: true,
    },
  },
  methods: {
    updateMarkers() {
      this.markers.forEach((marker) => this.map.removeLayer(marker));
      this.markers = []; // Clear the markers array

      const ESTIEMicon = L.icon({
        iconUrl:
          "https://estiem.blob.core.windows.net/estiem-images/marker.png",
        iconSize: [40, 40],
        iconAnchor: [20, 40],
        popupAnchor: [1, -34],
      });

      this.eventsMap.forEach((element) => {
        // TODO use event component instead of this
        const popupContent = `
        <div style="display: flex; flex-direction: column; align-items: center; gap: 1rem;">
          <h1>${element.ev.name}</h1>
          <div>
            <i class="material-icons" style="vertical-align:middle">place</i>${element.ev.place}
            </div>
          <a href="/event/${element.ev.id}" style="color: var(--estiem-green);">See more</a>
        </div>
        `;
        const marker = L.marker(element.position, { icon: ESTIEMicon })
          .addTo(this.map)
          .bindTooltip(element.ev.name)
          .bindPopup(popupContent);

        this.markers.push(marker); // Keep track of the new marker
      });
    },
  },
};
</script>

<style scoped>
#map {
  width: 100%;
  height: calc(100vh - 300px);
  min-height: 550px;
}
</style>
