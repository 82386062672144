import { EventCategory } from './event-category.js';

export const EventTypeOptions = [
  { text: 'Academic Days', category: EventCategory.ACADEMIC, value: 11 },
  { text: 'Activity Week', category: EventCategory.CULTURAL, value: 10 },
  { text: 'Alumni CM', category: EventCategory.ALUMNI, value: 21 },
  { text: 'Alumni Meeting', category: EventCategory.ALUMNI, value: 16 },
  { text: 'Alumni Round Table', category: EventCategory.ALUMNI, value: 22 },
  { text: 'Alumni', category: EventCategory.ALUMNI, value: 12 },
  { text: 'Be X For X Days', category: EventCategory.CAREER, value: 23 },
  { text: 'Braintrainer', category: EventCategory.PERSONAL, value: 2 },
  { text: 'Business Booster', category: EventCategory.CAREER, value: 14 },
  { text: 'Central ESTIEM', category: EventCategory.CENTRAL, value: 7 },
  { text: 'Council Meeting', category: EventCategory.CENTRAL, value: 8 },
  { text: 'Europe3D', category: EventCategory.CULTURAL, value: 1 },
  { text: 'Language Programme', category: EventCategory.CULTURAL, value: 13 },
  { text: 'Lean Six Sigma', category: EventCategory.ACADEMIC, value: 15 },
  { text: 'Local Group Exchange', category: EventCategory.EXCHANGE, value: 9 },
  { text: 'Other academic events', category: EventCategory.ACADEMIC, value: 18 },
  { text: 'Other career events', category: EventCategory.CAREER, value: 19 },
  { text: 'Other cultural events', category: EventCategory.CULTURAL, value: 17 },
  { text: 'Other events', category: EventCategory.OTHER, value: 6 },
  { text: 'Other personal events', category: EventCategory.PERSONAL, value: 20 },
  { text: 'Summer Academy', category: EventCategory.PERSONAL, value: 3 },
  { text: 'TIMES', category: EventCategory.CAREER, value: 4 },
  { text: 'Vision', category: EventCategory.ACADEMIC, value: 5 },
];
