<template>
  <div id="#test2" >
    <router-view
      @update-participants="fetchEventParticipants"
      :event="event"
      :participant="participant"
      :participantsFetched="participantsFetched"
      :eventParticipants="eventParticipants"
      :isOrganiser="isOrganiser"
      class="event-container"
    >
    </router-view>
  </div>
</template>


<script>
import axios from 'axios';

export default {
  data() {
    return {
      eventParticipants: [],
      event: {},
      participantsFetched: false,
    };
  },
  watch: {
    $eventId() {
      this.fetchEvent();
    },

  },
  computed: {
    userId() {
      return this.$store.getters.user.userId;
    },
    eventId() {
      return this.$route.params.id;
    },
    participant() {
      return this.eventParticipants.find(
        p => p.userId === this.userId,
      );
    },
    isOrganiser() {
      return this.participant && this.participant.registrationStatus === 5;
    },
  },
  methods: {
    fetchEventParticipants() {
      const url = `/events/${this.eventId}/participants`;
      axios.get(url)
        .then((response) => {
          this.eventParticipants = response.data;
          this.participantsFetched = true;
        });
    },
    fetchEvent() {
      const url = `/events/${this.$route.params.id}`;
      axios.get(url)
        .then((response) => {
          this.event = response.data;
        });
    },
  },
  created() {
    const jwt = localStorage.getItem('token');
    if (jwt) {
      this.fetchEventParticipants();
    }

    this.fetchEvent(this.eventId);
  },
}
;
</script>

<style scoped>
  .event-container {
    margin-bottom: 0;
  }
</style>
