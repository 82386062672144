<template>
    <div id="group-details" v-if="group">
      <div v-if="!groupExists"> <page404></page404> </div>
      <div v-else>
        <div>
          <div class="background">
            <!-- Group-specific styling -->
            <p class="group-name"><b>{{ group.name }}</b></p>
            <!-- Other group details -->
          </div>

          <!-- Group properties similar to profile page here -->
          <section class="grid-container">
            <!-- Replace the following divs with actual content -->
            <div class="about-group">{{ group.description }}</div>
            <div class="group-info">
                <router-link :to="'/profile/' + groupAdmin.userName">
                  <p>Group Admin: {{ groupAdmin.firstNameEnglish }} {{ groupAdmin.lastNameEnglish }}</p>
                </router-link>
                <p>Public Subscription Enabled: {{ group.publicSubscriptionEnabled }}</p>
                <p>Sender Restriction: {{ SenderRestrictionOptions[group.senderRestriction].text }}</p>
              <!-- More properties -->
            </div>
            <!-- Add other properties based on your group object -->
            <!-- Admin-only properties -->
            <div v-if="isAdminOrGroupAdmin" class="admin-info">
                <!-- Include properties from EstiemGroupExtendedViewModel that are only visible to admins -->
                <p>Admin User ID: {{ group.adminUserId }}</p>
                <p>Email Enabled: {{ group.emailEnabled }}</p>
                <p>Has External Members: {{ group.hasExternalMembers }}</p>
                <p v-if="group.expirationDate">Expiration Date: {{ group.expirationDate }}</p>
                <p>Default Group: {{ group.isDefaultGroup }}</p>
                <p>Group Alias: {{ group.alias }}</p>
                <p>Active: {{ group.isActive }}</p>
                <p>Last Changed: {{ group.lastChanged }}</p>
                <p>Members Count: {{ group.membersCount }}</p>
                <b-button variant="info" :to="{ name: 'group-edit', params: {group : this.group} }">Edit Group Info</b-button>
            </div>
      </section>

      <section v-if="isAdminOrGroupAdmin" class="admin-info">
        <backtotop />
            <b-button v-b-toggle.members-collapse variant="info">View Group Members</b-button>
            <b-button variant="danger" v-if="!toggle" @click="deleteGroup">Delete Group</b-button>
            <spinner v-if="toggle" :small="true"></spinner>
            <b-collapse id="members-collapse" class="mt-2" @show="fetchMembers">
            <b-card>
                <b-button v-if="isAdminOrGroupAdmin" variant="danger" @click="removeSelectedMembers">Remove Selected Members</b-button>
                <div class="table-wrapper">
                  <b-table
                  ref="membersTable"
                  striped hover
                  :items="members"
                  :fields="fields"
                  selectable
                  select-mode="multi"
                  @row-selected=onRowSelected
                  >
                  <!--TODO: figure out how to make this checkbox visible, currently clicking the row only changes its colour-->
                  <template v-slot:cell(selected)= {rowSelected} >
                    <template v-if="rowSelected"">
                      <font-awesome-icon :icon="['fas', 'check']" />
                    </template>
                  </template>
                  </b-table>
                </div>
            </b-card>
            <b-button v-if="isAdminOrGroupAdmin" v-b-toggle.add-member-collapse variant="secondary" class="mb-2">
                Add Members
            </b-button>
            <b-collapse v-if="isAdminOrGroupAdmin" id="add-member-collapse" class="mt-2">
                <vue-bootstrap-typeahead
                class="add-member-search"
                :data="potentialMembers"
                v-model="estiemerQuery"
                size="sm"
                :serializer="member => `${member.firstNameEnglish} ${member.lastNameEnglish}, LG ${member.localGroupName}`"
                placeholder="Search member to add"
                @hit="selectedMemberToAdd = $event"
                ></vue-bootstrap-typeahead>
                <b-button @click="addMemberToGroup">
                Add Member
                </b-button>
            </b-collapse>
            </b-collapse>
          </section>
        </div>
      </div>
    </div>
  </template>

<script>
import axios from 'axios';
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead';
import _ from 'underscore';
import page404 from '../page404.vue';
import loginPage from '../profile/login-page.vue';
import spinner from '../Spinner/spinner.vue';
import backtotop from '@/components/home/back-to-top-btn.vue';
import { SenderRestrictionOptions } from '@/helpers/group-detail.js';
export default {
  data() {
    return {
      toggle: false,
      group: null,
      groupExists: true,
      isAdminOrGroupAdmin: false,
      groupAdmin: {},
      members: [],
      selectedMemberIds: [],
      fields: [
        { key: 'selected',
          label: 'Select',
          class: 'text-center',
          formatter: (value, { item }) => item && item.userId && this.selectedMemberIds.includes(item.userId),
        },
        { key: 'userId', label: 'User ID' },
        { key: 'firstName', label: 'First Name' },
        { key: 'lastName', label: 'Last Name' },
        { key: 'localGroup', label: 'Local Group' }, // Custom label for display
      ],
      estiemerQuery: '',
      potentialMembers: [],
      selectedMemberToAdd: null,
      SenderRestrictionOptions,
    };
  },
  components: {
    loginPage,
    page404,
    spinner,
    backtotop,
    VueBootstrapTypeahead,
  },
  methods: {
    fetchGroup() {
      const groupId = this.$route.params.id;
      axios.get(`/groups/${groupId}`)
        .then((response) => {
          this.group = response.data;
          this.checkAdminStatus(); // Check admin status after group is fetched
          this.fetchGroupAdmin();
        })
        .catch(() => {
          this.groupExists = false;
        });
    },
    checkAdminStatus() {
      // You now have the group data, so you can check if the user is an admin or group admin
      const isAdmin = this.$store.getters.userroles.includes('Admins');
      const currentUser = this.$store.getters.user;
      const isGroupAdmin = currentUser && this.group && currentUser.userId === this.group.adminUserId;
      this.isAdminOrGroupAdmin = isAdmin || isGroupAdmin;
      if (this.isAdminOrGroupAdmin) {
        // Now that we know the user is an admin, we can fetch the extended group details
        this.fetchExtendedGroup();
      }
    },
    fetchExtendedGroup() {
      // This will only be called if the user is an admin or group admin
      const groupId = this.$route.params.id;
      axios.get(`/groups/${groupId}/extended`)
        .then((response) => {
          // Merge the extended properties into the group data
          // You may adjust as necessary depending on your data structure
          this.group = { ...this.group, ...response.data };
        })
        .catch((error) => {
          console.error('Error fetching extended group details:', error);
        });
    },
    fetchMembers() {
      const groupId = this.$route.params.id;
      console.log('fetching members');
      axios.get(`/groups/${groupId}/members`)
        .then((response) => {
          console.log(response.data);
          this.members = response.data.map((member) => {
            const localGroup = this.$store.getters.localgroup(member.localGroupId);
            console.log('Local group:', localGroup);
            return {
              userId: member.userId,
              firstName: member.firstNameEnglish,
              lastName: member.lastNameEnglish,
              localGroup: localGroup ? localGroup.name : 'Unknown', // Assuming localGroup object has a 'name' property
            };
          });
        })
        .catch((error) => {
          console.error('Error fetching group members:', error);
        });
    },
    fetchGroupAdmin() {
      const adminId = this.group.adminUserId;
      const url = `/user/${adminId}`;
      axios.get(url)
        .then((response) => {
          this.groupAdmin = response.data;
        })
        .catch((error) => {
          console.error('Error fetching group admin:', error);
        });
    },
    onRowSelected(selectedRows) {
      this.selectedMemberIds = selectedRows.map(row => row.userId);
    },
    removeSelectedMembers() {
      if (this.selectedMemberIds.length === 0) {
      // Notify the user that no members are selected
        return;
      }
      const groupId = this.$route.params.id;
      // Assume the API accepts an array of userIds for deletion
      const payload = { userIds: this.selectedMemberIds };
      axios.delete(`/groups/${groupId}/users`, { data: payload })
        .then(() => {
        // Filter out the members that were deleted
          this.members = this.members.filter(member => !this.selectedMemberIds.includes(member.userId));
          // Reset the selected member IDs
          this.selectedMemberIds = [];
          // Update the table to reflect the changes
          this.$refs.membersTable.clearSelected();
        })
        .catch((error) => {
          console.error('Error removing group members:', error);
        });
    },
    addMemberToGroup() {
      if (!this.selectedMemberToAdd) {
      // Handle the case where no member is selected
        alert('Please select a member to add.');
        return;
      }
      // Add the member to the group
      const groupId = this.$route.params.id;
      const payload = {
        userIds: [this.selectedMemberToAdd.userId], // The API expects an array of user IDs
      };
      axios.post(`/groups/${groupId}/users`, payload)
        .then(() => {
        // Handle successful addition, e.g., refresh members list
          this.fetchMembers();
          // Clear the search query and selected member
          this.estiemerQuery = '';
          this.selectedMemberToAdd = null;
        })
        .catch((error) => {
        // Handle errors
          console.error('Error adding member to group:', error);
        });
    },
    deleteGroup() {
      const groupId = this.$route.params.id;
      axios.delete(`/groups/${groupId}`)
        .then((response) => {
          if (response.status === 204) {
            this.toggle = true;
            setTimeout(() => {
              this.$router.push({ name: 'GroupsList' });
            }, 1000);
          }
          if (response.status === 400) alert('Invalid group');
          if (response.status === 401) alert('User is not logged in');
          if (response.status === 403) alert('User is not authorized to delete this group.');
        })
        .catch((error) => {
          console.debug('Could not delete the group', error);
        });
    },
  },
  watch: {
    // When the query value changes, fetch new results from
    // the API - in practice this action should be debounced
    estiemerQuery:
        _.debounce(function (newQuery) {
          axios
            .get(`/user/search/${newQuery}`)
            .then((res) => {
              this.potentialMembers = res.data;
            });
        }, 750),
  },
  created() {
    this.fetchGroup();
    this.$store.dispatch('fetchLGS');
  },
};
</script>

<style scoped>
#group-details {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0;
  position: relative;
}

.table-wrapper {
  width: 100%;
  overflow-x: scroll;
}

.background {
  width: 100%;
  background-color: #205e44; /* Adjust the color to match your site's theme */
  padding: 1rem;
  color: white;
}

.group-name {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
}

.about-group {
  margin: 1rem;
  font-size: 18px;
  background-color: #f8f9fa;
  padding: 1rem;
  border-radius: 8px;
}

.group-info,
.admin-info {
  margin: 1rem;
  background-color: #ffffff;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.admin-info {
  background-color: #e9ecef;
}

.group-info a {
  color: inherit;
}

p {
  margin: 0.5rem 0;
}

@media screen and (max-width: 600px) {
  .background {
    padding: 0.5rem;
  }

  .group-name {
    font-size: 20px;
  }

  .about-group,
  .group-info,
  .admin-info {
    margin: 0.5rem;
    padding: 0.5rem;
  }
}
</style>

