<template>
  <card class="mx-5 my-2 card-container">
    <!-- ToDo: uncomment this when the endpoint for list of participants returns their usernames as well
      <router-link class="card-link" :to="'/profile/' + userName" style="display: flex; ">
      -->
    <b-row align-v="center">
      <b-col class="col-4 col-sm-auto p-0 mr-2">
        <a :href="'/profile/' + participant.userId">
          <img :src="this.profileImg" />
        </a>
      </b-col>
      <b-col class="ml-2 p-0 col-size col-sm-auto">
        <a :href="'/profile/' + participant.userId">
          <h2 class="mt-1 mb-2">
            {{ participant.userFirstNameEnglish }}
            {{ participant.userLastNameEnglish }}
          </h2>
        </a>
        <div v-for="status in eventStatus" :key="status.status">
          <div v-if="participantStatus===status.status">
            <i class="material-icons md-dark">{{status.materialIcon}}</i><span>{{ participantStatus }}</span><br>
          </div>
        </div>
        <i class="material-icons md-dark">location_city</i><span>{{ participant.userLocalGroupName }}</span><br>
        <div v-if="this.isCm">
          <i class="material-icons md-dark">account_balance</i><span>{{ participantCMStatus }}</span><br>
        </div>
        <div v-if="isOrganiser || isAdmin || isLrOfEvent">
          <i class="material-icons md-dark">get_app</i><span>Applied on {{ participant.registrationDate | formatdate }}</span><br>
        </div>
      </b-col>
      <b-col class="col-auto p-0 m-0">
        <input
          type="checkbox"
          @change="addremovelocal($event)"
          v-model="checked"
          class="participant-checkbox"
          v-if="isOrganiser || isAdmin || isLrOfEvent"/>
<!--      <b-button size="sm" v-if="participantStatus=='Waiting'" v-b-modal.register class="button-reg">Register</b-button>-->
<!--      <b-button size="sm" v-if="participantStatus=='Registered'" v-b-modal.dns class="button-dns">Did not show</b-button>-->
      </b-col>
    </b-row>
  </card>
</template>

<script>
import moment from 'moment';
import axios from 'axios';


export default {
  data() {
    return {
      checked: false,
      isSelected: false,
      searchString: '',
      profileImg: '',
      eventStatus: [
        { status: 'Registered', materialIcon: 'done' },
        { status: 'Cancelled', materialIcon: 'close' },
        { status: 'Cancelled after deadline', materialIcon: 'warning' },
        { status: 'Did not show up', materialIcon: 'warning' },
        { status: 'Organiser', materialIcon: 'assignment' },
        { status: 'Application withdrawn', materialIcon: 'undo' },
        { status: 'Waiting', materialIcon: 'schedule' },
      ],
    };
  },
  filters: {
    formatdate(value) {
      if (value) {
        return moment(value).format('DD.MM.YYYY');
      }
      return '';
    },
  },
  props: {
    participant: {},
    isCm: {
      default: false,
    },
    isOrganiser: {
      default: false,
    },
    isLrOfEvent: {
      default: false,
    },
    isAdmin: {
      default: false,
    },
  },

  methods: {
    addremovelocal() {
      // console.log(this.participant.userId);
      this.$emit('changeState', this.checked, this.participant.userId);
    },
    getProfileImage() {
      const id = this.participant.userId;
      axios.get(`/files/profile/${id}`)
        .then((response) => {
          if (response.data.imageUrls.length > 0) { this.profileImg = response.data.imageUrls[0]; } else {
            axios.get('files/profile/99999').then((response2) => {
              this.profileImg = response2.data.imageUrls[0];
            });
          }
        });
    },
  },
  computed: {
    // ToDo:  get portalUserName from the endpoint that returns participants
    userName() {
      return this.estiemer.portalUserUserName.replace(' ', '%20');
    },
    trimFirstName() {
      return this.participant.userFirstNameEnglish.trim();
    },
    trimLastName() {
      return this.participant.userLastNameEnglish.trim();
    },
    participantCMStatus() {
      const cmStatuses = ['None', 'Delegate', 'Board', 'Working Group Leader', 'Leader', 'Extra Place', 'Cancelled', 'Media', 'Secretary', 'Chairperson', 'Trainer', 'Voting Committee', 'Project Leader'];
      return cmStatuses[this.participant.cmStatus];
    },
    participantStatus() {
      if (this.participant.registrationStatus === 0) {
        return 'Registered';
      } else if (this.participant.registrationStatus === 1) {
        return 'Waiting';
      } else if (this.participant.registrationStatus === 2) {
        return 'Cancelled';
      } else if (this.participant.registrationStatus === 3) {
        return 'Cancelled after deadline';
      } else if (this.participant.registrationStatus === 4) {
        return 'Did not show up';
      } else if (this.participant.registrationStatus === 5) {
        return 'Organiser';
      } else if (this.participant.registrationStatus === 6) {
        return 'Application withdrawn';
      }
      return 'None';
    },
  },
  created() {
    this.getProfileImage();
  },
};
</script>

<style scoped>
.material-icons.md-dark { color: rgba(32, 94, 68, 1); }
.material-icons.md-dark.md-inactive { color: rgba(32, 94, 68, 0.5); }

.material-icons {
  vertical-align: middle;
}

.card-container {
  min-width: 380px;
  max-width: 500px;
  border: 0px solid;
}

img {
  object-fit: cover;
  width: 120px;
  height: 120px;
}

@media screen and (max-width: 350px){
  img {
    width: 100px;
    height: 100px;
  }
  h2,i {
    font-size: 12pt;
  }
  span {
    font-size: 10pt;
  }
  .col-size {
    width: 200px;
  }
}

a {
  text-decoration: none !important;
}

.participant-checkbox {
  height: 20px;
  width: 20px;
}

.col-size {
  width: 240px;
}
</style>
