<template>
    <div class="card">
      <div class="row" v-if="!editing">
        <div class="col-2">
          <div class="card-block start-time">
            {{eventSchedule.startTime | formatDateFromString}}
          </div>
        </div>
        <div class="col-10">
          <div class="card-block" >
              <b>{{eventSchedule.title}}</b>
              <b-btn v-if="isOrganiser" @click="editThis" class="edit-button">EDIT</b-btn>
<!--            <vue-markdown>{{eventSchedule.description}}</vue-markdown>-->
          </div>
        </div>
      </div>
      <div class="row" v-if="editing">
        <div v-if="errors.length > 0">{{errors}}</div>
        <div class="col-12">
          <div class="card-block" >
            Starting Time:
            <b-input type="datetime-local" v-model="eventSchedule.startTime"></b-input>
            Duration in minutes:
            <b-input type="number" v-model="eventSchedule.duration"></b-input>
            Activity Title
            <b-input type="text" placeholder="Activity Title" v-model="eventSchedule.title"></b-input>
            Activity Description <br>
            <textarea class="edit-description" :cols="width" rows="5" id="basic" v-model="eventSchedule.description" placeholder="Activity Description"></textarea>
            <b-btn @click="saveThis" >SAVE</b-btn>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import axios from 'axios';
// VueMarkdown is deprecated. If you want to revive this component use another library
// import VueMarkdown from 'vue-markdown';
import moment from 'moment';

export default {
  data() {
    return {
      editing: false,
      url: '/eventschedule',
      eventSchedule: {},
      errors: '',
    };
  },
  props: {
    eventScheduleProp: {
    },
    isOrganiser: {
      default: false,
    },
  },
  created() {
    this.editIfEmpty();
    this.eventSchedule = { ...this.eventScheduleProp };
  },
  methods: {
    checkForm() {
      if (this.name && this.age) {
        return true;
      }
      this.errors = [];
      if (!this.eventSchedule.title) {
        this.errors.push('Title required.');
      }
      if (!this.eventSchedule.startTime) {
        this.errors.push('Starting time required.');
      }
      if (this.getEndTime() !== true) {
        this.errors.push('End time bad calculation.');
      }
      return null;
      /* TODO
        if (!this.eventSchedule.duration) {
            this.errors.push('Duration required.');
        } */
    },
    editThis() {
      this.editing = true;
    },
    // TODO: Correctly sum the duration to the starting date
    getEndTime() {
      this.eventSchedule.endTime = moment.utc(this.eventSchedule.startTime).format();
      if (this.eventSchedule.duration) {
        this.eventSchedule.endTime = moment(this.eventSchedule.endTime).add(this.eventSchedule.duration, 'minutes');
      } else {
        this.eventSchedule.endTime = moment(this.eventSchedule.endTime).add(120, 'minutes');
      }

      return true;
    },
    saveThis() {
      // TODO: check for empty values
      // TODO: check for duplicate time value - notification: "if you have two or more activities at the same time, include them all into the same object"
      // TODO: check for plausibility. an Item should never have a time that is not between that start and end time of the event it belongs to
      this.checkForm();
      if (this.errors.length) { return false; }

      this.editing = false;

      if (this.eventSchedule.eventScheduleId) {
        axios.put(this.url, this.eventSchedule)
          .then((scheduleItem) => {
            this.$emit('putEvent', scheduleItem.data);
          });
      } else {
        axios.post(this.url, this.eventSchedule)
          .then((scheduleItem) => {
            this.$emit('postEvent', scheduleItem.data);
          });
      }
      return null;
    },
    editIfEmpty() {
      if (!this.eventScheduleProp.title || !this.eventScheduleProp.startTime) this.editing = true;
    },
  },
  computed: {
    width() {
      return window.innerWidth < 500 ? 30 : 60;
    },
  },
  components: {
    // VueMarkdown,
  },
};
</script>

<style scoped>
.edit-button {
    float: right;
}

.edit-description {
  display: block;
}

.start-time {
  font-size: 0.8em;
  padding-left: 5px;
}
</style>
