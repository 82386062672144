<template>
  <b-button class="back-to-top" v-on:click="moveToTop" v-scroll="showBtn">
    <FontAwesomeIcon icon="chevron-up"></FontAwesomeIcon>
  </b-button>
</template>

<script>
export default {
  methods: {
    moveToTop() {
      const c = document.documentElement.scrollTop || document.body.scrollTop;

      if (c > 0) {
        window.requestAnimationFrame(this.moveToTop);
        window.scrollTo(0, c - (c / 15));
      }
    },
    showBtn() {
      const btn = document.querySelector('.back-to-top');
      if (btn !== null) {
        window.onscroll = () => {
          if (document.documentElement.scrollTop < 25 && btn) {
            btn.style.visibility = 'hidden';
          } else {
            btn.style.visibility = 'visible';
          }
        };
      }
    },
  },
};
</script>

<style scoped>
.back-to-top {
  position: fixed;
  bottom: 50px;
  right: 17px;
  cursor: pointer;
  z-index: 1;
  width: 40px;
  height: 40px;
  visibility: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 0%;
  background-color: white;
  color: var(--estiem-green);
}

.back-to-top:hover {
  background-color: var(--estiem-green);
  color: white;
}
@media screen and (max-width: 450px) {
  .back-to-top {
    position: fixed;
    bottom: 70px;
    right: 17px;
  }
}
@media screen and (max-width: 768px) {
  .back-to-top {
    position: fixed;
    bottom: 70px;
    right: 17px;
  }
}
@media screen and (max-width: 1120px) {
  .back-to-top {
    position: fixed;
    bottom: 80px;
    right: 17px;
  }
}
</style>
