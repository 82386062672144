import axios from 'axios';
import VueJwtDecode from 'vue-jwt-decode';

import {ROLE_IDS} from '../../environments/index.js';

// Initial state
const state = {
  user: '',
  authenticated: false,
  authenticatedSSO: false,
  userroles: [],
};

// Getters
const getters = {
  user: () => state.user,
  authenticated: () => state.authenticated,
  authenticatedSSO: () => state.authenticatedSSO,
  userroles: () => state.userroles,
  isLR: () => state.userroles.includes('lr'),
  isAdmin: () => state.userroles.includes('Admins'),
  isBoardAlumni: () => state.userroles.includes('boardAlumni'),
};

// Actions
const actions = {
  login({ commit }, payload) {
    const url = '/user/current';
    axios
      .get(url)
      .then((response) => {
        commit('LOGIN', {
          user: response.data,
          isSSO: payload.isSSO,
        });
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err);
      });
    // Set the last login time for this user during SSO logins
    // for non-SSO the backend already does this
    if (payload.isSSO) {
      const lastLoginUrl = '/user/current/lastlogin';
      axios.post(lastLoginUrl);
    }
  },
  logout({ commit }) {
    commit('LOGOUT');
  },
};

// Mutations
const mutations = {
  LOGIN(st, payload) {
    // Set this here because here we actually properly have it in all cases
    localStorage.setItem('userName', payload.user.userName);
    // eslint-disable-line
    state.user = payload.user;
    state.authenticated = true;
    state.authenticatedSSO = payload.isSSO;
    const token = VueJwtDecode.decode(localStorage.getItem('token'));
    if (token.roles.includes(ROLE_IDS.ADMINS)) {
      state.userroles.push('Admins');
    }
    if (token.roles.includes(ROLE_IDS.LOCAL_RESPONSIBLES)) {
      state.userroles.push('lr');
    }
    if (token.roles.includes(ROLE_IDS.BOARD_ALUMNI)) {
      state.userroles.push('boardAlumni');
    }
    // Add Student / Alumni status to the roles
    if (state.user.status === 2) {
      state.userroles.push('Student');
    }
    if (state.user.alumniStatus === 2) {
      state.userroles.push('Alumni');
    }
  },
  LOGOUT() {
    // eslint-disable-line
    state.user = '';
    state.authenticated = false;
    state.authenticatedSSO = false;
    state.userroles = [];
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
