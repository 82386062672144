// eslint-disable-next-line import/prefer-default-export
/* eslint-disable */
export const CmRegistrationStatus = {
    0: 'None',
    1: 'Delegate',
    2: 'Board',
    3: 'WorkingGroupLeader',
    4: 'Leader',
    5: 'ExtraPlace',
    6: 'Cancelled',
    7: 'Media',
    8: 'Secretary',
    9: 'Chairperson',
    10: 'Trainer',
    11: 'VotingCommittee',
    12: 'ProjectLeader'
};
