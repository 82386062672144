<template>
  <div v-if="!doesUserExist">
    <page404></page404>
  </div>

  <div v-else-if="isDeletedUser" class="deletedUser">
    <div class="deletedUserText">This user was deleted.</div>
  </div>

  <div id="profile" v-else>
    <div class="background" v-if="ready">
      <p class="username">
        <b>{{ user.firstNameEnglish }} {{ user.lastNameEnglish }}</b>
      </p>
      <p class="localgroup">
        <a class="lg-link" :href="'/localgroup/' + lg.id">
          <b>Local Group {{ lg.name }}</b>
        </a>
        <a
          v-if="exchangeLg !== undefined"
          class="lg-link"
          :href="'/localgroup/' + exchangeLg.id"
        >
          <b>, {{ exchangeLg.name }}</b>
        </a>
      </p>
    </div>

    <div class="profilePicture">
      <img
        v-if="ready"
        :src="profileImage"
        style="margin: auto; width: 200px; height:200px; object-fit: cover; border-radius: 2px"
      />
    </div>

    <div class="grid-container">
      <!-- About me and editing the profile -->
      <div class="about-me">
        <b-button
          v-if="ready && (isLoggedInUser || isAdmin)"
          variant="outline-primary"
          class="edit-button"
          @click="edit"
        >
          <i class="material-icons md-dark">edit</i> Edit Profile
        </b-button>
        <router-link
          v-if="isLoggedInUser && user.alumniStatus === 1"
          :to="{ name: 'alumni-join' }"
        >
          <b-button variant="outline-primary" class="edit-button">
            Join Alumni
          </b-button>
        </router-link>
      </div>

      <!-- Basic user info -->
      <div class="userInfo">
        <p v-if="user.birthday">Birthday: {{ getBirthday }}</p>
        <p v-if="user.studiesStart">Start of studies: {{ getStartOfStudies }}</p>
      </div>
      <div v-if="user.estiemActivities || user.interests" class="estiem-background">

        <p style="font-weight:bold">What you need to know about me</p>
        <div v-html="user.interests"></div>
        <p style="font-weight:bold">ESTIEM background</p>
        <div v-html="user.estiemActivities"></div>

      </div>

      <!-- User contact info -->
      <div class="user-contacts" v-if="ready">
        <div v-if="user.mobilePhone">
          <font-awesome-icon class="profile-icons" :icon="['fas', 'phone-alt']" />
          <a :href="'tel:' + user.mobilePhone" title="Phone">
            {{ user.mobilePhone }}
          </a>
        </div>

        <div>
          <font-awesome-icon class="profile-icons" :icon="['fas', 'envelope']" />
          <a :href="'mailto:' + userEmail + '@estiem.org'" title="Email">
            {{ userEmail }}@estiem.org
          </a>
        </div>

        <div v-if="user.skypeName">
          <font-awesome-icon class="profile-icons" :icon="['fab', 'skype']" />
          <a :href="'skype:' + user.skypeName + '?call'">
            {{ user.skypeName }}
          </a>
        </div>

        <div v-if="user.instagram">
          <font-awesome-icon class="profile-icons" :icon="['fab', 'instagram']" />
          <a target="_blank" :href="'https://instagram.com/' + instagram">
            {{ user.instagram }}
          </a>
        </div>

        <div v-if="user.linkedin">
          <font-awesome-icon class="profile-icons" :icon="['fab', 'linkedin']" />
          <a target="_blank" :href="linkedinUrl">
            {{ user.firstNameEnglish }} {{ user.lastNameEnglish }}
          </a>
        </div>

        <div v-if="user.twitter">
          <font-awesome-icon class="profile-icons" :icon="['fab', 'twitter']" />
          <a target="_blank" :href="'https://twitter.com/' + user.twitter">
            {{ user.twitter }}
          </a>
        </div>

        <div v-if="user.snapchat">
          <font-awesome-icon class="profile-icons" :icon="['fab', 'snapchat']" />
          <a target="_blank" :href="'https://www.snapchat.com/add/' + user.snapchat">
            {{ user.snapchat }}
          </a>
        </div>
      </div>

      <!-- Events table -->
      <div class="user-events" v-if="ready && userEvents.length > 0">
        <p style="font-weight:bold">Participated in {{ userEvents.length }} events</p>
        <div class="table-wrapper">
          <div class="table-container">
            <b-table
              striped
              hover
              :fields="fields"
              :items="userEvents"
              responsive="sm"
            >
              <template #cell(eventName)="data">
                <router-link
                  class="card-link"
                  :to="{ name: 'eventDetails', params: { id: data.item.eventId } }"
                >
                  {{ data.item.eventName }}
                </router-link>
              </template>
              <template #cell(eventPlace)="data">
                {{ data.item.eventPlace }}
              </template>
              <template #cell(eventDate)="data">
                {{ data.item.eventStartDate | formatdate }} to
                {{ data.item.eventEndDate | formatdate }}
              </template>
            </b-table>
          </div>
        </div>
      </div>

      <!-- Groups table -->
      <div class="user-groups" v-if="groups.length > 0">
        <div class="groups-header">
          <p style="font-weight:bold; margin: 0;">Groups</p>
        </div>
        <div class="table-wrapper">
          <div class="table-container">
            <b-table
              ref="GroupsTable"
              striped
              hover
              selectable
              responsive="sm"
              select-mode="multi"
              @row-selected="onRowSelected"
              :fields="fieldsGroup"
              :items="groups"
            >
              <template #cell(roleId)="data">
                {{ data.item.roleId }}
              </template>
              <template #cell(name)="data">
                {{ data.item.name }}
              </template>
              <template #cell(description)="data">
                {{ data.item.description }}
              </template>
              <template #cell(actions)="data">
                <router-link
                  class="card-link"
                  :to="{ name: 'group-details', params: { id: data.item.roleId } }"
                >
                See more
              </router-link>
            </template>
            <template v-slot:cell(selected)={rowSelected}>
              <template v-if="rowSelected">
                <font-awesome-icon :icon="['fas', 'check']" />
              </template>
            </template>
          </b-table>
          </div>
        </div>
        <div class="groups-header">
          <router-link to="/groups">
              <b-button variant="primary" size="sm">Find and Join New Groups</b-button>
          </router-link>
          <b-button
            v-if="isAdminOrItisMyAccount"
            variant="danger"
            @click="removeSelectedGroups"
            size="sm"
            >
            Leave Selected Groups
          </b-button>
        </div>
        <br /><br />
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import page404 from '../page404.vue';

export default {
  data() {
    return {
      userExists: true,
      ready: false,
      user: {},
      lg: {},
      exchangeLg: undefined,
      profileImage: '',
      userEvents: [],
      groups: [],
      selectedGroupIds: [],
      fields: [
        { key: 'eventName', label: 'Event', sortable: true },
        { key: 'eventPlace', label: 'Place', sortable: true },
        { key: 'eventDate', label: 'Event Date', sortable: true }
      ],
      fieldsGroup: [
        {
          key: 'selected',
          label: 'Select',
          class: 'text-center',
          formatter: (value, { item }) =>
            item && item.roleId && this.selectedGroupIds.includes(item.roleId)
        },
        { key: 'roleId', label: 'Role Id', sortable: true },
        { key: 'name', label: 'Name', sortable: true },
        { key: 'description', label: 'Description', sortable: true },
        { key: 'actions', label: 'Actions' }
      ],
      isAdminOrItisMyAccount: false
    };
  },
  components: {
    page404,
  },
  filters: {
    formatdate(value) {
      return value ? moment(value).format('DD.MM.YYYY') : '';
    }
  },
  computed: {
    doesUserExist() {
      return this.userExists;
    },
    isDeletedUser() {
      return this.user.status === 4;
    },
    getBirthday() {
      return this.user.birthday
        ? moment(this.user.birthday).format('DD.MM.YYYY')
        : '';
    },
    getStartOfStudies() {
      return this.user.studiesStart
        ? moment(this.user.studiesStart).format('MMM YYYY')
        : '';
    },
    isLoggedInUser() {
      const currentUser = this.$store.getters.user;
      return currentUser && currentUser.userId === this.user.userId;
    },
    userEmail() {
      return this.user.portalUserUserName
        ? this.user.portalUserUserName.toLowerCase().replace(/ /g, '.')
        : '';
    },
    userName() {
      return this.user.portalUserUserName || '';
    },
    instagram() {
      return this.user.instagram && this.user.instagram.charAt(0) === '@'
        ? this.user.instagram.substring(1)
        : this.user.instagram;
    },
    isAdmin() {
      return this.$store.getters.userroles.includes('Admins');
    },
    linkedinUrl() {
      if (!this.user.linkedin) return '';
      let data = this.user.linkedin.trim();
      if (data.startsWith('@')) {
        data = data.substring(1);
      }
      if (data.includes('linkedin.com') && !/^https?:\/\//.test(data)) {
        return `https://${data}`;
      }
      if (!data.includes('linkedin.com')) {
        return `https://www.linkedin.com/in/${data}`;
      }
      return data;
    }
  },
  watch: {
    '$route.params.id': function () {
      this.fetchUser();
    }
  },
  methods: {
    fetchUser() {
      const id = this.$route.params.id;
      const userUrl = `/user/${id}/full`;
      axios
        .get(userUrl, { withCredentials: true })
        .then((response) => {
          this.user = response.data;
          this.userExists = true;
          this.fetchProfileImage();
          axios.get('/localgroups', { withCredentials: true }).then((response1) => {
            this.lg = response1.data.find(
              (localgroup) => localgroup.id === this.user.localGroupId
            );
            this.exchangeLg = response1.data.find(
              (localgroup) => localgroup.id == this.user.exchangeLocalGroupId
            );
          });
          this.fetchUserEvents();
          this.fetchGroups();
          this.ready = true;
        })
        .catch(() => {
          this.userExists = false;
          this.ready = true;
        });
    },
    fetchUserEvents() {
      const id = encodeURIComponent(this.userName);
      const eventsUrl = `/user/${id}/events`;
      axios
        .get(eventsUrl, { withCredentials: true })
        .then((response) => {
          let events = response.data;
          if (events) {
            // Filter out canceled events (status not 0 or 5)
            events = events.filter(
              (a) => a.registrationStatus === 0 || a.registrationStatus === 5
            );
            // Sort events by end date descending
            events.sort(
              (a, b) => new Date(b.eventEndDate) - new Date(a.eventEndDate)
            );
            this.userEvents = events;
          }
        })
        .catch((error) => {
          console.error('Error fetching user events:', error);
        });
    },
    fetchProfileImage() {
      const id = this.user.userId;
      axios
        .get(`/files/profile/${id}`, { withCredentials: true })
        .then((response) => {
          if (response.data.imageUrls && response.data.imageUrls.length > 0) {
            this.profileImage = response.data.imageUrls[0];
          }
        })
        .catch((error) => {
          console.error('Error fetching profile image:', error);
        });
    },
    fetchGroups() {
      this.checkAdminStatus();
      if (this.isAdminOrItisMyAccount) {
        axios
          .get(`/user/${this.user.userId}/groups`, { withCredentials: true })
          .then((response) => {
            this.groups = response.data;
          })
          .catch((error) => {
            console.error('Error fetching groups:', error);
          });
      }
    },
    checkAdminStatus() {
      const isAdmin = this.$store.getters.userroles.includes('Admins');
      const currentUser = this.$store.getters.user;
      const itIsMyAccount = currentUser.userId === this.user.userId;
      this.isAdminOrItisMyAccount = isAdmin || itIsMyAccount;
    },
    onRowSelected(selectedRows) {
      this.selectedGroupIds = selectedRows.map((row) => row.roleId);
    },
    removeSelectedGroups() {
      if (this.selectedGroupIds.length === 0) {
        return;
      }
      const userId = this.user.userId;
      const payload = { userIds: [userId] };
      this.selectedGroupIds.forEach((element) => {
        axios
          .delete(`/groups/${element}/users`, { data: payload, withCredentials: true })
          .then(() => {
            this.groups = this.groups.filter((Group) => Group.roleId !== element);
            this.selectedGroupIds = [];
            if (this.$refs.GroupsTable) {
              this.$refs.GroupsTable.clearSelected();
            }
            window.location.reload();
          })
          .catch((error) => {
            console.error('Error removing group members:', error);
          });
      });
    },
    edit() {
      this.$router.push(`${this.$route.fullPath}/edit`);
    }
  },
  created() {
    this.fetchUser();
  }
};
</script>

<style scoped>
/* Profile layout */
#profile {
  display: flex;
  flex-direction: column;
  position: relative;
}

.background {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  height: 200px;
  padding-top: 10px;
  background-color: var(--green-primary);
}

.grid-container {
  display: grid;
  grid-template-columns: auto 456px 328px auto;
  grid-template-rows: auto auto auto auto auto;
  gap: 10px;
  margin-top: 120px;
}

.about-me {
  grid-column: 2 / 4;
  grid-row-start: 1;
  padding: 15px 150px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
}

.userInfo {
  grid-column: 2/3;
  grid-row-start: 2;
}

.user-contacts {
  grid-column: 3/4;
  grid-row-start: 2;
  overflow: visible;
}

.estiem-background {
  grid-column: 2/4;
  grid-row-start: 3;
}

.user-events {
  grid-column: 2/4;
  grid-row-start: 4;
}

.user-groups {
    grid-column: 2/4;
    grid-row-start: 5;
  }

.groups-header {
  grid-column: 2/4;
  display: flex;
  justify-content: space-between;
  margin: 7.5%;
}

.username {
  color: white;
  font-size: 20px;
}

.localgroup {
  color: white;
  font-size: 0;
}

.lg-link {
  color: white;
  text-decoration: none;
  font-size: 16px;
}

.lg-link b {
  padding: 5px 0;
}

.lg-link :hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.profilePicture {
  display: flex;
  position: absolute;
  min-height: 100px;
  min-width: 100px;
  top: 100px;
  width: 100%;
}

.deletedUser .deletedUserText {
  color: rgb(128, 128, 128);
  font-size: 26px;
  font-weight: 500;
  text-align: center;
  vertical-align: middle;
  padding: 5%;
}

/* Buttons and icons */
.profile-icons {
  font-size: 20px;
  color: #205e44;
  margin-right: 5px;
}

.edit-button {
  color: #205e44;
  border-color: #205e44;
  margin-top: 0;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 150px;
  padding: 0 20px;
}

.edit-button i.material-icons {
  margin-right: 8px;
  font-size: 24px;
}

.edit-button:hover {
  background-color: var(--green-primary);
  color: white;
  cursor: pointer;
}

.edit-button:hover > i {
  color: white;
}

/* Table wrappers - unify styling and behavior for both tables */
.table-wrapper {
  max-width: 90%;
  margin: 0 auto;
  margin-bottom: 10px;
}

.table-container {
  max-height: 350px;
  overflow-y: auto;
  overflow-x: hidden; /* We rely on the table layout for wrapping instead of forcing horizontal scroll */
  box-sizing: border-box;
  margin: 0 auto;
  width: 100%;
}

/* On smaller screens, use a slightly narrower container plus some padding */
@media screen and (max-width: 768px) {
  .table-wrapper {
    width: 95%;
    margin: 0 auto;
  }
}

/* Make table columns automatically wrap content */
::v-deep .table-container table {
  width: 100%;
  table-layout: fixed;
}

::v-deep .table-container table thead th {
  position: sticky !important;
  top: 0;
  background: white;
  z-index: 2;
}

::v-deep .table-container table tbody td {
  white-space: normal;
  word-wrap: break-word;
}

/* Additional small-screen layout adjustments */
@media screen and (max-width: 768px) {
  .grid-container {
    grid-template-columns: 5px auto auto 5px;
    justify-content: center;
  }
  .about-me {
    padding: 0 10px 15px;
    text-align: center;
  }
  .userInfo {
    grid-column: 2/4;
    grid-row-start: 2;
  }
  .user-contacts {
    grid-column: 2/4;
    grid-row-start: 3;
    overflow: visible;
  }
  .estiem-background {
    display: none;
  }
  .profilePicture {
    text-align: left;
    justify-content: center;
  }
  .user-groups {
    grid-column: 1 / -1;
  }
}

p {
  margin-bottom: 5px;
}

a {
  color: black;
}
</style>
