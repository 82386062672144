<template>
<div>
    <!-- Styled -->
    <b-form-file @change="add"
      ref="file-input"
      multiple
      :state="true"
      placeholder="Choose images..."
      drop-placeholder="Drop file here..."
      accept="image/*"
      style="text-align: left; margin-top: 10px;"
      plain
    ></b-form-file>
    <p>Selected files: <br />
    <template>
      <div  v-for="file in files" :key="file.name">
        {{ file ? file.name : '' }} <br />
      </div>
    </template>
    </p>
  <b-button variant="danger" v-on:click="reset">Delete images from the server</b-button>
  <b-alert :show="deleteSuccess"
           dismissible
           variant="success">
    Deleted files from the server.
  </b-alert>
  <b-alert :show="deleteFailure"
           dismissible
           fade
           variant="danger">
    Could not delete the files from the server: {{ deleteFailureMsg }}
  </b-alert>
</div >
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      files: [],
      deleteSuccess: false,
      deleteFailure: false,
      deleteFailureMsg: '',
    };
  },
  props: {
    type: '',
  },
  methods: {
    async reset() {
      this.files = [];
      let endpoint = '/files/event/';
      if (this.type === 'thumb') endpoint = '/files/eventhead/';
      await axios.delete(endpoint + this.$route.params.id).then(
        (response) => {
          if (response.status === 204) this.deleteSuccess = true;
        },
        (error) => {
          this.deleteFailure = true;
          this.deleteFailureMsg = error.message;
          this.$refs['file-input'].reset();
        },
      );
    },
    add(event) {
      const fileList = event.srcElement.files;
      Array.from(fileList).forEach((file) => {
        // reject files bigger than 500kb
        if (file.size < 4096000) {
          this.files.push(file);
          this.state = true;
        } else {
          this.state = false;
          alert('Please only upload files smaller than 4 MB.');
          // Couldn't manage to manipulate the text in the file input
        }
      });
      // pass up the updated file list to the parent component
      this.$emit('pass-files', this.files);
    },
  },
};
</script>

<style scoped>
  .row-group {
    display: flex;
    width: 100%;
  }
</style>
