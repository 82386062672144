<template>
  <div>
    <eventHeader :event="event" :participant="participant"></eventHeader>
    <iframe v-if="authenticated && event.locations" :src="event.locations" :style="'width: 100%; height: ' + height + 'px'"></iframe>
    <iframe v-else-if="authenticated && eventId === 5008" src="https://www.google.com/maps/d/u/0/embed?mid=15DF6wCa5dB5n1Zra0lBA-NIqh2YF6qyJ" :style="'width: 100%; height: ' + height + 'px'"></iframe>
    <iframe v-else-if="authenticated && eventId === 5101" src="https://www.google.com/maps/d/u/3/embed?mid=1734Q3Z8p0EBZjFOQhOfudv2ULwQPX4rA" :style="'width: 100%; height: ' + height + 'px'"></iframe>
    <div v-else>
      <h1> Please log in to view the map </h1>
    </div>
  </div>
</template>

<!-- TODO: Add filters and sorting -->
<script>
import eventHeader from './event-header.vue';

export default {
  components: {
    eventHeader,
  },
  props: {
    event: {

    },
  },
  computed: {
    authenticated() {
      return this.$store.getters.authenticated;
    },
    height() {
      if (screen.width <= 450) {
        return window.innerHeight - 260;
      }
      return window.innerHeight;
    },
    eventId() {
      return this.$route.params.id;
    },
  },
};
</script>

