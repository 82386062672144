<template>
  <div>
    <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdG4QX_geutVcFW_msWPpOuRovpiJJ3FxdlUwk-lAuSwclZkA/viewform?embedded=true"></iframe>
  </div>
</template>

<!-- TODO: Add filters and sorting -->
<script>

export default {
  computed: {
    height() {
      return window.innerHeight;
    },
  },
};
</script>


<style scoped>

iframe {
  width: 100%;
  height: calc(100vh - 91px);
}

</style>
