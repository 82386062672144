<template>
  <div>
    <div>
      <b-input-group class="localgroupPage">
        <b-form-input class="searchbar" v-model="searchString" placeholder="Search for Local Groups or Country"></b-form-input>
        <b-input-group-button class="change-view">
          <b-btn v-b-tooltip.hover v-bind:title="displaySwitch" @click="switchDisplay">
            <i class="material-icons">{{mapOrList}}</i>
          </b-btn>
        </b-input-group-button>
        <div class="buttons">
        <!-- This is useless, the backend does only send active LGs -->
          <!-- <span class="include_inactive" :key="searchString" :style="searchString !=='' ? 'visibility:hidden; margin-left:10px; margin-top:8px;' : 'visibility:visible; margin-left:10px; margin-top:8px;'">
            <b-form-checkbox id="includeInactive" v-model="includeInactive">Include Inactive?</b-form-checkbox>
          </span> -->
          <b-btn v-if="authenticated" target="_blank" href="https://internal.estiem.org/lgx/" variant="secondary">Find an Exchange LG</b-btn>
        </div>
      </b-input-group>
    </div>
    <localgroup-list v-if="!displayMap" :searchString="searchString" :localgroups="lgs" :includeInactive="includeInactive"></localgroup-list>
    <localgroup-map v-if="displayMap" :searchString="searchString" :localgroups="lgs" :includeInactive="includeInactive"></localgroup-map>
  </div>
</template>

<script>
import axios from 'axios';
import localgroupMap from './localgroupmap.vue';
import localgroupList from './localgrouplist.vue';

export default {
  data() {
    return {
      searchString: '',
      lgs: [],
      center: { lat: 53.0, lng: 15.0 },
      displayMap: false,
      mapOrList: 'map',
      displaySwitch: 'Map View',
      includeInactive: false,
    };
  },
  components: {
    localgroupList,
    localgroupMap,
  },
  computed: {
    authenticated() {
      return this.$store.getters.authenticated;
    },
    height() {
      return window.innerHeight - 86;
    },
  },
  methods: {
    switchDisplay() {
      this.displayMap = !this.displayMap;
      this.mapOrList = (this.displayMap ? 'view_module' : 'map');
      this.searchString = '';
      /* console.log('displayMap == '+this.displayMap+'; mapOrList == '+this.mapOrList); */
      if (this.displaySwitch === 'Map View') {
        this.displaySwitch = 'List View';
      } else {
        this.displaySwitch = 'Map View';
      }
    },
  },
  created() {
    axios.get('/localgroups')
      .then((response) => {
        this.lgs = response.data;
        /* if (this.searchString != null) {
          this.lgs = this.filterLocalGroupsBySearchstring(lgs);
        } */
      });
  },
};
</script>

<style scoped>
.localgroupPage{
  padding-top: 20px;
  margin-bottom: 30px;
  max-width: 80%;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
}

.material-icons{
  vertical-align:middle;
}

 @media only screen and (max-width: 885px) and (min-width: 450px) {
   .searchbar {
     min-width: 400px;
     max-height: 50px;
    }

    .change-view {
     max-height: 50px;
    }

    .buttons {
      margin-top: 15px;
    }

    .include_inactive {
      margin-left: 0;
    }
 }

  @media only screen and (max-width: 449px) {
       .searchbar {
          min-width: 100px;
          max-height: 50px;
        }

        .change-view {
         max-height: 50px;
        }

        .buttons {
          margin-top: 15px;
        }

        .include_inactive {
          margin-left: 0;
        }
  }

</style>
