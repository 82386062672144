import Vue from 'vue';
import Vuex from 'vuex';
import events from './modules/events.js';
import user from './modules/user.js';
import localgroups from './modules/localgroups.js';

Vue.use(Vuex);
export default new Vuex.Store({
  modules: {
    events,
    user,
    localgroups,
  },
});
