import eventMap from './event-map.vue';
import eventApply from './event-apply.vue';
import eventDetails from './event-details.vue';
import eventParticipants from './event-participants.vue';
import eventEdit from './event-edit.vue';
import eventScheduleList from './event-schedule-list.vue';
import eventFeedbackReport from './event-feedback-report.vue';

const eventroutes = [
  {
    path: 'participants',
    name: 'eventParticipants',
    component: eventParticipants,
    meta: { requiresAuth: true },
  },
  {
    path: 'apply',
    name: 'eventApply',
    component: eventApply,
    meta: { requiresAuth: true },
    /* beforeEnter: (to, from, next) => {
      TODO: add condition to if it is before deadline
      const event = store.getters.event(to.params.id);
      if (moment().isAfter(event.applicationStartDate) && moment().isBefore(event.applicationEndDate)) {
        return next();
      }
      return false;
  }, */
  },
  {
    path: '/',
    name: 'eventDetails',
    component: eventDetails,
  },
  {
    path: 'edit',
    name: 'eventEdit',
    component: eventEdit,
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      next((comp) => {
        comp.fetchEvent();
        comp.fetchLGs();
      });
    },
  },
  {
    path: 'schedule',
    name: 'eventSchedule',
    component: eventScheduleList,
  },
  {
    path: 'map',
    name: 'eventMap',
    component: eventMap,
  },
  {
    path: 'feedback-report',
    name: 'eventFeedbackReport',
    component: eventFeedbackReport,
    meta: { requiresAuth: true },
  },
];

export default eventroutes;
