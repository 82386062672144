<template>
  <div v-if="!!event">
    <eventHeader :event="event" :participant="participant"></eventHeader>
    <spinner v-if="!contentLoaded"></spinner>
    <!--section class="agenda"-->
    <!-- //TODO:Bring the agenda from footer i think-->
    <!-- //TODO: add an overlay button, make the modal -->
    <!--      <h2>Agenda</h2>
      <img v-b-modal.show-pic src="https://lh6.googleusercontent.com/xGZYCJA9VRwZ4fB-vT2UwzovcAocePcXgTuwwm8VohdiIUwsWOXZ-QHkxof_ctYDDcJ-hh_QQUGB3V_nplOZOf4V9ib0zNzEdYR_XGdO7EZCTDGUUUlc34bGswiL42A9NDD7GPOR" alt="event agenda">
    </section-->
    <section v-if="event.description" class="text">
      <div class="text-description">
        <div v-html="event.description"></div>
      </div>
    </section>
        <section class="photo" v-if="contentLoaded && sliderPictures.length > 0">
      <!-- Slider Section-->
      <div class="carousel-img">
        <eventSlider :sliderPictures="sliderPictures"></eventSlider>
      </div>
    </section>
    <section class="type-event">
      <!--Info Event section-->
      <!-- //TODO: MAKE CSS WORK-->
      <div v-if="alreadyRegistered && event.arrivalInfo">
        <h3>Arriving in {{ event.place }}</h3>
        <div v-html="event.arrivalInfo" />
      </div>
      <div v-if="alreadyRegistered && event.accomodation">
        <h3>Accommodation</h3>
        <div v-html="event.accomodation" />
      </div>
      <div v-if="event.partners">
        <h3>Partners</h3>
        <div v-html="event.partners"></div>
      </div>
      <div v-if="eventTypeName">
        <!-- Commented out the image, because it does not seem to be working -->
        <!-- <img v-bind:src="'/static/img/projects/' + eventTypeName.img" /> -->
        <br />
        <h3>What is {{ eventTypeName.text }}?</h3>
        <div>
          {{ eventTypeName.description }}
          <span v-if="eventTypeName.link">
            <router-link :to="eventTypeName.link" class="inline-link">Read more</router-link>
          </span>
        </div>
      </div>
    </section>
    <!-- Section for the photo slide-->
    <!-- TODO:Make a new component -->
    <!--
    <section class="photo">
    <h2>Photos</h2>-->
    <!--
      <b-container fluid class = "photo-block">
        <b-img thumbnail fluid src="https://lh6.googleusercontent.com/xGZYCJA9VRwZ4fB-vT2UwzovcAocePcXgTuwwm8VohdiIUwsWOXZ-QHkxof_ctYDDcJ-hh_QQUGB3V_nplOZOf4V9ib0zNzEdYR_XGdO7EZCTDGUUUlc34bGswiL42A9NDD7GPOR" alt="Img1"></b-img>        </b-container>
    </section>-->
    <BackToTop></BackToTop>
  </div>
</template>

<script>
// TODO: change how the back to top bottom looks like
import axios from 'axios';
import spinner from '../Spinner/spinner.vue';
import BackToTop from '../home/back-to-top-btn.vue';
import eventSlider from './event-slider.vue';
import { Events } from '../../helpers/event-types.js';
import { RegistrationStatus } from '../../helpers/registration-status.js';
import eventHeader from './event-header.vue';

export default {
  data() {
    return {
      evTypeOptions: [...Events],
      sliderPictures: [],
    };
  },
  components: {
    spinner,
    BackToTop,
    eventSlider,
    eventHeader,
  },
  computed: {
    contentLoaded() {
      return this.event.name;
    },
    eventOrganiser() {
      return this.participant.registrationStatus === RegistrationStatus.ORGANIZER;
    },
    alreadyRegistered() {
      return (this.participant.registrationStatus === RegistrationStatus.REGISTERED || this.participant.registrationStatus === RegistrationStatus.ORGANIZER);
    },
    eventTypeName() {
      return this.evTypeOptions.find(
        e => e.value === this.event.eventType,
      ); // filter event type name
    },
    userId() {
      return this.$store.getters.user.userId;
    },
  },


  methods: {
    getEventTypeName() {
      this.eventTypeName = this.evTypeOptions.find(
        e => e.value === this.event.eventType,
      ); // filter event type name
    },
    fetchEvent() {
      const id = this.$route.params.id;
      const url = `/events/${id}`;
      axios.get(url).then((response) => {
        this.event = response.data;
      });
    },
    async fetchImages() {
      const response = await axios.get(`files/event/${this.$route.params.id}`);
      this.sliderPictures = response.data.imageUrls;
    },
  },
  props: {
    event: {
    },
    participant: {
      default() { return {}; },
    },
  },
  mounted() {
    this.fetchEvent();
    this.fetchImages();
  },
};

</script>


<style scoped>


h1 {
  margin-bottom: 50px!important;
  color: black!important;
  font-size: 36px!important;
  font-style: bold!important;
}

h2 {
  color: black!important;
  font-size: 24px!important;
}

h3 {
  color: black!important;
  font-size: 20px!important;
  margin-top: 16px!important;
}

.material-icons {
  font-size: 35px;
  margin: 0 10px;
  color: #205E44;
  }


.agenda {
    h2 {
        text-align: center;
        margin: 40px 0;
    }
    img {
        width: 100%;
        height: 264px;
        object-fit:contain;
    }
}
.type-event
{
  display: grid;
  grid-template-columns: auto 684px auto;
  grid-template-rows: max-content;
  margin: 20px 0;
  div {
    grid-column-start:2;
    grid-column-end:2;
    grid-auto-rows : max-content;
  }
}
.text{
    display: grid;
    grid-template-columns: auto 684px auto;
    grid-template-rows: max-content;
    margin: 20px 0;
    .text-description {
        overflow:hidden;
        grid-column-start:2;
        grid-column-end:2;
        grid-auto-rows : max-content;
        text-align: left;
        font-family: Roboto;
        font-style: normal;
        header {
            margin-top : 40px 0;
            margin-bottom : 2opx 0;
            font-size:125%;
            font-weight: 700;
        }
        p {
            font-weight: 400;
        }
    }
}
.carousel-img
{
  overflow: hidden;
  flex:100%;
  margin-left: auto;
  margin-right: auto;
  max-width: 640px;
}
.photo {
  display: grid;
  flex:100%;
  margin-left: auto;
  margin-right: auto;
  border:none;
    h2 {
        text-align: center;
    }
}
/*This is for not using the carousel
.photo-block {
  overflow:hidden;
 // padding: 1rem 1.25rem 1rem 1.25rem;
  margin-left:auto;
  margin-right:auto;
  justify-content: center;
  align-content: center;
  img{
    margin: 1rem 1.25rem 1rem 1.25rem;
    padding: 0;
    border:0;
    max-width: 320px;
    max-height: 320px;
    object-fit : contain;
  }
}
*/
@media screen and (max-width: 1024px)
{
  .event-pic {
    grid-row-start: 1;
    grid-column-start:3;
    grid-column-end:4;
  }
  .type-event{
    margin: 10px 0px;
    grid-template-columns: auto 31px auto 31px auto;
      div {
      grid-column-start:3;
      grid-column-end:4;
    }
  }

  .text {
    margin: 10px 0px;
    grid-template-columns: auto 31px auto 31px auto;
      .text-description {
        grid-column-start:3;
        grid-column-end:4;
      }
  }
}
@media screen and (min-width: 1800px) {
  .links {
    grid-column-start: 2;
    grid-column-end: 3;
  }
}

</style>
