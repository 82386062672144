<template>
  <!--<div id="container">-->
  <div id="profile" v-if="isRightUser || isAdmin" >
<!--    <img class="card-img-top" :src="imageUrl" alt="">-->
    <div class="profile-image-container">
      <div style="flex: 1 1 100px;">
        <!--blank space-->
      </div>
      <div>
        <img class="profile-image" v-if="ready" :src="this.profileImage" >
        <input type="file" @input="uploadPhoto" accept="image/*" id='upload' style="display:none;">
      </div>
      <div style="flex: 1 1 100px;">
        <button class="edit-button" @click="openDialog">
          <i style="margin-left: -3px;" class="material-icons">edit</i>
        </button>
        <button class="delete-button" @click="showDeleteConfirm">
          <i style="margin-left: -3px;" class="material-icons">delete</i>
        </button>
      </div>
    </div>
    <div class="infobox">
      <div class="userInfo">
        <div class="personalInfo">
          <br />
          <span class="username"><b>{{ user.firstNameEnglish }} {{ user.lastNameEnglish }}</b></span>
          <br/>
          <span class="localgroup"><b>{{ this.lg.name }}{{ this.exchangeLg !== undefined ? ', ' + this.exchangeLg.name : '' }}</b></span>
        </div>
        <div class="view-profile">
          <router-link v-if="authenticated" :to="'/profile/' + userProfile">
            <span>View my profile</span>
          </router-link>
        </div>
        <div v-if="form.userId">
          <h3 v-b-toggle.accordion-1>PROFILE</h3>
          <b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel">
            <b-row>
              <b-col sm="4"><label for="about">About you</label></b-col>
              <b-col sm="8"> <b-form-textarea v-model="form.interests" id="about" placeholder="Describe yourself, your interests, hobbies, believes..."></b-form-textarea></b-col>
            </b-row>
            <b-row>
              <b-col sm="4"><label for="about">ESTIEM background</label></b-col>
              <!-- <b-col sm="8"> <b-form-textarea v-model="form.estiemActivities" id="about" placeholder="Tell other ESTIEMers about your favourite experiences, events or achievements" ></b-form-textarea></b-col> -->
              <b-col sm="8">
                <div id="about">
                <vue-editor
                  id="estiem-background-editor"
                  v-model="form.estiemActivities"
                ></vue-editor>
                </div>
                </b-col>
            </b-row>
            <b-row>
              <b-col sm="4"><label for="phone">Phone</label></b-col>
              <b-col sm="8"><b-form-input v-model="form.mobilePhone" type="text" id="phone" placeholder="e.g. +47 123 45 678" maxlength="150" /></b-col>
            </b-row>
            <!-- Birthday and Start of studies are smalldatetime in the db, so needs to be converted back -->
            <b-row>
              <b-col sm="4"><label for="birthday">Birthday</label></b-col>
              <b-col sm="8"><b-form-input class="date-inputs" v-model="form.birthday" type="date" id="birthday" placeholder="DD-MM-YYYY" maxlength="10" /></b-col>
            </b-row>
            <!-- Birthday and Start/End of studies are smalldatetime in the db, so needs to be converted back -->
            <b-row>
              <b-col sm="4"><label for="studyStart">Start of studies</label></b-col>
              <b-col sm="8"><b-form-input class="date-inputs" v-model="form.studiesStart" type="date" id="studyStart" placeholder="DD-MM-YYYY" maxlength="10" /></b-col>
            </b-row>
            <!-- Birthday and Start/End of studies are smalldatetime in the db, so needs to be converted back -->
            <b-row>
              <b-col sm="4"><label for="studyEnd">Estimated end of studies</label></b-col>
              <b-col sm="8"><b-form-input class="date-inputs" v-model="form.studiesEnd" type="date" id="studyEnd" placeholder="DD-MM-YYYY" maxlength="10" autocomplete="off" /></b-col>
            </b-row>
            <b-row>
              <b-col sm="4"><label for="skype"><Skype>Skype Username</Skype></label></b-col>
              <b-col sm="8"><b-form-input v-model="form.skypeName" type="text" id="skype" playceholder="skype username" autocomplete="off" /></b-col>
            </b-row>
            <b-row>
              <b-col sm="4"><label for="linkedin">LinkedIn Profile</label></b-col>
              <b-col sm="8"><b-form-input v-model="form.linkedin" type="text" id="linkedin" placeholder="https://www.linkedin.com/in/<yourAccount>" autocomplete="off" /></b-col>
            </b-row>
            <b-row>
              <b-col sm="4"><label>Instagram</label></b-col>
              <b-col sm="8"><b-form-input v-model="form.instagram" type="text" id="linkedin" placeholder="@your_instagram_profile" autocomplete="off" /></b-col>
            </b-row>
            <b-row v-if="alumnus">
              <b-col sm="4"><label for="Company">Current Employer (alumni)</label></b-col>
              <b-col sm="8"><b-form-input v-model="form.company" type="text" id="company" placeholder="Who do you work for?" maxlength="150" /></b-col>
            </b-row>
            <b-row v-if="alumnus">
              <b-col sm="4"><label for="companyPosition">Current Position (alumni)</label></b-col>
              <b-col sm="8"><b-form-input v-model="form.companyPosition" type="text" id="companyPosition" placeholder="What is your position in your company?" maxlength="150" /></b-col>
            </b-row>
            <b-row v-if="alumnus">
              <b-col sm="4"><label for="city">Current city (alumni)</label></b-col>
              <b-col sm="8"><b-form-input v-model="form.hometown" type="text" id="city" :value="user.city" placeholder="e.g. Oslo" maxlength="150" /></b-col>
            </b-row>
          </b-collapse>
          <h3 v-b-toggle.accordion-3>ACCOUNT SETTINGS</h3>
          <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
            <!-- First name and Last name can be changed (such as getting married), but they should understand it changes the email address -->
             <b-row>
            <div class="alert alert-danger mx-3" role="danger">Warning: Changing your name will also change your ESTIEM email address and log you out</div>
            </b-row>
            <b-row>
              <b-col sm="4"><label for="firstName">First name</label></b-col>
              <b-col sm="8"><b-form-input v-model="form.firstNameEnglish" type="text" id="firstName" :value="user.firstNameEnglish" placeholder="First name" maxlength="50" required /></b-col>
            </b-row>
            <!-- First name and Last name can be changed (such as getting married), but they should understand it changes the email address -->
            <b-row>
              <b-col sm="4"><label for="lastName">Last name</label></b-col>
              <b-col sm="8"><b-form-input v-model="form.lastNameEnglish" type="text" id="lastName" :value="user.lastNameEnglish" placeholder="Last name" maxlength="50" required /></b-col>
            </b-row>
            <!-- Email needs validation -->
            <b-row>
              <b-col sm="4"><label for="email">ESTIEM Email Address</label></b-col>
              <b-col sm="8"><b-form-input readonly v-model="userEmail" type="email" id="email" :value="userEmail" placeholder="Email" required /></b-col>
            </b-row>
            <b-row>
              <b-col sm="4"><label>Private Email</label>
              <i class="material-icons profile-icons" style="vertical-align:middle" v-b-tooltip.hover title="Emails to your ESTIEM mail are forwarded to your private email">info_outline</i></b-col>
              <b-col sm="8"><b-form-input v-model="form.EmailAddress" type="email" id="email" :value="user.portalUserEmail" placeholder="Email" required /></b-col>
            </b-row>

            <!-- Have to make sure that field is only updated if new password is written in -->
            <!-- commented out: reset password function is not available on the backend yet
              <b-row>
              <b-col sm="4"><label for="newPassword">New password</label></b-col>
              <b-col sm="8"><b-form-input v-model="password" type="password" id="newPassword" placeholder="New password" value="" maxlength="20" required /></b-col>
            </b-row>
            <b-row>
              <b-col sm="4"><label for="confirmPassword">Confirm password</label></b-col>
              <b-col sm="8"><b-form-input v-model="password" type="password" id="confirmPassword" placeholder="Confirm password" value="" maxlength="20" required /></b-col>
            </b-row>
            -->
            <!-- Users can't change their own Local Group -->
           <b-row v-if="!isAdmin">
             <b-col sm="4"><label for="status">Local Group</label> </b-col>
             <b-col sm="8"><b-form-select disabled v-model="form.localGroupId" :options="localGroupNames" required></b-form-select></b-col>
           </b-row>
           <!-- Admins can change the Local Group Local Group -->
          <b-row v-if="isAdmin">
             <b-col sm="4"><label for="status">Local Group</label> </b-col>
             <b-col sm="8"><b-form-select v-model="form.localGroupId" :options="localGroupNames" required></b-form-select></b-col>
           </b-row>
           <!-- Users can't change their own Exchange Local Group -->
           <b-row v-if="!isAdmin">
             <b-col sm="4"><label for="status">Exchange Local Group</label> <i class="material-icons profile-icons" style="vertical-align:middle" v-b-tooltip.hover
             title="To change your Exchange Local Group write to help.it@estiem.org">info_outline</i></b-col>
             <b-col sm="8"><b-form-select disabled v-model="form.exchangeLocalGroupId" :options="localGroupNames" required></b-form-select></b-col>
           </b-row>
           <!-- Admins can change the  Exchange Local Group -->
            <b-row v-if="isAdmin">
             <b-col sm="4"><label for="status">Exchange Local Group</label> <i class="material-icons profile-icons" style="vertical-align:middle" v-b-tooltip.hover
             title="To change your Exchange Local Group write to help.it@estiem.org">info_outline</i></b-col>
             <b-col sm="8"><b-form-select v-model="form.exchangeLocalGroupId" :options="localGroupNames" required></b-form-select></b-col>
           </b-row>
            <b-row>
             <b-col sm="4"><label>Apply for Alumni</label> <i class="material-icons profile-icons" style="vertical-align:middle" v-b-tooltip.hover
             title="To apply for ESTIEM Alumni, use the application form on the old Alumni Portal.">info_outline</i></b-col>
             <b-col class="pt-2"><a target="_blank" class="align-middle" href="https://old.estiem.org/Alumni/default.aspx?PageId=1026">Go to the old Alumni Portal</a></b-col>
           </b-row>
            <b-row>
             <b-col sm="4"><label>Change password</label> <i class="material-icons profile-icons" style="vertical-align:middle" v-b-tooltip.hover
             title="To apply for ESTIEM Alumni, use the application form on the old Alumni Portal.">info_outline</i></b-col>
             <b-col class="pt-2"><a target="_blank" class="align-middle" href="https://myaccount.microsoft.com">Go to Microsoft</a></b-col>
           </b-row>
          </b-collapse>
          <!-- commented out: email and privacy settings are yet to be implemented
          <h3 v-b-toggle.accordion-4>PRIVACY AND EMAIL SETTINGS</h3>
          <b-collapse id="accordion-4" accordion="my-accordion" role="tabpanel">
            <b-table :items="items" :fields="fields">
              <template v-for="(field, index) in fields" :slot="field.key" slot-scope="data">
                <div :key="index">
                  <div v-if="field.colType === 'group'">
                    <h5>{{data.item.name}}</h5>
                  </div>
                  <div v-else-if="field.colType === 'description'">
                    <span>{{data.item.description}}</span>
                  </div>
                  <div v-else>
                    <span v-if="data.item.status === 'mandatory'">Mandatory list</span>
                    <b-button size="sm" v-if="data.item.status === 'notjoined'">Join list</b-button>
                    <b-button size="sm" v-if="data.item.status === 'joined'">Leave list</b-button>
                  </div>
                </div>
              </template>
            </b-table>
          </b-collapse>
          -->
          <!-- <h3 v-b-toggle.accordion-5>ENGINEERING SPECIALISATIONS</h3>
          <b-collapse id="accordion-5" accordion="my-accordion" role="tabpanel">
            <b-form-select v-model="selectedEngineering" :options="optionsEngineering" multiple class="select engineering"></b-form-select>
          </b-collapse>
          <h3 v-b-toggle.accordion-6>MANAGEMENT SPECIALISATIONS</h3>
          <b-collapse id="accordion-6" accordion="my-accordion" role="tabpanel">
            <b-form-select v-model="selectedManagement" :options="optionsManagement" multiple class="select management"></b-form-select>
          </b-collapse>
          <h3 v-b-toggle.accordion-7>POSITION (ALUMNI)</h3>
          <b-collapse id="accordion-7" accordion="my-accordion" role="tabpanel">
            <b-form-select v-model="selectedPosition" :options="optionsPosition" multiple class="select position"></b-form-select>
          </b-collapse>
          <h3 v-b-toggle.accordion-8>FUNCTIONS (ALUMNI)</h3>
          <b-collapse id="accordion-8" accordion="my-accordion" role="tabpanel">
            <b-form-select v-model="selectedFunction" :options="optionsFunction" multiple class="select functions"></b-form-select>
          </b-collapse>
          <h3 v-b-toggle.accordion-9>INDUSTRIES (ALUMNI)</h3>
          <b-collapse id="accordion-9" accordion="my-accordion" role="tabpanel">
            <b-form-select v-model="selectedIndustry" :options="optionsIndustry" multiple class="select industries"></b-form-select>
          </b-collapse> -->
        </div>
        <b-alert :show="showErrorMsg"
             dismissible
             variant="danger">
        Could not edit Profile: {{ editErrorMsg }}
        </b-alert>
        <b-alert :show="showSuccess"
                 fade
             variant="success">
        Profile successfully updated!
        </b-alert>

        <b-alert :show="showDeleteErrorMsg"
             dismissible
             variant="danger">
        Could not delete User: {{ editErrorMsg }}
        </b-alert>
        <b-alert :show="showDeleteSuccess"
                 fade
             variant="success">
        User successfully deleted! Redirecting to homepage ...
        </b-alert>


        <div class="buttons" v-show="toggle">
          <b-btn v-on:click="updateProfile" variant="success">Save changes</b-btn>
          <b-btn @click="showProfileDeleteConfirm" variant="danger">Delete profile</b-btn>
        </div>
      </div>
    </div>
    <!-- Modal Components -->
    <!--
    <b-modal id="delete" hide-footer title="Delete user">
      <div>
        <p>Still working on this functionality. To delete your user, please use the <a href="https://old.estiem.org/Internal/default.aspx?PageId=518" class="modal-link">Old Portal</a>.</p>
      </div>
    </b-modal>
    -->
    <b-modal ref="confirm-user-delete" hide-footer title="Confirm Delete">
      <div v-if="isAdmin && !isRightUser">Do you want to delete this user?<br/>
      This action can not be undone!</div>
      <div v-if="isRightUser">Do you want to delete your profile?<br/>
      This action can not be undone!</div>
      <br />
      <b-button @click="deleteUser" variant="danger">Yes</b-button>
      <b-button @click="closeDeleteModal">Cancel</b-button>
    </b-modal>

    <b-modal ref="confirm-photo-delete" hide-footer title="Confirm Delete">
      <div v-if="isAdmin && !isRightUser">Do you want to delete this user's profile picture?</div>
      <div v-if="isRightUser">Do you want to delete your profile picture?</div>
      <br />
      <b-button @click="deletePhoto" variant="danger">Yes</b-button>
      <b-button @click="closeModal">Cancel</b-button>
    </b-modal>
  </div>
  <!--</div>-->
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import { VueEditor } from 'vue2-editor';
import { imageUrl } from '../../helpers/image-helper.js';
import auth from '../../auth/index.js';

export default {
  data() {
    return {
      toggle: true,
      profileImage: '',
      ready: false,
      user: {},
      lg: {},
      exchangeLg: undefined,
      localgroups: [],
      form: {
        userId: '',
        EmailAddress: '',
        firstNameEnglish: '',
        lastNameEnglish: '',
        birthday: '',
        mobilePhone: '',
        interests: '',
        estiemActivities: '',
        studiesStart: '',
        studiesEnd: '',
        skypeName: '',
        hometown: '',
        instagram: '',
        linkedin: '',
        localGroupId: '',
        exchangeLocalGroupId: '',
        status: '',
        alumniStatus: '',
        company: '',
        companyPosition: '',
      },
      showSuccess: false,
      showErrorMsg: false,
      showDeleteSuccess: false,
      showDeleteErrorMsg: false,
      editErrorMsg: '',
    };
  },
  components: {
    VueEditor,
  },
  filters: {
    formatdate(value) {
      if (value) {
        return moment(value).format('DD.MM.YYYY');
      }
      return '';
    },
  },
  computed: {
    authenticated() {
      return this.$store.getters.authenticated;
    },
    userId() {
      return this.$store.getters.user.userId;
    },
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
    isRightUser() {
      const user = this.$store.getters.user;
      const loggedInUserId = user.userId;
      return loggedInUserId === this.user.userId;
    },
    userName() {
      return this.user.portalUserUserName;
      /* some estiemer profile pages don't display the data
      --> spaces at the end of the name that prevents from getting the right data in the database
      change the names in the db? ==> see with Robin */
    },
    alumnus() {
      return this.$store.getters.userroles.includes('Alumni');
    },
    userProfile() {
      return this.userName.split(' ').join('%20');
    },
    userEmail() {
      const userName = this.user.portalUserUserName.toLowerCase();
      return `${userName.replace(/ /g, '.')}@estiem.org`;
    },
    getBirthday() {
      if (this.user.birthday) {
        return moment(this.user.birthday).format('DD-MM-YYYY');
      }
      return '';
    },
    getStartOfStudies() {
      if (this.user.studiesStart) {
        return moment(this.user.studiesStart).format('DD-MM-YYYY');
      }
      return '';
    },
    imageUrl() {
      return imageUrl(this.lg.name, this.user.localGroupId);
    },
    localGroupNames() {
      let lgNames = this.localgroups.map(lg => ({ text: lg.name, value: lg.id }));
      lgNames = lgNames.concat([{ text: 'No Local Group', value: 121 }]);
      return lgNames;
    },
  },
  watch: {
    '$route.params.id': function () {
      this.fetchUser();
    },
  },
  methods: {
    clearExtraSpaces(name) {
      let len = name.length;
      for (let i = len; i >= 0; i -= 1) {
        while (name[i] === ' ' && name[i - 1] === ' ') {
          name = name.slice(0, i) + name.slice(i + 1);
        }
      }
      len = name.length;
      if (name.charAt(len - 1) === ' ') {
        name = name.substring(0, len - 1);
      }
      return name;
    },
    fetchUser() {
      return new Promise((resolve) => {
        let id = this.$route.params.id;
        id = this.clearExtraSpaces(id);
        const userUrl = `/user/${id}/full`;
        axios.get(userUrl)
          .then((response) => {
            this.user = response.data;
            this.updateParameters();
            this.fetchProfileImage(this.user.userId);
            axios.get('/localgroups')
              .then((response1) => {
                this.lg = response1.data.find(localgroup => localgroup.id === this.user.localGroupId);
                this.exchangeLg = response1.data.find(localgroup => localgroup.id === this.user.exchangeLocalGroupId)
                this.ready = true;
                resolve();
              });
          });
      });
    },
    fetchLGs() {
      axios.get('/localgroups')
        .then((response) => {
          this.localgroups = response.data
            .sort((a, b) => {
              if (a.name > b.name) return 1;
              if (b.name > a.name) return -1;
              return 0;
            })
            .filter(lg => lg.status !== 8);
        });
    },
    uploadPhoto(event) {
      const photo = event.target.files[0];
      if (photo.size > 256000) {
        alert('The Profile Picture should not be larger than 256kb');
      } else {
        const formData = new FormData();
        formData.append('profileImage', photo, photo.name);
        const config = {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        };
        axios.post(`/files/profile/${this.user.userId}`, formData, config).then(() => {
          this.fetchProfileImage(this.user.userId);
        });
      }
    },
    deletePhoto() {
      axios.delete(`/files/profile/${this.user.userId}`).then(() => {
        this.fetchProfileImage(this.user.userId);
        this.$refs['confirm-photo-delete'].hide();
      });
    },
    openDialog() {
      document.getElementById('upload').click();
    },
    closeModal() {
      this.$refs['confirm-photo-delete'].hide();
    },
    updateParameters() {
      this.form = {
        ...this.user,
      };
      this.form.EmailAddress = this.user.portalUserEmail;
      if (this.user.birthday != null) this.form.birthday = this.user.birthday.substring(0, 10);
      if (this.user.studiesStart != null) this.form.studiesStart = this.user.studiesStart.substring(0, 10);
      if (this.user.studiesEnd != null) this.form.studiesEnd = this.user.studiesEnd.substring(0, 10);
    },
    checkForm() {
      if (this.form.linkedin && !this.form.linkedin.includes('linkedin.com/in/')) alert('please enter the url of your linkedin account in the format linkedin.com/in/<YourUserName>');
      else if (!this.form.firstNameEnglish || !this.form.lastNameEnglish) alert('Name must be filled out');
      else if (!this.form.status || !this.form.alumniStatus) alert('status and/or alumni status wrong');
      else return true;
      return false;
      // TODO: Check if fields were filled before, ask for confirmation if they are now empty
      // TODO add more checks for data validation, including range of different numbers, type of content etc.
    },
    updateProfile() {
      this.toggle = false;
      if (this.checkForm()) {
        axios.put(`user/${this.form.userId}`, this.form).then(() => {
          this.showSuccessMsg();
        }).catch((error) => {
          const errs = error.response.data.errors;
          if (errs) {
            Object.entries(errs).forEach(([k, v]) => {
              this.editErrorMsg += `${k}: ${v}`;
              this.editErrorMsg += '\n';
            });
          } else this.editErrorMsg = `Updating the profile failed with ${error.response.status.toString()} ${error.response.statusText}`;
          this.showErrorMsg = true;
        });
      }
    },

    deleteUser() {
      this.toggle = false;
      const url = `/users/${this.user.userId}`;
      axios.delete(url)
        .then(() => {
          this.showModal = false;
          this.showDeleteSuccessMsg();
        })
        .catch((error) => {
          this.showDeleteErrorMsg = true;
          this.editErrorMsg += `\nDeleting the user failed with ${error.response.status.toString()} ${error.response.statusText}`;
          const errors = error.response.data.errors;
          const keys = Object.keys(error.response.data.errors);
          keys.forEach((key) => {
            this.editErrorMsg += `\n${key}: ${errors[key]}`;
          });
          this.closeDeleteModal();
        });
    },
    closeDeleteModal() {
      this.$refs['confirm-user-delete'].hide();
    },
    logout() {
      auth.logout();
    },
    showDeleteSuccessMsg() {
      this.showDeleteSuccess = true;
      this.closeDeleteModal();
      // If the user is an Admin, he will only be redirected to the homepage, otherwise also logged out
      if (this.isAdmin) {
        setTimeout(() => {
          document.location.href = '/';
        }, 3000);
      } else {
        setTimeout(() => {
          document.location.href = '/';
          this.logout();
        }, 3000);
      }
    },
    showSuccessMsg() {
      this.showSuccess = true;
      setTimeout(() => {
        this.showSuccess = false;
        document.location.href = `/profile/${this.user.userId}/`;
      }, 0);
    },
    fetchProfileImage(id) {
      axios.get(`/files/profile/${id}`)
        .then((response) => {
          if (response.data.imageUrls.length > 0) { this.profileImage = response.data.imageUrls[0]; } else {
            axios.get('files/profile/99999').then((response2) => {
              this.profileImage = response2.data.imageUrls[0];
            });
          }
        });
    },
    showDeleteConfirm() {
      this.$refs['confirm-photo-delete'].show();
    },
    showProfileDeleteConfirm() {
      this.$refs['confirm-user-delete'].show();
    },
  },
  created() {
    this.fetchUser();
    this.fetchLGs();
  },
};
</script>


<style scoped>

#estiem-background-editor {
  height: 350px;
}

  textarea{
    min-height: 6.5em;
  }
#profile {
    width:100%;
    margin: 0;
    top:0;
}

a {
  font-size:1rem;
  }

td {
    padding: 10px !important;
}

.edit-button {
   color: white;
   background-color: #205e44;
   border: none;
   border-radius: 50%;
   width: 30px;
   height: 30px;
 }

 .alert {
   margin-left: auto;
   margin-right: auto;

   font-size: 1rem


 }

.delete-button {
  color: white;
  background-color: darkred;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
}

.card-img-top {
  width:100%;
  max-height:200px;
  min-height:120px;
  object-fit: cover;
  border-bottom: 1px solid rgb(180,180,180);
}


.profile-image-container {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-top: 20px;
}

.profile-image {
  height:200px;
  width: 200px;
  object-fit: cover;
  border: 1px solid rgb(180,180,180);
}

.infobox {
  margin: 20px auto 60px;
  padding: 0 2rem;
  display:table;
    width:100%;
    max-width:700px;
    background-color: white;
    border-style: solid;
    border-width: 1px;
    border-color: rgb(180,180,180);
}

label {
  font-size: 1rem;
  margin: 0.5rem 0;
}

.date-inputs {
  font-size: 1rem;
}

.userInfo{
    font-size:12px;
    color:gray;
    display:table-cell;
}

.date-inputs {
  font-size: 1rem;

}

@media screen and (max-width: 992px) {
    .userInfo{
    display:block;
    width:100%;
    /*padding-left:80px;*/
    text-align:left;

    }
    .personalInfo{
        width:100%;
    }

    .infobox {
      border-width: 0px;
      margin-top: 0px;
    }
}


.personalInfo {
    text-align:center;
    color:#205e44;
}
.username{
    font-size: 20px;
    top:60px;

}
.localgroup{
    font-size: 16px;
    color:#205e44;
    text-align: center;
}

.view-profile {
  font-size: 14px;
  text-align: center;
  color:#205e44;
}

.buttons {
  text-align:center;
  margin-top:10px;
  margin-bottom:20px;
}

.modal-link {
  text-decoration: underline;
}


h3 {
  margin: 10px 0 5px;
  padding: 12px 0px;
  background-color:#205e44;
  color: #fff!important;
  text-align: center;
}


</style>
