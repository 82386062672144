<template>
  <div>

    <b-carousel
      id="carousel-1"
      v-model="slide"
      :interval="7000"
      controls
      indicators
      background="#ababab"
      img-width="1024"
      img-height="680"
      style="text-shadow: 1px 1px 2px #333;"
      @sliding-start="onSlideStart"
      @sliding-end="onSlideEnd"
    >
      <!-- Text slides with image -->
      <template>
        <b-carousel-slide v-for="img in sliderPictures" :img-src="img" :key="img"></b-carousel-slide>
      </template>
    </b-carousel>
  </div>

</template>

<script>


export default {
  data() {
    return {
      slide: 0,
      sliding: null,
    };
  },
  props: ['sliderPictures'],
  methods: {
    onSlideStart() {
      this.sliding = true;
    },
    onSlideEnd() {
      this.sliding = false;
    },
  },
};
</script>

<style scoped>
</style>
