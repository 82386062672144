import { EventCategory } from './event-category.js';

export const EventCategoryOptions = [
  { text: 'All events', value: '' },
  {
    text: 'Main events',
    value: 'main',
    eventTypes: [
      { text: 'Academic Days', value: 11 },
      { text: 'Lean Six Sigma', value: 15 },
      { text: 'Vision', value: 5 },
      { text: 'OtherAcademic', value: 18 },
      { text: 'TIMES', value: 4 },
      { text: 'Business Booster', value: 14 },
      { text: 'OtherCareer', value: 19 },
      { text: 'Europe3D', value: 1 },
      { text: 'Activity Week', value: 10 },
      { text: 'Language Programme', value: 13 },
      { text: 'OtherCultural', value: 17 },
      { text: 'Europe3D', value: 1 },
      { text: 'Activity Week', value: 10 },
      { text: 'Braintrainer', value: 2 },
      { text: 'Summer Academy', value: 3 },
      { text: 'OtherPersonal', value: 20 },
      { text: 'Council Meeting', value: 8 },
      { text: 'Be X For X Days', value: 23 },
    ],
  },
  {
    text: 'Academic development',
    value: EventCategory.ACADEMIC,
    eventTypes: [
      { text: 'Academic Days', value: 11 },
      { text: 'Lean Six Sigma', value: 15 },
      { text: 'Vision', value: 5 },
      { text: 'OtherAcademic', value: 18 },
    ],
  },
  {
    text: 'Career development',
    value: EventCategory.CAREER,
    eventTypes: [
      { text: 'TIMES', value: 4 },
      { text: 'Business Booster', value: 14 },
      { text: 'OtherCareer', value: 19 },
      { text: 'Be X For X Days', value: 23 },
    ],
  },
  {
    text: 'Cultural development',
    value: EventCategory.CULTURAL,
    eventTypes: [
      { text: 'Europe3D', value: 1 },
      { text: 'Activity Week', value: 10 },
      { text: 'Language Programme', value: 13 },
      { text: 'OtherCultural', value: 17 },

    ],
  },
  {
    text: 'Personal development',
    value: EventCategory.PERSONAL,
    eventTypes: [
      { text: 'Braintrainer', value: 2 },
      { text: 'Summer Academy', value: 3 },
      { text: 'OtherPersonal', value: 20 },
    ],
  },
  {
    text: 'Local Group exchange',
    value: EventCategory.EXCHANGE,
    eventTypes: [
      { text: 'Exchange', value: 9 },
    ],
  },
  {
    text: 'Central ESTIEM',
    value: EventCategory.CENTRAL,
    eventTypes: [
      { text: 'Central ESTIEM Events', value: 7 },
      { text: 'Council Meeting', value: 8 },
    ],
  },
  {
    text: 'Alumni',
    value: EventCategory.ALUMNI,
    eventTypes: [
      { text: 'Alumni', value: 12 },
      { text: 'Alumni', value: 16 },
      { text: 'Alumni', value: 21 },
      { text: 'Alumni', value: 22 }],
  },
];
