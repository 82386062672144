export const HOST = import.meta.env.VITE_HOST;

export const MSAL_CONFIG = {
  auth: {
    clientId: import.meta.env.VITE_MSAL_CLIENT_ID,
    authority: import.meta.env.VITE_MSAL_AUTHORITY,
    redirectUri: import.meta.env.VITE_MSAL_REDIRECT_URI,
    postLogoutRedirectUri: import.meta.env.VITE_MSAL_POST_LOGOUT_REDIRECT_URI,
  },
};

export const ROLE_IDS = {
  ADMINS: import.meta.env.VITE_ROLE_ADMINS,
  LOCAL_RESPONSIBLES: import.meta.env.VITE_ROLE_LOCAL_RESPONSIBLES,
  BOARD_ALUMNI: import.meta.env.VITE_ROLE_BOARD_ALUMNI,
};

export const API_PERMISSION = import.meta.env.VITE_API_PERMISSION;
