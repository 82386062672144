export const memberStatus = {
  0: 'Disabled',
  1: 'None',
  2: 'Member',
  4: 'Deleted',
  7: 'All',
};
export const alumniStatus = {
  1: 'None',
  2: 'Member',
  4: 'Applicant',
  8: 'UnConfirmed',
  15: 'All',
};
export const statusAlumniFilter = {
  1: '[All]',
  2: 'Members (Junior and normal)',
  3: 'Junior Alumni',
  4: 'Regular Alumni',
  5: 'Applicants (incl. unconfirmed and junior)',
  6: 'Unconfirmed Applicants',
};
