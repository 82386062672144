<template>
  <nav class="navbar-footer">
    <aside id="menuAside" v-show="this.menuVisible">
      <menu>
        <li v-if="!authenticated" v-b-modal.login>
          <i class="material-icons" style="vertical-align:middle">input</i>
          <span>Sign in</span>
        </li>
        <li v-if="!authenticated">
          <RouterLink to="/sign-up">
            <i class="material-icons" style="vertical-align:middle">add_circle_outline</i>
            <span>Sign Up</span>
          </RouterLink>
        </li>
        <li v-if="authenticated && !authenticatedSSO" @click="logout">
          <i class="material-icons" style="vertical-align:middle">input</i>
          <span>Sign out</span>
        </li>
        <li v-if="authenticatedSSO" @click="logoutSSO">
          <i class="material-icons" style="vertical-align:middle">input</i>
          <span>Sign out from SSO</span>
        </li>
        <li v-if="alumnus">
          <a href="https://old.estiem.org/Alumni/Default.aspx?pageId=481" target="_blank">
            <i class="material-icons" style="vertical-align:middle">launch</i>
            <span>Old Alumni Portal</span>
          </a>
        </li>
        <li>
          <a href="https://old.estiem.org/default.aspx?pageId=508" target="_blank">
            <i class="material-icons" style="vertical-align:middle">launch</i>
            <span>Old Portal</span>
          </a>
        </li>
        <li v-if="authenticated">
          <a href="https://internal.estiem.org/lxix-council-meeting/" target="_blank">
            <i class="material-icons" style="vertical-align:middle">event</i>
            <span>Council Meeting</span>
          </a>
        </li>
        <li>
          <a href="https://internal.estiem.org/career-center-overview/partners/" target="_blank">
            <i class="material-icons" style="vertical-align:middle">business</i>
            <span>Partners</span>
          </a>
        </li>
        <li @click="toggleTools">
          <i class="material-icons" style="vertical-align:middle">supervisor_account</i>
          <span>Tools</span>
          <i class="material-icons" style="vertical-align:middle;">arrow_drop_down</i>
        </li>
        <ul v-if="this.toolsMenuOpened">
          <li v-if="(localResponsible || admin)">
            <RouterLink to="/sign-up-list">
              <span>Applicants List</span>
            </RouterLink>
          </li>
          <li>
            <a href="https://internal.estiem.org/opencalls/" target="_blank">
              <span>Join Central ESTIEM</span>
            </a>
          </li>
          <li>
            <a href="https://drive.google.com/drive/folders/0AGqnWG0MGFdUUk9PVA" target="_blank">
              <span>Members Drive</span>
            </a>
          </li>
          <li>
            <a target="_blank" href="https://estiem.elium.com/">
              <span>Elium</span>
            </a>
          </li>
          <li>
            <a href="https://internal.estiem.org/career-center-overview/" target="_blank">
              <span>Career Center</span>
            </a>
          </li>
        </ul>
        <li @click="toggleShop">
          <i class="material-icons" style="vertical-align:middle">shopping_cart</i>
          <span>Shop</span>
          <i class="material-icons" style="vertical-align:middle;">arrow_drop_down</i>
        </li>
        <ul v-if="this.shopMenuOpened">
          <li>
            <a href="https://shop.spreadshirt.nl/estiem" target="_blank">
              <span>ESTIEM Shop</span>
            </a>
          </li>
          <li>
            <a href="https://internal.estiem.org/magazine-shop/" target="_blank">
              <span>ESTIEM Magazine Shop</span>
            </a>
          </li>
        </ul>
        <li @click="toggleAbout">
          <i class="material-icons" style="vertical-align:middle">info</i>
          <span>About</span>
          <i class="material-icons" style="vertical-align:middle;">arrow_drop_down</i>
        </li>
        <ul v-if="this.aboutMenuOpened">
          <li>
            <RouterLink to="/about">
              <span>About ESTIEM</span>
            </RouterLink>
          </li>
          <li>
            <RouterLink to="/structure">
              <span>ESTIEM Structure</span>
            </RouterLink>
          </li>
          <li>
            <RouterLink to="/localgroups">
              <span>Local Groups</span>
            </RouterLink>
          </li>
          <li v-if="authenticated">
            <RouterLink to="/active">
              <span>Active ESTIEM</span>
            </RouterLink>
          </li>
          <li v-if="authenticated">
            <RouterLink to="/groups">
              <span>ESTIEM Groups</span>
            </RouterLink>
          </li>
        </ul>
      </menu>
    </aside>
    <menu>
      <li>
        <RouterLink to="/">
          <i class="material-icons" style="vertical-align:middle">home</i>
          <span>Home</span>
        </RouterLink>
      </li>
      <li>
        <RouterLink to="/events">
          <i class="material-icons" style="vertical-align:middle">event</i>
          <span>Events</span>
        </RouterLink>
      </li>
      <li v-if="!authenticated" @click="loginSSO">
        <font-awesome-icon :icon="['fab', 'microsoft']" />
        <span>SSO Login</span>
      </li>
      <li v-if="authenticated">
        <RouterLink :to="'/profile/' + userProfile">
          <i class="material-icons" style="vertical-align:middle">person</i>
          <span>My Profile</span>
        </RouterLink>
      </li>
      <li @click="toogleMenu">
        <font-awesome-icon size="xl" :icon="['fas', 'bars']" />
      </li>
    </menu>
  </nav>
</template>

<script>
import auth from '../auth/index.js';
import { RouterLink } from 'vue-router';
import { size } from 'underscore';

export default {
  data() {
    return {
      aboutMenuOpened: false,
      shopMenuOpened: false,
      toolsMenuOpened: false,
      menuVisible: false,
      clicked: false,
    };
  },
  computed: {
    authenticated() {
      return this.$store.getters.authenticated;
    },
    authenticatedSSO() {
      return this.$store.getters.authenticatedSSO;
    },
    alumnus() {
      return this.$store.getters.userroles.includes('Alumni') || this.$route.name === 'alumni';
    },
    userName() {
      return localStorage.getItem('userName');
    },
    userProfile() {
      return encodeURIComponent(this.userName);
    },
    localResponsible() {
      return this.$store.getters.isLR;
    },
    admin() {
      return this.$store.getters.isAdmin;
    },
  },
  methods: {
    logout() {
      auth.logout();
    },
    loginSSO() {
      auth.loginSSO();
    },
    logoutSSO() {
      auth.logoutSSO();
    },
    toggleAbout() {
      this.aboutMenuOpened = !this.aboutMenuOpened;
    },
    toggleShop() {
      this.shopMenuOpened = !this.shopMenuOpened;
    },
    toggleTools() {
      this.toolsMenuOpened = !this.toolsMenuOpened;
    },
    hideMenu() {
      this.menuVisible = false;
    },
    toogleMenu() {
      this.menuVisible = !this.menuVisible;
      if (this.menuVisible)
        this.clicked = true;
      else
        this.clicked = false;
    },
    checkCollapseMenuVisibilty(e) {
      if (this.clicked) {
        this.clicked = false;
        document.stopPropagation();
      } else if(!document.getElementById('menuAside').contains(e.target))
        this.hideMenu();
    },
  },
  mounted() {
    document.addEventListener('click', this.checkCollapseMenuVisibilty);
    document.addEventListener('scroll', this.checkCollapseMenuVisibilty);
  },
};
</script>

<style  scoped>
ul, li, menu {
  margin: 0;
  padding: 0;
}
.navbar-footer {
  position: fixed;
  bottom: 0;
  z-index: 9999;
  background-color: var(--estiem-green);
  color: rgba(255,255,255,0.75);
  height: fit-content;
  width: 100%;
  padding-block: 0.5rem;
}

nav > menu {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  height: fit-content;
}


aside > menu, li, nav > menu > li > a {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

aside > menu {
  row-gap: 0.2rem;
}
aside > menu > li {
  display: flex;
  flex-direction: row;
  column-gap: 0.5rem;
  align-items: center;
  justify-content: center;
  width: 100%;
}

li:hover {
  background-color: var(--green-accent-1);
  cursor: pointer;
}

li > a {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 0.5rem;
}

aside {
  background-color: var(--estiem-green);
  width: 100%;
  margin-bottom: 1rem;
}

a {
  color: inherit;
  text-decoration: none;
}

a:hover {
  color: inherit;
  text-decoration: none;
}

</style>
