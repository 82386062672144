/* NOTE
  Full list of attributes:
  https://help.tableau.com/current/api/embedding_api/en-us/reference/interfaces/vizsettings.html
  ^ These work for sure
  https://help.tableau.com/current/api/embedding_api/en-us/reference/interfaces/viz.html
  ^ These work _probably_ too :D, idk which ones are actually used

  Attributes are for <tableau-viz> elements -- some, e.g. id are universal, but most are specific to the Tableau API

  Filters are for <viz-filter> elements -- these are children of <tableau-viz> elements and are created dynamically
  When working with filters make sure to follow the documentation,
  https://help.tableau.com/current/api/embedding_api/en-us/reference/interfaces/filter.html
  and consider different filter types
  https://help.tableau.com/current/api/embedding_api/en-us/reference/enums/tableau.filtertype.html

  DO NOTE:!!!
  For the 'categorical' filters to work dynamically / contextually,
  NO EMPTY ARRAY must be passed as the 'values' property in the filter object in this file!!!

  Metadata is for the dashboard itself -- this is not used by the Tableau API, but can be used to enhance the display
 */
export const usersCreatedSince2017DashboardData = {
  attributes: {
    id: 'viz-users-created-since-2017',
    src: 'https://eu-west-1a.online.tableau.com/t/estiemanalysis/views/UsersCreatedSince2017/2017-2022Jan-Dec3',
    width: '1280px',
    height: '760px',
    hideTabs: true,
    toolbar: 'bottom',
  },
  filters: [],
  metadata: {
    title: 'Users created since 2017',
    description: 'Users created since 2017',
  },
};


export const wipAzureCostAnalysisDashboardData = {
  attributes: {
    id: 'viz-wip-azure-cost-analysis',
    src: 'https://eu-west-1a.online.tableau.com/#/site/estiemanalysis/views/WIPAzureCostAnalysis/Dashboard1',
    toolbar: 'bottom',
    width: '100%',
  },
  filters: [],
  metadata: {
    title: 'WIP Azure Cost Analysis',
    description: 'WIP Azure Cost Analysis',
  },
};


export const eventSelectionCriteriaDashboardData = {
  attributes: { // https://help.tableau.com/current/api/embedding_api/en-us/docs/embedding_api_configure.html#table-of-properties-and-values-for-embedded-objects-and-components
    id: 'viz-event-selection-criteria-dashboard',
    src: 'https://eu-west-1a.online.tableau.com/#/site/estiemanalysis/views/EventSelectionCriteria_1_4/EventSelectionDashboard',
    height: '800px',
    width: '100%',
    toolbar: 'hidden',
    device: 'desktop',
  },
  filters: [
    // These are applied contextually in tableau-helper-functions.js
    // { filterType: 'categorical', fieldName: 'UserId', values: [] },
    // { filterType: 'categorical', fieldName: 'EventId', values: [] },
  ],
  metadata: {
    title: 'Event Selection Criteria',
    description: '',
  },
};


export const eventFeedbackReportDashboardData = {
  attributes: {
    id: 'viz-event-feedback-report-dashboard',
    src: 'https://eu-west-1a.online.tableau.com/#/site/estiemanalysis/views/EventFeedbackReport2024Tiled/EventFeedbackReportTiled',
    height: '800px', // Note, this doesn't work as an attribute but in tableau-embed.vue there's a check if it's not specified here
    width: '100%',
    toolbar: 'hidden',
    device: 'desktop', // This dashboard has a specified "desktop" layout which works well for the embed if not mobile. It was configured during the creation of the dashboard in Tableau.
  },
  filters: [
    // { filterType: 'categorical', fieldName: 'Event Id', values: [0] },
  ],
  metadata: {  // As of 06 FEB 2025, both Hannes the VP Activities and Tom Louis the AC leader wanted to leave this empty.
    title: '',
    description: '',
  },
};


export const superstoreEmbedded800x800 = {
  attributes: {
    id: 'viz-superstore-test',
    src: 'https://public.tableau.com/views/DeveloperSuperstore/Overview',
    toolbar: 'bottom',
    width: '800px',
    height: '800px',
  },
  filters: [],
  metadata: {
    title: 'Superstore Embedded 800x800',
    description: 'This is a generic test / example dashboard by Tableau. Here its use is convenient for testing a non-ESTIEM dashboard.',
  },
};
