<template>
  <div id="app">
    <navbar/>
    <div id="container">
      <router-view/>
    </div>
    <desktop-footer v-show="width>1119"/>
    <vfooter v-show="width<=1119"/>
  </div>
</template>

<script>
import navbar from './layout/navbar.vue';
import vfooter from './layout/footer.vue';
import desktopFooter from './layout/desktop-footer.vue';

export default {
  data() {
    return {
      width: window.innerWidth,
    };
  },
  methods: {
    onResize() {
      this.width = window.innerWidth;
    },
  },
  created() {
    window.addEventListener('resize', this.onResize);
    const params = {
      eventtypes: 'all',
    };
    const authToken = localStorage.getItem('token');
    const isSSO = localStorage.getItem('type') === 'sso';
    this.$store.dispatch('fetchEvents', params);
    this.$store.dispatch('fetchAlumniEvents', params);

    if (authToken) {
      this.$store.dispatch('login', { isSSO });
    }
  },
  destroyed() {
    window.removeEventListener('resize', this.onResize);
  },
  components: {
    navbar,
    vfooter,
    desktopFooter,
  },
};
</script>

<style>
/*
ESTIEM Colors
PRC color specification: https://docs.google.com/spreadsheets/d/1o3WCWgu9AzLJ2TAxKy8uP5lszkgsk9ZqGbj4AFLhBq0/edit#gid=0
*/
/*
For bootstrap navbar. Keep here just in case
https://work.smarchal.com/twbscolor/css/e74c3cc0392becf0f1ffbbbc0
*/
:root {
  --estiem-green : rgb(32, 94, 68);
  --green-primary : rgb(32, 94, 68);
  --green-accent-1 : rgb(80, 137, 113);
  --green-light : rgb(105, 165, 142);
  --green-accent-2 : rgb(165, 201, 187);
  --green-silver : rgb(165, 175, 171);
  --blue-primary : rgb(12, 43, 94);
  --blue-accent : rgb(157, 171, 206); /*in the PRC doc it is Dark Blue Accent III */
  --orange-primary : rgb(247, 143, 30);
  --orange-accent : rgb(248, 179, 105); /*Orange Accent II*/
  --babyblue-primary : rgb(52, 145, 189);
  --babyblue-accent : rgb(157, 198, 217); /*Baby Blue Accent III*/
  --red-primary : rgb(118, 18, 20);
  --red-accent : rgb(178, 122, 123); /*Red Accent III*/
  --gray-primary : rgb(128, 130, 133);
  --gray-20 : rgb(209, 213, 214);
  --gray-10 : rgb(232, 234, 234);
  --dropdown-background: rgba(255,255,255,0.0);
}

#container {
  padding-bottom: 50px; /* Example height of footer */
  width: 100%;
  height: auto; /* Allow content to determine the height */
  box-sizing: border-box;
}
</style>
