<template>
    <div>
        <eventHeader :event="event" :participant="participant"></eventHeader>
        <!-- Kinda hacky, but add and edit permissions are the same -->
        <!-- v-if="eventSchedule && eventSchedule[0].allowedToEdit" -->
        <div class="container-narrow">
            <div style= "margin: auto; text-align: center">
                <h1 v-if="!eventSchedule && !scheduleLoaded" >Loading Agenda. Please wait. </h1>
                <br>
                <h2 v-if="!eventSchedule && !scheduleLoaded">If it is taking more than a few seconds, try reloading the page. </h2>
                <h1 v-if="!eventSchedule && !isOrganiser && scheduleLoaded">No agenda has been added for this event yet. Please contact the organisers to have an agenda added.</h1>
                <h1 v-if="!eventSchedule && isOrganiser && scheduleLoaded">No agenda has been added for this event yet. You can add agenda points by clicking the button below.</h1>
                <!--
                    this needs a change in the backend, because currently only the organisers can add items
                    <b-btn v-if="isOrganiser || isAdmin" @click="addActivity" style="margin: auto">Add Agenda Item</b-btn>
                 -->
                <b-btn v-if="isOrganiser" @click="addActivity" style="margin: auto">Add Agenda Item</b-btn>
                <h4 v-if="isOrganiser" >You can use Makdown in the description of agenda items for text formating, inserting links, tables, and images.</h4>
                <!--
                <a target="_blank" href="https://github.com/adam-p/markdown-here/wiki/Markdown-Cheatsheet"><h4 v-if="isOrganiser || isAdmin" >Click here to see how to use Markdown </h4></a>
                <a target="_blank" href="https://www.tablesgenerator.com/markdown_tables"><h4 v-if="isOrganiser || isAdmin" >Click here to convert excel/csv sheets to Markdown </h4></a>
                -->
                <a target="_blank" href="https://github.com/adam-p/markdown-here/wiki/Markdown-Cheatsheet"><h4 v-if="isOrganiser" >Click here to see how to use Markdown </h4></a>
                <a target="_blank" href="https://www.tablesgenerator.com/markdown_tables"><h4 v-if="isOrganiser" >Click here to convert excel/csv sheets to Markdown </h4></a>
            </div>
            <event-schedule-activity
                v-if="toBeAdded"
                :eventScheduleProp="toBeAdded"
                @postEvent="postEvent($event)"
            ></event-schedule-activity>
            <template v-if="eventSchedule">
                <div v-for="(schedule, index) in eventSchedule" :key="schedule.startTime + schedule.title">
                    <div class="day" v-if="index === 0 || eventSchedule[index-1].startTime.substring(0, 10) !== schedule.startTime.substring(0, 10) ">
                        {{schedule.startTime | formatDateFromStringLong}}
                    </div>
                    <event-schedule-activity
                        :eventScheduleProp="schedule"
                        :id="schedule.now && 'now'"
                        @putEvent="handleputEvent($event)"
                        :isOrganiser="isOrganiser || isAdmin"
                    ></event-schedule-activity>
                </div>
            </template>
            <div style= "text-align: center; margin-top: 50px" v-else>
                <h1 style="margin: auto"> No Agenda has been uploaded for this event </h1>
                <h2 style="margin: auto">Feel free to contact this event's organisers to ask them to add items to the agenda</h2>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
import moment from 'moment';
import Vue from 'vue';
import eventScheduleActivity from './event-schedule-activity.vue';
import eventHeader from './event-header.vue';


export default {
  data() {
    return {
      eventSchedule: undefined,
      toBeAdded: undefined,
      scheduleLoaded: false,
    };
  },
  props: {
    isOrganiser: {
      default: false,
    },
    event: {

    },
  },
  created() {
    const eventId = this.$route.params.id;
    axios.get(`/eventschedule/${eventId}`)
      .then((data) => {
        this.eventSchedule = data.data;
        let first = false;
        const now = moment();
        this.eventSchedule.forEach((e) => {
          if (moment(e.startTime).isSameOrAfter(now)) {
            if (!first) {
              e.now = true;
              first = true;
            }
          }
          this.scheduleLoaded = true;
        });
        Vue.nextTick(this.goToCurrentDate);
      }).catch((error) => {
        if (error.response) {
          this.scheduleLoaded = true;
        }
      });
  },
  methods: {
    addActivity() {
      this.toBeAdded = {
        eventId: this.eventId,
        title: '',
        description: '',
        startTime: '',
        endTime: '',
      };
    },
    goToCurrentDate() {
      const objControl = document.getElementById('now');
      objControl.scrollIntoView(true);
      window.scrollBy(0, -200);
    },
    postEvent(event) {
      this.toBeAdded = undefined;
      this.eventSchedule.push(event);
    },
    handleputEvent(event) {
      const index = this.eventSchedule.findIndex(es => es.Id === event.Id);
      Vue.set(this.eventSchedule, index, event);
    },
  },
  computed: {
    eventId() {
      return Number(this.$route.params.id);
    },
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
  },
  components: {
    eventScheduleActivity,
    eventHeader,
  },
};
</script>

<style scoped>
.container-narrow {
    max-width: 700px;
    margin: auto;
}

.day {
    margin: 20px;
}
</style>
