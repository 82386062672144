<template>
  <div
    class="card"
    style="flex-basis:37%; flex-grow: 0; margin: 5px 20px 5px 20px;"
  >
    <!-- ToDo: check if this component is even used anywhere - not sure if theis componenet works like this, but I owuld suggest just using search/estiemer-card instead
    <router-link class="card-link" :to="'/profile/' + estiemer.userFirstNameEnglish + '%20' + estiemer.userLastNameEnglish" style="display: flex; ">
    -->
    <router-link
      class="card-link"
      :to="'/profile/' + userName"
      style="display: flex; "
    >
      <div class="card-block" style="order:1; flex-shrink: 2; width:110px;">
        <!-- <img v-if="estiemer.userThumbNail==!null" style="margin: auto; height:80px;
 display: inline-block;" src="https://m.popkey.co/987552/NGLb3.gif" /> -->
        <img
          style="margin: auto; max-width: 80px; max-height: 160px; display: inline-block;"
          :src="
            !!estiemer.userThumbNail
              ? 'data:image/png;base64,' + estiemer.userThumbNail
              : 'https://old.estiem.org/Userimage.aspx?id=' +
                estiemer.userId +
                '&thumb=true'
          "
        />
      </div>
      <div
        class="card-block"
        style="padding: 10px 10px 10px 20px; order:2; flex-grow: 2"
      >
        <h3>{{ estiemer.firstNameEnglish }} {{ estiemer.lastNameEnglish }}</h3>
        <i class="material-icons md-dark" style="vertical-align:middle"
          >location_city</i
        >
        {{ estiemer.userLocalGroupName }}
        <i class="material-icons md-dark" style="vertical-align:middle"
          >history</i
        >
        {{ estiemer.studiesStart }}
        <i class="material-icons md-dark" style="vertical-align:middle"
          >tag_faces</i
        >
        {{ estiemer.interests }}
        <i
          class="material-icons md-dark"
          style="vertical-align:middle"
          v-if="estiemer.alumniStatus == 1"
          >account_circle</i
        >
        <i
          class="material-icons md-dark"
          style="vertical-align:middle"
          v-if="estiemer.alumniStatus == 2"
          >supervisor_account</i
        >
      </div>
    </router-link>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  data() {
    return {
      isSelected: false,
    };
  },
  filters: {
    formatdate(value) {
      if (value) {
        return moment(value).format('DD.MM.YYYY');
      }
      return '';
    },
  },
  props: {
    estiemer: {},
  },
  computed: {
    userName() {
      return this.estiemer.portalUserUserName.replace(' ', '%20');
    },
  },
};
</script>

<style scoped>
.material-icons.md-dark {
  color: rgba(32, 94, 68, 1);
}
.material-icons.md-dark.md-inactive {
  color: rgba(32, 94, 68, 0.5);
}
</style>
