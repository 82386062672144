<template>
  <div>
    <b-card-group deck v-if="!isList">
      <template>
        <event v-for="event in events" :event="event" v-if="!event.isSelected" :key="event.id" user-on-map="true" />
      </template>
    </b-card-group>
    <template>
      <list-event v-for="event in events" :event="event" v-if="!event.isSelected && isList" :key="event.id" user-on-map="true" />
    </template>
  </div>
</template>

<script>
import event from './event.vue';
import listEvent from './list-event.vue';

export default {
  data() {
    return {};
  },
  props: {
    events: {},
    isList: {
    },
    count: {
      default: 100,
    },
    applicationOpen: {
      default: false,
    },
    /* what is this */
    exchanges: {
      default: false,
    },
    evTypes: {
      default: () => [],
    },
    evCategory: {
      default: '',
    },
    earliestDate: {
      default: '',
    },
    latestDate: {
      default: '',
    },
  },
  components: {
    event,
    listEvent,

  },
  methods: {

  },
};
</script>

<style scoped>

.card-deck {
    width: 90%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin: 1% 0px 0px 5%;
    /*align-content: center;*/
    overflow: auto;
    resize:both;
}

</style>
