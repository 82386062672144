<template>
  <div>
    <eventHeader :event="event" :participant="participant"></eventHeader>
    <!-- After login should go straight back to the same page, not to home page-->
    <div id="event-apply" class="card">
      <div class="card-block" v-if="!isCm">
        <div v-if="getApplicationOpen() != 'open' && !isParticipant">
          <p>You can not currently apply for this event.</p>
          <p v-if="event.applicationStartDate && event.applicationEndDate">
          The application period {{ getApplicationOpen() }} from {{ event.applicationStartDate | formatdateEv }} to {{ event.applicationEndDate | formatdateEv }}
          </p>
        </div>
        <div v-else>
        <h3>Motivation text</h3>
          <p>
            <ol>
              <li v-for="question in generalQuestionsForEvents" :key="question">
                {{question}}
              </li>
                <li v-for="question in getSpecificQuestionByCategory" :key="question">
                  {{question}}
            </li>
            </ol>
          </p>
        <b-form-group class="appForm">
          <b-form-textarea id="editor" v-model="form.motivationText" rows="10" maxlength="550"></b-form-textarea>
          <div class="input-group-addon" v-text="(550 - form.motivationText.length) + ' characters left'"></div>
        </b-form-group>
          <center>
            <!--User is applying for the first time-->
            <b-btn v-if="authenticated && !isParticipant && !isCancelled" @click.once="postApplication" variant="success">Apply for this event</b-btn>
            <!--User is reapplying-->
            <b-btn v-else-if="authenticated && isCancelled" @click.once="changeRegistrationStatusToWaiting" variant="success">Re-apply for this event</b-btn>
            <!--User already applied-->
            <div v-else-if="authenticated && isParticipant && [0,1].includes(participant.registrationStatus)">
              <b-btn @click.once="updateApplication" variant="success">Update application</b-btn>
              <b-btn @click.once="cancelApplication" variant="danger">Withdraw from event</b-btn>
            </div>
            <b-btn  v-else disabled variant="success">Can not apply</b-btn>
            <!-- Not authenticated-->
          </center>
          <br>
          <p><em> Please note that by submitting the application for this event you are agreeing to following conditions:
            <ol>
              <li>Withdrawal of the participation after the cancellation deadline (<b>{{ event.retireDeadline | formatdateEv }} </b>) will require paying either all or part of the participation fee set by the organisers of the event</li>
              <li>In case you can’t participate in the event, you have to inform organisers about it.</li>
              <li>The transportation to and from the event is not provided by the organising Local Group.</li>
            </ol>
          </em></p>
        </div>
      </div>
      <div v-else class="card-block">
            <p><em> If you want to visit a Council Meeting you can get registered via the following ways:<br>
            <ol>
              <li>Contact your LR if you like to become delegate for your Local Group</li>
              <li>Write to <a href="mailto:cmcontact@estiem.org">cmcontact@estiem.org</a> to to find out how you can attend the Council Meeting by other means</li>
            </ol>
            <br>
              If you are a Local Responsible and want to add your delegates, please go to the participants tab and click on 'Add participants'
          </em></p>
        </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import axios from 'axios';
import eventHeader from './event-header.vue';
import { RegistrationStatus } from '../../helpers/registration-status.js';
import { EventTypeOptions } from '../../helpers/event-type-options.js'
import { EventQuestions } from '../../helpers/event-questions.js'

export default {
  data() {
    return {
      config: {
        toolbar: [['Bold', 'Italic', 'Underline', 'Strike']],
        height: 300,
      },
      form: {
        eventId: Number(this.$route.params.id),
        motivationText: '',
      },
      terms: true, // this needs to be set to false as soon as there are terma and
      ev: {},
      eventParticipants: [], // to check if user already applied for the event
      applicationData: {},
      evTypeOptions: [...EventTypeOptions],
      generalQuestionsForEvents: [...EventQuestions.generalQuestionsForEvents],
      specificQuestionsForEvents: [...EventQuestions.specificQuestionsForEvents],
    };
  },
  components: {
    eventHeader,
  },
  computed: {
    authenticated() {
      return this.$store.getters.authenticated;
    },
    userId() {
      return this.$store.getters.user.userId;
    },
    isParticipant() {
      return this.participant.registrationStatus === RegistrationStatus.WAITING || this.participant.registrationStatus === RegistrationStatus.REGISTERED || this.participant.registrationStatus === RegistrationStatus.ORGANIZER;
    },
    isCancelled() {
      return (this.participant.registrationStatus === RegistrationStatus.APPLICATION_WITHDRAWN ||
      this.participant.registrationStatus === RegistrationStatus.CANCELLED ||
      this.participant.registrationStatus === RegistrationStatus.CANCELLED_AFTER_DEADLINE);
    },
    isCm() {
      return this.event.eventType === 8;
    },
    eventType() {
      return this.evTypeOptions.find(e => e.value === this.event.eventType);
    },
    eventCategoryName() {
      return this.eventType.category;
    },
    getSpecificQuestionByCategory() {
      const category = this.eventCategoryName;
      let questions = this.specificQuestionsForEvents.filter(question => question.category === category);
      questions = questions[0].specificQuestions;
      return questions;
    },
  },
  props: {
    event: {

    },
    participant: {
      default() { return {}; },
    },
  },
  filters: {
    formatdateEv(value) {
      if (value) {
        return moment(value).format('LL');
      }
      return '';
    },
  },
  methods: {
    getApplication() {
      if (this.participant) {
        this.form.motivationText = this.participant.applicationText;
      }
    },
    postApplication() {
      if (this.validatePost() === false) {
        return false;
      }
      axios
        .post(
          `/events/${this.$route.params.id}/participants`,
          { ...this.form, userId: this.userId, registrationStatus: RegistrationStatus.WAITING },
        )
        .then(() => {
          this.$router.push({ path: `/event/${this.$route.params.id}?status=applied` });
          this.$router.go(this.$router.currentRoute); // this updates the page afterwards
        })
        .catch(() => {});
      return true;
    },
    updateApplication() {
      axios
        .put(
          `/events/${this.$route.params.id}/participants/${this.userId}`,
          { ...this.form, userId: this.userId },
        )
        .then(() => {
          this.$router.push({ path: `/event/${this.$route.params.id}?status=updated` });
          this.$router.go(this.$router.currentRoute);
        })
        .catch(() => {});
      return true;
    },
    cancelApplication() {
      axios
        .put(
          `/events/${this.$route.params.id}/participants/status`,
          // update the current user's registration status to Canceled, the backend automatically checks the
          // application and cancellation deadline and sets the correct status
          [{ userId: this.userId, registrationStatus: RegistrationStatus.CANCELLED }],
        )
        .then(() => {
          this.$router.push({ path: `/event/${this.$route.params.id}?status=cancelled` });
          this.$router.go(this.$router.currentRoute); // this updates the page afterwards
        })
        .catch(() => {});
      return true;
    },
    changeRegistrationStatusToWaiting() {
      axios
        .put(
          `/events/${this.$route.params.id}/participants/status`,
          // update the current user's registration status to Waiting
          [{ userId: this.userId, registrationStatus: RegistrationStatus.WAITING }],
        )
        .then(() => {
          this.$router.push({ path: `/event/${this.$route.params.id}?status=reapplied` });
          this.$router.go(this.$router.currentRoute); // this updates the page afterwards
        })
        .catch(() => {});
      return true;
    },
    validatePost() {
      return this.terms;
    },
    getApplicationOpen() {
      const now = moment();
      //  const id = this.$route.params.id;
      if (!this.event.applicationEndDate || !this.event.applicationStartDate) { return 'not set'; }
      if (now.isSameOrBefore(this.event.applicationEndDate) && now.isSameOrAfter(this.event.applicationStartDate)) { return 'open'; }
      if (now.isAfter(this.event.applicationEndDate)) { return 'was'; }
      if (now.isBefore(this.event.applicationStartDate)) { return 'will be'; }
      return null;
    },
  },
  mounted() {
    this.getApplication();
    this.ready = true;
    const id = this.$route.params.id;
    this.ev = this.$store.getters.event(id);
  },
};
</script>


<style scoped>


.appTitle {
  padding: 5px;
}

.appForm {
  padding: 5px;
}

#event-apply {
  max-width: 800px;
  margin: auto;
}

.card {
  margin: auto;
  padding: 10px;
}

.checkbox {
  margin: 0;
}

#editor {
  padding: 5px;
}

h2 {
  padding-top: 10px;
  text-align: center;
}

.termstext {
  color: rgb(80, 137, 113);
  font-style: italic;
}

button {
  margin-bottom: 10px;
}
</style>
